import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalTitle } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { obtenerProfileAction } from "./../../actions/profileActions";
import { cerrarSesionAction } from "../../actions/authActions";

import menuIcon from "../../assets/images/menu.svg";
import closeIcon from "../../assets/images/close.svg";
import CambiarPassword from "./CambiarPassword";

const Header = ({ title }) => {
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const menus = useSelector((state) => state.profile.menus);
   const user = useSelector((state) => state.auth.user);
   const [showBackground, setShowBackground] = useState(false);
   const [showModal, setShowModal] = useState(false);

   const handleCloseModal = () => {
      setShowModal(false);
   };

   const handleOpenModal = () => {
      setShowModal(true);
   };

   useEffect(() => {
      dispatch(obtenerProfileAction(user.role));

      const overlay = document.getElementById("overlay");

      document
         .querySelector(".hamburMobile")
         .addEventListener("click", function () {
            document.querySelector(".menuMobile").classList.add("leftMargin1");
            document
               .querySelector(".menuMobile")
               .classList.remove("leftMargin2");
            overlay.classList.add("overlayUp");
            setShowBackground(true);
         });
      document
         .querySelector(".closeMenu")
         .addEventListener("click", function () {
            document.querySelector(".menuMobile").classList.add("leftMargin2");
            document
               .querySelector(".menuMobile")
               .classList.remove("leftMargin1");
            overlay.classList.remove("overlayUp");
            setShowBackground(false);
         });

      overlay.addEventListener("click", function () {
         document.querySelector(".menuMobile").classList.add("leftMargin2");
         document.querySelector(".menuMobile").classList.remove("leftMargin1");
         overlay.classList.remove("overlayUp");
         setShowBackground(false);
      });
   }, []);

   const cerrarSesion = () => {
      dispatch(cerrarSesionAction());

      navigate("/");
   };

   return (
      <Fragment>
         <div className="row bg-grayLigth2 d-flex align-items-center pt-3 pb-3 pb-lg-0 pt-lg-0">
            <button
               type="button"
               data-toggle="modal"
               data-target="#errorUsuario"
            >
               <img
                  src={menuIcon}
                  alt=""
                  className="mr-2 iconMenu hamburMobile"
               />
            </button>
            <div className="col-5 pl-5 ml-3">
               <h1 id="logo" className="titulo p-0 m-0">
                  <strong><Link to="/home">SOLIDEZ EMPRESARIAL</Link></strong>
               </h1>
               <p className="m-0 subtitulo">{title}</p>
            </div>
            <div className="col-6 col-md-6 text-right p-2 p-md-3">
               <div className="w-100">
                  <p className="name-user mb-0">
                     Bienvenido,{" "}
                     <span className="name-admin">
                        <strong>{user.personal.name} {user.personal.lastName}</strong>
                     </span>
                  </p>
                  <button className="c-link-cerrar" style={{ cursor: "pointer" }}
                              onClick={handleOpenModal}>
                  Cambiar contraseña
                  </button>
                  {' - '}
                  <button className="c-link-cerrar" style={{ cursor: "pointer" }}
                              onClick={() => {
                                 cerrarSesion();
                              }}>
                  Cerrar sesión
                  </button>
               </div>
            </div>
         </div>

         <div className="menuMobile">
            <div className="closeMenu">
               <img width="24" height="24" src={closeIcon} alt="" />
            </div>
            <nav>
               <ul>
                  <li className="mt-5 titleMenu">Administración</li>
                  {menus &&
                     menus.map((menu) =>
                        menu.selected && menu.module === "Administración" ? (
                           <li key={menu.id}><Link
                              to={menu.url}
                              className=""
                           >
                              {menu.description}
                           </Link></li>
                        ) : null
                     )}
                  <hr />
                  <li className="titleMenu">RRHH</li>
                  <li>
                  {menus &&
                     menus.map((menu) =>
                        menu.selected && menu.module === "RRHH" ? (
                           <li key={menu.id}><Link
                              to={menu.url}
                              className=""
                           >
                              {menu.description}
                           </Link></li>
                        ) : null
                     )}
                  </li>
                  <hr />
                  <li className="titleMenu">Otros</li>
                  {menus &&
                     menus.map((menu) =>
                        menu.selected && menu.module === "Otros" ? (
                           <li key={menu.id}><Link
                              to={menu.url}
                              className=""
                           >
                              {menu.description}
                           </Link></li>
                        ) : null
                     )}
                  <hr />
                  <li className="titleMenu">Reportes</li>
                  {menus &&
                     menus.map((menu) =>
                        menu.selected && menu.module === "Reportes" ? (
                           <li key={menu.id}><Link
                              to={menu.url}
                              className=""
                           >
                              {menu.description}
                           </Link></li>
                        ) : null
                     )}
               </ul>
            </nav>
         </div>
         <div
            id="overlay"
            style={{ visibility: showBackground ? "visible" : "hidden" }}
         ></div>

<Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
               <ModalTitle>
                  <h3 className="titulo text-center p-3">
                     Cambiar contraseña
                  </h3>
               </ModalTitle>
            </Modal.Header>
            <CambiarPassword handleCloseModal={handleCloseModal} />
         </Modal>
      </Fragment>
   );
};

export default Header;
