import {
   CITY_DESCARGA_COMENZAR,
   CITY_DESCARGA_EXITO,
   CITY_DESCARGA_ERROR,
   DISTRICT_DESCARGA_COMENZAR,
   DISTRICT_DESCARGA_EXITO,
   DISTRICT_DESCARGA_ERROR
} from '../types';

const initialState = {
   cities: [],
   districts: [],
   loading: null
};

export default function(state = initialState, action) {
   switch(action.type) {
      case CITY_DESCARGA_COMENZAR:
         return {
            ...state,
            cities: [],
            loading: true
         }
      case CITY_DESCARGA_EXITO:
         return {
            ...state,
            cities: action.payload,
            loading: false
         }
      case CITY_DESCARGA_ERROR:
         return {
            ...state,
            cities: [],
            loading: false
         }
      case DISTRICT_DESCARGA_COMENZAR:
         return {
            ...state,
            districts: [],
            loading: true
         }
      case DISTRICT_DESCARGA_EXITO:
         return {
            ...state,
            districts: action.payload,
            loading: false
         }
      case DISTRICT_DESCARGA_ERROR:
         return {
            ...state,
            districts: [],
            loading: false
         }
      default:
         return state;
   }
}