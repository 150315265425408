import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Busqueda from "../components/personal/Busqueda";
import ListadoPersonal from "../components/personal/ListadoPersonal";
import TituloTabla from "../components/personal/TituloTabla";
import Loading from "../components/layout/Loading";
import { obtenerStoreActivosAction } from "../actions/storeActions";

const Personal = () => {
   const dispatch = useDispatch();

   const loading = useSelector((state) => state.personal.loading);

   const [data, setData] = useState({
      code: '',
      name: '',
      storeId: '',
      state: '',
      typeDocument: '',
      numDocument: '',
      limit: 10,
      offset: 0
   });

   useEffect(() => {
      dispatch(obtenerStoreActivosAction());
   }, []);

   return (
      <Fragment>
         <Header title={"ADMINISTRACIÓN DE PERSONAL"}/>
         <Loading loading={loading} />
         <Busqueda data={data} setData={setData} />
         <TituloTabla />
         <ListadoPersonal data={data} setData={setData} />
         <Footer/>
      </Fragment>
   );
};

export default Personal;
