import React, { useState } from "react";

import ViewAnswer from "./ViewAnswer";

export default function ViewQuestion({
   currentQuestion,
   number,
   description,
   listQuestion,
   setListQuestion,
   replied,
   setReplied,
   onHandleQuestionChange,
}) {

   const answers = currentQuestion && currentQuestion.answer ? currentQuestion.answer : [];
   const [listAnswer, setListAnswer] = useState(answers);
   const onHandleAnswerChange = (idx, questionId) => (evt) => {
      let newList = [...listAnswer];
      let newAnswer;

      newAnswer = listAnswer.filter((answer, sidx) => {
         if (idx === sidx)
            return { ...answer, isCorrect: true };
      });
         
      const answerSelected = newAnswer && newAnswer.length > 0 ? newAnswer[0] : null;

      newList = replied.filter((answer) => {
         if (answer.questionId !== questionId)
            return {id: answer.id, questionId: answer.questionId};
      });

      newList.push({id: answerSelected.id, questionId: answerSelected.questionId});
      setReplied(newList);
      
      //let questions = [...listQuestion];
      //questions[number - 1].answers = newList;
      //setListQuestion(questions);
   };

   const onSetListAnswer = () => {
      if (listAnswer.length < 5) {
         setListAnswer([...listAnswer, { description: "", isCorrect: false }]);
      }
   };

   return (
      <div className="boxQuestion">
         <div className="questions">
            <p>
            {number}{'. '}{currentQuestion.description}
            </p>
         </div>
         <div className="checkboxAll">
            {listAnswer.map((answer, index) => (
               <ViewAnswer
                  key={index}
                  number={index + 1}
                  answers={answers}
                  answer={answer}
                  group={"group_" + (number)}
                  marked={currentQuestion.marked}
                  onHandleAnswerChange={onHandleAnswerChange}
               />
            ))}
         </div>
      </div>
   );
}
