import {
   TICKET_AGREGAR_COMENZAR,
   TICKET_AGREGAR_EXITO, 
   TICKET_AGREGAR_ERROR, 
   TICKET_DESCARGA_COMENZAR, 
   TICKET_DESCARGA_EXITO, 
   TICKET_DESCARGA_ERROR, 
   TICKET_DESCARGA_COMENZAR_TOTAL,
   TICKET_DESCARGA_EXITO_TOTAL, 
   TICKET_DESCARGA_ERROR_TOTAL, 
   TICKET_OBTENER_COMENZAR,
   TICKET_OBTENER_EXITO,
   TICKET_OBTENER_ERROR,
   TICKET_FIRMAR_COMENZAR,
   TICKET_FIRMAR_EXITO,
   TICKET_FIRMAR_ERROR,
   TICKET_YEAR_COMENZAR,
   TICKET_YEAR_EXITO,
   TICKET_YEAR_ERROR,
   TICKET_REPORTE_COMENZAR, 
   TICKET_REPORTE_EXITO, 
   TICKET_REPORTE_ERROR, 
} from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

// ---------- OBTENER AÑOS ----------
export function obtenerYearAction() {
   return async (dispatch) => {
         dispatch(obtenerYear());

         try {
            const tope = 5;
            const initialYear = new Date().getFullYear() - (tope - 1);
            const years = Array.from({length: tope}, (_, i) => i + initialYear);

            dispatch(obtenerYearExito(years))
         } catch (error) {
            dispatch(obtenerYearError());
         }
   }
}

const obtenerYear = () => ({
   type: TICKET_YEAR_COMENZAR,
   payload: true
})

const obtenerYearExito = data => ({
   type: TICKET_YEAR_EXITO,
   payload: data
})

const obtenerYearError = () => ({
   type: TICKET_YEAR_ERROR,
   payload: true
})

// ---------- CARGAR TICKET ----------
export function cargarTicketAction(data) {
   return async (dispatch) => {
      dispatch(cargarTicket());

      try {
         const response = await clienteAxios.post("/tickets", data);

         dispatch(cargarTicketExito(response.data));

         if (response.status === 201) {
            Swal.fire("Correcto", "Se cargaron las boletas correctamente", "success");
         } else {
            Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
         }
      } catch (error) {
         const code = error.response.status;

         let mensaje = "";
         if (code === 409) {
            mensaje = error.response.data.message;
         } if (code === 422) {
            mensaje = error.response.data.message;
         } else {
            mensaje = "Hubo un error, intenta de nuevo";
         }
         dispatch(cargarTicketError(true));

         Swal.fire({icon: "error", title: "Hubo un error", text: mensaje});
      }
   }
}

const cargarTicket = () => ({
   type: TICKET_AGREGAR_COMENZAR
})

const cargarTicketExito = data => ({
   type: TICKET_AGREGAR_EXITO,
   payload: data
})

const cargarTicketError = estado => ({
   type: TICKET_AGREGAR_ERROR,
   payload: estado
})

// ---------- OBTENER BOLETAS ----------
export function descargarTicketsAction(data, personalId, offset) {
   return async (dispatch) => {
      if (data) {
         let parameters = '';
         if (personalId) {
            dispatch(descargarTickets());
         } else {
            dispatch(descargarTicketsTotal());
            parameters = `limit=${data.limit}&offset=${offset}&`;
         }

         if (personalId) {
            parameters = parameters + `personalId=${personalId}&`
         }
         if (data.year) {
            parameters = parameters + `year=${data.year}&`
         }
         if (data.name) {
            parameters = parameters + `name=${data.name}&`
         }
         if (data.typeDocument) {
            parameters = parameters + `typeDocument=${data.typeDocument}&`
         }
         if (data.numDocument) {
            parameters = parameters + `numDocument=${data.numDocument}&`
         }

         try {
            const url = personalId ? `/tickets/year/${data.year}?personalId=${personalId}` : `/tickets/year/${data.year}?${parameters}`;
            const resultado = await clienteAxios.get(url);

            if (personalId) {
               dispatch(descargarTicketsExito(resultado.data))
            } else {
               resultado.data.limit = data.limit;
               dispatch(descargarTicketsTotalExito(resultado.data))
            }
         } catch (error) {
            if (personalId) {
               dispatch(descargarTicketsError());
            } else {
               dispatch(descargarTicketsTotalError());
            }
         }
      }
   }
}

const descargarTickets = () => ({
   type: TICKET_DESCARGA_COMENZAR,
   payload: true
})

const descargarTicketsExito = data => ({
   type: TICKET_DESCARGA_EXITO,
   payload: data
})

const descargarTicketsError = () => ({
   type: TICKET_DESCARGA_ERROR,
   payload: true
})

const descargarTicketsTotal = () => ({
   type: TICKET_DESCARGA_COMENZAR_TOTAL,
   payload: true
})

const descargarTicketsTotalExito = data => ({
   type: TICKET_DESCARGA_EXITO_TOTAL,
   payload: data
})

const descargarTicketsTotalError = () => ({
   type: TICKET_DESCARGA_ERROR_TOTAL,
   payload: true
})

// ---------- FIRMAR BOLETA ----------
export function firmarTicketAction(id) {
   return async (dispatch) => {

      dispatch(firmarTicketComenzar());

      try {
         const response = await clienteAxios.patch(`/tickets/signing/${id}`);

         let data = response.data;
         data.id = id;
         data.signing = 1;

         dispatch(firmarTicketExito(data));
         Swal.fire("Correcto", "La boleta se firmó correctamente", "success");
      } catch (error) {
         dispatch(firmarTicketError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const firmarTicketComenzar = () => ({
   type: TICKET_FIRMAR_COMENZAR
})

const firmarTicketExito = data => ({
   type: TICKET_FIRMAR_EXITO,
   payload: data
})

const firmarTicketError = () => ({
   type: TICKET_FIRMAR_ERROR,
   payload: true
})

// ---------- REPORTE TICKETS ----------
export function reporteTicketsAction(data) {
   return async (dispatch) => {
         dispatch(reporteTicket());

         let query = '';
         if (data.name) {
            query = query + `name=${data.name}&`
         }
         if (data.typeDocument) {
            query = query + `typeDocument=${data.typeDocument}&`
         }
         if (data.numDocument) {
            query = query + `numDocument=${data.numDocument}&`
         }
         if (data.year) {
            query = query + `year=${data.year}&`
         }
         if (data.month) {
            query = query + `month=${data.month}&`
         }
         if (data.signing) {
            query = query + `signing=${data.signing}&`
         }

         const url = query === '' ? '/tickets/report' : '/tickets/report?' + query;

         try {
            const resultado = await clienteAxios.get(url);

            dispatch(reporteTicketExito(resultado.data))
         } catch (error) {
            dispatch(reporteTicketError());
         }
   }
}

const reporteTicket = () => ({
   type: TICKET_REPORTE_COMENZAR,
   payload: true
})

const reporteTicketExito = data => ({
   type: TICKET_REPORTE_EXITO,
   payload: data
})

const reporteTicketError = () => ({
   type: TICKET_REPORTE_ERROR,
   payload: true
})