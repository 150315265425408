import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import eyesIcon from "../../assets/images/eye1.svg";
import pdfIcon from "../../assets/images/pdf_icon.svg";

export default function MemoItemTodos({ memo, handleOpenModal }) {
   const user = useSelector((state) => state.auth.user);

   const type = memo.type;
   const year = memo.period ? memo.period.substring(0, 4) : '';
   const month = memo.period ? memo.period.substring(6, 4) : '';
   let descMonth = "";
   switch (month) {
      case "01":
         descMonth = "Enero";
         break;
      case "02":
         descMonth = "Febrero";
         break;
      case "03":
         descMonth = "Marzo";
         break;
      case "04":
         descMonth = "Abril";
         break;
      case "05":
         descMonth = "Mayo";
         break;
      case "06":
         descMonth = "Junio";
         break;
      case "07":
         descMonth = "Julio";
         break;
      case "08":
         descMonth = "Agosto";
         break;
      case "09":
         descMonth = "Setiembre";
         break;
      case "10":
         descMonth = "Octubre";
         break;
      case "11":
         descMonth = "Noviembre";
         break;
      case "12":
         descMonth = "Diciembre";
         break;
      default:
         break;
   }

   return (
      <tr>
         <td>{memo.id}</td>
         <td>{descMonth} {year}</td>
         <td>{memo.description}</td>
         <td>{memo.person}</td>
         <td>{memo.typeDocument === '01' ? 'DNI' : memo.typeDocument === '02' ? 'Pasaporte' : 'Carnet de Extranjeria'}</td>
         <td>{memo.numDocument}</td>
         <td>
         <a href={memo.url} target="_blank" alt="" rel="noreferrer">
               <img src={pdfIcon} className="mr-2 iconTabla" alt="Ver" />
            </a>
         </td>
      </tr>
   );
}
