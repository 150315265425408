export const LOGIN_INICIO = 'LOGIN_INICIO';
export const LOGIN_EXITOSO = 'LOGIN_EXITOSO';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const USER_CONTRASENIA_COMENZAR = 'USER_CONTRASENIA_COMENZAR';
export const USER_CONTRASENIA_EXITO = 'USER_CONTRASENIA_EXITO';
export const USER_CONTRASENIA_ERROR = 'USER_CONTRASENIA_ERROR';

export const USER_RECUPERAR_CONTRASENIA_COMENZAR = 'USER_RECUPERAR_CONTRASENIA_COMENZAR';
export const USER_RECUPERAR_CONTRASENIA_EXITO = 'USER_RECUPERAR_CONTRASENIA_EXITO';
export const USER_RECUPERAR_CONTRASENIA_ERROR = 'USER_RECUPERAR_CONTRASENIA_ERROR';

export const CONFIRMAR_CUENTA_COMENZAR = 'CONFIRMAR_CUENTA_COMENZAR';
export const CONFIRMAR_CUENTA_EXITO = 'CONFIRMAR_CUENTA_EXITO';
export const CONFIRMAR_CUENTA_ERROR = 'CONFIRMAR_CUENTA_ERROR';

export const CERRAR_SESION = 'CERRAR_SESION';

export const PERSONAL_AGREGAR_COMENZAR = 'PERSONAL_AGREGAR_COMENZAR';
export const PERSONAL_AGREGAR_EXITO = 'PERSONAL_AGREGAR_EXITO';
export const PERSONAL_AGREGAR_ERROR = 'PERSONAL_AGREGAR_ERROR';
export const PERSONAL_DESCARGA_COMENZAR = 'PERSONAL_DESCARGA_COMENZAR';
export const PERSONAL_DESCARGA_EXITO = 'PERSONAL_DESCARGA_EXITO';
export const PERSONAL_DESCARGA_ERROR = 'PERSONAL_DESCARGA_ERROR';
export const PERSONAL_ACTIVOS_DESCARGA_COMENZAR = 'PERSONAL_ACTIVOS_DESCARGA_COMENZAR';
export const PERSONAL_ACTIVOS_DESCARGA_EXITO = 'PERSONAL_ACTIVOS_DESCARGA_EXITO';
export const PERSONAL_ACTIVOS_DESCARGA_ERROR = 'PERSONAL_ACTIVOS_DESCARGA_ERROR';
export const PERSONAL_ROL_DESCARGA_COMENZAR = 'PERSONAL_ROL_DESCARGA_COMENZAR';
export const PERSONAL_ROL_DESCARGA_EXITO = 'PERSONAL_ROL_DESCARGA_EXITO';
export const PERSONAL_ROL_DESCARGA_ERROR = 'PERSONAL_ROL_DESCARGA_ERROR';
export const PERSONAL_OBTENER_COMENZAR = 'PERSONAL_OBTENER_COMENZAR';
export const PERSONAL_OBTENER_EXITO = 'PERSONAL_OBTENER_EXITO';
export const PERSONAL_OBTENER_ERROR = 'PERSONAL_OBTENER_ERROR';
export const PERSONAL_EDITAR_COMENZAR = 'PERSONAL_EDITAR_COMENZAR';
export const PERSONAL_EDITAR_EXITO = 'PERSONAL_EDITAR_EXITO';
export const PERSONAL_EDITAR_ERROR = 'PERSONAL_EDITAR_ERROR';
export const PERSONAL_ESTADO_COMENZAR = 'PERSONAL_ESTADO_COMENZAR';
export const PERSONAL_ESTADO_EXITO = 'PERSONAL_ESTADO_EXITO';
export const PERSONAL_ESTADO_ERROR = 'PERSONAL_ESTADO_ERROR';
export const PERSONAL_REPORTE_COMENZAR = 'PERSONAL_REPORTE_COMENZAR';
export const PERSONAL_REPORTE_FULL_COMENZAR = 'PERSONAL_REPORTE_FULL_COMENZAR';
export const PERSONAL_REPORTE_EXITO = 'PERSONAL_REPORTE_EXITO';
export const PERSONAL_REPORTE_FULL_EXITO = 'PERSONAL_REPORTE_FULL_EXITO';
export const PERSONAL_REPORTE_ERROR = 'PERSONAL_REPORTE_ERROR';

export const STORE_AGREGAR_COMENZAR = 'STORE_AGREGAR_COMENZAR';
export const STORE_AGREGAR_EXITO = 'STORE_AGREGAR_EXITO';
export const STORE_AGREGAR_ERROR = 'STORE_AGREGAR_ERROR';
export const STORE_DESCARGA_COMENZAR = 'STORE_DESCARGA_COMENZAR';
export const STORE_DESCARGA_EXITO = 'STORE_DESCARGA_EXITO';
export const STORE_DESCARGA_ERROR = 'STORE_DESCARGA_ERROR';
export const STORE_ACTIVOS_DESCARGA_COMENZAR = 'STORE_ACTIVOS_DESCARGA_COMENZAR';
export const STORE_ACTIVOS_DESCARGA_EXITO = 'STORE_ACTIVOS_DESCARGA_EXITO';
export const STORE_ACTIVOS_DESCARGA_ERROR = 'STORE_ACTIVOS_DESCARGA_ERROR';
export const STORE_OBTENER_COMENZAR = 'STORE_OBTENER_COMENZAR';
export const STORE_OBTENER_EXITO = 'STORE_OBTENER_EXITO';
export const STORE_OBTENER_ERROR = 'STORE_OBTENER_ERROR';
export const STORE_EDITAR_COMENZAR = 'STORE_EDITAR_COMENZAR';
export const STORE_EDITAR_EXITO = 'STORE_EDITAR_EXITO';
export const STORE_EDITAR_ERROR = 'STORE_EDITAR_ERROR';
export const STORE_ESTADO_COMENZAR = 'STORE_ESTADO_COMENZAR';
export const STORE_ESTADO_EXITO = 'STORE_ESTADO_EXITO';
export const STORE_ESTADO_ERROR = 'STORE_ESTADO_ERROR';
export const STORE_MANAGERS_DESCARGA_COMENZAR = 'STORE_MANAGERS_DESCARGA_COMENZAR';
export const STORE_MANAGERS_DESCARGA_EXITO = 'STORE_MANAGERS_DESCARGA_EXITO';
export const STORE_MANAGERS_DESCARGA_ERROR = 'STORE_MANAGERS_DESCARGA_ERROR';

export const MALL_AGREGAR_COMENZAR = 'MALL_AGREGAR_COMENZAR';
export const MALL_AGREGAR_EXITO = 'MALL_AGREGAR_EXITO';
export const MALL_AGREGAR_ERROR = 'MALL_AGREGAR_ERROR';
export const MALL_DESCARGA_COMENZAR = 'MALL_DESCARGA_COMENZAR';
export const MALL_DESCARGA_EXITO = 'MALL_DESCARGA_EXITO';
export const MALL_DESCARGA_ERROR = 'MALL_DESCARGA_ERROR';
export const MALL_OBTENER_COMENZAR = 'MALL_OBTENER_COMENZAR';
export const MALL_OBTENER_EXITO = 'MALL_OBTENER_EXITO';
export const MALL_OBTENER_ERROR = 'MALL_OBTENER_ERROR';
export const MALL_EDITAR_COMENZAR = 'MALL_EDITAR_COMENZAR';
export const MALL_EDITAR_EXITO = 'MALL_EDITAR_EXITO';
export const MALL_EDITAR_ERROR = 'MALL_EDITAR_ERROR';
export const MALL_ESTADO_COMENZAR = 'MALL_ESTADO_COMENZAR';
export const MALL_ESTADO_EXITO = 'MALL_ESTADO_EXITO';
export const MALL_ESTADO_ERROR = 'MALL_ESTADO_ERROR';

export const CAMPAING_AGREGAR_COMENZAR = 'CAMPAING_AGREGAR_COMENZAR';
export const CAMPAING_AGREGAR_EXITO = 'CAMPAING_AGREGAR_EXITO';
export const CAMPAING_AGREGAR_ERROR = 'CAMPAING_AGREGAR_ERROR';
export const CAMPAING_DESCARGA_COMENZAR = 'CAMPAING_DESCARGA_COMENZAR';
export const CAMPAING_DESCARGA_EXITO = 'CAMPAING_DESCARGA_EXITO';
export const CAMPAING_DESCARGA_ERROR = 'CAMPAING_DESCARGA_ERROR';
export const CAMPAING_OBTENER_COMENZAR = 'CAMPAING_OBTENER_COMENZAR';
export const CAMPAING_OBTENER_EXITO = 'CAMPAING_OBTENER_EXITO';
export const CAMPAING_OBTENER_ERROR = 'CAMPAING_OBTENER_ERROR';
export const CAMPAING_EDITAR_COMENZAR = 'CAMPAING_EDITAR_COMENZAR';
export const CAMPAING_EDITAR_EXITO = 'CAMPAING_EDITAR_EXITO';
export const CAMPAING_EDITAR_ERROR = 'CAMPAING_EDITAR_ERROR';
export const CAMPAING_ESTADO_COMENZAR = 'CAMPAING_ESTADO_COMENZAR';
export const CAMPAING_ESTADO_EXITO = 'CAMPAING_ESTADO_EXITO';
export const CAMPAING_ESTADO_ERROR = 'CAMPAING_ESTADO_ERROR';
export const CAMPAING_REPORTE_COMENZAR = 'CAMPAING_REPORTE_COMENZAR';
export const CAMPAING_REPORTE_EXITO = 'CAMPAING_REPORTE_EXITO';
export const CAMPAING_REPORTE_ERROR = 'CAMPAING_REPORTE_ERROR';

export const PRODUCT_AGREGAR_COMENZAR = 'PRODUCT_AGREGAR_COMENZAR';
export const PRODUCT_AGREGAR_EXITO = 'PRODUCT_AGREGAR_EXITO';
export const PRODUCT_AGREGAR_ERROR = 'PRODUCT_AGREGAR_ERROR';
export const PRODUCT_DESCARGA_COMENZAR = 'PRODUCT_DESCARGA_COMENZAR';
export const PRODUCT_DESCARGA_EXITO = 'PRODUCT_DESCARGA_EXITO';
export const PRODUCT_DESCARGA_ERROR = 'PRODUCT_DESCARGA_ERROR';
export const PRODUCT_OBTENER_COMENZAR = 'PRODUCT_OBTENER_COMENZAR';
export const PRODUCT_OBTENER_EXITO = 'PRODUCT_OBTENER_EXITO';
export const PRODUCT_OBTENER_ERROR = 'PRODUCT_OBTENER_ERROR';
export const PRODUCT_EDITAR_COMENZAR = 'PRODUCT_EDITAR_COMENZAR';
export const PRODUCT_EDITAR_EXITO = 'PRODUCT_EDITAR_EXITO';
export const PRODUCT_EDITAR_ERROR = 'PRODUCT_EDITAR_ERROR';
export const PRODUCT_ESTADO_COMENZAR = 'SCHEDULE_ESTADO_COMENZAR';
export const PRODUCT_ESTADO_EXITO = 'PRODUCT_ESTADO_EXITO';
export const PRODUCT_ESTADO_ERROR = 'PRODUCT_ESTADO_ERROR';
export const PRODUCT_REPORTE_COMENZAR = 'PRODUCT_REPORTE_COMENZAR';
export const PRODUCT_REPORTE_EXITO = 'PRODUCT_REPORTE_EXITO';
export const PRODUCT_REPORTE_ERROR = 'PRODUCT_REPORTE_ERROR';

export const CITY_DESCARGA_COMENZAR = 'CITY_DESCARGA_COMENZAR';
export const CITY_DESCARGA_EXITO = 'CITY_DESCARGA_EXITO';
export const CITY_DESCARGA_ERROR = 'CITY_DESCARGA_ERROR';
export const DISTRICT_DESCARGA_COMENZAR = 'DISTRICT_DESCARGA_COMENZAR';
export const DISTRICT_DESCARGA_EXITO = 'DISTRICT_DESCARGA_EXITO';
export const DISTRICT_DESCARGA_ERROR = 'DISTRICT_DESCARGA_ERROR';

export const COMMISSION_AGREGAR_COMENZAR = 'COMMISSION_AGREGAR_COMENZAR';
export const COMMISSION_AGREGAR_EXITO = 'COMMISSION_AGREGAR_EXITO';
export const COMMISSION_AGREGAR_ERROR = 'COMMISSION_AGREGAR_ERROR';
export const COMMISSION_DESCARGA_COMENZAR = 'COMMISSION_DESCARGA_COMENZAR';
export const COMMISSION_DESCARGA_EXITO = 'COMMISSION_DESCARGA_EXITO';
export const COMMISSION_DESCARGA_ERROR = 'COMMISSION_DESCARGA_ERROR';
export const COMMISSION_OBTENER_COMENZAR = 'COMMISSION_OBTENER_COMENZAR';
export const COMMISSION_OBTENER_EXITO = 'COMMISSION_OBTENER_EXITO';
export const COMMISSION_OBTENER_ERROR = 'COMMISSION_OBTENER_ERROR';
export const COMMISSION_EDITAR_COMENZAR = 'COMMISSION_EDITAR_COMENZAR';
export const COMMISSION_EDITAR_EXITO = 'COMMISSION_EDITAR_EXITO';
export const COMMISSION_EDITAR_ERROR = 'COMMISSION_EDITAR_ERROR';
export const COMMISSION_ESTADO_COMENZAR = 'COMMISSION_ESTADO_COMENZAR';
export const COMMISSION_ESTADO_EXITO = 'COMMISSION_ESTADO_EXITO';
export const COMMISSION_ESTADO_ERROR = 'COMMISSION_ESTADO_ERROR';
export const COMMISSION_META_COMENZAR = 'COMMISSION_META_COMENZAR';
export const COMMISSION_META_EXITO = 'COMMISSION_META_EXITO';
export const COMMISSION_META_ERROR = 'COMMISSION_META_ERROR';
export const COMMISSION_DETALLE_COMENZAR = 'COMMISSION_DETALLE_COMENZAR';
export const COMMISSION_DETALLE_EXITO = 'COMMISSION_DETALLE_EXITO';
export const COMMISSION_DETALLE_ERROR = 'COMMISSION_DETALLE_ERROR';
export const COMMISSION_PRECIO_COMENZAR = 'COMMISSION_PRECIO_COMENZAR';
export const COMMISSION_PRECIO_EXITO = 'COMMISSION_PRECIO_EXITO';
export const COMMISSION_PRECIO_ERROR = 'COMMISSION_PRECIO_ERROR';
export const COMMISSION_COMISION_COMENZAR = 'COMMISSION_COMISION_COMENZAR';
export const COMMISSION_COMISION_EXITO = 'COMMISSION_COMISION_EXITO';
export const COMMISSION_COMISION_ERROR = 'COMMISSION_COMISION_ERROR';

export const SCHEDULE_AGREGAR_COMENZAR = 'SCHEDULE_AGREGAR_COMENZAR';
export const SCHEDULE_AGREGAR_EXITO = 'SCHEDULE_AGREGAR_EXITO';
export const SCHEDULE_AGREGAR_ERROR = 'SCHEDULE_AGREGAR_ERROR';
export const SCHEDULE_DESCARGA_COMENZAR = 'SCHEDULE_DESCARGA_COMENZAR';
export const SCHEDULE_DESCARGA_EXITO = 'SCHEDULE_DESCARGA_EXITO';
export const SCHEDULE_DESCARGA_ERROR = 'SCHEDULE_DESCARGA_ERROR';
export const SCHEDULE_OBTENER_COMENZAR = 'SCHEDULE_OBTENER_COMENZAR';
export const SCHEDULE_OBTENER_EXITO = 'SCHEDULE_OBTENER_EXITO';
export const SCHEDULE_OBTENER_ERROR = 'SCHEDULE_OBTENER_ERROR';
export const SCHEDULE_EDITAR_COMENZAR = 'SCHEDULE_EDITAR_COMENZAR';
export const SCHEDULE_EDITAR_EXITO = 'SCHEDULE_EDITAR_EXITO';
export const SCHEDULE_EDITAR_ERROR = 'SCHEDULE_EDITAR_ERROR';
export const SCHEDULE_ESTADO_COMENZAR = 'SCHEDULE_ESTADO_COMENZAR';
export const SCHEDULE_ESTADO_EXITO = 'SCHEDULE_ESTADO_EXITO';
export const SCHEDULE_ESTADO_ERROR = 'SCHEDULE_ESTADO_ERROR';
export const SCHEDULE_REPORTE_COMENZAR = 'SCHEDULE_REPORTE_COMENZAR';
export const SCHEDULE_REPORTE_EXITO = 'SCHEDULE_REPORTE_EXITO';
export const SCHEDULE_REPORTE_ERROR = 'SCHEDULE_REPORTE_ERROR';

export const MESSAGE_AGREGAR_COMENZAR = 'MESSAGE_AGREGAR_COMENZAR';
export const MESSAGE_AGREGAR_EXITO = 'MESSAGE_AGREGAR_EXITO';
export const MESSAGE_AGREGAR_ERROR = 'MESSAGE_AGREGAR_ERROR';
export const MESSAGE_DESCARGA_COMENZAR = 'MESSAGE_DESCARGA_COMENZAR';
export const MESSAGE_DESCARGA_EXITO = 'MESSAGE_DESCARGA_EXITO';
export const MESSAGE_DESCARGA_ERROR = 'MESSAGE_DESCARGA_ERROR';
export const MESSAGE_OBTENER_COMENZAR = 'MESSAGE_OBTENER_COMENZAR';
export const MESSAGE_OBTENER_EXITO = 'MESSAGE_OBTENER_EXITO';
export const MESSAGE_OBTENER_ERROR = 'MESSAGE_OBTENER_ERROR';
export const MESSAGE_LEER_COMENZAR = 'MESSAGE_LEER_COMENZAR';
export const MESSAGE_LEER_EXITO = 'MESSAGE_LEER_EXITO';
export const MESSAGE_LEER_ERROR = 'MESSAGE_LEER_ERROR';
export const MESSAGE_CANTIDAD_COMENZAR = 'MESSAGE_CANTIDAD_COMENZAR';
export const MESSAGE_CANTIDAD_EXITO = 'MESSAGE_CANTIDAD_EXITO';
export const MESSAGE_CANTIDAD_ERROR = 'MESSAGE_CANTIDAD_ERROR';

export const EXAM_AGREGAR_COMENZAR = 'EXAM_AGREGAR_COMENZAR';
export const EXAM_AGREGAR_EXITO = 'EXAM_AGREGAR_EXITO';
export const EXAM_AGREGAR_ERROR = 'EXAM_AGREGAR_ERROR';
export const EXAM_DESCARGA_COMENZAR = 'EXAM_DESCARGA_COMENZAR';
export const EXAM_DESCARGA_EXITO = 'EXAM_DESCARGA_EXITO';
export const EXAM_DESCARGA_ERROR = 'EXAM_DESCARGA_ERROR';
export const EXAM_OBTENER_COMENZAR = 'EXAM_OBTENER_COMENZAR';
export const EXAM_OBTENER_EXITO = 'EXAM_OBTENER_EXITO';
export const EXAM_OBTENER_ERROR = 'EXAM_OBTENER_ERROR';
export const EXAM_EDITAR_COMENZAR = 'EXAM_EDITAR_COMENZAR';
export const EXAM_EDITAR_EXITO = 'EXAM_EDITAR_EXITO';
export const EXAM_EDITAR_ERROR = 'EXAM_EDITAR_ERROR';
export const EXAM_PREGUNTA_DESCARGA_COMENZAR = 'EXAM_PREGUNTA_DESCARGA_COMENZAR';
export const EXAM_PREGUNTA_DESCARGA_EXITO = 'EXAM_PREGUNTA_DESCARGA_EXITO';
export const EXAM_PREGUNTA_DESCARGA_ERROR = 'EXAM_PREGUNTA_DESCARGA_ERROR';
export const EXAM_RESPONDER_COMENZAR = 'EXAM_RESPONDER_COMENZAR';
export const EXAM_RESPONDER_EXITO = 'EXAM_RESPONDER_EXITO';
export const EXAM_RESPONDER_ERROR = 'EXAM_RESPONDER_ERROR';
export const EXAM_REPORTE_1_COMENZAR = 'EXAM_REPORTE_1_COMENZAR';
export const EXAM_REPORTE_1_EXITO = 'EXAM_REPORTE_1_EXITO';
export const EXAM_REPORTE_1_ERROR = 'EXAM_REPORTE_1_ERROR';
export const EXAM_REPORTE_2_COMENZAR = 'EXAM_REPORTE_2_COMENZAR';
export const EXAM_REPORTE_2_EXITO = 'EXAM_REPORTE_2_EXITO';
export const EXAM_REPORTE_2_ERROR = 'EXAM_REPORTE_2_ERROR';

export const POLICY_AGREGAR_COMENZAR = 'POLICY_AGREGAR_COMENZAR';
export const POLICY_AGREGAR_EXITO = 'POLICY_AGREGAR_EXITO';
export const POLICY_AGREGAR_ERROR = 'POLICY_AGREGAR_ERROR';
export const POLICY_DESCARGA_COMENZAR = 'POLICY_DESCARGA_COMENZAR';
export const POLICY_DESCARGA_EXITO = 'POLICY_DESCARGA_EXITO';
export const POLICY_DESCARGA_ERROR = 'POLICY_DESCARGA_ERROR';
export const POLICY_OBTENER_COMENZAR = 'POLICY_OBTENER_COMENZAR';
export const POLICY_OBTENER_EXITO = 'POLICY_OBTENER_EXITO';
export const POLICY_OBTENER_ERROR = 'POLICY_OBTENER_ERROR';
export const POLICY_EDITAR_COMENZAR = 'POLICY_EDITAR_COMENZAR';
export const POLICY_EDITAR_EXITO = 'POLICY_EDITAR_EXITO';
export const POLICY_EDITAR_ERROR = 'POLICY_EDITAR_ERROR';
export const POLICY_ESTADO_COMENZAR = 'POLICY_ESTADO_COMENZAR';
export const POLICY_ESTADO_EXITO = 'POLICY_ESTADO_EXITO';
export const POLICY_ESTADO_ERROR = 'POLICY_ESTADO_ERROR';

export const PROFILE_DESCARGA_COMENZAR = 'PROFILE_DESCARGA_COMENZAR';
export const PROFILE_DESCARGA_EXITO = 'PROFILE_DESCARGA_EXITO';
export const PROFILE_DESCARGA_ERROR = 'PROFILE_DESCARGA_ERROR';
export const PROFILE_OBTENER_COMENZAR = 'PROFILE_OBTENER_COMENZAR';
export const PROFILE_OBTENER_EXITO = 'PROFILE_OBTENER_EXITO';
export const PROFILE_OBTENER_ERROR = 'PROFILE_OBTENER_ERROR';
export const PROFILE_EDITAR_COMENZAR = 'PROFILE_EDITAR_COMENZAR';
export const PROFILE_EDITAR_EXITO = 'PROFILE_EDITAR_EXITO';
export const PROFILE_EDITAR_ERROR = 'PROFILE_EDITAR_ERROR';

export const TICKET_AGREGAR_COMENZAR = 'TICKET_AGREGAR_COMENZAR';
export const TICKET_AGREGAR_EXITO = 'TICKET_AGREGAR_EXITO';
export const TICKET_AGREGAR_ERROR = 'TICKET_AGREGAR_ERROR';
export const TICKET_DESCARGA_COMENZAR = 'TICKET_DESCARGA_COMENZAR';
export const TICKET_DESCARGA_EXITO = 'TICKET_DESCARGA_EXITO';
export const TICKET_DESCARGA_ERROR = 'TICKET_DESCARGA_ERROR';
export const TICKET_DESCARGA_COMENZAR_TOTAL = 'TICKET_DESCARGA_COMENZAR_TOTAL';
export const TICKET_DESCARGA_EXITO_TOTAL = 'TICKET_DESCARGA_EXITO_TOTAL';
export const TICKET_DESCARGA_ERROR_TOTAL = 'TICKET_DESCARGA_ERROR_TOTAL';
export const TICKET_OBTENER_COMENZAR = 'TICKET_OBTENER_COMENZAR';
export const TICKET_OBTENER_EXITO = 'TICKET_OBTENER_EXITO';
export const TICKET_OBTENER_ERROR = 'TICKET_OBTENER_ERROR';
export const TICKET_FIRMAR_COMENZAR = 'TICKET_FIRMAR_COMENZAR';
export const TICKET_FIRMAR_EXITO = 'TICKET_FIRMAR_EXITO';
export const TICKET_FIRMAR_ERROR = 'TICKET_FIRMAR_ERROR';
export const TICKET_YEAR_COMENZAR = 'TICKET_YEAR_COMENZAR';
export const TICKET_YEAR_EXITO = 'TICKET_YEAR_EXITO';
export const TICKET_YEAR_ERROR = 'TICKET_YEAR_ERROR';
export const TICKET_REPORTE_COMENZAR = 'TICKET_REPORTE_COMENZAR';
export const TICKET_REPORTE_EXITO = 'TICKET_REPORTE_EXITO';
export const TICKET_REPORTE_ERROR = 'TICKET_REPORTE_ERROR';

export const MEMO_AGREGAR_COMENZAR = 'MEMO_AGREGAR_COMENZAR';
export const MEMO_AGREGAR_EXITO = 'MEMO_AGREGAR_EXITO';
export const MEMO_AGREGAR_ERROR = 'MEMO_AGREGAR_ERROR';
export const MEMO_DESCARGA_COMENZAR = 'MEMO_DESCARGA_COMENZAR';
export const MEMO_DESCARGA_EXITO = 'MEMO_DESCARGA_EXITO';
export const MEMO_DESCARGA_ERROR = 'MEMO_DESCARGA_ERROR';
export const MEMO_DESCARGA_COMENZAR_TOTAL = 'MEMO_DESCARGA_COMENZAR_TOTAL';
export const MEMO_DESCARGA_EXITO_TOTAL = 'MEMO_DESCARGA_EXITO_TOTAL';
export const MEMO_DESCARGA_ERROR_TOTAL = 'MEMO_DESCARGA_ERROR_TOTAL';
export const MEMO_OBTENER_COMENZAR = 'MEMO_OBTENER_COMENZAR';
export const MEMO_OBTENER_EXITO = 'MEMO_OBTENER_EXITO';
export const MEMO_OBTENER_ERROR = 'MEMO_OBTENER_ERROR';
export const MEMO_FIRMAR_COMENZAR = 'MEMO_FIRMAR_COMENZAR';
export const MEMO_FIRMAR_EXITO = 'MEMO_FIRMAR_EXITO';
export const MEMO_FIRMAR_ERROR = 'MEMO_FIRMAR_ERROR';
export const MEMO_YEAR_COMENZAR = 'MEMO_YEAR_COMENZAR';
export const MEMO_YEAR_EXITO = 'MEMO_YEAR_EXITO';
export const MEMO_YEAR_ERROR = 'MEMO_YEAR_ERROR';
export const MEMO_REPORTE_COMENZAR = 'MEMO_REPORTE_COMENZAR';
export const MEMO_REPORTE_EXITO = 'MEMO_REPORTE_EXITO';
export const MEMO_REPORTE_ERROR = 'MEMO_REPORTE_ERROR';

export const CHECKLIST_AGREGAR_COMENZAR = 'CHECKLIST_AGREGAR_COMENZAR';
export const CHECKLIST_AGREGAR_EXITO = 'CHECKLIST_AGREGAR_EXITO';
export const CHECKLIST_AGREGAR_ERROR = 'CHECKLIST_AGREGAR_ERROR';
export const CHECKLIST_DESCARGA_COMENZAR = 'CHECKLIST_DESCARGA_COMENZAR';
export const CHECKLIST_DESCARGA_EXITO = 'CHECKLIST_DESCARGA_EXITO';
export const CHECKLIST_DESCARGA_ERROR = 'CHECKLIST_DESCARGA_ERROR';
export const CHECKLIST_OBTENER_COMENZAR = 'CHECKLIST_OBTENER_COMENZAR';
export const CHECKLIST_OBTENER_EXITO = 'CHECKLIST_OBTENER_EXITO';
export const CHECKLIST_OBTENER_ERROR = 'CHECKLIST_OBTENER_ERROR';
export const CHECKLIST_ESTADO_COMENZAR = 'CHECKLIST_ESTADO_COMENZAR';
export const CHECKLIST_ESTADO_EXITO = 'CHECKLIST_ESTADO_EXITO';
export const CHECKLIST_ESTADO_ERROR = 'CHECKLIST_ESTADO_ERROR';
export const CHECKLIST_PREGUNTA_DESCARGA_COMENZAR = 'CHECKLIST_PREGUNTA_DESCARGA_COMENZAR';
export const CHECKLIST_PREGUNTA_DESCARGA_EXITO = 'CHECKLIST_PREGUNTA_DESCARGA_EXITO';
export const CHECKLIST_PREGUNTA_DESCARGA_ERROR = 'CHECKLIST_PREGUNTA_DESCARGA_ERROR';
export const CHECKLIST_RESPONDER_COMENZAR = 'CHECKLIST_RESPONDER_COMENZAR';
export const CHECKLIST_RESPONDER_EXITO = 'CHECKLIST_RESPONDER_EXITO';
export const CHECKLIST_RESPONDER_ERROR = 'CHECKLIST_RESPONDER_ERROR';
export const CHECKLIST_REPORTE_COMENZAR = 'CHECKLIST_REPORTE_COMENZAR';
export const CHECKLIST_REPORTE_FULL_COMENZAR = 'CHECKLIST_REPORTE_FULL_COMENZAR';
export const CHECKLIST_REPORTE_EXITO = 'CHECKLIST_REPORTE_EXITO';
export const CHECKLIST_REPORTE_FULL_EXITO = 'CHECKLIST_REPORTE_FULL_EXITO';
export const CHECKLIST_REPORTE_ERROR = 'CHECKLIST_REPORTE_ERROR';
