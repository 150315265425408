import {
   PERSONAL_AGREGAR_COMENZAR,
   PERSONAL_AGREGAR_EXITO, 
   PERSONAL_AGREGAR_ERROR, 
   PERSONAL_DESCARGA_COMENZAR, 
   PERSONAL_DESCARGA_EXITO, 
   PERSONAL_DESCARGA_ERROR, 
   PERSONAL_ACTIVOS_DESCARGA_COMENZAR, 
   PERSONAL_ACTIVOS_DESCARGA_EXITO, 
   PERSONAL_ACTIVOS_DESCARGA_ERROR, 
   PERSONAL_ROL_DESCARGA_COMENZAR, 
   PERSONAL_ROL_DESCARGA_EXITO, 
   PERSONAL_ROL_DESCARGA_ERROR, 
   PERSONAL_OBTENER_COMENZAR,
   PERSONAL_OBTENER_EXITO,
   PERSONAL_OBTENER_ERROR,
   PERSONAL_EDITAR_COMENZAR,
   PERSONAL_EDITAR_EXITO,
   PERSONAL_EDITAR_ERROR,
   PERSONAL_REPORTE_COMENZAR, 
   PERSONAL_REPORTE_FULL_COMENZAR, 
   PERSONAL_REPORTE_EXITO, 
   PERSONAL_REPORTE_FULL_EXITO, 
   PERSONAL_REPORTE_ERROR,
   PERSONAL_ESTADO_COMENZAR,
   PERSONAL_ESTADO_EXITO,
   PERSONAL_ESTADO_ERROR,
} from '../types';

const initialState = {
   personales: [],
   size: 0,
   personalsActivos: [],
   personalsRoles: [],
   reportWeb: [],
   reportFull: [],
   currentPersonal: null,
   loading: null
};

export default function(state = initialState, action) {
   switch(action.type) {
      case PERSONAL_AGREGAR_COMENZAR:
      case PERSONAL_EDITAR_COMENZAR:
      case PERSONAL_OBTENER_COMENZAR:
      case PERSONAL_ESTADO_COMENZAR:
         return {
            ...state,
            currentPersonal: null,
            loading: true
         }
      case PERSONAL_AGREGAR_EXITO:
         return {
            ...state,
            personales: [...state.personales, action.payload],
            loading: false
         }
      case PERSONAL_OBTENER_EXITO:
         return {
            ...state,
            currentPersonal: action.payload,
            loading: false
         }
      case PERSONAL_EDITAR_EXITO:
         const currentPersonal = action.payload;

         let personalModify = state.personales.find(personal => personal.id === currentPersonal.id);
         personalModify.code = currentPersonal.code;
         personalModify.name = currentPersonal.name;
         personalModify.lastName = currentPersonal.lastName;
         personalModify.typeDocument = currentPersonal.typeDocument;
         personalModify.numDocument = currentPersonal.numDocument;
         personalModify.phone = currentPersonal.phone;
         personalModify.position = currentPersonal.position;
         personalModify.type = currentPersonal.type;
         personalModify.state = currentPersonal.state === "1" ? true : false;
         personalModify.birthday = currentPersonal.birthday;
         personalModify.admissionDate = currentPersonal.admissionDate;
         personalModify.user.code = currentPersonal.user.code;
         if (personalModify.work && currentPersonal.work) {
            personalModify.work = [{
               id: currentPersonal.work.PersonalStore.storeId,
               address: currentPersonal.work.address,
               PersonalStore: {
                  storeId: currentPersonal.work.PersonalStore.storeId
               }
            }];
         }

         return {
            ...state,
            personales: state.personales.filter(personal => {return personal.id === personalModify.id ? personalModify : personal}),
            loading: false
         }
      case PERSONAL_ESTADO_EXITO:
         const idPersonal = action.payload;
         let personalState = state.personales.find(personal => personal.id === idPersonal);
         personalState.state = !personalState.state
            
         return {
            ...state,
            personales: state.personales.filter(personal => personal.id === personalState.id ? personalState : personal),
            loading: false
         }
      case PERSONAL_AGREGAR_ERROR:
      case PERSONAL_EDITAR_ERROR:
      case PERSONAL_OBTENER_ERROR:
      case PERSONAL_ESTADO_ERROR:
         return {
            ...state,
            currentPersonal: null,
            loading: false
         }
      case PERSONAL_DESCARGA_COMENZAR:
         return {
            ...state,
            personales: [],
            loading: true
         }
      case PERSONAL_DESCARGA_EXITO:
         return {
            ...state,
            personales: action.payload.rows,
            size: Math.ceil(action.payload.count / action.payload.limit),
            loading: false
         }
      case PERSONAL_DESCARGA_ERROR:
         return {
            ...state,
            personales: [],
            size: 0,
            loading: false
         }
      case PERSONAL_ACTIVOS_DESCARGA_COMENZAR:
         return {
            ...state,
            personalsActivos: [],
            loading: true
         }
      case PERSONAL_ACTIVOS_DESCARGA_EXITO:
         return {
            ...state,
            personalsActivos: action.payload,
            loading: false
         }
      case PERSONAL_ACTIVOS_DESCARGA_ERROR:
         return {
            ...state,
            personalsActivos: null,
            loading: false
         }
      case PERSONAL_ROL_DESCARGA_COMENZAR:
         return {
            ...state,
            personalsRoles: [],
            loading: true
         }
      case PERSONAL_ROL_DESCARGA_EXITO:
         return {
            ...state,
            personalsRoles: action.payload,
            loading: false
         }
      case PERSONAL_ROL_DESCARGA_ERROR:
         return {
            ...state,
            personalsRoles: null,
            loading: false
         }
      case PERSONAL_REPORTE_COMENZAR:
         return {
            ...state,
            report: [],
            loading: true
         }
      case PERSONAL_REPORTE_FULL_COMENZAR:
         return {
            ...state,
            reportFull: [],
            loading: true
         }
      case PERSONAL_REPORTE_EXITO:
         return {
            ...state,
            reportWeb: action.payload.rows,
            size: Math.ceil(action.payload.count / action.payload.limit),
            loading: false
         }
      case PERSONAL_REPORTE_FULL_EXITO:
            return {
               ...state,
               reportFull: action.payload,
               loading: false
            }
      case PERSONAL_REPORTE_ERROR:
         return {
            ...state,
            report: [],
            reportFull: [],
            loading: false
         }
      default:
         return state;
   }
}