import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

import { buscarMessageAction } from "../../actions/messageActions";

import "react-datepicker/dist/react-datepicker.css";

registerLocale("es", es);

const Busqueda = () => {
   const dispatch = useDispatch();

   const personal = useSelector((state) => state.auth.user.personal);
   const [data, setData] = useState({
      subject: "",
      readed: "",
   });

   const [startDate, setStartDate] = useState("");
   const [endDate, setEndDate] = useState("");

   const { subject, readed } = data;

   const onChangeBuscar = (e) => {
      setData({
         ...data,
         [e.target.name]: e.target.value,
      });
   };

   const onHandleBuscarSubmit = (e) => {
      e.preventDefault();

      const dataObject = {
         personal_id: personal.id,
         subject,
         startDate,
         endDate,
         readed,
      };

      dispatch(buscarMessageAction(dataObject));
   };

   return (
      <Fragment>
         <div className="row pl-3 pr-3 pt-0">
            <div className="container">
               <form onSubmit={onHandleBuscarSubmit}>
                  <div className="btnsOptions ml-lg-3 mt-md-3 mt-xl-0 align-items-center">
                     <div className="row imputsCampos2">
                        <div id="busquedaMessage" className="col-12 col-lg-10 offset-lg-2">
                           <div className="row">
                              <h2 className="subTituloInterno pt-4 pb-2 pt-lg-5 pb-lg-5">
                                 Seleccionar filtro:
                              </h2>
                           </div>
                           <div className="row">
                              <label htmlFor="subject" className="labelFecha-1">
                                 Asunto:
                              </label>
                              <input
                                 name="subject"
                                 type="text"
                                 placeholder="Ingresar Asunto"
                                 value={subject}
                                 onChange={onChangeBuscar}
                              />

                              <label htmlFor="readed" className="labelCliente">
                                 Leido:
                              </label>
                              <select
                                 name="readed"
                                 value={readed}
                                 onChange={onChangeBuscar}
                              >
                                 <option value="">Todos</option>
                                 <option value="1">Si</option>
                                 <option value="0">No</option>
                              </select>
                           </div>
                           <div className="row mt-lg-3">
                              <label
                                 htmlFor="startDate"
                                 className="labelFecha-1"
                              >
                                 Desde:
                              </label>
                              <DatePicker
                                 name="startDate"
                                 dateFormat="dd/MM/yyyy"
                                 locale="es"
                                 selected={startDate}
                                 isClearable
                                 placeholderText="DD/MM/AAAA"
                                 maxDate={new Date()}
                                 value={startDate}
                                 onChange={(date) => setStartDate(date)}
                                 autoComplete="off"
                              />

                              <label htmlFor="endDate" className="labelFecha-1">
                                 Hasta:
                              </label>
                              <DatePicker
                                 name="endDate"
                                 dateFormat="dd/MM/yyyy"
                                 locale="es"
                                 selected={endDate}
                                 isClearable
                                 placeholderText="DD/MM/AAAA"
                                 maxDate={new Date()}
                                 value={endDate}
                                 onChange={(date) => setEndDate(date)}
                                 autoComplete="off"
                              />
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-12 col-lg-2 offset-lg-5 p-0 p-lg-3">
                           <input
                              type="submit"
                              className="btn-grisDark text-white btn-Solidez mt-4 w-100"
                              value="Buscar"
                           />
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </Fragment>
   );
};

export default Busqueda;
