import React from "react";

const TicketItem = ({ ticket }) => {
   return (
      <tr>
         <td>{ticket.description}</td>
         <td>{ticket.type}</td>
         <td>{ticket.period}</td>
         <td>{ticket.person}</td>
         <td>{ticket.typeDocument}</td>
         <td>{ticket.numDocument}</td>
         <td>{ticket.signing}</td>
         <td>{ticket.createdAt}</td>
         <td>{ticket.updatedAt ? ticket.updatedAt : ticket.updatedAt}</td>
      </tr>
   );
};

export default TicketItem;
