import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import eyesIcon from "../../assets/images/eye1.svg";
import pdfIcon from "../../assets/images/pdf_icon.svg";

export default function TicketItem({ ticket, handleOpenModal }) {
   const user = useSelector((state) => state.auth.user);

   const type = ticket.type;
   const year = ticket.period ? ticket.period.substring(0, 4) : '';
   const month = ticket.period ? ticket.period.substring(6, 4) : '';
   let descMonth = "";
   switch (month) {
      case "01":
         descMonth = "Enero";
         break;
      case "02":
         descMonth = "Febrero";
         break;
      case "03":
         descMonth = "Marzo";
         break;
      case "04":
         descMonth = "Abril";
         break;
      case "05":
         descMonth = "Mayo";
         break;
      case "06":
         descMonth = "Junio";
         break;
      case "07":
         descMonth = "Julio";
         break;
      case "08":
         descMonth = "Agosto";
         break;
      case "09":
         descMonth = "Setiembre";
         break;
      case "10":
         descMonth = "Octubre";
         break;
      case "11":
         descMonth = "Noviembre";
         break;
      case "12":
         descMonth = "Diciembre";
         break;
      default:
         break;
   }

   descMonth = type === "01" ? descMonth : type === '02' ? "CTS " + descMonth : 'Liquidación ' + descMonth;

   return (
      <div className="btn-boletas btn-Home-Solidez-Boletas d-flex p-1 d-flex align-items-center">
         <p className="mr-auto p-2 mb-0">
            {descMonth} {year}
         </p>
         <button type="button" onClick={() => handleOpenModal(ticket)}>
            <img src={eyesIcon} alt="" className=" p-2" />
         </button>
      </div>
   );
}
