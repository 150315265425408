import React from "react";
import { useSelector } from "react-redux";

const ExamItemReport2 = ({ exam }) => {
   const user = useSelector((state) => state.auth.user);
   const score = exam.personal && exam.personal[0].ExamPersonal && exam.personal[0].ExamPersonal.score !== null ? exam.personal[0].ExamPersonal.score.toFixed(2) : '-';

   return (
      <tr>
         <td>{exam.code}</td>
         <td>{exam.description}</td>
         <td>{exam.startDate}</td>
         <td>{exam.endDate}</td>
         <td>{exam.countQuestion}</td>
         <td>{exam.countAudience}</td>
         <td>{exam.countApproved}</td>
         <td>{exam.countDisapproved}</td>
         <td>{exam.countNotAnswerd}</td>
      </tr>
   );
};

export default ExamItemReport2;
