import React, { useState } from "react";
import { Modal, ModalTitle } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Pagination from '@mui/material/Pagination';

import { cambiarEstadoProductAction, buscarProductAction } from "../../actions/productActions";

import EditarProduct from "./EditarProduct";
import ProductItem from "./ProductItem";

const ListadoProduct = ({ data, setData }) => {
   const dispatch = useDispatch();

   const user = useSelector((state) => state.auth.user);
   const products = useSelector((state) => state.product.products);
   const size = useSelector((state) => state.product.size);

   const [showModal, setShowModal] = useState(false);
   const [currentProduct, setCurrentProduct] = useState({});

   const { limit, offset } = data;

   const handleCloseModal = () => {
      setShowModal(false);
   };

   const handleOpenModal = (product) => {
      setShowModal(true);
      setCurrentProduct(product);
   };

   const onHandleChange = (id) => {
      dispatch(cambiarEstadoProductAction(id));
   };

   const onChangePage = (e, page) => {
      const offset = 0 + (page - 1) * limit;

      setData({
         ...data,
         'offset': offset
      });
  
      dispatch(buscarProductAction(data, offset));
    }

    const changeValuePage = (e) => {
      setData({
         ...data,
         [e.target.name]: e.target.value
      });

      data.limit = Number(e.target.value);
  
      dispatch(buscarProductAction(data, offset));
    }

   return (
      <div className="container-fluid p-0">
         <div className="cajaTable ">
            <div className="resultadoTable d-none">
               <p className="txtResultado text-center">
                  No hay resultado de búsqueda
               </p>
            </div>
            <div class="container-fluid imputsCamposMostrar">
               <div class="row mt-lg-3">
                  <label htmlFor="limit" className="mr-3 mt-1">Mostrar resultados:</label>
                  <select name='limit' onChange={changeValuePage}>
                     <option value="10">10</option>
                     <option value="15">15</option>
                     <option value="25">25</option>
                     <option value="50">50</option>
                     <option value="100">100</option> 
                  </select> 
               </div>
         </div>
            <div className="cabeceraTable2 cuperoTable3 mt-3 mb-100">
               <table>
                  <thead>
                     <tr>
                        <th>Imagen</th>
                        <th>Producto</th>
                        <th>Tamaño</th>
                        <th>Precio</th>
                        {user.role === '05' ? (null) : (<th>Estado</th>)}
                        <th>Acción</th>
                     </tr>
                  </thead>
                  <tbody>
                     {products &&
                        products.map((product) => (
                           <ProductItem key={product.id} product={product} onHandleChange={onHandleChange} handleOpenModal={handleOpenModal}/>
                        ))}
                  </tbody>
               </table>
            </div>
            <Pagination style={{paddingBottom: '25px'}} count={size} onChange={onChangePage} />
         </div>

         <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
               <ModalTitle>
                  <h3 className="titulo text-center p-3">Editar producto</h3>
               </ModalTitle>
            </Modal.Header>
            <EditarProduct
               currentProduct={currentProduct}
               handleCloseModal={handleCloseModal}
            />
         </Modal>
      </div>
   );
};

export default ListadoProduct;
