import React from "react";

const CampaingItem = ({ campaing }) => {
   return (
      <tr>
         <td>{campaing.title}</td>
         <td>{campaing.description}</td>
         <td>{campaing.startDate}</td>
         <td>{campaing.endDate}</td>
         <td>{campaing.createdAt}</td>
         <td>{campaing.state ? 'Activo' : 'Inactivo'}</td>
      </tr>
   );
};

export default CampaingItem;
