import {
   TICKET_AGREGAR_COMENZAR,
   TICKET_AGREGAR_EXITO, 
   TICKET_AGREGAR_ERROR, 
   TICKET_DESCARGA_COMENZAR, 
   TICKET_DESCARGA_EXITO, 
   TICKET_DESCARGA_ERROR, 
   TICKET_DESCARGA_COMENZAR_TOTAL,
   TICKET_DESCARGA_EXITO_TOTAL, 
   TICKET_DESCARGA_ERROR_TOTAL, 
   TICKET_OBTENER_COMENZAR,
   TICKET_OBTENER_EXITO,
   TICKET_OBTENER_ERROR,
   TICKET_FIRMAR_COMENZAR,
   TICKET_FIRMAR_EXITO,
   TICKET_FIRMAR_ERROR,
   TICKET_YEAR_COMENZAR,
   TICKET_YEAR_EXITO,
   TICKET_YEAR_ERROR,
   TICKET_REPORTE_COMENZAR, 
   TICKET_REPORTE_EXITO, 
   TICKET_REPORTE_ERROR, 
} from '../types';

const initialState = {
   tickets: [],
   ticketsTotal: [],
   sizeTotal: 0,
   report: [],
   years: [],
   currentTicket: null,
   loading: null
};

export default function(state = initialState, action) {
   switch(action.type) {
      case TICKET_AGREGAR_COMENZAR:
      case TICKET_FIRMAR_COMENZAR:
      case TICKET_OBTENER_COMENZAR:
         return {
            ...state,
            currentTicket: null,
            loading: true
         }
      case TICKET_OBTENER_EXITO:
         return {
            ...state,
            currentTicket: action.payload,
            loading: false
         }
      case TICKET_AGREGAR_EXITO:
         const user = JSON.parse(localStorage.getItem('user'));
         const array = state.tickets;
         const auxArray = action.payload.filter(item => {return item.typeDocument === user.personal.typeDocument && item.numDocument === user.personal.numDocument});
         const newTickets = array.concat(auxArray);
         return {
            ...state,
            tickets: newTickets,
            currentTicket: auxArray,
            loading: false
         }
      case TICKET_FIRMAR_EXITO:
         const currentTicket = action.payload;
         let ticketModify = state.tickets.find(ticket => ticket.id === currentTicket.id);
         ticketModify.signing = currentTicket.signing;
         
         return {
            ...state,
            tickets: state.tickets.filter(ticket => ticket.id === ticketModify.id ? ticketModify : ticket),
            loading: false
         }
      case TICKET_AGREGAR_ERROR:
      case TICKET_FIRMAR_ERROR:
      case TICKET_OBTENER_ERROR:
         return {
            ...state,
            currentTicket: null,
            loading: false
         }
      case TICKET_DESCARGA_COMENZAR:
         return {
            ...state,
            tickets: [],
            loading: true
         }
      case TICKET_DESCARGA_COMENZAR_TOTAL:
         return {
            ...state,
            ticketsTotal: [],
            sizeTotal: 0,
            loading: true
         }
      case TICKET_DESCARGA_EXITO:
         return {
            ...state,
            tickets: action.payload,
            loading: false
         }
      case TICKET_DESCARGA_EXITO_TOTAL:
         return {
            ...state,
            ticketsTotal: action.payload.rows,
            sizeTotal: Math.ceil(action.payload.count / action.payload.limit),
            loading: false
         }
      case TICKET_DESCARGA_ERROR:
         return {
            ...state,
            tickets: [],
            loading: false
         }
      case TICKET_DESCARGA_ERROR_TOTAL:
         return {
            ...state,
            ticketsTotal: [],
            sizeTotal: 0,
            loading: false
         }
      case TICKET_YEAR_COMENZAR:
      case TICKET_YEAR_ERROR:
         return {
            ...state,
            years: [],
            loading: true
         }
      case TICKET_YEAR_EXITO:
         return {
            ...state,
            years: action.payload,
            loading: false
         }
      case TICKET_REPORTE_COMENZAR:
         return {
            ...state,
            report: [],
            loading: true
         }
      case TICKET_REPORTE_EXITO:
         return {
            ...state,
            report: action.payload,
            loading: false
         }
      case TICKET_REPORTE_ERROR:
         return {
            ...state,
            report: [],
            loading: false
         }
      default:
         return state;
   }
}