import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { obtenerRoleAction } from "./../actions/profileActions";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Busqueda from "../components/profile/Busqueda";
import ListProfile from "../components/profile/ListProfile";
import Loading from "../components/layout/Loading";

const Profile = () => {
   const dispatch = useDispatch();

   const [idRole, onHandleRole] = useState("");

   const loading = useSelector((state) => state.profile.loading);

   useEffect(() => {
      dispatch(obtenerRoleAction());
   }, []);

   return (
      <Fragment>
         <Header title={"ADMINISTRACIÓN DE PERFILES"} />
         <Loading loading={loading} />
         <Busqueda idRole={idRole} onHandleRole={onHandleRole}/>
         <ListProfile idRole={idRole}/>
         <Footer />
      </Fragment>
   );
};

export default Profile;
