import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import html2pdf from "html2pdf.js";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

import { reporteFullChecklistAction } from "../../../actions/checklistActions";

import excelIcon from "../../../assets/images/excel_icon.svg";
import pdfIcon from "../../../assets/images/pdf_icon.svg";

const TituloTabla = ({ data, startDate, endDate, type, setType }) => {
   const dispatch = useDispatch();

   const user = useSelector((state) => state.auth.user);
   const checklists = useSelector((state) => state.checklist.reportFull);

   const reportFull = () => {
      const dataObject = {
         personalId: data && data.personalId ? data.personalId : "",
         storeId: data && data.storeId ? data.storeId : "",
         startDate: startDate ? startDate : "",
         endDate: endDate ? endDate : "",
      };

      dispatch(reporteFullChecklistAction(dataObject));
   };

   const exportarPdf = () => {
      setType("pdf");

      reportFull();
   };

   const exportarExcel = () => {
      setType("excel");

      reportFull();
   };

   useEffect(() => {
      if (checklists.length > 0) {
         if (type === 'pdf') {
            let configuracion = {
               margin: 0.1,
               filename: "Reporte_Checklist.pdf",
               html2canvas: { scale: 1 },
               jsPDF: { unit: "pt", format: "A4", orientation: "l" },
            };
      
            const documento = document.getElementById("tablaReporteChecklist");
            html2pdf(documento, configuracion);
         } else if (type === 'excel') {
            const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
         const ws = XLSX.utils.json_to_sheet(checklists);
         ws.A1.v = "Identificador";
         ws.B1.v = "Descripción";
         ws.C1.v = "Personal";
         ws.D1.v = "Tienda";
         ws.E1.v = "Metros";
         ws.F1.v = "Pregunta";
         ws.G1.v = "Respuesta";
         ws.H1.v = "Fecha";
         const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
         const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
         });
         const data = new Blob([excelBuffer], { type: fileType });
         FileSaver.saveAs(data, "Campañas.xlsx");
         }
      }
   }, [checklists]);

   return (
      <Fragment>
         <div className="container-fluid mt-md-4 p-0 mt-5 pt-5">
            <div className="col-7 col-md-3 col-lg-12 text-center text-lg-right p-0 pt-4 pb-4 pb-lg-0 pr-lg-4">
               {user &&
               (user.role === "01" ||
                  user.role === "02" ||
                  user.role === "03") ? (
                  <div>
                     <button type="button" onClick={() => exportarPdf()}>
                        <img
                           src={pdfIcon}
                           className="mr-2 iconTabla2"
                           alt="Exportar a PDF"
                        />
                     </button>{" "}
                     <button type="button" onClick={() => exportarExcel()}>
                        <img
                           src={excelIcon}
                           className="mr-2 iconTabla2"
                           alt="Exportar a Excel"
                        />
                     </button>
                  </div>
               ) : null}
            </div>
         </div>
      </Fragment>
   );
};

export default TituloTabla;
