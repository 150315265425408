import {
   MESSAGE_AGREGAR_COMENZAR,
   MESSAGE_AGREGAR_EXITO, 
   MESSAGE_AGREGAR_ERROR, 
   MESSAGE_DESCARGA_COMENZAR, 
   MESSAGE_DESCARGA_EXITO, 
   MESSAGE_DESCARGA_ERROR,
   MESSAGE_OBTENER_COMENZAR,
   MESSAGE_OBTENER_EXITO,
   MESSAGE_OBTENER_ERROR,
   MESSAGE_LEER_COMENZAR,
   MESSAGE_LEER_EXITO,
   MESSAGE_LEER_ERROR,
   MESSAGE_CANTIDAD_COMENZAR,
   MESSAGE_CANTIDAD_EXITO,
   MESSAGE_CANTIDAD_ERROR,
} from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

// ---------- CREAR MENSAJE ----------
export function crearMessageAction(data) {
   return async (dispatch) => {
      dispatch(agregarMessage());

      try {
         const response = await clienteAxios.post("/messages", data);
         dispatch(agregarMessageExito(response.data));

         if (response.status === 201) {
            Swal.fire("Correcto", "El mensaje se envió exitosamente", "success");
         } else {
            Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
         }
      } catch (error) {
         const code = error.response.status;
         let mensaje = "";
         if (code === 409) {
            mensaje = error.response.data.message;
         } else {
            mensaje = "Hubo un error, intenta de nuevo";
         }
         dispatch(agregarMessageError(true));

         Swal.fire({icon: "error", title: "Hubo un error", text: mensaje});
      }
   }
}

const agregarMessage = () => ({
   type: MESSAGE_AGREGAR_COMENZAR
})

const agregarMessageExito = data => ({
   type: MESSAGE_AGREGAR_EXITO,
   payload: data
})

const agregarMessageError = estado => ({
   type: MESSAGE_AGREGAR_ERROR,
   payload: estado
})

// ---------- BUSCAR MENSAJES ----------
export function buscarMessageAction(data) {
   return async (dispatch) => {
         dispatch(descargarMessage());

         let query = '';
         if (data.sendedById && data.sendedById !== '') {
            query = query + `sendedById=${data.sendedById}&`;
         }
         if (data.startDate && data.startDate !== '') {
            query = query + `startDate=${data.startDate}&`;
         }
         if (data.endDate && data.endDate !== '') {
            query = query + `endDate=${data.endDate}&`;
         }
         if (data.readed && data.readed !== '') {
            query = query + `readed=${data.readed}&`;
         }
         if (data.readed && data.readed !== '') {
            query = query + `readed=${data.readed}&`;
         }
         if (data.personal_id && data.personal_id !== '') {
            query = query + `personal_id=${data.personal_id}&`;
         }

         const url = query === '' ? '/messages' : '/messages?' + query;

         try {
            const resultado = await clienteAxios.get(url);

            dispatch(descargarMessageExito(resultado.data))
         } catch (error) {
            dispatch(descargarMessageError());
         }
   }
}

const descargarMessage = () => ({
   type: MESSAGE_DESCARGA_COMENZAR,
   payload: true
})

const descargarMessageExito = data => ({
   type: MESSAGE_DESCARGA_EXITO,
   payload: data
})

const descargarMessageError = () => ({
   type: MESSAGE_DESCARGA_ERROR,
   payload: true
})

// ---------- OBTENER PERSONAL ----------
export function obtenerMessageAction(id) {
   return async (dispatch) => {
      if (id) {
         dispatch(descargarObtenerMessage());

         try {
            const resultado = await clienteAxios.get(`/messages/${id}`);

            dispatch(descargarObtenerMessageExito(resultado.data))
         } catch (error) {
            dispatch(descargarObtenerMessageError());
         }
      }
   }
}

const descargarObtenerMessage = () => ({
   type: MESSAGE_OBTENER_COMENZAR,
   payload: true
})

const descargarObtenerMessageExito = data => ({
   type: MESSAGE_OBTENER_EXITO,
   payload: data
})

const descargarObtenerMessageError = () => ({
   type: MESSAGE_OBTENER_ERROR,
   payload: true
})

// ---------- LEER MENSAJE ----------
export function leerMensajeAction(id, data) {
   return async (dispatch) => {
      dispatch(leerMensaje());

      try {
         const resultado = await clienteAxios.patch(`/messages/${id}`, data);

         dispatch(leerMensajeExito(id));
      } catch (error) {
         dispatch(leerMensajeError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Se produjo un error al leer el mensaje"});
      }
   }
}

const leerMensaje = () => ({
   type: MESSAGE_LEER_COMENZAR,
   payload: true
})

const leerMensajeExito = data => ({
   type: MESSAGE_LEER_EXITO,
   payload: data
})

const leerMensajeError = () => ({
   type: MESSAGE_LEER_ERROR,
   payload: true
})

// ---------- CANTIDAD MENSAJES ----------
export function cantidadMessageAction(personalId) {
   return async (dispatch) => {
      if (personalId) {
         dispatch(descargarCantidadMessage());

         try {
            const resultado = await clienteAxios.get(`/messages/count/${personalId}`);
            dispatch(descargarCantidadMessageExito(resultado.data))
         } catch (error) {
            dispatch(descargarCantidadMessageError());
         }
      }
   }
}

const descargarCantidadMessage = () => ({
   type: MESSAGE_CANTIDAD_COMENZAR,
   payload: true
})

const descargarCantidadMessageExito = data => ({
   type: MESSAGE_CANTIDAD_EXITO,
   payload: data
})

const descargarCantidadMessageError = () => ({
   type: MESSAGE_CANTIDAD_ERROR,
   payload: true
})