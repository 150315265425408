import {
   COMMISSION_AGREGAR_COMENZAR,
   COMMISSION_AGREGAR_EXITO,
   COMMISSION_AGREGAR_ERROR,
   COMMISSION_DESCARGA_COMENZAR,
   COMMISSION_DESCARGA_EXITO,
   COMMISSION_DESCARGA_ERROR,
   COMMISSION_OBTENER_COMENZAR,
   COMMISSION_OBTENER_EXITO,
   COMMISSION_OBTENER_ERROR,
   COMMISSION_EDITAR_COMENZAR,
   COMMISSION_EDITAR_EXITO,
   COMMISSION_EDITAR_ERROR,
   COMMISSION_ESTADO_COMENZAR,
   COMMISSION_ESTADO_EXITO,
   COMMISSION_ESTADO_ERROR,
   COMMISSION_META_COMENZAR,
   COMMISSION_META_EXITO,
   COMMISSION_META_ERROR,
   COMMISSION_DETALLE_COMENZAR,
   COMMISSION_DETALLE_EXITO,
   COMMISSION_DETALLE_ERROR,
   COMMISSION_PRECIO_COMENZAR,
   COMMISSION_PRECIO_EXITO,
   COMMISSION_PRECIO_ERROR,
   COMMISSION_COMISION_COMENZAR,
   COMMISSION_COMISION_EXITO,
   COMMISSION_COMISION_ERROR,
} from "../types";
import clienteAxios from "../config/axios";
import Swal from "sweetalert2";

// ---------- CREAR COMISION ----------
export function crearCommissionAction(data, name, year, month) {
   return async (dispatch) => {
      dispatch(agregarCommission());

      try {
         const response = await clienteAxios.post("/commissions", data);

         response.data.personal = new Object();
         response.data.personal.name = name;
         response.data.personal.lastName = '';
         response.data.year = year;
         response.data.month = month;

         dispatch(agregarCommissionExito(response.data));

         if (response.status === 201) {
            Swal.fire(
               "Correcto",
               "La comisión se registró correctamente",
               "success"
            );
         } else {
            Swal.fire({
               icon: "error",
               title: "Hubo un error",
               text: "Hubo un error, intenta de nuevo",
            });
         }
      } catch (error) {
         const code = error.response.status;
         let mensaje = "";
         if (code === 409) {
            mensaje = error.response.data.message;
         } else {
            mensaje = "Hubo un error, intenta de nuevo";
         }
         dispatch(agregarCommissionError(true));

         Swal.fire({ icon: "error", title: "Hubo un error", text: mensaje });
      }
   };
}

const agregarCommission = () => ({
   type: COMMISSION_AGREGAR_COMENZAR,
});

const agregarCommissionExito = (data) => ({
   type: COMMISSION_AGREGAR_EXITO,
   payload: data,
});

const agregarCommissionError = (estado) => ({
   type: COMMISSION_AGREGAR_ERROR,
   payload: estado,
});

// ---------- BUSCAR COMISIONES ----------
export function buscarCommissionAction(data, offset) {
   return async (dispatch) => {
      dispatch(descargarCommission());

      let query = `limit=${data.limit}&offset=${offset}&`;
      if (data.personalId && data.personalId !== "") {
         query = query + `personalId=${data.personalId}&`;
      }
      if (data.year && data.year !== "") {
         query = query + `year=${data.year}&`;
      }
      if (data.month && data.month !== "") {
         query = query + `month=${data.month}&`;
      }
      if (data.state && data.state !== "") {
         query = query + `state=${data.state}&`;
      }

      const url = query === "" ? "/commissions" : "/commissions?" + query;

      try {
         const resultado = await clienteAxios.get(url);

         dispatch(descargarCommissionExito(resultado.data));
      } catch (error) {
         dispatch(descargarCommissionError());
      }
   };
}

const descargarCommission = () => ({
   type: COMMISSION_DESCARGA_COMENZAR,
   payload: true,
});

const descargarCommissionExito = (data) => ({
   type: COMMISSION_DESCARGA_EXITO,
   payload: data,
});

const descargarCommissionError = () => ({
   type: COMMISSION_DESCARGA_ERROR,
   payload: true,
});

// ---------- OBTENER COMISION ----------
export function obtenerCommissionAction(id) {
   return async (dispatch) => {
      if (id) {
         dispatch(descargarObtenerCommission());

         try {
            const resultado = await clienteAxios.get(`/commissions/${id}`);

            dispatch(descargarObtenerCommissionExito(resultado.data));
         } catch (error) {
            dispatch(descargarObtenerCommissionError());
         }
      }
   };
}

const descargarObtenerCommission = () => ({
   type: COMMISSION_OBTENER_COMENZAR,
   payload: true,
});

const descargarObtenerCommissionExito = (data) => ({
   type: COMMISSION_OBTENER_EXITO,
   payload: data,
});

const descargarObtenerCommissionError = () => ({
   type: COMMISSION_OBTENER_ERROR,
   payload: true,
});

// ---------- ACTUALIZAR COMISION ----------
export function actualizarCommissionAction(id, data, year, month) {
   return async (dispatch) => {
      dispatch(actualizarCommission());

      try {
         await clienteAxios.patch(`/commissions/${id}`, data);

         data.id = id;
         data.year = year;
         data.month = month;
         dispatch(actualizarCommissionExito(data));
         Swal.fire(
            "Correcto",
            "La comisión se actualizó correctamente",
            "success"
         );
      } catch (error) {
         dispatch(actualizarCommissionError());
         Swal.fire({
            icon: "error",
            title: "Hubo un error",
            text: "Hubo un error, intenta de nuevo",
         });
      }
   };
}

const actualizarCommission = () => ({
   type: COMMISSION_EDITAR_COMENZAR,
});

const actualizarCommissionExito = (data) => ({
   type: COMMISSION_EDITAR_EXITO,
   payload: data,
});

const actualizarCommissionError = () => ({
   type: COMMISSION_EDITAR_ERROR,
   payload: true,
});

// ---------- CAMBIAR ESTADO ----------
export function cambiarEstadoCommissionAction(id) {
   return async (dispatch) => {
      dispatch(cambiarEstadoCommission());

      try {
         await clienteAxios.patch(`/commissions/state/${id}`);

         dispatch(cambiarEstadoCommissionExito(id));
         //Swal.fire("Correcto", "Se cambio el estado correctamente", "success");
      } catch (error) {
         dispatch(cambiarEstadoCommissionError());
         Swal.fire({
            icon: "error",
            title: "Hubo un error",
            text: "Hubo un error, intenta de nuevo",
         });
      }
   };
}

const cambiarEstadoCommission = () => ({
   type: COMMISSION_ESTADO_COMENZAR,
});

const cambiarEstadoCommissionExito = (id) => ({
   type: COMMISSION_ESTADO_EXITO,
   payload: id,
});

const cambiarEstadoCommissionError = () => ({
   type: COMMISSION_ESTADO_ERROR,
   payload: true,
});

// ---------- OBTENER META ----------
export function obtenerMetaCommissionAction(personalId, period) {
   return async (dispatch) => {
      dispatch(obtenerMetaCommission());

      try {
         const response = await clienteAxios.get(`/commissions/goal?personalId=${personalId}&period=${period}`);
         dispatch(obtenerMetaCommissionExito(response.data));
      } catch (error) {
         dispatch(obtenerMetaCommissionError());
         /*Swal.fire({
            icon: "error",
            title: "Hubo un error",
            text: "Hubo un error, intenta de nuevo",
         });*/
      }
   };
}

const obtenerMetaCommission = () => ({
   type: COMMISSION_META_COMENZAR,
});

const obtenerMetaCommissionExito = (data) => ({
   type: COMMISSION_META_EXITO,
   payload: data,
});

const obtenerMetaCommissionError = () => ({
   type: COMMISSION_META_ERROR,
   payload: true,
});

// ---------- OBTENER DETALLE ----------
export function buscarCommissionDetalleAction(data, offset) {
   return async (dispatch) => {
      dispatch(descargarCommissionDetalle());

      let query = '';
      //let query = `limit=${data.limit}&offset=${offset}&`;
      if (data.personalId && data.personalId !== "") {
         query = query + `personalId=${data.personalId}&`;
      }
      if (data.period && data.period !== "") {
         query = query + `period=${data.period}`;
      }

      const url = query === "" ? "/commissions/detail" : "/commissions/detail?" + query;

      try {
         const resultado = await clienteAxios.get(url);

         dispatch(descargarCommissionDetalleExito(resultado.data));
      } catch (error) {
         dispatch(descargarCommissionDetalleError());
      }
   };
}

const descargarCommissionDetalle = () => ({
   type: COMMISSION_DETALLE_COMENZAR,
   payload: true,
});

const descargarCommissionDetalleExito = (data) => ({
   type: COMMISSION_DETALLE_EXITO,
   payload: data,
});

const descargarCommissionDetalleError = () => ({
   type: COMMISSION_DETALLE_ERROR,
   payload: true,
});

// ---------- ACTUALIZAR PRECIO ----------
export function cambiarPrecioCommissionAction(id, priceAnterior, data) {
   return async (dispatch) => {
      dispatch(cambiarPrecioCommission());

      try {
         const result = await clienteAxios.patch(`/commissions/price/${id}`, data);
         result.data.priceAnterior = priceAnterior;

         dispatch(cambiarPrecioCommissionExito(result.data));
         Swal.fire("Correcto", "Se actualizó el precio correctamente", "success");
      } catch (error) {
         dispatch(cambiarPrecioCommissionError());
         Swal.fire({
            icon: "error",
            title: "Hubo un error",
            text: "Hubo un error, intenta de nuevo",
         });
      }
   };
}

const cambiarPrecioCommission = () => ({
   type: COMMISSION_PRECIO_COMENZAR,
});

const cambiarPrecioCommissionExito = (id) => ({
   type: COMMISSION_PRECIO_EXITO,
   payload: id,
});

const cambiarPrecioCommissionError = () => ({
   type: COMMISSION_PRECIO_ERROR,
   payload: true,
});

// ---------- CALCULAR COMISION ----------
export function calcularComisionCommissionAction(data) {
   return async (dispatch) => {
      dispatch(calcularComisionCommission());

      try {
         const result = await clienteAxios.post(`/commissions/amount`, data);

         dispatch(calcularComisionCommissionExito(result.data));
         Swal.fire("Correcto", "Se calculó la comisión correctamente correctamente", "success");
      } catch (error) {
         dispatch(calcularComisionCommissionError());
         Swal.fire({
            icon: "error",
            title: "Hubo un error",
            text: "Hubo un error, intenta de nuevo",
         });
      }
   };
}

const calcularComisionCommission = () => ({
   type: COMMISSION_COMISION_COMENZAR,
});

const calcularComisionCommissionExito = (id) => ({
   type: COMMISSION_COMISION_EXITO,
   payload: id,
});

const calcularComisionCommissionError = () => ({
   type: COMMISSION_COMISION_ERROR,
   payload: true,
});