import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import eyesIcon from "../../assets/images/eye1.svg";
import pdfIcon from "../../assets/images/pdf_icon.svg";

export default function TicketItemTodos({ ticket, handleOpenModal }) {
   const user = useSelector((state) => state.auth.user);

   const type = ticket.type;
   const year = ticket.period ? ticket.period.substring(0, 4) : '';
   const month = ticket.period ? ticket.period.substring(6, 4) : '';
   let descMonth = "";
   switch (month) {
      case "01":
         descMonth = "Enero";
         break;
      case "02":
         descMonth = "Febrero";
         break;
      case "03":
         descMonth = "Marzo";
         break;
      case "04":
         descMonth = "Abril";
         break;
      case "05":
         descMonth = "Mayo";
         break;
      case "06":
         descMonth = "Junio";
         break;
      case "07":
         descMonth = "Julio";
         break;
      case "08":
         descMonth = "Agosto";
         break;
      case "09":
         descMonth = "Setiembre";
         break;
      case "10":
         descMonth = "Octubre";
         break;
      case "11":
         descMonth = "Noviembre";
         break;
      case "12":
         descMonth = "Diciembre";
         break;
      default:
         break;
   }

   descMonth = type === "01" ? descMonth : "CTS " + descMonth;

   return (
      <tr>
         <td>{ticket.id}</td>
         <td>{descMonth} {year}</td>
         <td>{ticket.type === '01' ? 'BOLETA' : 'CTS'}</td>
         <td>{ticket.description}</td>
         <td>{ticket.person}</td>
         <td>{ticket.typeDocument === '01' ? 'DNI' : ticket.typeDocument === '02' ? 'Pasaporte' : 'Carnet de Extranjeria'}</td>
         <td>{ticket.numDocument}</td>
         <td>
         <a href={ticket.url} target="_blank" alt="" rel="noreferrer">
               <img src={pdfIcon} className="mr-2 iconTabla" alt="Ver" />
            </a>
         </td>
      </tr>
   );
}
