import React, { useState } from "react";
import { Modal, ModalTitle } from "react-bootstrap";
import { useSelector } from "react-redux";

import VerMessage from "./VerMessage";
import MessageItem from "./MessageItem";

const ListadoMessage = () => {

   const messages = useSelector((state) => state.message.messages);

   const [showModal, setShowModal] = useState(false);
   const [currentMessage, setCurrentMessage] = useState({});

   const handleCloseModal = () => {
      setShowModal(false);
   };

   const handleOpenModal = (message) => {
      setShowModal(true);
      setCurrentMessage(message);
   };

   return (
      <div className="container-fluid p-0">
         <div className="cajaTable ">
            <div className="resultadoTable d-none">
               <p className="txtResultado text-center">
                  No hay resultado de búsqueda
               </p>
            </div>
            <div className="cabeceraTable2 cuperoTable3 mt-3 mb-100">
               <table>
                  <thead>
                     <tr>
                        <th>Asunto</th>
                        <th>Fecha</th>
                        <th>Enviado Por</th>
                        <th>Ver</th>
                     </tr>
                  </thead>
                  <tbody>
                     {messages &&
                        messages.map((message) => (
                           <MessageItem key={message.id} message={message} handleOpenModal={handleOpenModal}/>
                        ))}
                  </tbody>
               </table>
            </div>
         </div>

         <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
               <ModalTitle>
                  <h3 className="titulo text-center p-3">Ver mensaje</h3>
               </ModalTitle>
            </Modal.Header>
            <VerMessage
               currentMessage={currentMessage}
               handleCloseModal={handleCloseModal}
            />
         </Modal>
      </div>
   );
};

export default ListadoMessage;
