import React from "react";

const Footer = () => {
   return (
      <div className="row mt-5 pt-5">
         <div className="fotoFooter d-block d-lg-flex align-items-center">
            <div className="col-12 col-md-6 ">
               <p className="c-link mt-2 m-lg-0">
                  Copyright © {new Date().getFullYear()}, Solidez Empresarial – Todos los derechos
                  reservados.{" "}
               </p>
            </div>
            <div className="col-12 col-md-6 text-lg-right">
               <p className="c-link mt-2 m-lg-0">
                  Powered by{" "}
                  <a
                     href="https://putuquia.com"
                     target="_blank"
                     rel="noreferrer"
                  >
                     Putuquia
                  </a>
               </p>
            </div>
         </div>
      </div>
   );
};

export default Footer;
