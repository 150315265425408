export default class Question {
   static TYPES = Object.freeze({
     SINGLE: {description: "Opción: Selección única", value: '01'},
     MULTIPLE: {description: "Opción: Selección múltiple", value: '02'},
     TEXT: {description: "Respuesta corta", value: '03'}
   });
 
   static DEFAULTS = Object.freeze({
     text: "Nueva Pregunta",
     type: Question.TYPES.SINGLE.value,
     options: [],
   });
 
   constructor(params = {}) {
     const { text, type, options, hasOptions, id } = { ...Question.DEFAULTS, ...params };
     this.text = text;
     this.type = type;
     this.options = options;
     this.id = id || Math.random();
   }
 
   get hasOptions() {
     return (
       this.type === Question.TYPES.SINGLE.value ||
       this.type === Question.TYPES.MULTIPLE.value
     );
   }
 
   get inputType() {
     if (this.type === Question.TYPES.SINGLE.value) return "radio";
     if (this.type === Question.TYPES.MULTIPLE.value) return "checkbox";
     throw new Error("This question does not have an input type.");
   }
 
   merge(patch) {
     return new Question({ ...this, ...patch });
   }
 }
 