import React, { useState } from "react";

export default function Answer({ number, answers, description, isCorrect, group, onHandleAnswerChange }) {
   /*const [data, setData] = useState({
      selected: "",
      description: "",
   });

   const { selected, description } = data;

   const onChangeQuestion = (e) => {
      setData({
         ...data,
         [e.target.name]: e.target.value,
      });
   };*/

   return (
      <div>
         <label>
         {" "}
         <input
            name={group}
            type="radio"
            value={isCorrect}
            onChange={onHandleAnswerChange(number - 1)}
         />
         {" "}
         <input
            name="description"
            type="text"
            className="inputRespuestas"
            placeholder="Ingresar Respuesta"
            value={description}
            onChange={onHandleAnswerChange(number - 1)}
         />
      </label>
      </div>
   );
}
