import React, { useState, useEffect, Fragment } from "react";
import { Modal, ModalTitle, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
   actualizarCommissionAction,
   buscarCommissionDetalleAction,
} from "../../actions/commissionActions";
import { MONTHS } from "../../utils/Constans";

import { getYears } from "../../utils/functions";

import editIcon from "../../assets/images/edit.svg";
import EditarPrice from "./EditarPrice";

const EditarCommission = ({ currentCommission, handleCloseModal }) => {
   const dispatch = useDispatch();

   const detail = useSelector((state) => state.commission.details);

   const [showModal, setShowModal] = useState(false);
   const [data, onHandleData] = useState({
      personalId: currentCommission.personalId,
      name:
         currentCommission.personal.name +
         " " +
         currentCommission.personal.lastName,
      amount: currentCommission.amount,
      year: currentCommission.period.slice(0, 4),
      month: currentCommission.period.substring(4, 6),
      state: currentCommission.state ? "1" : "0",
   });

   const [dataPrice, onHandleDataPrice] = useState({
      id: 0,
      personalId: currentCommission.personalId,
      price: '',
      period: '',

   });

   const { personalId, name, amount, year, month, state } = data;

   const YEARS = getYears();

   const handleCloseModal2 = () => {
      setShowModal(false);
   };

   const handleOpenModal2 = (item) => {
      setShowModal(true);
      onHandleDataPrice({
         ...dataPrice,
         id: item.id,
         price: item.price,
         period: item.period
      });
   };

   const onChangeData = (e) => {
      onHandleData({
         ...data,
         [e.target.name]: e.target.value,
      });
   };

   const onHandleSubmit = (e) => {
      e.preventDefault();

      const monthDesc =
         month === "01"
            ? "Enero"
            : month === "02"
            ? "Febrero"
            : month === "03"
            ? "Marzo"
            : month === "04"
            ? "Abril"
            : month === "05"
            ? "Mayo"
            : month === "06"
            ? "Junio"
            : month === "07"
            ? "Julio"
            : month === "08"
            ? "Agosto"
            : month === "09"
            ? "Setiembre"
            : month === "10"
            ? "Octubre"
            : month === "11"
            ? "Noviembre"
            : "Diciembre";

      const dataObject = {
         amount: Number(amount),
         period: year + month,
         state,
      };

      dispatch(
         actualizarCommissionAction(
            currentCommission.id,
            { ...dataObject },
            year,
            monthDesc
         )
      );

      e.target.reset();
      handleCloseModal();
   };

   useEffect(() => {
      const data = {
         personalId: currentCommission.personalId,
         period: currentCommission.period,
      };
      dispatch(buscarCommissionDetalleAction(data));
   }, []);

   return (
      <Fragment>
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                     <div className="fform-group col-12 col-md-12">
                        <label htmlFor="name" className="c-grisDark mb-0">
                           Personal:
                        </label>
                        <input
                           type="text"
                           name="name"
                           className="form-control"
                           value={name}
                           disabled
                        />
                     </div>
                     <div className="form-group col-6 imputsCampos2 mt-0">
                        <label htmlFor="year" className="c-grisDark mb-0">
                           Año:
                        </label>
                        <select
                           name="year"
                           className="w-100"
                           value={year}
                           onChange={onChangeData}
                           required
                           disabled
                        >
                           <option value="">Seleccionar Año</option>
                           {YEARS &&
                              YEARS.length > 0 &&
                              YEARS.map((year) => (
                                 <option key={year.value} value={year.value}>
                                    {year.description}
                                 </option>
                              ))}
                        </select>
                     </div>
                     <div className="form-group col-6 imputsCampos2 mt-0">
                        <label htmlFor="month" className="c-grisDark mb-0">
                           Mes:
                        </label>
                        <select
                           name="month"
                           className="w-100"
                           value={month}
                           onChange={onChangeData}
                           required
                           disabled
                        >
                           <option value="">Seleccionar Mes</option>
                           {MONTHS &&
                              MONTHS.length > 0 &&
                              MONTHS.map((month) => (
                                 <option key={month.value} value={month.value}>
                                    {month.description}
                                 </option>
                              ))}
                        </select>
                     </div>
                     {/*<div className="form-group col-12 col-md-6">
                        <label htmlFor="amount" className="c-grisDark mb-0">
                           Comisión:
                        </label>
                        <input
                           type="text"
                           name="amount"
                           className="form-control"
                           placeholder="Ingrese la comisión"
                           value={amount}
                           onChange={onChangeData}
                           required
                        />
                     </div>*/}
                  </div>
                  {/*<div className="row">
                     <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                        <label
                           htmlFor="state"
                           className="labelCliente mb-0 pt-0"
                        >
                           Estado:
                        </label>
                        <select
                           name="state"
                           className="w-100"
                           value={state}
                           onChange={onChangeData}
                           required
                        >
                           <option value="1">Activo</option>
                           <option value="0">Inactivo</option>
                        </select>
                     </div>
                  </div>*/}
                  <div className="row">
                     <div className="cabeceraTable2 cuperoTable3 mt-3 mb-100">
                        <table>
                           <thead>
                              <tr>
                                 <th>Precio</th>
                                 <th>Fecha</th>
                                 <th>Edicion</th>
                              </tr>
                           </thead>
                           <tbody>
                              {detail &&
                                 detail.map((item) => (
                                    <tr>
                                       <td>{item.price}</td>
                                       <td>{item.createdAt}</td>
                                       <td>
                                       <button type="button" onClick={() => handleOpenModal2(item)}>
                                          <img src={editIcon} className="mr-2 iconTabla" alt="Calcular comisión" />
                                       </button>
                                       </td>
                                    </tr>
                                 ))}
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               {/*<button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="submit"
               >
                  Guardar
               </button>*/}
            </Modal.Footer>
         </Form>

         <Modal show={showModal} onHide={handleCloseModal2}>
            <Modal.Header closeButton>
               <ModalTitle>
                  <h3 className="titulo text-center p-3">Editar Precio</h3>
               </ModalTitle>
            </Modal.Header>
            <EditarPrice
               currentCommission={dataPrice}
               handleCloseModal={handleCloseModal2}
            />
         </Modal>
      </Fragment>
   );
};

export default EditarCommission;
