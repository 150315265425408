import React from "react";

import eyesIcon from "../../assets/images/eye.svg";

const MessageItem = ({ message, handleOpenModal }) => {
   const readed = message.personal && message.personal[0] && message.personal[0].MessagePersonal ? message.personal[0].MessagePersonal.readed : false;

   return (
      <tr style={{backgroundColor: readed ? null : '#D6EEEE'}}>
         <td>{message.subject}</td>
         <td>{message.createdAt}</td>
         <td>{message.sendedBy.name + " " + message.sendedBy.lastName}</td>
         <td>
            <button type="button" onClick={() => handleOpenModal(message)}>
               <img src={eyesIcon} className="mr-2 iconTabla" alt="Ver" />
            </button>
         </td>
      </tr>
   );
};

export default MessageItem;
