import React, { useState } from "react";
import { useSelector } from "react-redux";

import ExamItemReport2 from "./ExamItemReport2";

const ListadoExamReport2 = () => {
   const exams = useSelector((state) => state.exam.report2);
   const user = useSelector((state) => state.auth.user);

   return (
      <div id="tablaReporteExamenReport2" className="container-fluid p-0">
         <div className="col-12">
               <h2 className="titulo text-center">Reporte Total de Exámenes</h2>
            </div>
         <div className="cajaTable ">
            <div className="resultadoTable d-none">
               <p className="txtResultado text-center">
                  No hay resultado de búsqueda
               </p>
            </div>
            <div className="cabeceraTable2 cuperoTable3 mt-3 mb-100">
               <table>
                  <thead>
                     <tr>
                        <th>Código</th>
                        <th>Descripción</th>
                        <th>Fecha Inicio</th>
                        <th>Fecha Fin</th>
                        <th>Total Preguntas</th>
                        <th>Total Personas</th>
                        <th>Total Aprobados</th>
                        <th>Total Desaprobados</th>
                        <th>Total No Respondieron</th>
                     </tr>
                  </thead>
                  <tbody>
                     {exams &&
                        exams.map((exam) => (
                           <ExamItemReport2
                              key={exam.id}
                              exam={exam}
                           />
                        ))}
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   );
};

export default ListadoExamReport2;
