import React, { useState } from "react";
import Swal from 'sweetalert2';

import Answer from "./Answer";

export default function Question({
   number,
   description,
   answers,
   listQuestion,
   setListQuestion,
   onHandleQuestionChange,
}) {

   const [listAnswer, setListAnswer] = useState([
      {
         description: "",
         isCorrect: false,
      },
   ]);

   const onHandleAnswerChange = (idx) => (evt) => {
      let newList = [...listAnswer];
      let newAnswer;
      if (evt.target.name === 'description') {
          newAnswer = listAnswer.map((answer, sidx) => {
            if (idx !== sidx) return answer;
            return { ...answer, description: evt.target.value };
         });
         newList[idx] = newAnswer[idx];
         setListAnswer(newList);
      } else {
         newAnswer = listAnswer.map((answer, sidx) => {
            if (idx !== sidx) return { ...answer, isCorrect: false };
            return { ...answer, isCorrect: true };
         });
         newList = newAnswer;
         setListAnswer(newList);
      }
      
      let questions = [...listQuestion];
      questions[number - 1].answers = newList;
      setListQuestion(questions);
   };

   const onSetListAnswer = () => {
      if (listAnswer.length < 5) {
         setListAnswer([...listAnswer, { description: "", isCorrect: false }]);
      } else {
         Swal.fire("Limite de respuestas", "El número máximo de respuestas es cinco", "warning");
      }
   };

   return (
      <div className="boxQuestion">
         <p>Pregunta {number}:</p>
         <div className="questions">
            <p>
               <input
                  name="description"
                  type="text"
                  placeholder="Ingresar Pregunta"
                  value={description}
                  className="form-control"
                  onChange={onHandleQuestionChange(number - 1)}
               />
            </p>
         </div>
         <div className="checkboxAll">
            {listAnswer.map((answer, index) => (
               <Answer
                  key={index}
                  number={index + 1}
                  answers={answers}
                  description={answer.description}
                  isCorrect={answer.isCorrect}
                  group={"group_" + (number)}
                  onHandleAnswerChange={onHandleAnswerChange}
               />
            ))}
         </div>
         <button type="button" className="btnAdd" onClick={onSetListAnswer}>
            + Agregar Respuestas
         </button>
      </div>
   );
}
