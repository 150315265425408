import React, { Fragment, useState, useEffect, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form } from "react-bootstrap";

import { obtenerExamQuestionsAction, responderExamAction } from "../../actions/examActions";
import { formatDate, compareDate } from '../../utils/functions';
import ViewQuestion from "./ViewQuestion";

const ViewExam = ({ currentExam, handleCloseModal }) => {
   const dispatch = useDispatch();

   const [data, setData] = useState({
      description: currentExam.description,
      startDate: currentExam.startDate,
      endDate: currentExam.endDate
   });

   const currentDate = formatDate(new Date());
   const activo = compareDate(currentExam.endDate, currentDate);

   const user = useSelector((state) => state.auth.user);
   const questions = useSelector(state => state.exam.questions);
   const [replied, setReplied] = useState([]);
   const [listQuestion, setListQuestion] = useState([
      {
         description: "",
         answers: [{ description: "", isCorrect: false }],
      },
   ]);

   const { description } = data;

   const onChangeData = (e) => {
      if (e.target && e.target.name === "description") {
         setData({
            ...data,
            [e.target.name]: e.target.value,
         });
      }
   };

   const onHandleQuestionChange = (idx) => (evt) => {
      /*let newList = [...listQuestion];
      const newQuestion = listQuestion.map((question, sidx) => {
         if (idx !== sidx) return question;
         return { ...question, description: evt.target.value };
      });
      newList[idx] = newQuestion[idx];
      setListQuestion(newList);*/
   };
   
   const onHandleSubmit = (e) => {
      e.preventDefault();

      dispatch(responderExamAction({
         examId: currentExam.id,
         userId: user.id,
         replied: replied
      }));

      e.target.reset();
      handleCloseModal();
   };

   const onSetListQuestion = () => {
      setListQuestion([
         ...listQuestion,
         { description: "", answers: [{ description: "", isCorrect: false }] },
      ]);
   };

   useEffect(() => {
      dispatch(obtenerExamQuestionsAction(currentExam.id, user.personal.id));
   }, []);

   return (
      <Fragment>
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                  <div className="form-group col-4">
                        <label
                           htmlFor="code"
                           className="c-grisDark mb-0"
                        >
                           Código:
                        </label>
                        <span>{' ' + currentExam.code}</span>
                     </div>
                     <div className="form-group col-8">
                        <label
                           htmlFor="description"
                           className="c-grisDark mb-0"
                        >
                           Descripción:
                        </label>
                        <span>{' ' + currentExam.description}</span>
                     </div>
                  </div>
                  <div className="row imputsCampos2">
                     <div className="form-group col-12 col-md-6 mt-0">
                        <label htmlFor="startDate" className="labelFecha-1">
                           Fecha Inicio:
                        </label>
                        <span>{currentExam.startDate}</span>
                     </div>
                     <div className="form-group col-12 col-md-6 mt-0">
                        <label htmlFor="endDate" className="labelFecha-1">
                           Fecha Fin:
                        </label>
                        <span>{currentExam.endDate}</span>
                     </div>
                  </div>

                  <hr />

                  {questions &&
                     questions.map((question, index) => (
                        <ViewQuestion
                           key={index}
                           currentQuestion={question}
                           number={index + 1}
                           description={question.description}
                           answers={question.answers}
                           listQuestion={questions}
                           setListQuestion={setListQuestion}
                           replied={replied}
                           setReplied={setReplied}
                           onHandleQuestionChange={onHandleQuestionChange}
                        />
                     ))}

                  <hr />
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               {questions && questions.length > 0 && questions[0].marked ? (
                  null
               ) : (
                  activo ? (<button
                     className="btn-grisDark text-white btn-Solidez mt-4"
                     type="submit"
                  >
                     Aceptar
                  </button>) : (null)
               )}
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default ViewExam;
