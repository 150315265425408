import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { descargarTicketsAction } from "../../actions/ticketActions";
import { seleccionarCriterioBusqueda } from "../../utils/messages";
import { getYears } from "../../utils/functions";

const Busqueda = ({ type, data, setData }) => {
   const dispatch = useDispatch();

   const user = useSelector((state) => state.auth.user);

   const { year, name, typeDocument, numDocument, offset } = data;

   const years = getYears();

   const onChangeBuscar = (e) => {
      setData({
         ...data,
         [e.target.name]: e.target.value,
      });
   };

   const onHandleBuscarSubmit = (e) => {
      e.preventDefault();

      if (year === "") {
         seleccionarCriterioBusqueda();
      } else {
         if (type === "personal") {
            dispatch(descargarTicketsAction(data, user.personal.id));
         } else {
            dispatch(descargarTicketsAction(data, null, offset));
         }
      }
   };

   return (
      <Fragment>
         <div className="row pl-3 pr-3 pt-0">
            <div className="container">
               <form onSubmit={onHandleBuscarSubmit}>
                  <div className="btnsOptions ml-lg-3 mt-md-3 mt-xl-0  align-items-center">
                     <div className="row imputsCampos2">
                        <div className="col-12 col-lg-10 offset-lg-2">
                           <div className="row">
                              <h2 className="subTituloInterno pt-4 pb-2 pt-lg-5 pb-lg-5">
                                 Seleccionar filtro:
                              </h2>
                           </div>
                           <div className="row">
                              <label htmlFor="year" className="labelFecha-1">
                                 Año:
                              </label>
                              <select
                                 name="year"
                                 value={year}
                                 onChange={onChangeBuscar}
                              >
                                 <option value="">Seleccionar Año</option>
                                 {years &&
                                    years.map((item) => (
                                       <option key={item.value} value={item.value}>
                                          {item.description}
                                       </option>
                                    ))}
                              </select>
                              <div
                                 style={{
                                    display:
                                       type === "personal" ? "none" : "block",
                                 }}
                              >
                                 <label htmlFor="name" className="labelFecha-1">
                                    Nombres:
                                 </label>
                                 <input
                                    name="name"
                                    type="text"
                                    placeholder="Ingresar Nombres"
                                    value={name}
                                    onChange={onChangeBuscar}
                                 />
                              </div>
                           </div>
                           <div
                              className="row mt-lg-3"
                              style={{
                                 display:
                                    type === "personal" ? "none" : "block",
                              }}
                           >
                              <label
                                 htmlFor="typeDocument"
                                 className="labelFecha-1"
                              >
                                 Tipo Documento:
                              </label>
                              <select
                                 name="typeDocument"
                                 value={typeDocument}
                                 onChange={onChangeBuscar}
                              >
                                 <option value="">Seleccionar</option>
                                 <option value="01">DNI</option>
                                 <option value="02">Pasaporte</option>
                                 <option value="03">
                                    Carnet de Extranjería
                                 </option>
                              </select>

                              <label
                                 htmlFor="numDocument"
                                 className="labelFecha-1"
                              >
                                 Núm. Documento:
                              </label>
                              <input
                                 name="numDocument"
                                 type="text"
                                 placeholder="Ingresar Número Documento"
                                 value={numDocument}
                                 onChange={onChangeBuscar}
                              />
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-12 col-lg-2 offset-lg-5 p-0 p-lg-3">
                           <input
                              type="submit"
                              className="btn-grisDark text-white btn-Solidez mt-4 w-100"
                              value="Buscar"
                           />
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </Fragment>
   );
};

export default Busqueda;
