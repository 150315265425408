import {
   PERSONAL_AGREGAR_COMENZAR,
   PERSONAL_AGREGAR_EXITO, 
   PERSONAL_AGREGAR_ERROR, 
   PERSONAL_DESCARGA_COMENZAR, 
   PERSONAL_DESCARGA_EXITO, 
   PERSONAL_DESCARGA_ERROR,
   PERSONAL_ACTIVOS_DESCARGA_COMENZAR, 
   PERSONAL_ACTIVOS_DESCARGA_EXITO, 
   PERSONAL_ACTIVOS_DESCARGA_ERROR,
   PERSONAL_ROL_DESCARGA_COMENZAR,
   PERSONAL_ROL_DESCARGA_EXITO,
   PERSONAL_ROL_DESCARGA_ERROR,
   PERSONAL_OBTENER_COMENZAR,
   PERSONAL_OBTENER_EXITO,
   PERSONAL_OBTENER_ERROR,
   PERSONAL_EDITAR_COMENZAR,
   PERSONAL_EDITAR_EXITO,
   PERSONAL_EDITAR_ERROR,
   PERSONAL_REPORTE_COMENZAR, 
   PERSONAL_REPORTE_FULL_COMENZAR, 
   PERSONAL_REPORTE_EXITO, 
   PERSONAL_REPORTE_FULL_EXITO, 
   PERSONAL_REPORTE_ERROR,
   PERSONAL_ESTADO_COMENZAR,
   PERSONAL_ESTADO_EXITO,
   PERSONAL_ESTADO_ERROR,
} from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

// ---------- CREAR PERSONAL ----------
export function crearPersonalAction(data, descStore) {
   return async (dispatch) => {
      dispatch(agregarPersonal());

      try {
         const response = await clienteAxios.post("/personals", data);
         response.data.work = [{address: descStore}];

         dispatch(agregarPersonalExito(response.data));

         if (response.status === 201) {
            Swal.fire("Correcto", "El usuario se registró correctamente", "success");
         } else {
            Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
         }
      } catch (error) {
         console.log(error.response.status, error.response.data.message);
         const code = error.response.status;
         let mensaje = "";
         if (code === 409 || code === 403) {
            mensaje = error.response.data.message;
         } else {
            mensaje = "Hubo un error, intenta de nuevo";
         }
         dispatch(agregarPersonalError(true));

         Swal.fire({icon: "error", title: "Hubo un error", text: mensaje});
      }
   }
}

const agregarPersonal = () => ({
   type: PERSONAL_AGREGAR_COMENZAR
})

const agregarPersonalExito = data => ({
   type: PERSONAL_AGREGAR_EXITO,
   payload: data
})

const agregarPersonalError = estado => ({
   type: PERSONAL_AGREGAR_ERROR,
   payload: estado
})

// ---------- BUSCAR PERSONALES ----------
export function buscarPersonalAction(data, offset) {
   return async (dispatch) => {
      if (data) {
         dispatch(descargarPersonal());

         let query = `limit=${data.limit}&offset=${offset}&`;
         if (data.code && data.code !== '') {
            query = query + `code=${data.code}&`;
         }
         if (data.name && data.name !== '') {
            query = query + `name=${data.name}&`;
         }
         if (data.storeId && data.storeId !== '') {
            query = query + `storeId=${data.storeId}&`;
         }
         if (data.typeDocument && data.typeDocument !== '') {
            query = query + `typeDocument=${data.typeDocument}&`;
         }
         if (data.numDocument && data.numDocument !== '') {
            query = query + `numDocument=${data.numDocument}&`;
         }
         if (data.state && data.state !== '') {
            query = query + `state=${data.state}&`;
         }

         const url = query === '' ? '/personals' : '/personals?' + query;

         try {
            const resultado = await clienteAxios.get(url);
            resultado.data.limit = data.limit;

            dispatch(descargarPersonalExito(resultado.data))
         } catch (error) {
            dispatch(descargarPersonalError());
         }
      }
   }
}

const descargarPersonal = () => ({
   type: PERSONAL_DESCARGA_COMENZAR,
   payload: true
})

const descargarPersonalExito = data => ({
   type: PERSONAL_DESCARGA_EXITO,
   payload: data
})

const descargarPersonalError = () => ({
   type: PERSONAL_DESCARGA_ERROR,
   payload: true
})

// ---------- LISTAR PERSONAL ACTIVOS ----------
export function obtenerPersonalActivosAction() {
   return async (dispatch) => {
      dispatch(descargarPersonalActivos());

      try {
         const resultado = await clienteAxios.get('/personals/list');

         dispatch(descargarPersonalActivosExito(resultado.data))
      } catch (error) {
         dispatch(descargarPersonalActivosError());
      }
   }
}

const descargarPersonalActivos = () => ({
   type: PERSONAL_ACTIVOS_DESCARGA_COMENZAR,
   payload: true
})

const descargarPersonalActivosExito = data => ({
   type: PERSONAL_ACTIVOS_DESCARGA_EXITO,
   payload: data
})

const descargarPersonalActivosError = () => ({
   type: PERSONAL_ACTIVOS_DESCARGA_ERROR,
   payload: true
})

// ---------- OBTENER PERSONAL POR ROL ----------
export function obtenerPersonalRolAction(role) {
   return async (dispatch) => {
      if (role) {
         dispatch(descargarObtenerPersonalRol());

         try {
            const resultado = await clienteAxios.get(`/personals/role/${role}`);

            dispatch(descargarObtenerPersonalRolExito(resultado.data))
         } catch (error) {
            dispatch(descargarObtenerPersonalRolError());
         }
      }
   }
}

const descargarObtenerPersonalRol = () => ({
   type: PERSONAL_ROL_DESCARGA_COMENZAR,
   payload: true
})

const descargarObtenerPersonalRolExito = data => ({
   type: PERSONAL_ROL_DESCARGA_EXITO,
   payload: data
})

const descargarObtenerPersonalRolError = () => ({
   type: PERSONAL_ROL_DESCARGA_ERROR,
   payload: true
})

// ---------- OBTENER PERSONAL ----------
export function obtenerPersonalAction(id) {
   return async (dispatch) => {
      if (id) {
         dispatch(descargarObtenerPersonal());

         try {
            const resultado = await clienteAxios.get(`/personals/${id}`);

            dispatch(descargarObtenerPersonalExito(resultado.data))
         } catch (error) {
            dispatch(descargarObtenerPersonalError());
         }
      }
   }
}

const descargarObtenerPersonal = () => ({
   type: PERSONAL_OBTENER_COMENZAR,
   payload: true
})

const descargarObtenerPersonalExito = data => ({
   type: PERSONAL_OBTENER_EXITO,
   payload: data
})

const descargarObtenerPersonalError = () => ({
   type: PERSONAL_OBTENER_ERROR,
   payload: true
})

// ---------- ACTUALIZAR PERSONAL ----------
export function actualizarPersonalAction(id, data, other,descStore) {
   return async (dispatch) => {

      dispatch(actualizarPersonal());

      try {
         await clienteAxios.patch(`/personals/${id}`, data);

         data.id = id;
         data.work.address = other.address;
         dispatch(actualizarPersonalExito(data));
         Swal.fire("Correcto", "El personal se actualizó correctamente", "success");
      } catch (error) {
         dispatch(actualizarPersonalError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const actualizarPersonal = () => ({
   type: PERSONAL_EDITAR_COMENZAR
})

const actualizarPersonalExito = data => ({
   type: PERSONAL_EDITAR_EXITO,
   payload: data
})

const actualizarPersonalError = () => ({
   type: PERSONAL_EDITAR_ERROR,
   payload: true
})

// ---------- CAMBIAR ESTADO ----------
export function cambiarEstadoPersonalAction(id) {
   return async (dispatch) => {

      dispatch(cambiarEstadoPersonal());

      try {
         await clienteAxios.patch(`/personals/state/${id}`);

         dispatch(cambiarEstadoPersonalExito(id));
         //Swal.fire("Correcto", "Se cambio el estado correctamente", "success");
      } catch (error) {
         dispatch(cambiarEstadoPersonalError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const cambiarEstadoPersonal = () => ({
   type: PERSONAL_ESTADO_COMENZAR
})

const cambiarEstadoPersonalExito = id => ({
   type: PERSONAL_ESTADO_EXITO,
   payload: id
})

const cambiarEstadoPersonalError = () => ({
   type: PERSONAL_ESTADO_ERROR,
   payload: true
})

// ---------- REPORTE PERSONAL ----------
export function reportePersonalAction(data, offset) {
   return async (dispatch) => {
         dispatch(reportePersonal());

         let query = `limit=${data.limit}&offset=${offset}&`;
         if (data.code && data.code !== '') {
            query = query + `code=${data.code}&`;
         }
         if (data.name && data.name !== '') {
            query = query + `name=${data.name}&`;
         }
         if (data.storeId && data.storeId !== '') {
            query = query + `storeId=${data.storeId}&`;
         }
         if (data.typeDocument && data.typeDocument !== '') {
            query = query + `typeDocument=${data.typeDocument}&`;
         }
         if (data.numDocument && data.numDocument !== '') {
            query = query + `numDocument=${data.numDocument}&`;
         }
         if (data.state && data.state !== '') {
            query = query + `state=${data.state}&`;
         }
         if (data.type && data.type !== '') {
            query = query + `type=${data.type}&`;
         }

         const url = query === '' ? '/personals/report' : '/personals/report?' + query;

         try {
            const resultado = await clienteAxios.get(url);

            resultado.data.limit = data.limit;

            dispatch(reportePersonalExito(resultado.data))
         } catch (error) {
            dispatch(reportePersonalError());
         }
   }
}

export function reporteFullPersonalAction(data) {
   return async (dispatch) => {
         dispatch(reporteFullPersonal());

         let query = '';
         if (data.code && data.code !== '') {
            query = query + `code=${data.code}&`;
         }
         if (data.name && data.name !== '') {
            query = query + `name=${data.name}&`;
         }
         if (data.storeId && data.storeId !== '') {
            query = query + `storeId=${data.storeId}&`;
         }
         if (data.typeDocument && data.typeDocument !== '') {
            query = query + `typeDocument=${data.typeDocument}&`;
         }
         if (data.numDocument && data.numDocument !== '') {
            query = query + `numDocument=${data.numDocument}&`;
         }
         if (data.state && data.state !== '') {
            query = query + `state=${data.state}&`;
         }

         const url = query === '' ? '/personals/report' : '/personals/report?' + query;

         try {
            const resultado = await clienteAxios.get(url);

            dispatch(reporteFullPersonalExito(resultado.data))
         } catch (error) {
            dispatch(reportePersonalError());
         }
   }
}

const reportePersonal = () => ({
   type: PERSONAL_REPORTE_COMENZAR,
   payload: true
})

const reporteFullPersonal = () => ({
   type: PERSONAL_REPORTE_FULL_COMENZAR,
   payload: true
})

const reportePersonalExito = data => ({
   type: PERSONAL_REPORTE_EXITO,
   payload: data
})

const reporteFullPersonalExito = data => ({
   type: PERSONAL_REPORTE_FULL_EXITO,
   payload: data
})

const reportePersonalError = () => ({
   type: PERSONAL_REPORTE_ERROR,
   payload: true
})