import React, { useState } from "react";
import Question from "../../models/Question";
import ListController from "../../controllers/ListController";
import styled from "styled-components";

import styles from "../../css/checklist.css";

export default function QuestionForm({ question, setQuestion }) {

  const { text, type } = question;
  function handleChangeText(e) {
    setQuestion(question.merge({ text: e.target.value }));
  }

  function handleChangeType(e) {
    setQuestion(question.merge({ type: e.target.value }));
  }

  function setOptions(options) {
    setQuestion(question.merge({ options }));
  }

  const listController = new ListController(question.options, setOptions);

  return (
    <div>
      <label>Pregunta:</label>
      <input type="text" value={question.text} onChange={handleChangeText} />

      <label htmlFor="question-type">Tipo de Pregunta:</label>
      <select
        id="question-type"
        value={question.type}
        onChange={handleChangeType}
      >
        {Object.values(Question.TYPES).map(type => (
          <option key={type.value} value={type.value}>
            {type.description}
          </option>
        ))}
      </select>

      {question.hasOptions && (
        <fieldset>
          <legend>Opciones</legend>

          {question.options.map((option, i) => (
            <Option key={i}>
              <input
                type="text"
                placeholder="Ingresa una opción"
                name={option}
                value={option}
                onChange={e => listController.set(i, e.target.value)}
              />
              <Buttons>
                {/*<Button onClick={() => listController.moveUp(i)}>
                  <i className="fas fa-angle-up" />
                </Button>
                <Button onClick={() => listController.moveDown(i)} className={styles.btnChecklist}>
                  <i className="fas fa-angle-down" />
                </Button
                <Button onClick={() => listController.remove(i)} className={styles.btnChecklist}>
                  <i className="fas fa-trash-alt" />
                </Button>*/}
                <button type="button" onClick={() => listController.moveUp(i)} className={styles.btnChecklist}>
                  <i className="fas fa-angle-up" />
                </button>&nbsp;
                <button type="button" onClick={() => listController.moveDown(i)} className={styles.btnChecklist}>
                  <i className="fas fa-angle-down" />
                </button>&nbsp;
                <button type="button" onClick={() => listController.remove(i)} className={styles.btnChecklist}>
                  <i className="fas fa-trash-alt" />
                </button>
              </Buttons>
            </Option>
          ))}
          <p>
            {/*<Button onClick={() => listController.add("")} className={styles.btnChecklist}>
              <i className="fas fa-plus icon" />
              Add Option
            </Button>*/}
            <button type="button" onClick={() => listController.add("")} className={styles.btnChecklist}>
              <i className="fas fa-plus icon" />{" "}
              Agregar Opción
            </button>
          </p>
        </fieldset>
      )}
    </div>
  );
}

const Option = styled.div`
  display: flex;
`

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  background: none;
  color: #0366ee;
  margin-left: 0.2em;
`;
