import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import html2pdf from "html2pdf.js";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

import { reporteFullChecklistAction } from "../../../actions/checklistActions";

import pdfIcon from "../../../assets/images/pdf.svg";
import excelIcon from "../../../assets/images/excel.svg";

const ChecklistItem = ({ checklist, data, startDate, endDate, icon, setType }) => {
   const dispatch = useDispatch();

   const user = useSelector((state) => state.auth.user);
   const checklists = useSelector((state) => state.checklist.reportFull);

   const reportFull = (checklistId, storeId, personal_id, fecha) => {
      const dataObject = {
         checklistId: checklistId,
         personalId: personal_id,
         fecha: fecha,
         storeId: storeId,
         startDate: startDate ? startDate : "",
         endDate: endDate ? endDate : "",
      };

      dispatch(reporteFullChecklistAction(dataObject));
   };

   const exportarPdf = (checklistId, storeId, personal_id, fecha) => {
      setType("pdf");

      reportFull(checklistId, storeId, personal_id, fecha);
   };

   const exportarExcel = (checklistId, storeId, personal_id, fecha) => {
      setType("excel");

      reportFull(checklistId, storeId, personal_id, fecha);
   };

   return (
      <tr>
         <td>{checklist.description}</td>
         <td>{checklist.personal}</td>
         <td>{checklist.store}</td>
         {checklist.question ? (<td>{checklist.question}</td>) : (null)}
         {checklist.answer ? (<td>{checklist.answer}</td>) : (null)}
         <td>{checklist.createdAt}</td>
         {icon === 'SI' ? (
            <td>
            <button type="button" onClick={() => exportarPdf(checklist.checklist_id, checklist.store_id, checklist.personal_id, checklist.fecha)}>
                  <img src={pdfIcon} className="mr-2 iconTabla" alt="Exportar a PDF" />
               </button>
            </td>
         ) : (null)}
         {icon === 'SI' ? (
            <td>
            <button type="button" onClick={() => exportarExcel(checklist.id, checklist.store_id, checklist.personal_id, checklist.fecha)}>
                  <img src={excelIcon} className="mr-2 iconTabla" alt="Exportar a Excel" />
               </button>
            </td>
         ) : (null)}
      </tr>
   );
};

export default ChecklistItem;
