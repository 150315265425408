import React from "react";
import { useSelector } from "react-redux";
import Switch from "react-switch";

import eyesIcon from "../../assets/images/eye.svg";
import pdfIcon from "../../assets/images/pdf_icon.svg";

const PolicyItem = ({ policy, onHandleChange, handleOpenModal }) => {
   const user = useSelector((state) => state.auth.user);

   return (
      <tr>
         <td>{policy.description}</td>
         <td>
            <a href={policy.url} target="_blank" alt="" rel="noreferrer">
               <img src={pdfIcon} className="mr-2 iconTabla" alt="Ver" />
            </a>
         </td>
         {user.role === '01' || user.role === '03' ? (<td>
            <Switch
               onChange={() => onHandleChange(policy.id)}
               checked={policy.state}
               className="react-switch"
            />
         </td>) : (null)}
         {user.role === '01' || user.role === '03' ? (<td>
            <button type="button" onClick={() => handleOpenModal(policy)}>
               <img src={eyesIcon} className="mr-2 iconTabla" alt="Ver" />
            </button>
         </td>) : (null)}
      </tr>
   );
};

export default PolicyItem;
