import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import Select from "react-select";
import Swal from 'sweetalert2';

import { obtenerPersonalActivosAction } from "../../actions/personalActions";
import { crearExamAction } from "../../actions/examActions";
import Question from "./Question";

import "react-datepicker/dist/react-datepicker.css";

registerLocale("es", es);

const NuevoExam = ({ handleCloseModal }) => {
   const dispatch = useDispatch();

   const user = useSelector((state) => state.auth.user.personal);
   const personals = useSelector((state) => state.personal.personalsActivos);
   const roles = [
      { value: "04", label: "Administrador de la Tienda" },
      { value: "05", label: "Vendedor" },
   ];
   let optionsPersonal = [];
   if (personals) {
      personals.map(personal => optionsPersonal.push({
         value: personal.id,
         label: personal.name + ' ' + personal.lastName
      }));
   }
   const [optionsSelect, setOptionsSelect] = useState([]);
   const [checkedState, setCheckedState] = useState(
      new Array(roles.length).fill(false)
  );
   const [data, setData] = useState({
      code: "",
      description: "",
   });

   const [startDate, setStartDate] = useState("");
   const [endDate, setEndDate] = useState("");
   const [listQuestion, setListQuestion] = useState([
      {
         description: "",
         answers: [{ description: "", isCorrect: false }],
      },
   ]);

   const { code, description } = data;

   const onChangeData = (e) => {
      if (e.target && (e.target.name === "description" || e.target.name === "code")) {
         setData({
            ...data,
            [e.target.name]: e.target.value,
         });
      } else {
         setOptionsSelect(e);
      }
   };

   const onHandleQuestionChange = (idx) => (evt) => {
      let newList = [...listQuestion];
      const newQuestion = listQuestion.map((question, sidx) => {
         if (idx !== sidx) return question;
         return { ...question, description: evt.target.value };
      });
      newList[idx] = newQuestion[idx];
      setListQuestion(newList);
   };

   const procesarExamen = (e) => {
      const algunaPreguntaVacia = listQuestion.some(question => question.description === '');
      let algunaRespuestaVacia = false;
      let algunaEsCorrecta = false;
      listQuestion.map(question => {
         //if (!algunaEsCorrecta) algunaEsCorrecta = false;

         const descriptionEmpty = question.answers.some(answer => answer.description === '');
         if (!algunaRespuestaVacia) algunaRespuestaVacia = descriptionEmpty;
         const isCorrectEmpty = question.answers.some(answer => answer.isCorrect);
         //if (!algunaEsCorrecta) 
         algunaEsCorrecta = isCorrectEmpty;
         for (let i = 0; i <question.answers.length; i++) {
            if (question.answers[i].isCorrect) {
               algunaEsCorrecta = true;
               break;
            }
         }
      });

      //let someDescriptionEmpty = false;
      let someAnswerEmpty = false;
      let someIsCorrectEmpty = false;
      for (let i = 0; i < listQuestion.length; i++) {
         const question = listQuestion[i];
         someAnswerEmpty = false;
         someIsCorrectEmpty = false;

         for (let j = 0; j < question.answers.length; j++) {
            const answer = question.answers[j];
            if (answer.description === '') {
               someAnswerEmpty = true;
            }
            if (answer.isCorrect) {
               someIsCorrectEmpty = true;
            }
            if (someAnswerEmpty && someIsCorrectEmpty) {
               break;
            }
         }

         if (someAnswerEmpty && !someIsCorrectEmpty) {
            break;
         }
      }

      if (algunaPreguntaVacia || someAnswerEmpty || !someIsCorrectEmpty) {
         if (algunaPreguntaVacia) Swal.fire("Preguntas", "Debe ingresar las descripciones a todas las preguntas", "warning");
         if (someAnswerEmpty) Swal.fire("Respuestas", "Debe ingresar las descripciones a todas las respuestas", "warning");
         if (!someIsCorrectEmpty) Swal.fire("Respuestas", "Debe marcar una respuesta como correcta en todas las preguntas", "warning");
      } else {
         let personalsSelected = [];
         optionsSelect.map(option => personalsSelected.push({personalId: option.value}));

         let rolesSelected = [];
         checkedState && checkedState.map((option, index) => {
            if (option)
               rolesSelected.push({rolId: '0' + (index + 4)})
         });

         const jsonObject = {
            code: code,
            description: description,
            startDate: startDate.toISOString().slice(0, 10),
            endDate: endDate.toISOString().slice(0, 10),
            userId: user.id,
            listQuestion,
            personal: personalsSelected,
            roles: rolesSelected
         }

         dispatch(crearExamAction(jsonObject));

         e.target.reset();
         handleCloseModal();
      }
   }

   const onHandleSubmit = (e) => {
      e.preventDefault();

      Swal.fire({
         title: '¿Esta seguro de crear el exámen?',
         showCancelButton: true,
         confirmButtonText: 'Si',
         cancelButtonText: `No`,
       }).then((result) => {
         if (result.isConfirmed) {
            procesarExamen(e);
         }
       })
   };
   //https://jsbin.com/piriqerono/edit?js,console,output
   //https://phaser.io/
   const onSetListQuestion = () => {
      setListQuestion([
         ...listQuestion,
         { description: "", answers: [{ description: "", isCorrect: false }] },
      ]);
   };

   const handleOnChange = (position) => {
      const updatedCheckedState = checkedState.map((item, index) =>
        index === position ? !item : item
      );
  
      setCheckedState(updatedCheckedState);
    };

   useEffect(() => {
      dispatch(obtenerPersonalActivosAction());
   }, []);

   return (
      <Fragment>
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                     <div className="form-group col-4">
                        <label
                           htmlFor="code"
                           className="c-grisDark mb-0"
                        >
                           Código:
                        </label>
                        <input
                           type="text"
                           name="code"
                           className="form-control"
                           placeholder="Ingrese el código"
                           value={code}
                           onChange={onChangeData}
                           required
                        />
                     </div>
                     <div className="form-group col-8">
                        <label
                           htmlFor="description"
                           className="c-grisDark mb-0"
                        >
                           Descripción:
                        </label>
                        <input
                           type="text"
                           name="description"
                           className="form-control"
                           placeholder="Ingrese el nombre del examen"
                           value={description}
                           onChange={onChangeData}
                           required
                        />
                     </div>
                  </div>
                  <div className="row imputsCampos2">
                     <div className="form-group col-12 col-md-4 mt-0">
                        <label htmlFor="startDate" className="labelFecha-1">
                           Fecha Inicio:
                        </label>
                        <DatePicker
                           name="startDate"
                           dateFormat="dd/MM/yyyy"
                           locale="es"
                           selected={startDate}
                           isClearable
                           placeholderText="DD/MM/AAAA"
                           value={startDate}
                           onChange={(date) => setStartDate(date)}
                           autoComplete="off"
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-4 mt-0">
                        <label htmlFor="endDate" className="labelFecha-1">
                           Fecha Fin:
                        </label>
                        <DatePicker
                           name="endDate"
                           dateFormat="dd/MM/yyyy"
                           locale="es"
                           selected={endDate}
                           isClearable
                           placeholderText="DD/MM/AAAA"
                           value={endDate}
                           onChange={(date) => setEndDate(date)}
                           autoComplete="off"
                           required
                        />
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group col-12">
                        <label
                           htmlFor="description"
                           className="c-grisDark mb-0"
                        >
                           ¿A quienes será dirijido el examen?:
                        </label>
                        <Select
                           placeholder="Seleccionar"
                           options={optionsPersonal}
                           className="basic-multi-select"
                           onChange={onChangeData}
                           isMulti
                        />
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group col-12">
                        <label
                           htmlFor="description"
                           className="c-grisDark mb-0"
                        >
                           ¿A que roles será dirijido el examen?:
                        </label>
                        <ul className="checkbox">                        
                        {roles.map(({ value, label }, index) => (
                              <li key={index}>
                              <div className="toppings-list-item">
                                 <div className="left-section">
                                    <input
                                    type="checkbox"
                                    id={`custom-checkbox-${index}`}
                                    name={value}
                                    value={value}
                                    checked={checkedState[index]}
                                    onChange={() => handleOnChange(index)}
                                    />
                                    <label htmlFor={`custom-checkbox-${index}`}>{label}</label>
                                 </div>
                              </div>
                              </li>
                           )
                        )}
                        </ul>
                     </div>
                  </div>
                  <div className="row ml-1 d-block">
                     <button
                        type="button"
                        className="btnAdd"
                        onClick={onSetListQuestion}
                     >
                        + Agregar Pregunta
                     </button>
                  </div>

                  <hr />

                  {listQuestion &&
                     listQuestion.map((question, index) => (
                        <Question
                           key={index}
                           number={index + 1}
                           description={question.description}
                           answers={question.answers}
                           listQuestion={listQuestion}
                           setListQuestion={setListQuestion}
                           onHandleQuestionChange={onHandleQuestionChange}
                        />
                     ))}

                  <hr />
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               <button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="submit"
               >
                  Aceptar
               </button>
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default NuevoExam;
