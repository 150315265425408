import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form } from "react-bootstrap";
import Swal from "sweetalert2";

import { actualizarContraseniaPersonalAction } from "../../actions/authActions";

const CambiarPassword = ({ handleCloseModal }) => {
   const dispatch = useDispatch();

   const user = useSelector((state) => state.auth.user);

   const [data, onHandleData] = useState({
      userId: user.id,
      rePassword: '',
      password: ''
   });

   const { userId, password, rePassword } = data;

   const [passwordType, setPasswordType] = useState("password");
   const [password2Type, setPassword2Type] = useState("password");

    const togglePassword = () => {
         if(passwordType==="password")
         {
         setPasswordType("text")
         return;
         }
         setPasswordType("password")
    }

    const togglePassword2 = () => {
         if(password2Type==="password")
         {
         setPassword2Type("text")
         return;
         }
         setPassword2Type("password")
    }

   const onChangeData = (e) => {
      onHandleData({
         ...data,
         [e.target.name]: e.target.value,
      });
   };

   const onHandleSubmit = (e) => {
      e.preventDefault();

      if (password === rePassword) {
         dispatch(actualizarContraseniaPersonalAction(userId, {
            password
         }));
   
         e.target.reset();
         handleCloseModal();
      } else {
         Swal.fire(
            "Alerta",
            "Las contraseñas tienen que ser iguales",
            "warning"
         );
      }
   };

   return (
      <Fragment>
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                     <div className="form-group col-12 col-md-12">
                     <label
                           htmlFor="password"
                           className="labelCliente mb-0 pt-0"
                        >
                           Password: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <input
                           type={passwordType}
                           name="password"
                           className="form-control txtPassword"
                           placeholder="Ingrese el password"
                           value={password}
                           onChange={onChangeData}
                           minLength={8}
                           maxLength={50}
                           width="80%"
                           required
                        />
                         <button type="button"
                           className="btn show_password style-password2"
                           onClick={togglePassword}
                        >
                           { passwordType==="password"? <i className="hideeyes icon"></i> :<i className="showeyes icon"></i> }
                        </button>
                     </div>
                     <div className="form-group col-12 col-md-12">
                     <label
                           htmlFor="rePassword"
                           className="labelCliente mb-0 pt-0"
                        >
                           Confirmar Password: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <input
                           type={password2Type}
                           name="rePassword"
                           className="form-control txtPassword"
                           placeholder="Confirme el password"
                           value={rePassword}
                           onChange={onChangeData}
                           minLength={8}
                           maxLength={50}
                           required
                        />
                        <button type="button"
                           className="btn show_password style-password2"
                           onClick={togglePassword2}
                        >
                           { password2Type==="password"? <i className="hideeyes icon"></i> :<i className="showeyes icon"></i> }
                        </button>
                     </div>
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               <button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="submit"
               >
                  Guardar
               </button>
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default CambiarPassword;
