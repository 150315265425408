import React, { useState } from "react";

export default function ViewAnswer({ number, answers, answer, group, marked, onHandleAnswerChange }) {
const isCorrect = marked && marked.length > 0 && (answer.id === marked[0].answerId && answer.isCorrect) ? true : false;

   return (
      <div>
         <label>
         {" "}
         <input
            name={group}
            type="radio"
            value={answer.isCorrect}
            onChange={onHandleAnswerChange(number - 1, answer.questionId)}
            checked={marked ? answer.isCorrect : null}
            disabled={marked ? true : false}
         />
         {" "}
         {marked && marked.length > 0 ? (
            marked[0].isCorrect ? (
               isCorrect ? (
                  <span className="text-success">{answer.description}</span>
               ) : (
                  <span>{answer.description}</span>
               )
            ) : (
                  answer.id === marked[0].answerId ? (
                     <span className="text-danger">{answer.description}</span>
                  ) : (
                     <span>{answer.description}</span>
                  )
            )
         ) : <span>{answer.description}</span>}
      </label>
      </div>
   );
}
