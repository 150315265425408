import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalTitle } from "react-bootstrap";
import Pagination from '@mui/material/Pagination';

import { descargarMemosAction } from "../../actions/memoActions";
import MemoItemTodos from "./MemoItemTodos";
import ViewMemo from "./ViewMemo";

const ListadoMemoTodos = ({ data, setData }) => {
   const dispatch = useDispatch();

   const memos = useSelector((state) => state.memo.memosTotal);
   const size = useSelector((state) => state.memo.sizeTotal);
   const user = useSelector((state) => state.auth.user);

   const [showModal, setShowModal] = useState(false);
   const [currentMemo, setCurrentMemo] = useState({});

   const { limit, offset } = data;

   const handleCloseModal = () => {
      setShowModal(false);
   };

   const handleOpenModal = (memo) => {
      setShowModal(true);
      setCurrentMemo(memo);
   };

   const onHandleChange = (id) => {
      //dispatch(cambiarEstadoProductAction(id));
   };

   const onChangePage = (e, page) => {
      const offset = 0 + (page - 1) * limit;

      setData({
         ...data,
         'offset': offset
      });
  
      dispatch(descargarMemosAction(data, null, offset));
    }

    const changeValuePage = (e) => {
      setData({
         ...data,
         [e.target.name]: e.target.value
      });

      data.limit = Number(e.target.value);
  
      dispatch(descargarMemosAction(data, null, offset));
    }

   return (
      <div className="container-fluid">
         <div className="container-fluid mt-md-4 p-0 mt-5 pt-5">
            <div className="col-12">
               <h2 className="titulo text-center mb-5">
                  Resultado de la búsqueda
               </h2>
            </div>
         </div>
         <div class="container-fluid imputsCamposMostrar">
               <div class="row mt-lg-3">
                  <label  htmlFor="limit" className="mr-3 mt-1">Mostrar resultados:</label>
                  <select name='limit' onChange={changeValuePage}>
                     <option value="10">10</option>
                     <option value="15">15</option>
                     <option value="25">25</option>
                     <option value="50">50</option>
                     <option value="100">100</option> 
                  </select> 
               </div>
         </div>
         <div className="cabeceraTable2 cuperoTable3 mt-3 mb-100">
            <table>
               <thead>
                  <tr>
                     <th>Código</th>
                     <th>Periodo</th>
                     <th>Descripción</th>
                     <th>Persona</th>
                     <th>Tipo Documento</th>
                     <th>Número Documento</th>
                     <th>PDF</th>
                  </tr>
               </thead>
               <tbody>
                  {memos &&
                     memos.map((memo) => (
                        <MemoItemTodos
                           key={memo.id}
                           memo={memo}
                           onHandleChange={onHandleChange}
                           handleOpenModal={handleOpenModal}
                        />
                     ))}
               </tbody>
            </table>
         </div>
         <Pagination count={size} onChange={onChangePage} />

         <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <div className="modal-content">
               <Modal.Header closeButton>
                  <ModalTitle>
                     <h3 className="titulo text-center p-3">Ver memorandum</h3>
                  </ModalTitle>
               </Modal.Header>
               <ViewMemo
                  currentMemo={currentMemo}
                  handleCloseModal={handleCloseModal}
               />
            </div>
         </Modal>
      </div>
   );
};

export default ListadoMemoTodos;
