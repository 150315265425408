import {
   LOGIN_INICIO,
   LOGIN_EXITOSO, 
   LOGIN_ERROR, 
   CONFIRMAR_CUENTA_COMENZAR, 
   CONFIRMAR_CUENTA_EXITO, 
   CONFIRMAR_CUENTA_ERROR, 
   CERRAR_SESION,
   USER_CONTRASENIA_COMENZAR,
   USER_CONTRASENIA_EXITO,
   USER_CONTRASENIA_ERROR,
   USER_RECUPERAR_CONTRASENIA_COMENZAR,
   USER_RECUPERAR_CONTRASENIA_EXITO,
   USER_RECUPERAR_CONTRASENIA_ERROR
} from "../types";
import clienteAxios from "../config/axios";
import axios from 'axios';
import Swal from "sweetalert2";
//"start": "react-scripts --openssl-legacy-provider start",
// ---------- INICIAR SESION ----------
export function iniciarSesionAction(data) {
   return async (dispatch) => {
      dispatch(loginComenzar());

      try {
         const response = await clienteAxios.post("/auth/login", data);
         dispatch(loginExito(response.data));
      } catch (error) {
         console.log(error.response);
         console.log(error.response.data.message);
         const code = error.response.status;
         let mensaje = "";
         if (code === 409 || code === 401) {
            mensaje = error.response.data.message;
         } else {
            mensaje = "Hubo un error, intenta de nuevo";
         }
         dispatch(loginError(true));

         Swal.fire({icon: "error", title: "Hubo un error", text: mensaje});
      }
   }
}

const loginComenzar = () => ({
   type: LOGIN_INICIO
})

const loginExito = data => ({
   type: LOGIN_EXITOSO,
   payload: data
})

const loginError = estado => ({
   type: LOGIN_ERROR,
   payload: estado
})

// ---------- CERRAR SESION ----------
export function cerrarSesionAction() {
   return async (dispatch) => {
      dispatch(cerrarSesion());
      Swal.fire({icon: "success", text: "Se cerró su sesión con exito"});
   }
}

const cerrarSesion = () => ({
   type: CERRAR_SESION
})

// ---------- ACTUALIZAR CONTRASEÑA ----------
export function actualizarContraseniaPersonalAction(id, data) {
   return async (dispatch) => {

      dispatch(actualizarContrasenia());

      try {
         await clienteAxios.patch(`/auth/change-password-personal/${id}`, data);

         dispatch(actualizarContraseniaExito(data));
         Swal.fire("Correcto", "Se actualizó la contraseña exitosamente", "success");
      } catch (error) {
         dispatch(actualizarContraseniaError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const actualizarContrasenia = () => ({
   type: USER_CONTRASENIA_COMENZAR
})

const actualizarContraseniaExito = data => ({
   type: USER_CONTRASENIA_EXITO,
   payload: data
})

const actualizarContraseniaError = () => ({
   type: USER_CONTRASENIA_ERROR,
   payload: true
})

// ---------- RECUPERAR CONTRASEÑA ----------
export function recuperarContraseniaAction(email) {
   return async (dispatch) => {
      dispatch(recuperarContraseniaComenzar());

      try {
         const response = await clienteAxios.post("/auth/recovery", email);
         dispatch(recuperarContraseniaExito(response.data));
         Swal.fire("Correcto", "Se envio el mensaje de recuperación al correo ingresado", "success");
      } catch (error) {
         console.log(error.response);
         console.log(error.response.data.message);
         const code = error.response.status;
         let mensaje = "";
         if (code === 409) {
            mensaje = error.response.data.message;
         } else {
            mensaje = "Hubo un error, intenta de nuevo";
         }
         dispatch(recuperarContraseniaError(true));

         Swal.fire({icon: "error", title: "Hubo un error", text: mensaje});
      }
   }
}

const recuperarContraseniaComenzar = () => ({
   type: USER_RECUPERAR_CONTRASENIA_COMENZAR
})

const recuperarContraseniaExito = data => ({
   type: USER_RECUPERAR_CONTRASENIA_EXITO,
   payload: data
})

const recuperarContraseniaError = estado => ({
   type: USER_RECUPERAR_CONTRASENIA_ERROR,
   payload: estado
})