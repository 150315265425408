import {
   EXAM_AGREGAR_COMENZAR,
   EXAM_AGREGAR_EXITO, 
   EXAM_AGREGAR_ERROR, 
   EXAM_DESCARGA_COMENZAR, 
   EXAM_DESCARGA_EXITO, 
   EXAM_DESCARGA_ERROR, 
   EXAM_OBTENER_COMENZAR,
   EXAM_OBTENER_EXITO,
   EXAM_OBTENER_ERROR,
   EXAM_EDITAR_COMENZAR,
   EXAM_EDITAR_EXITO,
   EXAM_EDITAR_ERROR,
   EXAM_PREGUNTA_DESCARGA_COMENZAR, 
   EXAM_PREGUNTA_DESCARGA_EXITO, 
   EXAM_PREGUNTA_DESCARGA_ERROR,
   EXAM_RESPONDER_COMENZAR, 
   EXAM_RESPONDER_EXITO, 
   EXAM_RESPONDER_ERROR, 
   EXAM_REPORTE_1_COMENZAR, 
   EXAM_REPORTE_1_EXITO, 
   EXAM_REPORTE_1_ERROR, 
   EXAM_REPORTE_2_COMENZAR, 
   EXAM_REPORTE_2_EXITO, 
   EXAM_REPORTE_2_ERROR, 
} from '../types';

const initialState = {
   exams: [],
   report1: [],
   report2: [],
   questions:[],
   currentExam: null,
   loading: null
};

export default function(state = initialState, action) {
   switch(action.type) {
      case EXAM_AGREGAR_COMENZAR:
      case EXAM_EDITAR_COMENZAR:
      case EXAM_OBTENER_COMENZAR:
         return {
            ...state,
            currentExam: null,
            loading: true
         }
      case EXAM_AGREGAR_EXITO:
      case EXAM_OBTENER_EXITO:
         return {
            ...state,
            currentExam: action.payload,
            loading: false
         }
      case EXAM_EDITAR_EXITO:
         const currentExam = action.payload;
         //let examModify = state.exams.find(exam => exam.id === currentExam.id);
         //examModify.startHour = currentExam.startHour;
         //examModify.endHour = currentExam.endHour;
         
         return {
            ...state,
            //exams: state.exams.filter(exam => exam.id === examModify.id ? examModify : exam),
            loading: false
         }
      case EXAM_AGREGAR_ERROR:
      case EXAM_EDITAR_ERROR:
      case EXAM_OBTENER_ERROR:
         return {
            ...state,
            currentExam: null,
            loading: false
         }
      case EXAM_DESCARGA_COMENZAR:
         return {
            ...state,
            exams: [],
            loading: true
         }
      case EXAM_REPORTE_1_COMENZAR:
         return {
            ...state,
            exams: [],
            report1: [],
            loading: true
         }
      case EXAM_REPORTE_2_COMENZAR:
         return {
            ...state,
            exams: [],
            report2: [],
            loading: true
         }
      case EXAM_DESCARGA_EXITO:
         return {
            ...state,
            exams: action.payload,
            loading: false
         }
      case EXAM_REPORTE_1_EXITO:
         const array1 = [...action.payload];
         let reportFinal1 = [];
         if (array1 !== undefined && array1.length > 0 && array1[0].personal !== undefined && array1[0].personal.length > 0) {
            for (let i = 0; i < array1.length; i++) {
               const personal = array1[i].personal;
               for (let j = 0; j < personal.length; j++) {
                  let dateAux = new Date(personal[j].ExamPersonal.takedAt);
                  dateAux.setHours(dateAux.getHours() - 5)
                  const id = array1[i].id;
                  const code = array1[i].code;
                  const description = array1[i].description;
                  const startDate = array1[i].startDate;
                  const endDate = array1[i].endDate;
                  const name = personal[j].name + ' ' + personal[j].lastName;
                  const taked = personal[j].ExamPersonal.taked;
                  const score = personal[j].ExamPersonal.score;
                  const takedAt = dateAux.getFullYear() < 1980 ? null : dateAux;

                  reportFinal1.push({id, code, description, startDate, endDate, name, taked, score, takedAt});
               }
            }
         }
         
         return {
            ...state,
            report1: reportFinal1,
            loading: false
         }
      case EXAM_REPORTE_2_EXITO:
         const array2 = [...action.payload];
         let reportFinal2 = [];

         for (let i = 0; i < array2.length; i++) {
            const item = array2[i];
            const id = item.id;
            const description = item.description;
            const code = item.code;
            const startDate = item.startDate;
            const endDate = item.endDate;
            const finished = item.finished;
            const countQuestion = item.countQuestion;
            const countApproved = item.countApproved;
            const countDisapproved = item.countDisapproved;
            const countNotAnswerd = item.countNotAnswerd;
            const countAudience = item.countAudience;
   
            reportFinal2.push({id, code, description, startDate, endDate, finished, countQuestion, countApproved, countDisapproved, countNotAnswerd, countAudience});
         }
            
         return {
            ...state,
            report2: reportFinal2,
            loading: false
         }
      case EXAM_DESCARGA_ERROR:
      case EXAM_REPORTE_1_ERROR:
      case EXAM_REPORTE_2_ERROR:
         return {
            ...state,
            exams: [],
            report1: [],
            report2: [],
            loading: false
         }
      case EXAM_PREGUNTA_DESCARGA_COMENZAR:
         return {
            ...state,
            questions: [],
            loading: true
         }
      case EXAM_PREGUNTA_DESCARGA_EXITO:
         return {
            ...state,
            questions: action.payload,
            loading: false
         }
      case EXAM_PREGUNTA_DESCARGA_ERROR:
         return {
            ...state,
            questions: [],
            loading: false
         }
      case EXAM_RESPONDER_COMENZAR:
         return {
            ...state,
            loading: true
         }
      case EXAM_RESPONDER_EXITO:
         const data = action.payload;
         let examModify = state.exams.find(exam => exam.id === data.exam_Id);

         if (examModify) {
            examModify.personal[0].ExamPersonal.score = data.score;
         }

         return {
            ...state,
            questions: [],
            exams: state.exams.filter(exam => exam.id === examModify.id ? examModify : exam),
            loading: false
         }
      case EXAM_RESPONDER_ERROR:
         return {
            ...state,
            loading: false
         }
      default:
         return state;
   }
}