import React, { Fragment, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Form } from "react-bootstrap";

import { crearProductAction } from "../../actions/productActions";

const NuevoProduct = ({ handleCloseModal }) => {
   const dispatch = useDispatch();

   //const [image, setImage] = useState({ preview: "", data: "" });
   const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [message, setMessage] = useState([]);
  const [imageInfos, setImageInfos] = useState([]);
  const progressInfosRef = useRef(null);
   const [data, onHandleData] = useState({
      title: "",
      description: "",
      season: "",
      size: "",
      price: ""
   });

   const { title, description, season, size, price } = data;

   const onChangeData = (e) => {
      if (e.target.name === "title" || e.target.name === "description" || e.target.name === "size" || e.target.name === "price" || e.target.name === "season") {
         onHandleData({
            ...data,
            [e.target.name]: e.target.value,
         });
      } else {
         if (e.target.name === 'image') {
            /*const img = {
               preview: URL.createObjectURL(e.target.files[0]),
               data: e.target.files[0],
            };
            setImage(img);*/
            let images = [];

            for (let i = 0; i < e.target.files.length; i++) {
               images.push(URL.createObjectURL(e.target.files[i]));
            }

            setSelectedFiles(e.target.files);
            setImagePreviews(images);
            setProgressInfos({ val: [] });
            setMessage([]);
         }
      }
   };

   const onHandleSubmit = (e) => {
      e.preventDefault();

      const formData = new FormData();

      const json = JSON.stringify(data);

      formData.append(
         "json",
         json
       );
      /*formData.append(
         "file",
         selectedFiles.data
       );*/
       for (const key of Object.keys(selectedFiles)) {
         formData.append('files', selectedFiles[key])
     }
//https://www.tutsmake.com/upload-image-in-mysql-using-node-js-and-react-js/
 //https://www.positronx.io/react-file-upload-tutorial-with-node-express-and-multer/      
 //https://dev.to/przpiw/file-upload-with-react-nodejs-2ho7

      dispatch(crearProductAction(formData));
      e.target.reset();
      handleCloseModal();
   };

   return (
      <Fragment>
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="title" className="c-grisDark mb-0">
                           Producto:
                        </label>
                        <input
                           type="text"
                           name="title"
                           className="form-control"
                           placeholder="Ingrese el nombre del producto"
                           value={title}
                           onChange={onChangeData}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-12">
                        <label
                           htmlFor="description"
                           className="c-grisDark mb-0"
                        >
                           Descripción:
                        </label>
                        <textarea
                           name="description"
                           className="form-control"
                           placeholder="Ingrese la descripción"
                           value={description}
                           onChange={onChangeData}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-6">
                        <label
                           htmlFor="size"
                           className="c-grisDark mb-0"
                        >
                           Tamaño:
                        </label>
                        <input
                           type="text"
                           name="size"
                           className="form-control"
                           placeholder="Ingrese el tamaño"
                           value={size}
                           onChange={onChangeData}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-6">
                        <label
                           htmlFor="price"
                           className="c-grisDark mb-0"
                        >
                           Precio:
                        </label>
                        <input
                           type="text"
                           name="price"
                           className="form-control"
                           placeholder="Ingrese el precio"
                           value={price}
                           onChange={onChangeData}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="season" className="c-grisDark mb-0">
                           Temporada:
                        </label>
                        <input
                           type="text"
                           name="season"
                           className="form-control"
                           placeholder="Ingrese la temporada"
                           value={season}
                           onChange={onChangeData}
                        />
                     </div>
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="image" className="c-grisDark mb-0">
                           Imagen:
                        </label>
                        <input
                           type="file"
                           name="image"
                           className="form-control"
                           placeholder="Seleccione la imagen"
                           onChange={onChangeData}
                           accept="image/*"
                           multiple
                           required
                        />
                        {imagePreviews && (
                        <div>
                           {imagePreviews.map((img, i) => {
                              return (
                              <img style={{width: '350px', padding: '5px'}} className="preview" src={img} alt={"image-" + i} key={i} />
                              );
                           })}
                        </div>
                        )}
                     </div>
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               <button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="submit"
               >
                  Guardar
               </button>
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default NuevoProduct;
