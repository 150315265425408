import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalTitle } from "react-bootstrap";
import NuevoStore from "./NuevoStore";

const TituloTabla = () => {
   const user = useSelector((state) => state.auth.user);

   const [showModal, setShowModal] = useState(false);

   const handleCloseModal = () => {
      setShowModal(false);
   };

   const handleOpenModal = () => {
      setShowModal(true);
   };

   return (
      <Fragment>
         <div className="container-fluid mt-md-4 p-0 mt-5 pt-5">
            <div className="col-12">
               <h2 className="titulo text-center">Resultado de la búsqueda</h2>
            </div>
            <div className="col-7 col-md-3 col-lg-12 text-center text-lg-right p-0 pt-4 pb-4 pb-lg-0 pr-lg-4">
               {user &&
               (user.role === "01" ||
                  user.role === "02" ||
                  user.role === "03") ? (
                  <button
                     className="btn-grisDark text-white btn-Solidez mt-1 mb-1 d-grid d-lg-inline-block"
                     onClick={handleOpenModal}
                  >
                     Crear Nuevo
                  </button>
               ) : null}
            </div>
         </div>

         <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
               <ModalTitle>
                  <h3 className="titulo text-center p-3">Crear nueva tienda</h3>
               </ModalTitle>
            </Modal.Header>
            <NuevoStore handleCloseModal={handleCloseModal} />
         </Modal>
      </Fragment>
   );
};

export default TituloTabla;
