import React, { useState, useEffect, Fragment } from "react";
import { Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { leerMensajeAction } from "../../actions/messageActions";

const VerMessage = ({ currentMessage, handleCloseModal }) => {
   const dispatch = useDispatch();

   const user = useSelector((state) => state.auth.user.personal);

   const [data, onHandleData] = useState({
      subject: currentMessage.subject,
      message: currentMessage.message,
      sendedBy: currentMessage.sendedBy.name + ' ' + currentMessage.sendedBy.lastName,
   });

   const {
      subject,
      message,
      sendedBy,
   } = data;

   useEffect(() => {
      dispatch(leerMensajeAction(currentMessage.id, {personalId: user.id}));
   }, []);

   return (
      <Fragment>
         <Form>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                     <div className="form-group col-12 col-md-6">
                        <label htmlFor="sendedBy" className="c-grisDark mb-0">
                           Enviado Por:
                        </label>
                        <input
                           type="text"
                           name="sendedBy"
                           className="form-control"
                           value={sendedBy}
                           disabled
                        />
                     </div>
                  </div>

                  <div className="row">
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="subject" className="c-grisDark mb-0">
                           Asunto:
                        </label>
                        <input
                           type="text"
                           name="subject"
                           className="form-control"
                           value={subject}
                           readOnly
                        />
                     </div>
                  </div>

                  <div className="row">
                     <div className="form-group imputsCampos2 col-12 col-md-12 mt-0">
                        <label
                           htmlFor="message"
                           className="labelCliente mb-0 pt-0"
                        >
                           Mensaje:
                        </label>
                        <textarea
                           name={message}
                           className="form-control"
                           value={message}
                           rows="4"
                           readOnly
                        />
                     </div>
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cerrar
               </button>
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default VerMessage;
