import React from "react";

import Login from "../components/auth/Login";

const App = () => {
   return (
      <div className="container-fluid">
         <Login />
      </div>
   );
};

export default App;
