import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { reporteTicketsAction } from "../../../actions/ticketActions";
import { MONTHS } from "../../../utils/Constans";
import { getYears } from "../../../utils/functions";

const Busqueda = () => {
   const dispatch = useDispatch();

   //const years = useSelector((state) => state.ticket.years);
   const user = useSelector((state) => state.auth.user);

   const [data, onHandleData] = useState({
      year: "",
      month: '',
      name: "",
      typeDocument: "",
      numDocument: "",
      sisning: ''
   });

   const { year, month, name, typeDocument, numDocument, signing } = data;

   const years = getYears();

   const onChangeBuscar = (e) => {
      onHandleData({
         ...data,
         [e.target.name]: e.target.value,
      });
   };

   const onHandleBuscarSubmit = (e) => {
      e.preventDefault();

      dispatch(reporteTicketsAction(data));
   };

   return (
      <Fragment>
         <div className="row pl-3 pr-3 pt-0">
            <div className="container">
               <form onSubmit={onHandleBuscarSubmit}>
                  <div className="btnsOptions ml-lg-3 mt-md-3 mt-xl-0  align-items-center">
                     <div className="row imputsCampos2">
                        <div className="col-12 col-lg-10 offset-lg-2">
                           <div className="row">
                              <h2 className="subTituloInterno pt-4 pb-2 pt-lg-5 pb-lg-5">
                                 Seleccionar filtro:
                              </h2>
                           </div>
                           <div className="row">
                              <label htmlFor="year" className="labelFecha-1">
                                 Año:
                              </label>
                              <select
                                 name="year"
                                 value={year}
                                 onChange={onChangeBuscar}
                              >
                                 <option value="">Seleccionar Año</option>
                                 {years &&
                                    years.map((year) => (
                                       <option key={year.value} value={year.value}>
                                          {year.description}
                                       </option>
                                    ))}
                              </select>
                              <label
                                 htmlFor="month"
                                 className="c-grisDark mb-0"
                              >
                                 Mes:
                              </label>
                              <select
                                 name="month"
                                 value={month}
                                 onChange={onChangeBuscar}
                              >
                                 <option value="">Seleccionar Mes</option>
                                 {MONTHS &&
                                    MONTHS.length > 0 &&
                                    MONTHS.map((month) => (
                                       <option
                                          key={month.value}
                                          value={month.value}
                                       >
                                          {month.description}
                                       </option>
                                    ))}
                              </select>
                           </div>
                           <div className="row mt-lg-3">
                              <label
                                 htmlFor="typeDocument"
                                 className="labelFecha-1"
                              >
                                 Tipo Documento:
                              </label>
                              <select
                                 name="typeDocument"
                                 value={typeDocument}
                                 onChange={onChangeBuscar}
                              >
                                 <option value="">Seleccionar</option>
                                 <option value="01">DNI</option>
                                 <option value="02">Pasaporte</option>
                                 <option value="03">
                                    Carnet de Extranjería
                                 </option>
                              </select>

                              <label
                                 htmlFor="numDocument"
                                 className="labelFecha-1"
                              >
                                 Núm. Documento:
                              </label>
                              <input
                                 name="numDocument"
                                 type="text"
                                 placeholder="Ingresar Número Documento"
                                 value={numDocument}
                                 onChange={onChangeBuscar}
                              />
                           </div>
                           <div className="row mt-lg-3">
                              <label htmlFor="name" className="labelFecha-1">
                                 Nombres:
                              </label>
                              <input
                                 name="name"
                                 type="text"
                                 placeholder="Ingresar Nombres"
                                 value={name}
                                 onChange={onChangeBuscar}
                              />
                              <label
                                 htmlFor="typeDocument"
                                 className="labelFecha-1"
                              >
                                 ¿Tiene firma?:
                              </label>
                              <select
                                 name="signing"
                                 value={signing}
                                 onChange={onChangeBuscar}
                              >
                                 <option value="">Seleccionar</option>
                                 <option value="1">Si</option>
                                 <option value="0">No</option>
                              </select>
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-12 col-lg-2 offset-lg-5 p-0 p-lg-3">
                           <input
                              type="submit"
                              className="btn-grisDark text-white btn-Solidez mt-4 w-100"
                              value="Buscar"
                           />
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </Fragment>
   );
};

export default Busqueda;
