import React from "react";
import Switch from "react-switch";

import eyesIcon from "../../assets/images/eye.svg";

const StoreItem = ({ store, onHandleChange, handleOpenModal }) => {
   return (
      <tr>
         <td>{store.code}</td>
         <td>{store.address}</td>
         <td>{store.mall ? store.mall.description : null}</td>
         <td>{store.city ? store.city.description : null}</td>
         <td>{store.district ? store.district.description : null}</td>
         <td>
            <Switch
               onChange={() => onHandleChange(store.id)}
               checked={store.state}
               className="react-switch"
            />
         </td>
         <td>
            <button type="button" onClick={() => handleOpenModal(store)}>
               <img src={eyesIcon} className="mr-2 iconTabla" alt="Ver" />
            </button>
         </td>
      </tr>
   );
};

export default StoreItem;
