import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form } from "react-bootstrap";

import { cargarTicketAction } from "../../actions/ticketActions";
import { descargarTicketsAction } from "../../actions/ticketActions";

const NuevoTicket = ({ handleCloseModal, type, dataSearch }) => {
   const dispatch = useDispatch();

   const user = useSelector((state) => state.auth.user);

   const [pdf, setPdf] = useState([]);
   const [data, onHandleData] = useState({
      description: "",
   });

   const { description } = data;

   const onChangeData = (e) => {
      if (e.target.name === "description") {
         onHandleData({
            ...data,
            [e.target.name]: e.target.value,
         });
      } else {
         if (e.target.name === "pdf") {
            setPdf(e.target.files);
         }
      }
   };

   const onHandleSubmit = (e) => {
      e.preventDefault();

      const formData = new FormData();
      const json = JSON.stringify(data);

      formData.append("json", json);

      for (let i = 0; i < pdf.length; i++) {
         formData.append("file", pdf[i]);
      }

      dispatch(cargarTicketAction(formData));

      if (type === "personal") {
         setTimeout(function() {}, 3000);
         dispatch(descargarTicketsAction(dataSearch, user.personal.id));
      } else {
         dispatch(descargarTicketsAction(dataSearch));
      }

      e.target.reset();
      handleCloseModal();
   };

   return (
      <Fragment>
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                     <div className="form-group col-12 col-md-12">
                        <label
                           htmlFor="description"
                           className="c-grisDark mb-0"
                        >
                           Descripción:
                        </label>
                        <input
                           type="text"
                           name="description"
                           className="form-control"
                           placeholder="Ingrese la descripción"
                           value={description}
                           onChange={onChangeData}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="pdf" className="c-grisDark mb-0">
                           PDF:
                        </label>
                        <input
                           type="file"
                           name="pdf"
                           accept="application/pdf"
                           className="form-control"
                           placeholder="Seleccione el PDF"
                           onChange={onChangeData}
                           multiple
                           required
                        />
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group col-12 col-md-12">
                        <p><strong>Nota:</strong> El formato de las boletas a subir es el siguiente:</p>
                        <p>
                           <ul>
                              <li>
                                 TipoComprobante_TipoDocumento_NumeroDocumento_Periodo.pdf
                              </li>
                           </ul>
                        </p>
                        <p>Ejemplo: 01_01_45213652_202201.pdf</p>
                        <p>
                           <ul>
                              <li>TipoComprobante
                              <ul>
                                 <li>01: Boleta</li>
                                 <li>02: CTS</li>
                                 <li>03: Liquidación</li>
                              </ul>
                              </li> <li>TipoDocumento
                              <ul>
                                 <li>01: DNI</li>
                                 <li>02: Pasaporte</li>
                                 <li>03: Carnet de Extranjeria</li>
                              </ul>
                              </li>
                           </ul>
                        </p>
                     </div>
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               <button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="submit"
               >
                  Cargar
               </button>
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default NuevoTicket;
