import {
   MESSAGE_AGREGAR_COMENZAR,
   MESSAGE_AGREGAR_EXITO, 
   MESSAGE_AGREGAR_ERROR, 
   MESSAGE_DESCARGA_COMENZAR, 
   MESSAGE_DESCARGA_EXITO, 
   MESSAGE_DESCARGA_ERROR, 
   MESSAGE_OBTENER_COMENZAR,
   MESSAGE_OBTENER_EXITO,
   MESSAGE_OBTENER_ERROR,
   MESSAGE_LEER_COMENZAR,
   MESSAGE_LEER_EXITO,
   MESSAGE_LEER_ERROR,
   MESSAGE_CANTIDAD_COMENZAR,
   MESSAGE_CANTIDAD_EXITO,
   MESSAGE_CANTIDAD_ERROR,
} from '../types';

const initialState = {
   messages: [],
   count: 0,
   currentMessage: null,
   loading: null
};

export default function(state = initialState, action) {
   switch(action.type) {
      case MESSAGE_AGREGAR_COMENZAR:
      case MESSAGE_OBTENER_COMENZAR:
         return {
            ...state,
            currentMessage: null,
            loading: true
         }
      case MESSAGE_CANTIDAD_COMENZAR:
         return {
            ...state,
            count: 0,
            loading: true
         }
      case MESSAGE_CANTIDAD_EXITO:
         return {
            ...state,
            count: action.payload,
            loading: false
         }
      case MESSAGE_CANTIDAD_ERROR:
         return {
            ...state,
            count: 0,
            loading: false
         }
      case MESSAGE_LEER_COMENZAR:
         return {
            ...state,
            loading: true
         }
      case MESSAGE_LEER_EXITO:
         const mesageId = action.payload;
         let messageModify = state.messages.find(message => message.id === mesageId);
         if (messageModify.personal && messageModify.personal[0] && messageModify.personal[0].MessagePersonal) {
            messageModify.personal[0].MessagePersonal.readed = true;
         }

         return {
            ...state,
            messages: state.messages.filter(message => {return message.id === messageModify.id ? messageModify : message}),
            loading: false
         }
      case MESSAGE_LEER_ERROR:
         return {
            ...state,
            loading: false
         }   
      case MESSAGE_AGREGAR_EXITO:
      case MESSAGE_OBTENER_EXITO:
         return {
            ...state,
            currentMessage: action.payload,
            loading: false
         }
      case MESSAGE_AGREGAR_ERROR:
      case MESSAGE_OBTENER_ERROR:
         return {
            ...state,
            currentMessage: null,
            loading: false
         }
      case MESSAGE_DESCARGA_COMENZAR:
         return {
            ...state,
            messages: [],
            loading: true
         }
      case MESSAGE_DESCARGA_EXITO:
         return {
            ...state,
            messages: action.payload,
            loading: false
         }
      case MESSAGE_DESCARGA_ERROR:
         return {
            ...state,
            messages: [],
            loading: false
         }
      default:
         return state;
   }
}