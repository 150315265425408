import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import html2pdf from 'html2pdf.js';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

import excelIcon from "../../../assets/images/excel_icon.svg";
import pdfIcon from "../../../assets/images/pdf_icon.svg";

const TituloTabla = () => {

   const user = useSelector((state) => state.auth.user);
   const memos = useSelector((state) => state.memo.report);

   const exportarPdf = () => {
      let configuracion = {
         margin: 0.2,
         filename: "Reporte_Memos.pdf",
         html2canvas:  { scale: 2 },
         jsPDF: {unit: "in", format: "A4", orientation: "l"}
      };

      const documento = document.getElementById("tablaReporteMemo");
      html2pdf(documento, configuracion);
   }

   const exportarExcel = () => {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
      const ws = XLSX.utils.json_to_sheet(memos);
      ws.A1.v = 'Descripción';
      ws.B1.v = 'Periodo';
      ws.C1.v = 'Persona';
      ws.D1.v = 'Tipo Documento';
      ws.E1.v = 'Número Documento';
      ws.F1.v = 'Firma';
      ws.G1.v = 'Fecha creación';
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, 'Memos.xlsx');
   }

   return (
      <Fragment>
         <div className="container-fluid mt-md-4 p-0 mt-5 pt-5">
            <div className="col-7 col-md-3 col-lg-12 text-center text-lg-right p-0 pt-4 pb-4 pb-lg-0 pr-lg-4">
               {user && (user.role === '01' || user.role === '02' || user.role === '03') ? (
                  <div>
                     <button type="button" onClick={() => exportarPdf()}>
                        <img src={pdfIcon} className="mr-2 iconTabla2" alt="Exportar a PDF" />
                     </button>
                     {' '}
                     <button type="button" onClick={() => exportarExcel()}>
                        <img src={excelIcon} className="mr-2 iconTabla2" alt="Exportar a Excel" />
                     </button>
                  </div>
               ) : null}
            </div>
         </div>
      </Fragment>
   );
};

export default TituloTabla;
