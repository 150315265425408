import React, { useState, Fragment } from "react";
import { Modal, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Swal from 'sweetalert2';

import { firmarMemoAction } from "../../actions/memoActions";

const ViewMemorandum = ({ currentMemo, handleCloseModal }) => {
   const dispatch = useDispatch();

   const [data, onHandleData] = useState({
      description: currentMemo.description,
      signing: currentMemo.signing,
   });

   const { description, signing } = data;

   const onChangeData = (e) => {
      if (e.target.name === "description" || e.target.name === "signing") {
         onHandleData({
            ...data,
            [e.target.name]: e.target.value,
         });
      }
   };

   const onHandleSubmit = (e) => {
      e.preventDefault();
      
      Swal.fire({
         title: '¿Esta seguro de firmar el documento?',
         showCancelButton: true,
         confirmButtonText: 'Si',
         cancelButtonText: `No`,
       }).then((result) => {
         if (result.isConfirmed) {
            dispatch(firmarMemoAction(currentMemo.id));

            e.target.reset();
            handleCloseModal();
         }
       })
   };

   const onDownloadPdf = () => {

   }

   return (
      <Fragment>
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="description" className="c-grisDark mb-0">
                           Descripcion:
                        </label>
                        <input
                           type="text"
                           name="description"
                           className="form-control"
                           placeholder="Ingrese el nombre del producto"
                           value={description}
                           onChange={onChangeData}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-12 height500">
                        <embed
                           src={currentMemo.url + "#toolbar=0"}
                           scrolling="auto"
                           height="100%"
                           width="100%"
                        ></embed>
                     </div>
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cerrar
               </button>
               {signing ? (
                  <a
                     className="btn-grisDark text-white btn-Solidez mt-4"
                     href={currentMemo.url}
                     target="_blank"
                     download
                  >
                     Descargar
                  </a>
               ) : (
                  <button
                     className="btn-grisDark text-white btn-Solidez mt-4"
                     type="submit"
                  >
                     Firmar
                  </button>
               )}
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default ViewMemorandum;
