import React from "react";
import { useSelector } from "react-redux";

import CampaingItem from "./CampaingItem";

const ListadoCampaing = () => {
   const campaings = useSelector((state) => state.campaing.report);

   return (
      <div id="tablaReporteCampania"  className="container-fluid p-0">
         <div className="col-12">
               <h2 className="titulo text-center">Reporte de Campañas</h2>
            </div>
         <div className="cajaTable ">
            <div className="resultadoTable d-none">
               <p className="txtResultado text-center">
                  No hay resultado de búsqueda
               </p>
            </div>
            <div className="cabeceraTable2 cuperoTable3 mt-3 mb-100">
               <table>
                  <thead>
                     <tr>
                        <th>Campaña</th>
                        <th>Descripción</th>
                        <th>Inicio</th>
                        <th>Fin</th>
                        <th>Fecha Creación</th>
                        <th>Estado</th>
                     </tr>
                  </thead>
                  <tbody>
                     {campaings &&
                        campaings.map((campaing) => (
                           <CampaingItem key={campaing.id} campaing={campaing} />
                        ))}
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   );
};

export default ListadoCampaing;
