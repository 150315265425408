import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Busqueda from "../components/messages/Busqueda";
import ListadoMessage from "../components/messages/ListadoMessage";
import TituloTabla from "../components/messages/TituloTabla";
import Loading from "../components/layout/Loading";
import { obtenerPersonalActivosAction } from "../actions/personalActions";

const Commission = () => {
   const dispatch = useDispatch();

   const loading = useSelector((state) => state.message.loading);

   useEffect(() => {
      dispatch(obtenerPersonalActivosAction());
   }, []);

   return (
      <Fragment>
         <Header title={"MENSAJES"}/>
         <Loading loading={loading} />
         <Busqueda />
         <TituloTabla />
         <ListadoMessage />
         <Footer/>
      </Fragment>
   );
};

export default Commission;
