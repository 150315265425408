import React from 'react';

const PersonalItem = ({ personal }) => {
  return (
    <tr>
    <td>{personal.code}</td>
    <td>{personal.name + " " + personal.lastName}</td>
    <td>{personal.typeDocument === '01' ? 'DNI' : personal.typeDocument === '02' ? 'Pasaporte' : 'Carnet de Extranjeria'}</td>
    <td>{personal.numDocument}</td>
    <td>{personal.phone}</td>
    <td>{personal.email}</td>
    <td>{personal.store}</td>
    <td>{personal.position}</td>
 </tr>
  );
};

export default PersonalItem;
