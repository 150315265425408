import React, { useState, useEffect, Fragment } from "react";
import { Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { actualizarStoreAction } from "../../actions/storeActions";
import { listarDistritosAction } from "../../actions/ubigeoActions";

const EditarStore = ({ currentStore, handleCloseModal }) => {
   const dispatch = useDispatch();

   const malls = useSelector(state => state.mall.malls);
   const managers = useSelector(state => state.personal.personalsRoles);;//useSelector(state => state.store.managers);
   const cities = useSelector(state => state.ubigeo.cities);
   const districts = useSelector(state => state.ubigeo.districts);

   const inCharge = currentStore.collaborators.find(collaborator => collaborator.PersonalStore.inCharge === true);
   const [data, onHandleData] = useState({
      id: currentStore.id,
      code: currentStore.code,
      description: currentStore.description,
      address: currentStore.address,
      serie_number: currentStore.serie_number,
      email: currentStore.email,
      rpm: currentStore.rpm,
      phone: currentStore.phone,// === '' ? null : currentStore.phone,
      cityId: currentStore.cityId,
      districtId: currentStore.districtId,
      mallId: currentStore.mallId,
      inChargeId: inCharge ? inCharge.PersonalStore.personalId : '',
      state: currentStore.state ? "1" : "0"
   });

   const {
      id,
      code,
      description,
      address,
      serie_number,
      email,
      rpm,
      phone,
      cityId,
      districtId,
      mallId,
      inChargeId,
      state
   } = data;

   const onChangeData = (e) => {
      onHandleData({
         ...data,
         [e.target.name]: e.target.value,
      });
   };

   const onHandleSubmit = (e) => {
      e.preventDefault();
      
     const otherObject = {
        descriptionMall: document.getElementById("mallId").options[document.getElementById("mallId").selectedIndex].text,
        descriptionCity: document.getElementById("cityId").options[document.getElementById("cityId").selectedIndex].text,
        descriptionDistrict: document.getElementById("districtId").options[document.getElementById("districtId").selectedIndex].text,
     }

     let obj = {
      address: data.address,
      cityId: data.cityId,
      code: data.code,
      description: data.description,
      districtId: data.districtId,
      id: data.id,
      mallId: data.mallId,
      serie_number: data.serie_number,
      state: data.state
     }

     if (data.inChargeId && data.inChargeId !== '') {
      obj.inChargeId = data.inChargeId;
     }
     if (data.rpm && data.rpm !== '') {
      obj.rpm = data.rpm;
     }
     if (data.email && data.email !== '') {
      obj.email = data.email;
     }
     if (data.phone && data.phone !== '') {
      obj.phone = data.phone;
     }

     dispatch(actualizarStoreAction(currentStore.id, obj, otherObject));

      e.target.reset();
      handleCloseModal();
   };

   useEffect(() => {
      if (cityId !== '') {
         dispatch(listarDistritosAction(cityId));
      }
   }, [cityId]);

   return (
      <Fragment>
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
               <div className="row">
                     <div className="form-group col-12 col-md-6">
                        <label htmlFor="code" className="c-grisDark mb-0">
                           Código: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <input
                           type="text"
                           name="code"
                           className="form-control"
                           placeholder="Ingrese el código"
                           value={code}
                           onChange={onChangeData}
                           minLength={3}
                           maxLength={15}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-6">
                     <label htmlFor="description" className="c-grisDark mb-0">
                           Descripción: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <input
                           type="text"
                           name="description"
                           className="form-control"
                           placeholder="Ingrese la dirección"
                           value={description}
                           onChange={onChangeData}
                           minLength={3}
                           maxLength={60}
                           required
                        />
                     </div>
                  </div>
                  <div className="row">
                  <div className="form-group col-12 col-md-12">
                     <label htmlFor="address" className="c-grisDark mb-0">
                           Dirección: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <input
                           type="text"
                           name="address"
                           className="form-control"
                           placeholder="Ingrese la dirección"
                           value={address}
                           onChange={onChangeData}
                           minLength={3}
                           maxLength={200}
                           required
                        />
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                     <label
                           htmlFor="mallId"
                           className="labelCliente mb-0 pt-0"
                        >
                           Mall: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <select
                           id="mallId"
                           name="mallId"
                           className="w-100"
                           value={mallId}
                           onChange={onChangeData}
                           required
                        >
                           <option value="">Seleccionar Mall</option>
                           {malls &&
                              malls.length &&
                              malls.map((mall) => (
                                 <option key={mall.id} value={mall.id}>
                                    {mall.description}
                                 </option>
                              ))}
                        </select>
                     </div>
                     <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                     <label
                           htmlFor="inChargeId"
                           className="labelCliente mb-0 pt-0"
                        >
                           Encargado:
                        </label>
                        <select
                           name="inChargeId"
                           className="w-100"
                           value={inChargeId}
                           onChange={onChangeData}
                        >
                           <option value="">Seleccionar Encargado</option>
                           {managers &&
                              managers.length &&
                              managers.map((manager) => (
                                 <option key={manager.id} value={manager.id}>
                                    {manager.name} {manager.lastName} 
                                 </option>
                              ))}
                        </select>
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group col-12 col-md-6">
                        <label htmlFor="serie_number" className="c-grisDark mb-0">
                           Nro. Serie: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <input
                           type="text"
                           name="serie_number"
                           className="form-control"
                           placeholder="Ingrese el número de serie"
                           value={serie_number}
                           onChange={onChangeData}
                           minLength={3}
                           maxLength={50}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-6">
                        <label
                           htmlFor="email"
                           className="c-grisDark mb-0"
                        >
                           Correo:
                        </label>
                        <input
                           type="text"
                           name="email"
                           className="form-control"
                           placeholder="Ingrese su correo"
                           value={email}
                           onChange={onChangeData}
                           minLength={10}
                           maxLength={150}
                        />
                     </div>
                  </div>

                  <div className="row">
                     <div className="form-group col-12 col-md-6">
                        <label
                           htmlFor="phone"
                           className="labelCliente mb-0 pt-0"
                        >
                           Teléfono:
                        </label>
                        <input
                           type="text"
                           name="phone"
                           className="form-control"
                           placeholder="Ingrese el teléfono"
                           value={phone}
                           onChange={onChangeData}
                           minLength={7}
                           maxLength={9}
                        />
                     </div>
                     <div className="form-group col-12 col-md-6">
                        <label
                           htmlFor="rpm"
                           className="c-grisDark mb-0"
                        >
                           RPM:
                        </label>
                        <input
                           type="text"
                           name="rpm"
                           className="form-control"
                           placeholder="Ingrese el RPM"
                           value={rpm}
                           onChange={onChangeData}
                           minLength={9}
                           maxLength={9}
                        />
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                        <label
                           htmlFor="cityId"
                           className="labelCliente mb-0 pt-0"
                        >
                           Ciudad: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <select
                           id="cityId"
                           name="cityId"
                           className="w-100"
                           value={cityId}
                           onChange={onChangeData}
                           required
                        >
                           <option value="">Seleccionar Ciudad</option>
                           {cities &&
                              cities.length &&
                              cities.map((city) => (
                                 <option key={city.id} value={city.id}>
                                    {city.description}
                                 </option>
                              ))}
                        </select>
                     </div>
                     <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                        <label
                           htmlFor="districtId"
                           className="labelCliente mb-0 pt-0"
                        >
                           Distrito: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <select
                           id="districtId"
                           name="districtId"
                           className="w-100"
                           value={districtId}
                           onChange={onChangeData}
                           required
                        >
                           <option value="">Seleccionar Distrito</option>
                           {districts &&
                              districts.length &&
                              districts.map((district) => (
                                 <option key={district.id} value={district.id}>
                                    {district.description}
                                 </option>
                              ))}
                        </select>
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                        <label
                           htmlFor="state"
                           className="labelCliente mb-0 pt-0"
                        >
                           Estado: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <select
                           name="state"
                           className="w-100"
                           value={state}
                           onChange={onChangeData}
                           required
                        >
                           <option value="1">Activo</option>
                           <option value="0">Inactivo</option>
                        </select>
                     </div>
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               <button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="submit"
               >
                  Guardar
               </button>
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default EditarStore;
