import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalTitle } from "react-bootstrap";

import TicketItem from "./TicketItem";
import ViewTicket from "./ViewTicket";

const ListadoTicket = () => {
   const tickets = useSelector((state) => state.ticket.tickets);
   const user = useSelector((state) => state.auth.user);

   const [showModal, setShowModal] = useState(false);
   const [currentTicket, setCurrentTicket] = useState({});

   const handleCloseModal = () => {
      setShowModal(false);
   };

   const handleOpenModal = (ticket) => {
      setShowModal(true);
      setCurrentTicket(ticket);
   };

   const onHandleChange = (id) => {
      //dispatch(cambiarEstadoProductAction(id));
   };

   return (
      <div className="container-fluid">
         <div className="container-fluid mt-md-4 p-0 mt-5 pt-5">
            <div className="col-12">
               <h2 className="titulo text-center mb-5">
                  Resultado de la búsqueda
               </h2>
            </div>
         </div>
         <div className="container">
            <div className="row pt-2">
               {tickets &&
                  tickets.map((ticket) => (
                     <TicketItem
                        key={ticket.id}
                        ticket={ticket}
                        onHandleChange={onHandleChange}
                        handleOpenModal={handleOpenModal}
                     />
                  ))}
            </div>
         </div>

         <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <div className="modal-content">
               <Modal.Header closeButton>
                  <ModalTitle>
                     <h3 className="titulo text-center p-3">Ver boleta</h3>
                  </ModalTitle>
               </Modal.Header>
               <ViewTicket
                  currentTicket={currentTicket}
                  handleCloseModal={handleCloseModal}
               />
            </div>
         </Modal>
      </div>
   );
};

export default ListadoTicket;
