import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Busqueda from "../components/store/Busqueda";
import ListadoStore from "../components/store/ListadoStore";
import TituloTabla from "../components/store/TituloTabla";
import Loading from "../components/layout/Loading";
import { obtenerManagersAction } from "../actions/storeActions";
import { obtenerMallActivosAction } from "../actions/mallActions";
import { listarCiudadesAction } from "../actions/ubigeoActions";
import { obtenerPersonalRolAction } from "../actions/personalActions";

const Store = () => {
   const dispatch = useDispatch();

   const loading = useSelector((state) => state.store.loading);

   const [data, setData] = useState({
      code: '',
      address: '',
      mallId: '',
      inChargeId: '',
      state: '',
      limit: 10,
      offset: 0
   });

   useEffect(() => {
      dispatch(obtenerManagersAction());
      dispatch(obtenerMallActivosAction());
      dispatch(listarCiudadesAction());
      dispatch(obtenerPersonalRolAction('04'));
   }, []);

   return (
      <Fragment>
         <Header title={"ADMINISTRACIÓN DE TIENDAS"}/>
         <Loading loading={loading} />
         <Busqueda data={data} setData={setData} />
         <TituloTabla />
         <ListadoStore data={data} setData={setData}/>
         <Footer/>
      </Fragment>
   );
};

export default Store;
