import React, { useState, useEffect, Fragment } from "react";
import { Modal, Form } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
import { useDispatch, useSelector } from "react-redux";

import { actualizarProductAction } from "../../actions/productActions";

const EditarProduct = ({ currentProduct, handleCloseModal }) => {
   const dispatch = useDispatch();

   const user = useSelector((state) => state.auth.user);

   const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [imagePreviews, setImagePreviews] = useState([]);
   const [image, setImage] = useState({ preview: "", data: "" });
   const [images, setListImages] = useState([]);
   const [data, onHandleData] = useState({
      title: currentProduct.title,
      description: currentProduct.description,
      size: currentProduct.size,
      season: currentProduct.season,
      price: currentProduct.price,
      state: currentProduct.state ? "1" : "0",
   });

   const { title, description, size, price, season, state } = data;

   const disabled = user.role === '05' ? true : false;

   const onChangeData = (e) => {
      if (
         e.target.name === "title" ||
         e.target.name === "description" ||
         e.target.name === "season" ||
         e.target.name === "size" ||
         e.target.name === "price"
      ) {
         onHandleData({
            ...data,
            [e.target.name]: e.target.value,
         });
      } else {
         if (e.target.name === "image") {
            /*const img = {
               preview: URL.createObjectURL(e.target.files[0]),
               data: e.target.files[0],
            };
            setImage(img);*/
            let images = [];

            for (let i = 0; i < e.target.files.length; i++) {
               images.push(URL.createObjectURL(e.target.files[i]));
            }

            setSelectedFiles(e.target.files);
            setImagePreviews(images);
         }
      }
   };

   const onHandleSubmit = (e) => {
      e.preventDefault();

      const formData = new FormData();

      const json = JSON.stringify({
         title,
         description,
         size,
         price,
         season
      });

      formData.append("json", json);
      //formData.append("file", image.data);
      if (selectedFiles) {
         console.log(1);
         for (const key of Object.keys(selectedFiles)) {
         formData.append('files', selectedFiles[key])
     }
      } else {
         console.log(2);
         formData.append('files', selectedFiles)
      }

      dispatch(
         actualizarProductAction(
            currentProduct.id,
            { ...data },
            formData
         )
      );

      e.target.reset();
      handleCloseModal();
   };

   useEffect(() => {
      let imgs = [];
      if (currentProduct.image) {
         imgs = currentProduct.image.split(',');
      }

      let arr = [];
      imgs.map(image => {
         if (image !== '') {
            arr.push(image);
         }
      });
      setListImages(arr);
   }, []);

   return (
      <Fragment>
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                     <div className="form-group col-12 col-md-12">
                     <Carousel activeIndex={index} onSelect={handleSelect}>
                           {images.map((slide, i) => {
                           return (
                              <Carousel.Item key={i}>        
                           <img
                              className="d-block w-100"
                              src={slide}
                              alt="slider image"
                           />
                           </Carousel.Item>
                           )
                           })}
                           
                        </Carousel>
                     </div>
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="title" className="c-grisDark mb-0">
                           Producto:
                        </label>
                        <input
                           type="text"
                           name="title"
                           className="form-control"
                           placeholder="Ingrese el nombre del producto"
                           value={title}
                           onChange={onChangeData}
                           disabled={disabled}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-12">
                        <label
                           htmlFor="description"
                           className="c-grisDark mb-0"
                        >
                           Descripción:
                        </label>
                        <textarea
                           name="description"
                           className="form-control"
                           placeholder="Ingrese la descripción"
                           value={description}
                           onChange={onChangeData}
                           disabled={disabled}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-6">
                        <label htmlFor="size" className="c-grisDark mb-0">
                           Tamaño:
                        </label>
                        <input
                           type="text"
                           name="size"
                           className="form-control"
                           placeholder="Ingrese el tamaño"
                           value={size}
                           onChange={onChangeData}
                           disabled={disabled}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-6">
                        <label htmlFor="price" className="c-grisDark mb-0">
                           Precio:
                        </label>
                        <input
                           type="text"
                           name="price"
                           className="form-control"
                           placeholder="Ingrese el precio"
                           value={price}
                           onChange={onChangeData}
                           disabled={disabled}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="season" className="c-grisDark mb-0">
                           Temporada:
                        </label>
                        <input
                           type="text"
                           name="season"
                           className="form-control"
                           placeholder="Ingrese la temporada"
                           value={season}
                           onChange={onChangeData}
                           disabled={disabled}
                        />
                     </div>
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="image" className="c-grisDark mb-0">
                           Imagen:
                        </label>
                        <input
                           type="file"
                           name="image"
                           className="form-control"
                           placeholder="Seleccione la imagen"
                           accept="image/*"
                           disabled={disabled}
                           onChange={onChangeData}
                           multiple
                        />
                        {imagePreviews && (
                        <div>
                           {imagePreviews.map((img, i) => {
                              return (
                              <img style={{width: '350px', padding: '5px'}} className="preview" src={img} alt={"image-" + i} key={i} />
                              );
                           })}
                        </div>
                        )}
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                        <label
                           htmlFor="state"
                           className="labelCliente mb-0 pt-0"
                        >
                           Estado:
                        </label>
                        <select
                           name="state"
                           className="w-100"
                           value={state}
                           onChange={onChangeData}
                           disabled={disabled}
                           required
                        >
                           <option value="1">Activo</option>
                           <option value="0">Inactivo</option>
                        </select>
                     </div>
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               {disabled ? (null) : (<button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="submit"
               >
                  Guardar
               </button>)}
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default EditarProduct;
