import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Busqueda from "../components/ticket/Busqueda";
import ListadoTicket from "../components/ticket/ListadoTicket";
import ListadoTicketTodos from "../components/ticket/ListadoTicketTodos";
import TituloTabla from "../components/ticket/TituloTabla";
import Loading from "../components/layout/Loading";
import { obtenerYearAction } from "../actions/ticketActions";

const Ticket = () => {
   const dispatch = useDispatch();

   const user = useSelector((state) => state.auth.user);
   const loading = useSelector((state) => state.ticket.loading);

   const [key, setKey] = useState("personal");

   const [data, setData] = useState({
      year: "",
      name: "",
      typeDocument: "",
      numDocument: "",
      limit: 10,
      offset: 0
   });

   useEffect(() => {
      dispatch(obtenerYearAction());
   }, []);

   return (
      <Fragment>
         <Header title={"BOLETAS"} />
         <Loading loading={loading} />
         <Busqueda type={key} data={data} setData={setData} />
         <TituloTabla type={key} data={data} />
         {user && (user.role === "01" || user.role === "03") ? (
            <Tabs
               id="controlled-tab-example"
               activeKey={key}
               onSelect={(k) => setKey(k)}
               className="mb-2"
            >
               <Tab eventKey="personal" title="Boletas personales">
                  <ListadoTicket />
               </Tab>
               <Tab eventKey="total" title="Boletas de todos">
                  <ListadoTicketTodos data={data} setData={setData} />
               </Tab>
            </Tabs>
         ) : (
            <ListadoTicket />
         )}
         <Footer />
      </Fragment>
   );
};

export default Ticket;
