import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { reportePersonalAction } from "../../../actions/personalActions";
import { obtenerStoreActivosAction } from "../../../actions/storeActions";

const Busqueda = ({ data, setData }) => {
   const dispatch = useDispatch();

   const stores = useSelector(state => state.store.storesActivos);

   const { code, name, storeId, state, typeDocument, numDocument, offset } = data;

   const onChangeBuscar = (e) => {
      setData({
         ...data,
         [e.target.name]: e.target.value,
      });
   };

   const onHandleBuscarSubmit = (e) => {
      e.preventDefault();

      //const offset = 0;

      const dataObject = {
         ...data,
         type: '1',
      };

      dispatch(reportePersonalAction(dataObject, offset));
   }

   useEffect(() => {
      dispatch(obtenerStoreActivosAction());
   }, []);

   return (
      <Fragment>
      <div className="row pl-3 pr-3 pt-0">
         <div className="container">
            <form onSubmit={onHandleBuscarSubmit}>
               <div className="btnsOptions ml-lg-3 mt-md-3 mt-xl-0  align-items-center">
                  <div className="row imputsCampos2">
                     <div className="col-12 col-lg-10 offset-lg-2">
                        <div className="row">
                           <h2 className="subTituloInterno pt-4 pb-2 pt-lg-5 pb-lg-5">
                              Seleccionar filtro:
                           </h2>
                        </div>
                        <div className="row">
                           <label htmlFor="code" className="labelFecha-1">
                              Código:
                           </label>
                           <input name="code" type="text" placeholder="Ingresar Código" value={code}
                           onChange={onChangeBuscar} />

                           <label htmlFor="name" className="labelFecha-1">
                              Nombres:
                           </label>
                           <input name="name" type="text" placeholder="Ingresar Nombres" value={name}
                           onChange={onChangeBuscar} />
                        </div>
                        <div className="row mt-lg-3">
                           <label htmlFor="storeId" className="labelCliente">
                              Tienda:
                           </label>
                           <select name="storeId" value={storeId}
                           onChange={onChangeBuscar}>
                              <option value="">Seleccionar Tienda</option>
                              {stores && stores.map(store => (
                                 <option key={store.id} value={store.id}>{store.address}</option>
                              ))}
                           </select>

                           <label htmlFor="state" className="labelCliente">
                              Estado:
                           </label>
                           <select name="state" value={state}
                           onChange={onChangeBuscar}>
                              <option value="">Seleccionar Estado</option>
                              <option value="1">Activo</option>
                              <option value="0">Inactivo</option>
                           </select>
                        </div>
                        <div className="row mt-lg-3">

                        <label
                           htmlFor="typeDocument"
                           className="labelCliente"
                        >
                           Tipo de Doc.:
                        </label>
                        <select
                           name="typeDocument"
                           value={typeDocument}
                           onChange={onChangeBuscar}
                        >
                           <option value="">
                              Seleccionar tipo de documento
                           </option>
                           <option value="01">DNI</option>
                           <option value="02">Pasaporte</option>
                           <option value="03">Carnet de Extranjería</option>
                        </select>

                        <label
                           htmlFor="numDocument"
                           className="labelCliente"
                        >
                           Número Doc.:
                        </label>
                        <input
                           type="text"
                           name="numDocument"
                           placeholder="Número de documento"
                           value={numDocument}
                           onChange={onChangeBuscar}
                           minLength={5}
                           maxLength={15}
                        />
                  </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-12 col-lg-2 offset-lg-5 p-0 p-lg-3">
                        <input
                           type="submit"
                           className="btn-grisDark text-white btn-Solidez mt-4 w-100"
                           value="Buscar"
                        />
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
      </Fragment>
   );
};

export default Busqueda;
