import {
   SCHEDULE_AGREGAR_COMENZAR,
   SCHEDULE_AGREGAR_EXITO, 
   SCHEDULE_AGREGAR_ERROR, 
   SCHEDULE_DESCARGA_COMENZAR, 
   SCHEDULE_DESCARGA_EXITO, 
   SCHEDULE_DESCARGA_ERROR, 
   SCHEDULE_OBTENER_COMENZAR,
   SCHEDULE_OBTENER_EXITO,
   SCHEDULE_OBTENER_ERROR,
   SCHEDULE_EDITAR_COMENZAR,
   SCHEDULE_EDITAR_EXITO,
   SCHEDULE_EDITAR_ERROR,
   SCHEDULE_ESTADO_COMENZAR,
   SCHEDULE_ESTADO_EXITO,
   SCHEDULE_ESTADO_ERROR,
   SCHEDULE_REPORTE_COMENZAR,
   SCHEDULE_REPORTE_EXITO,
   SCHEDULE_REPORTE_ERROR,
} from '../types';

const initialState = {
   schedules: [],
   report: [],
   currentSchedule: null,
   currentPersonalId: null,
   loading: null
};

export default function(state = initialState, action) {
   switch(action.type) {
      case SCHEDULE_AGREGAR_COMENZAR:
      case SCHEDULE_EDITAR_COMENZAR:
      case SCHEDULE_OBTENER_COMENZAR:
      case SCHEDULE_ESTADO_COMENZAR:
         return {
            ...state,
            currentSchedule: null,
            loading: true
         }
      case SCHEDULE_OBTENER_EXITO:
         let auxSchedule = [];
         action.payload.map(schedule => auxSchedule.push({ event_id: schedule.event_id, title: schedule.title, type: schedule.type, start: new Date(schedule.start), end: new Date(schedule.end), PersonalId: schedule.PersonalId }))
         return {
            ...state,
            schedules: auxSchedule,
            currentPersonalId: action.payload.personalId,
            loading: false
         }
      case SCHEDULE_AGREGAR_EXITO:
            const schedule = { event_id: action.payload.id, title: action.payload.description, type: action.payload.type, start: new Date(action.payload.startDate), end: new Date(action.payload.endDate), PersonalId: action.payload.PersonalId };
            return {
               ...state,
               schedules: [...state.schedules, schedule],
               currentPersonalId: action.payload.personalId,
               loading: false
            }
      case SCHEDULE_EDITAR_EXITO:
         const currentSchedule = action.payload;
         let scheduleModify = state.schedules.find(schedule => schedule.event_id === currentSchedule.event_id);
         scheduleModify.title = currentSchedule.title;
         scheduleModify.type = currentSchedule.type;
         scheduleModify.start = currentSchedule.start;
         scheduleModify.end = currentSchedule.end;
 
         return {
            ...state,
            schedules: state.schedules.filter(schedule => schedule.event_id === scheduleModify.event_id ? scheduleModify : schedule),
            loading: false
         }
      case SCHEDULE_ESTADO_EXITO:
         const idSchedule = action.payload;
         console.log('DELETE', idSchedule);
         //let scheduleState = state.schedules.find(schedule => schedule.event_id === idSchedule);
         //scheduleState.state = !scheduleState.state
            
         return {
            ...state,
            schedules: state.schedules.filter(schedule => schedule.event_id !== idSchedule),
            loading: false
         }
      case SCHEDULE_AGREGAR_ERROR:
      case SCHEDULE_EDITAR_ERROR:
      case SCHEDULE_OBTENER_ERROR:
      case SCHEDULE_ESTADO_ERROR:
         return {
            ...state,
            currentSchedule: null,
            loading: false
         }
      case SCHEDULE_DESCARGA_COMENZAR:
         return {
            ...state,
            schedules: [],
            loading: true
         }
      case SCHEDULE_DESCARGA_EXITO:
         return {
            ...state,
            schedules: action.payload,
            loading: false
         }
      case SCHEDULE_REPORTE_COMENZAR:
         return {
            ...state,
            report: [],
            loading: false
         }
      case SCHEDULE_REPORTE_EXITO:
         return {
            ...state,
            report: action.payload,
            loading: false
         }
      case SCHEDULE_DESCARGA_ERROR:
      case SCHEDULE_REPORTE_ERROR:
         return {
            ...state,
            schedules: [],
            report: [],
            loading: false
         }
      default:
         return state;
   }
}