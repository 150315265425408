import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { buscarStoreAction } from "../../actions/storeActions";

const Busqueda = ({ data, setData }) => {
   const dispatch = useDispatch();

   const malls = useSelector(state => state.mall.malls);
   const managers = useSelector(state => state.personal.personalsRoles);

   const { code, address, mallId, inChargeId, state, offset } = data;

   const onChangeBuscar = (e) => {
      setData({
         ...data,
         [e.target.name]: e.target.value,
      });
   };

   const onHandleBuscarSubmit = (e) => {
      e.preventDefault();

      //const offset = 0;

      dispatch(buscarStoreAction(data, offset));
   }

   return (
      <Fragment>
      <div className="row pl-3 pr-3 pt-0">
         <div className="container">
            <form onSubmit={onHandleBuscarSubmit}>
               <div className="btnsOptions ml-lg-3 mt-md-3 mt-xl-0  align-items-center">
                  <div className="row imputsCampos2">
                     <div className="col-12 col-lg-10 offset-lg-2">
                        <div className="row">
                           <h2 className="subTituloInterno pt-4 pb-2 pt-lg-5 pb-lg-5">
                              Seleccionar filtro:
                           </h2>
                        </div>
                        <div className="row">
                           <label htmlFor="code" className="labelFecha-1">
                              Código:
                           </label>
                           <input name="code" type="text" placeholder="Ingresar Código" value={code}
                           onChange={onChangeBuscar} />

                           <label htmlFor="address" className="labelFecha-1">
                              Dirección:
                           </label>
                           <input name="address" type="text" placeholder="Ingresar Dirección" value={address}
                           onChange={onChangeBuscar} />
                        </div>
                        <div className="row mt-lg-3">
                           <label htmlFor="mallId" className="labelCliente">
                              Mall:
                           </label>
                           <select name="mallId" value={mallId}
                           onChange={onChangeBuscar}>
                              <option value="">Seleccionar Mall</option>
                              {malls && malls.map(mall => (
                                 <option key={mall.id} value={mall.id}>{mall.description}</option>
                              ))}
                           </select>

                           <label htmlFor="inChargeId" className="labelCliente">
                              Encargado:
                           </label>
                           <select name="inChargeId" value={inChargeId}
                           onChange={onChangeBuscar}>
                              <option value="">Seleccionar Encargado</option>
                              {managers && managers.map(manager => (
                                 <option key={manager.id} value={manager.id}>{manager.name} {manager.lastName}</option>
                              ))}
                           </select>
                        </div>
                        <div className="row  mt-lg-3">
                           <label htmlFor="state" className="labelCliente">
                              Estado:
                           </label>
                           <select name="state" value={state}
                           onChange={onChangeBuscar}>
                              <option value="">Seleccionar Estado</option>
                              <option value="1">Activo</option>
                              <option value="0">Inactivo</option>
                           </select>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-12 col-lg-2 offset-lg-5 p-0 p-lg-3">
                        <input
                           type="submit"
                           className="btn-grisDark text-white btn-Solidez mt-4 w-100"
                           value="Buscar"
                        />
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
      </Fragment>
   );
};

export default Busqueda;
