import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";

import { crearCommissionAction, obtenerMetaCommissionAction } from "../../actions/commissionActions";
import { MONTHS } from "../../utils/Constans";

import { getYears } from "../../utils/functions";

const NuevoCommission = ({ handleCloseModal }) => {
   const dispatch = useDispatch();

   const personals = useSelector((state) => state.personal.personalsActivos);
   const user = useSelector((state) => state.auth.user);
   const goalPersonal = useSelector((state) => state.commission.goal);
   const commission = useSelector((state) => state.commission.commission);

   const [data, onHandleData] = useState({
      personalId: user.role === "05" ? user.personal.id : "",
      goal: goalPersonal,
      price: '',
      //amount: "",
      year: "",
      month: "",
   });

   const { personalId, goal, price, year, month } = data;

   const [optionsSelect, setOptionsSelect] = useState({});
   const multi = user.role === "05" ? false : true;
   let optionsPersonal = [];
   if (personals) {
      if (multi) {
         personals.map((personal) =>
            optionsPersonal.push({
               value: personal.id,
               label: personal.name + " " + personal.lastName,
            })
         );
      }
   }

   const YEARS = getYears();

   const onChangeData = (e) => {
      if (
         e.target &&
         (e.target.name === "year" ||
            e.target.name === "month" ||
            e.target.name === "price" ||
            e.target.name === "goal" ||
            e.target.name === "amount")
      ) {
         if (e.target.name === "month") {
            onHandleData({
               ...data,
               [e.target.name]: e.target.value,
            });

            if (user.role === "05") {
               dispatch(obtenerMetaCommissionAction(user.personal.id, year + e.target.value));
            } else {
               dispatch(obtenerMetaCommissionAction(personalId, year + e.target.value));
            }
         } else {
            onHandleData({
               ...data,
               [e.target.name]: e.target.value,
            });
         }
      } else {
         onHandleData({
            ...data,
            personalId: e.value,
         });
         setOptionsSelect(e);
      }
   };

   const onHandleSubmit = (e) => {
      e.preventDefault();

      if (user.role === "05" && (goalPersonal === null || goalPersonal === 0)) {
         Swal.fire({
            icon: "warning",
            title: "Sin meta",
            text: "No se cuenta con una meta para el periodo seleccionado, favor de pedirle a su encargado que registre su meta",
         });
         return;
      }

      if (user.role !== "05" && goalPersonal > 0) {
         Swal.fire({
            icon: "warning",
            title: "Meta existente",
            text: "Este usuario ya cuenta con una meta registrada en el periodo seleccionado, favor de seleccionar otro periodo para registrar una nueva meta",
         });
         return;
      }

      const monthDesc =
         month === "01"
            ? "Enero"
            : month === "02"
            ? "Febrero"
            : month === "03"
            ? "Marzo"
            : month === "04"
            ? "Abril"
            : month === "05"
            ? "Mayo"
            : month === "06"
            ? "Junio"
            : month === "07"
            ? "Julio"
            : month === "08"
            ? "Agosto"
            : month === "09"
            ? "Setiembre"
            : month === "10"
            ? "Octubre"
            : month === "11"
            ? "Noviembre"
            : "Diciembre";

      let person = "";
      if (multi) {
         onHandleData({
            ...data,
            personalId: optionsSelect.value,
         });
         person = optionsSelect.label;
      } else {
         const select = document.getElementById("personalId");
         person = select.options[select.selectedIndex].text;
      }

      if (user.role === "05") {
         dispatch(
            crearCommissionAction(
               {
                  personalId,
                  amount: 0,
                  price: Number(price),
                  period: year + month,
               },
               person,
               year,
               monthDesc
            )
         );
      } else {
         dispatch(
            crearCommissionAction(
               {
                  personalId,
                  goal: Number(goal),
                  period: year + month,
               },
               person,
               year,
               monthDesc
            )
         );
      }
      
      e.target.reset();
      handleCloseModal();
   };

   useEffect(() => {
      if (goalPersonal !== null && goalPersonal > 0) {
         onHandleData({
            ...data,
            ['goal']: goalPersonal,
         });

         if (user.role !== "05") {
            Swal.fire({
               icon: "warning",
               title: "Meta existente",
               text: "Este usuario ya cuenta con una meta registrada en el periodo seleccionado, favor de seleccionar otro periodo para registrar una nueva meta",
            });
         }
      } else {
         onHandleData({
            ...data,
            ['goal']: 0,
         });

         if (year !== '' && month !== '' && goalPersonal !== -1 && user.role === "05") {
            Swal.fire({
               icon: "warning",
               title: "Sin meta",
               text: "No se cuenta con una meta para el periodo seleccionado, favor de pedirle a su encargado que registre su meta",
            });
         }
      }
   }, [goalPersonal]);

   /*useEffect(() => {
      const percent = (commission * 100) / goalPersonal * 1.0;
      let value = 0;

      if (percent < 70) {
         value = price * 0.001;
      } else if (percent >= 70 && percent < 80) {
         value = price * 0.002;
      } else if (percent >= 80 && percent < 90) {
         value = price * 0.0025;
      } else {
         value = price * 0.0035;
      }

      onHandleData({
         ...data,
         ['amount']: value,
      });
   }, [price]);*/

   return (
      <Fragment>
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                     <div className="form-group col-12 imputsCampos2 mt-0">
                        <label htmlFor="personalId" className="c-grisDark mb-0">
                           Personal:
                        </label>
                        {multi ? (
                           <Select
                              placeholder="Seleccionar"
                              options={optionsPersonal}
                              onChange={onChangeData}
                              className="imputsCampos3"
                              isDisabled={user.role === "05" ? true : false}
                              required
                           />
                        ) : (
                           <select
                              name="personalId"
                              id="personalId"
                              value={personalId}
                              onChange={onChangeData}
                              disabled={user.role === "05" ? true : false}
                              required
                           >
                              <option value="">Todos</option>
                              {personals &&
                                 personals.map((personal) => (
                                    <option
                                       key={personal.id}
                                       value={personal.id}
                                    >
                                       {personal.name} {personal.lastName}
                                    </option>
                                 ))}
                           </select>
                        )}
                     </div>
                     <div className="form-group col-6 imputsCampos2 mt-0">
                        <label htmlFor="year" className="c-grisDark mb-0">
                           Año:
                        </label>
                        <select
                           name="year"
                           className="w-100"
                           value={year}
                           onChange={onChangeData}
                           required
                        >
                           <option value="">Seleccionar Año</option>
                           {YEARS &&
                              YEARS.length > 0 &&
                              YEARS.map((year) => (
                                 <option key={year.value} value={year.value}>
                                    {year.description}
                                 </option>
                              ))}
                        </select>
                     </div>
                     <div className="form-group col-6 imputsCampos2 mt-0">
                        <label htmlFor="month" className="c-grisDark mb-0">
                           Mes:
                        </label>
                        <select
                           name="month"
                           className="w-100"
                           value={month}
                           onChange={onChangeData}
                           required
                        >
                           <option value="">Seleccionar Mes</option>
                           {MONTHS &&
                              MONTHS.length > 0 &&
                              MONTHS.map((month) => (
                                 <option key={month.value} value={month.value}>
                                    {month.description}
                                 </option>
                              ))}
                        </select>
                     </div>
                     <div className="form-group col-12 imputsCampos2 mt-0">
                        <label htmlFor="goal" className="c-grisDark mb-0">
                           Meta:
                        </label>
                        <input
                           type="text"
                           name="goal"
                           className="w-100"
                           style={user.role === "05" ? {backgroundColor: '#DDDDDD'} : {backgroundColor: '#FFFFFF'}}
                           value={goal}
                           onChange={onChangeData}
                           disabled={user.role === "05" ? true : false}
                           required
                        />
                     </div>
                     {user.role === "05" ? (
                        <Fragment>
                           <div className="form-group col-6 imputsCampos2 mt-0">
                        <label htmlFor="price" className="c-grisDark mb-0">
                           Valor de la venta:
                        </label>
                        <input
                           type="text"
                           name="price"
                           className="w-100"
                           placeholder="Ingrese el precio"
                           value={price}
                           onChange={onChangeData}
                           required
                        />
                     </div>
                     {/*<div className="form-group col-6 imputsCampos2 mt-0">
                        <label htmlFor="amount" className="c-grisDark mb-0">
                           Comisión:
                        </label>
                        <input
                           type="text"
                           name="amount"
                           className="w-100"
                           style={{backgroundColor: '#DDDDDD'}}
                           value={amount}
                           onChange={onChangeData}
                           disabled
                           required
                        />
                     </div>*/}
                        </Fragment>
                     ) : (
                        null
                     )}
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               <button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="submit"
               >
                  Guardar
               </button>
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default NuevoCommission;
