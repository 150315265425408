import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form } from "react-bootstrap";

import { crearStoreAction } from "../../actions/storeActions";
import { listarDistritosAction } from "../../actions/ubigeoActions";

const NuevoStore = ({ handleCloseModal }) => {
   const dispatch = useDispatch();

   const malls = useSelector(state => state.mall.malls);
   const managers = useSelector(state => state.personal.personalsRoles);
   //const personals = useSelector(state => state.personal.personalsActivos);
   const cities = useSelector(state => state.ubigeo.cities);
   const districts = useSelector(state => state.ubigeo.districts);

   const [errors, onHandleError] = useState([]);
   const [data, onHandleData] = useState({
      code: '',
      description: '',
      address: '',
      serie_number: '',
      email: '',
      rpm: '',
      phone: '',
      cityId: '',
      districtId: '',
      mallId: '',
      inChargeId: ''
   });

   const {
      code,
      description,
      address,
      serie_number,
      email,
      rpm,
      phone,
      cityId,
      districtId,
      mallId,
      inChargeId,
   } = data;

   const onChangeData = (e) => {
      onHandleData({
         ...data,
         [e.target.name]: e.target.value,
      });
   };

   const validarErrores = () => {
      let currentErrors = [];

      onHandleError([]);
      if (code === '') {
         currentErrors.push({field: 'code', error: 'El código no puede ser vacio'});
      }
      if (code.length <= 5) {
         currentErrors.push({field: 'code', error: 'El código debe tener una longitud mayor o igual a tres caracteres'});
      }
      if (description === '') {
         currentErrors.push({field: 'description', error: 'La descripción no puede ser vacio'});
      }
      if (description.length <= 5) {
         currentErrors.push({field: 'description', error: 'La descripción debe tener una longitud mayor o igual a tres caracteres'});
      }
      if (address === '') {
         currentErrors.push({field: 'address', error: 'La dirección no puede ser vacio'});
      }
      if (address.length <= 5) {
         currentErrors.push({field: 'address', error: 'La dirección debe tener una longitud mayor o igual a cinco caracteres'});
      }
      if (serie_number === '') {
         currentErrors.push({field: 'serie_number', error: 'El número de serie no puede ser vacio'});
      }
      if (serie_number.length <= 5) {
         currentErrors.push({field: 'serie_number', error: 'El número de serie debe tener una longitud mayor o igual a tres caracteres'});
      }

      onHandleError(currentErrors);
   }

   const onHandleSubmit = (e) => {
      e.preventDefault();

      const mall = document.getElementById("mallId");
      const city = document.getElementById("cityId");
      const district = document.getElementById("districtId");

      dispatch(crearStoreAction(data, mall.options[mall.selectedIndex].text, city.options[city.selectedIndex].text, district.options[district.selectedIndex].text));

      e.target.reset();
      handleCloseModal();
   };

   useEffect(() => {
      if (cityId !== '') {
         dispatch(listarDistritosAction(cityId));
      }
   }, [cityId]);

   return (
      <Fragment>
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                     <div className="form-group col-12 col-md-6">
                        <label htmlFor="code" className="c-grisDark mb-0">
                           Código: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <input
                           type="text"
                           name="code"
                           className="form-control"
                           placeholder="Ingrese el código"
                           value={code}
                           onChange={onChangeData}
                           minLength={3}
                           maxLength={15}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-6">
                     <label htmlFor="description" className="c-grisDark mb-0">
                           Descripción: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <input
                           type="text"
                           name="description"
                           className="form-control"
                           placeholder="Ingrese la dirección"
                           value={description}
                           onChange={onChangeData}
                           minLength={3}
                           maxLength={60}
                           required
                        />
                     </div>
                  </div>
                  <div className="row">
                  <div className="form-group col-12 col-md-12">
                     <label htmlFor="address" className="c-grisDark mb-0">
                           Dirección: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <input
                           type="text"
                           name="address"
                           className="form-control"
                           placeholder="Ingrese la dirección"
                           value={address}
                           onChange={onChangeData}
                           minLength={3}
                           maxLength={200}
                           required
                        />
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                     <label
                           htmlFor="mallId"
                           className="labelCliente mb-0 pt-0"
                        >
                           Mall: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <select
                           name="mallId"
                           id="mallId"
                           className="w-100"
                           value={mallId}
                           onChange={onChangeData}
                           required
                        >
                           <option value="">Seleccionar Mall</option>
                           {malls &&
                              malls.length &&
                              malls.map((mall) => (
                                 <option key={mall.id} value={mall.id}>
                                    {mall.description}
                                 </option>
                              ))}
                        </select>
                     </div>
                     <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                     <label
                           htmlFor="inChargeId"
                           className="labelCliente mb-0 pt-0"
                        >
                           Encargado:
                        </label>
                        <select
                           name="inChargeId"
                           className="w-100"
                           value={inChargeId}
                           onChange={onChangeData}
                        >
                           <option value="">Seleccionar Encargado</option>
                           {managers &&
                              managers.length &&
                              managers.map((manager) => (
                                 <option key={manager.id} value={manager.id}>
                                    {manager.name} {manager.lastName} 
                                 </option>
                              ))}
                        </select>
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group col-12 col-md-6">
                        <label htmlFor="serie_number" className="c-grisDark mb-0">
                           Nro. Serie: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <input
                           type="text"
                           name="serie_number"
                           className="form-control"
                           placeholder="Ingrese el número de serie"
                           value={serie_number}
                           onChange={onChangeData}
                           minLength={3}
                           maxLength={50}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-6">
                        <label
                           htmlFor="email"
                           className="c-grisDark mb-0"
                        >
                           Correo:
                        </label>
                        <input
                           type="text"
                           name="email"
                           className="form-control"
                           placeholder="Ingrese su correo"
                           value={email}
                           onChange={onChangeData}
                           minLength={10}
                           maxLength={150}
                        />
                     </div>
                  </div>

                  <div className="row">
                     <div className="form-group col-12 col-md-6">
                        <label
                           htmlFor="phone"
                           className="labelCliente mb-0 pt-0"
                        >
                           Teléfono:
                        </label>
                        <input
                           type="text"
                           name="phone"
                           className="form-control"
                           placeholder="Ingrese el teléfono"
                           value={phone}
                           onChange={onChangeData}
                           minLength={7}
                           maxLength={9}
                        />
                     </div>
                     <div className="form-group col-12 col-md-6">
                        <label
                           htmlFor="rpm"
                           className="c-grisDark mb-0"
                        >
                           RPM:
                        </label>
                        <input
                           type="text"
                           name="rpm"
                           className="form-control"
                           placeholder="Ingrese el RPM"
                           value={rpm}
                           onChange={onChangeData}
                           minLength={9}
                           maxLength={9}
                        />
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                        <label
                           htmlFor="cityId"
                           className="labelCliente mb-0 pt-0"
                        >
                           Ciudad: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <select
                           name="cityId"
                           id="cityId"
                           className="w-100"
                           value={cityId}
                           onChange={onChangeData}
                           required
                        >
                           <option value="">Seleccionar Ciudad</option>
                           {cities &&
                              cities.length &&
                              cities.map((city) => (
                                 <option key={city.id} value={city.id}>
                                    {city.description}
                                 </option>
                              ))}
                        </select>
                     </div>
                     <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                        <label
                           htmlFor="districtId"
                           className="labelCliente mb-0 pt-0"
                        >
                           Distrito: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <select
                           name="districtId"
                           id="districtId"
                           className="w-100"
                           value={districtId}
                           onChange={onChangeData}
                           required
                        >
                           <option value="">Seleccionar Distrito</option>
                           {districts &&
                              districts.length &&
                              districts.map((district) => (
                                 <option key={district.id} value={district.id}>
                                    {district.description}
                                 </option>
                              ))}
                        </select>
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group imputsCampos2 col-12 col-md-12 mt-0">
                                 <ul>
                                    {errors.map(error => (
                                       <li>{error.error}</li>
                                    ))}
                                 </ul>
                     </div>
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               <button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="submit"
               >
                  Guardar
               </button>
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default NuevoStore;
