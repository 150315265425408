import {
   POLICY_AGREGAR_COMENZAR,
   POLICY_AGREGAR_EXITO, 
   POLICY_AGREGAR_ERROR, 
   POLICY_DESCARGA_COMENZAR, 
   POLICY_DESCARGA_EXITO, 
   POLICY_DESCARGA_ERROR,
   POLICY_OBTENER_COMENZAR,
   POLICY_OBTENER_EXITO,
   POLICY_OBTENER_ERROR,
   POLICY_EDITAR_COMENZAR,
   POLICY_EDITAR_EXITO,
   POLICY_EDITAR_ERROR,
   POLICY_ESTADO_COMENZAR,
   POLICY_ESTADO_EXITO,
   POLICY_ESTADO_ERROR,
} from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

// ---------- CREAR NORMA ----------
export function crearPolicyAction(data) {
   return async (dispatch) => {
      dispatch(agregarPolicy());

      try {
         const response = await clienteAxios.post("/policies", data);
         dispatch(agregarPolicyExito(response.data));

         if (response.status === 201) {
            Swal.fire("Correcto", "La norma se registró correctamente", "success");
         } else {
            Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
         }
      } catch (error) {
         const code = error.response.status;
         let mensaje = "";
         if (code === 409) {
            mensaje = error.response.data.message;
         } else {
            mensaje = "Hubo un error, intenta de nuevo";
         }
         dispatch(agregarPolicyError(true));

         Swal.fire({icon: "error", title: "Hubo un error", text: mensaje});
      }
   }
}

const agregarPolicy = () => ({
   type: POLICY_AGREGAR_COMENZAR
})

const agregarPolicyExito = data => ({
   type: POLICY_AGREGAR_EXITO,
   payload: data
})

const agregarPolicyError = estado => ({
   type: POLICY_AGREGAR_ERROR,
   payload: estado
})

// ---------- BUSCAR NORMAS ----------
export function buscarPolicyAction(data, offset) {
   return async (dispatch) => {
         dispatch(descargarPolicy());

         let query = `limit=${data.limit}&offset=${offset}&`;
         if (data.description && data.description !== '') {
            query = query + `description=${data.description}&`;
         }
         if (data.state && data.state !== '') {
            query = query + `state=${data.state}&`;
         }

         const url = query === '' ? '/policies' : '/policies?' + query;

         try {
            const resultado = await clienteAxios.get(url);
            resultado.data.limit = data.limit;

            dispatch(descargarPolicyExito(resultado.data))
         } catch (error) {
            dispatch(descargarPolicyError());
         }
   }
}

const descargarPolicy = () => ({
   type: POLICY_DESCARGA_COMENZAR,
   payload: true
})

const descargarPolicyExito = data => ({
   type: POLICY_DESCARGA_EXITO,
   payload: data
})

const descargarPolicyError = () => ({
   type: POLICY_DESCARGA_ERROR,
   payload: true
})

// ---------- OBTENER NORMA ----------
export function obtenerPolicyAction(id) {
   return async (dispatch) => {
      if (id) {
         dispatch(descargarObtenerPolicy());

         try {
            const resultado = await clienteAxios.get(`/policies/${id}`);

            dispatch(descargarObtenerPolicyExito(resultado.data))
         } catch (error) {
            dispatch(descargarObtenerPolicyError());
         }
      }
   }
}

const descargarObtenerPolicy = () => ({
   type: POLICY_OBTENER_COMENZAR,
   payload: true
})

const descargarObtenerPolicyExito = data => ({
   type: POLICY_OBTENER_EXITO,
   payload: data
})

const descargarObtenerPolicyError = () => ({
   type: POLICY_OBTENER_ERROR,
   payload: true
})

// ---------- ACTUALIZAR NORMA ----------
export function actualizarPolicyAction(id, data, formData) {
   return async (dispatch) => {

      dispatch(actualizarPolicy());

      try {
         const response = await clienteAxios.patch(`/policies/${id}`, formData);

         data.id = id;
         data.url = response.data.url;

         dispatch(actualizarPolicyExito(data));
         Swal.fire("Correcto", "El producto se actualizó correctamente", "success");
      } catch (error) {
         dispatch(actualizarPolicyError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const actualizarPolicy = () => ({
   type: POLICY_EDITAR_COMENZAR
})

const actualizarPolicyExito = data => ({
   type: POLICY_EDITAR_EXITO,
   payload: data
})

const actualizarPolicyError = () => ({
   type: POLICY_EDITAR_ERROR,
   payload: true
})

// ---------- CAMBIAR ESTADO ----------
export function cambiarEstadoPolicyAction(id) {
   return async (dispatch) => {

      dispatch(cambiarEstadoPolicy());

      try {
         await clienteAxios.patch(`/policies/state/${id}`);

         dispatch(cambiarEstadoPolicyExito(id));
      } catch (error) {
         dispatch(cambiarEstadoPolicyError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const cambiarEstadoPolicy = () => ({
   type: POLICY_ESTADO_COMENZAR
})

const cambiarEstadoPolicyExito = id => ({
   type: POLICY_ESTADO_EXITO,
   payload: id
})

const cambiarEstadoPolicyError = () => ({
   type: POLICY_ESTADO_ERROR,
   payload: true
})
