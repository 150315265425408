import React, { useState } from "react";

export default function ViewAnswer({ number, answer, group, marked, type, descriptionQuestion, onHandleAnswerChange }) {
   const isCorrect = marked && marked.length > 0 && (answer.id === marked[0].answerId && answer.isCorrect) ? true : false;

   const [description, setDescription] = useState('');

   const fullText = descriptionQuestion + '-' + answer.id;

   return (
      <div>
         {type === '03' ? (
            <input id={group} name={group} alt={fullText} type='text' className="form-control" value={description} onChange={onHandleAnswerChange(number - 1, answer.checklistQuestionId, answer, setDescription)}/>
         ) : (
            <label>
            {" "}
            <input
               id={group}
               name={group}
               type={type === '01' ? 'radio' : 'checkbox'}
               value={answer.isCorrect}
               onChange={onHandleAnswerChange(number - 1, answer.checklistQuestionId, answer)}
               checked={marked ? answer.isCorrect : null}
               disabled={marked ? true : false}
            />
            {" "}
            {marked && marked.length > 0 ? (
               marked[0].isCorrect ? (
                  isCorrect ? (
                     <span className="text-success">{answer.description}</span>
                  ) : (
                     <span>{answer.description}</span>
                  )
               ) : (
                     answer.id === marked[0].answerId ? (
                        <span className="text-danger">{answer.description}</span>
                     ) : (
                        <span>{answer.description}</span>
                     )
               )
            ) : <span>{answer.description}</span>}
         </label>
         )}
         
      </div>
   );
}
