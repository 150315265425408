import React, { useState, Fragment } from "react";
import { Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { cambiarPrecioCommissionAction } from "../../actions/commissionActions";

import "react-datepicker/dist/react-datepicker.css";

const EditarPrice = ({ currentCommission, handleCloseModal }) => {
   const dispatch = useDispatch();

   const [price, onHandlePrice] = useState(currentCommission.price);

   const onChangeData = (e) => {
      onHandlePrice(e.target.value);
   };

   const onHandleSubmit = (e) => {
      e.preventDefault();

     dispatch(cambiarPrecioCommissionAction(currentCommission.id, currentCommission.price, {price: price}));

      e.target.reset();
      handleCloseModal();
   };

   return (
      <Fragment>
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                     <div className="form-group col-12 col-md-6">
                        <label htmlFor="price" className="c-grisDark mb-0">
                           Precio:
                        </label>
                        <input
                           type="text"
                           name="price"
                           className="form-control"
                           placeholder="Ingrese el código"
                           value={price}
                           onChange={onChangeData}
                           maxLength={15}
                           required
                        />
                     </div>
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               <button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="submit"
               >
                  Actualizar
               </button>
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default EditarPrice;
