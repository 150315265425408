import { combineReducers } from 'redux';
import { CERRAR_SESION } from '../types';

import authReducer from './authReducer';
import personalReducer from './personalReducer';
import storeReducer from './storeReducer';
import campaingReducer from './campaingReducer';
import productReducer from './productReducer';
import mallReducer from './mallReducer';
import ubigeoReducer from './ubigeoReducer';
import commissionReducer from './commissionReducer';
import scheduleReducer from './scheduleReducer';
import messageReducer from './messageReducer';
import examReducer from './examReducer';
import policyReducer from './policyReducer';
import profileReducer from './profileReducer';
import ticketReducer from './ticketReducer';
import memoReducer from './memoReducer';
import checklistReducer from './checklistReducer';

const appReducer = combineReducers({
   auth: authReducer,
   ubigeo: ubigeoReducer,
   campaing: campaingReducer,
   checklist: checklistReducer,
   commission: commissionReducer,
   mall: mallReducer,
   message: messageReducer,
   memo: memoReducer,
   schedule: scheduleReducer,
   store: storeReducer,
   exam: examReducer,
   personal: personalReducer,
   policy: policyReducer,
   product: productReducer,
   profile: profileReducer,
   ticket: ticketReducer
});

const rootReducer = (state, action) => {
   if (action.type === CERRAR_SESION) {
      state = undefined
    }

   return appReducer(state, action)
 }

 export default rootReducer;