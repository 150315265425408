import React from "react";
import { useSelector } from "react-redux";
import Switch from "react-switch";

import eyesIcon from "../../assets/images/eye.svg";
import taskIcon from "../../assets/images/task.svg";

const ChecklistItem = ({ checklist, onHandleChange, handleOpenModal }) => {
   const user = useSelector((state) => state.auth.user);
   const canEdit = user.role === '01' || user.role === '02' || user.role === '03' ? true : false;

   return (
      <tr>
         <td>{checklist.description}</td>
         <td>{checklist.createdAt}</td>
         {canEdit ? (
            <>
            <td>
            <Switch
               onChange={() => onHandleChange(checklist.id)}
               checked={checklist.state}
               className="react-switch"
            />
         </td>
         <td>
            <button type="button" onClick={() => handleOpenModal(checklist, '1')}>
               <img src={eyesIcon} className="mr-2 iconTabla" alt="Ver" />
            </button>
         </td>
         </>
         ) : (null)}
         <td>
         <button type="button" onClick={() => handleOpenModal(checklist, '2')}>
               <img src={taskIcon} className="mr-2 iconTabla" alt="Comenzar evaluación" />
            </button>
         </td>
      </tr>
   );
};

export default ChecklistItem;
