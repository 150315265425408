import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Busqueda from "../components/reports/memo/Buqueda";
import ListadoMemo from "../components/reports/memo/ListadoMemo";
import TituloTabla from "../components/reports/memo/TituloTabla";
import Loading from "../components/layout/Loading";
import { obtenerYearAction } from "../actions/memoActions";

const ReportMemo = () => {
   const dispatch = useDispatch();

   const loading = useSelector((state) => state.memo.loading);

   useEffect(() => {
      dispatch(obtenerYearAction());
   }, []);

   return (
      <Fragment>
         <Header title={"REPORTE DE MEMORANDUMS"}/>
         <Loading loading={loading} />
         <Busqueda />
         <TituloTabla />
         <ListadoMemo />
         <Footer/>
      </Fragment>
   );
};

export default ReportMemo;
