import {
   MALL_AGREGAR_COMENZAR,
   MALL_AGREGAR_EXITO,
   MALL_AGREGAR_ERROR,
   MALL_DESCARGA_COMENZAR,
   MALL_DESCARGA_EXITO,
   MALL_DESCARGA_ERROR,
   MALL_OBTENER_COMENZAR,
   MALL_OBTENER_EXITO,
   MALL_OBTENER_ERROR,
   MALL_EDITAR_COMENZAR,
   MALL_EDITAR_EXITO,
   MALL_EDITAR_ERROR,
   MALL_ESTADO_COMENZAR,
   MALL_ESTADO_EXITO,
   MALL_ESTADO_ERROR,
} from "../types";
import clienteAxios from "../config/axios";
import Swal from "sweetalert2";

// ---------- CREAR MALL ----------
export function crearMallAction(data) {
   return async (dispatch) => {
      dispatch(agregarMall());

      try {
         const response = await clienteAxios.post("/malls", data);
         dispatch(agregarMallExito(response.data));

         if (response.status === 201) {
            Swal.fire("Correcto", "El mall se registró correctamente", "success");
         } else {
            Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
         }
      } catch (error) {
         const code = error.response.status;
         let mensaje = "";
         if (code === 409) {
            mensaje = error.response.data.message;
         } else {
            mensaje = "Hubo un error, intenta de nuevo";
         }
         dispatch(agregarMallError(true));

         Swal.fire({icon: "error", title: "Hubo un error", text: mensaje});
      }
   }
}

const agregarMall = () => ({
   type: MALL_AGREGAR_COMENZAR
})

const agregarMallExito = data => ({
   type: MALL_AGREGAR_EXITO,
   payload: data
})

const agregarMallError = estado => ({
   type: MALL_AGREGAR_ERROR,
   payload: estado
})

// ---------- BUSCAR MALLS ----------
export function buscarMallAction(data) {
   return async (dispatch) => {
      if (data) {
         dispatch(descargarMall());

         let query = '';
         if (data.code && data.code !== '') {
            query = query + `code=${data.code}&`;
         }
         if (data.storeId && data.storeId !== '') {
            query = query + `storeId=${data.storeId}&`;
         }
         if (data.mallId && data.mallId !== '') {
            query = query + `mallId=${data.mallId}&`;
         }
         if (data.inChargeId && data.inChargeId !== '') {
            query = query + `inChargeId=${data.inChargeId}&`;
         }
         if (data.state && data.state !== '') {
            query = query + `state=${data.state}&`;
         }

         const url = query === '' ? '/malls' : '/malls?' + query;

         try {
            const resultado = await clienteAxios.get(url);

            dispatch(descargarMallExito(resultado.data))
         } catch (error) {
            dispatch(descargarMallError());
         }
      }
   }
}

// ---------- LISTAR MALLS ACTIVAS ----------
export function obtenerMallActivosAction() {
   return async (dispatch) => {
      dispatch(descargarMall());

      try {
         const resultado = await clienteAxios.get('/malls/list');

         dispatch(descargarMallExito(resultado.data))
      } catch (error) {
         dispatch(descargarMallError());
      }
   }
}

const descargarMall = () => ({
   type: MALL_DESCARGA_COMENZAR,
   payload: true
})

const descargarMallExito = data => ({
   type: MALL_DESCARGA_EXITO,
   payload: data
})

const descargarMallError = () => ({
   type: MALL_DESCARGA_ERROR,
   payload: true
})

// ---------- OBTENER MALL ----------
export function obtenerMallAction(id) {
   return async (dispatch) => {
      if (id) {
         dispatch(descargarObtenerMall());

         try {
            const resultado = await clienteAxios.get(`/malls/${id}`);

            dispatch(descargarObtenerMallExito(resultado.data))
         } catch (error) {
            dispatch(descargarObtenerMallError());
         }
      }
   }
}

const descargarObtenerMall = () => ({
   type: MALL_OBTENER_COMENZAR,
   payload: true
})

const descargarObtenerMallExito = data => ({
   type: MALL_OBTENER_EXITO,
   payload: data
})

const descargarObtenerMallError = () => ({
   type: MALL_OBTENER_ERROR,
   payload: true
})

// ---------- ACTUALIZAR MALL ----------
export function actualizarMallAction(id, data, other) {
   return async (dispatch) => {

      dispatch(actualizarStore());

      try {
         await clienteAxios.patch(`/malls/${id}`, data);

         //data.id = id;
         //data.work.address = other.address;
         dispatch(actualizarStoreExito(data));
         Swal.fire("Correcto", "El mall se actualizó correctamente", "success");
      } catch (error) {
         dispatch(actualizarStoreError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const actualizarStore = () => ({
   type: MALL_EDITAR_COMENZAR
})

const actualizarStoreExito = data => ({
   type: MALL_EDITAR_EXITO,
   payload: data
})

const actualizarStoreError = () => ({
   type: MALL_EDITAR_ERROR,
   payload: true
})

// ---------- CAMBIAR ESTADO ----------
export function cambiarEstadoMallAction(id) {
   return async (dispatch) => {

      dispatch(cambiarEstadoMall());

      try {
         await clienteAxios.patch(`/malls/state/${id}`);

         dispatch(cambiarEstadoMallExito(id));
         //Swal.fire("Correcto", "Se cambio el estado correctamente", "success");
      } catch (error) {
         dispatch(cambiarEstadoMallError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const cambiarEstadoMall = () => ({
   type: MALL_ESTADO_COMENZAR
})

const cambiarEstadoMallExito = id => ({
   type: MALL_ESTADO_EXITO,
   payload: id
})

const cambiarEstadoMallError = () => ({
   type: MALL_ESTADO_ERROR,
   payload: true
})
