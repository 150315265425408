import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

import { reporteChecklistAction } from "../../../actions/checklistActions";

import "react-datepicker/dist/react-datepicker.css";

registerLocale("es", es);

const Busqueda = ({ data, setData, startDate, endDate, setStartDate, setEndDate }) => {
   const dispatch = useDispatch();

   const stores = useSelector((state) => state.store.storesActivos);
   const personals = useSelector((state) => state.personal.personalsActivos);

   const { personalId, storeId, offset } = data;

   const [optionsPersonSelect, setOptionsPersonSelect] = useState({});
   let optionsPersonal = [];
   if (personals) {
      optionsPersonal.push({
         value: '',
         label: 'Todos'
      });
         personals.map(personal => optionsPersonal.push({
            value: personal.id,
            label: personal.name + ' ' + personal.lastName
         }));
   }

   const [optionsStoreSelect, setOptionsStoreSelect] = useState({});
   let optionsStore = [];
   if (stores) {
      optionsStore.push({
         value: '',
         label: 'Todos'
      });
         stores.map(store => optionsStore.push({
            value: store.id,
            label: store.address
         }));
   }

   const onChangePersonBuscar = (e) => {
      setOptionsPersonSelect(e);
      setData({
         ...data,
         personalId: e.value,
      });
   };

   const onChangeStoreBuscar = (e) => {
      setOptionsStoreSelect(e);
      setData({
         ...data,
         storeId: e.value,
      });
   };

   const onHandleBuscarSubmit = (e) => {
      e.preventDefault();

      //const offset = 0;

      const dataObject = {
         personalId: data.personalId,
         storeId: data.storeId,
         startDate,
         endDate,
         type: '1',
         limit: data.limit
      };

      dispatch(reporteChecklistAction(dataObject, offset));
   };

   return (
      <Fragment>
          <div className="row pl-3 pr-3 pt-0">
            <div className="container">
               <form onSubmit={onHandleBuscarSubmit}>
                  <div className="btnsOptions ml-lg-3 mt-md-3 mt-xl-0  align-items-center">
                     <div className="row imputsCampos2">
                        <div id="busquedaMessage" className="col-12 col-lg-10 offset-lg-2">
                           <div className="row">
                              <h2 className="subTituloInterno pt-4 pb-2 pt-lg-5 pb-lg-5">
                                 Seleccionar filtro:
                              </h2>
                           </div>
                           <div className="row">
                              <label htmlFor="personalId" className="labelFecha-1">
                                 Personal:
                              </label>
                              <Select
                                    placeholder="Todos"
                                    options={optionsPersonal}
                                    onChange={onChangePersonBuscar}
                                    className="imputsCampos3"
                                 />

                              <label htmlFor="storeId" className="labelCliente">
                                 Tienda:
                              </label>
                              <Select
                                    placeholder="Todos"
                                    options={optionsStore}
                                    onChange={onChangeStoreBuscar}
                                    className="imputsCampos3"
                                 />
                           </div>
                           <div className="row mt-lg-3">
                              <label
                                 htmlFor="startDate"
                                 className="labelFecha-1"
                              >
                                 Desde:
                              </label>
                              <DatePicker
                                 name="startDate"
                                 dateFormat="dd/MM/yyyy"
                                 locale="es"
                                 selected={startDate}
                                 isClearable
                                 placeholderText="DD/MM/AAAA"
                                 maxDate={new Date()}
                                 value={startDate}
                                 onChange={(date) => setStartDate(date)}
                                 autoComplete="off"
                              />

                              <label htmlFor="endDate" className="labelFecha-1">
                                 Hasta:
                              </label>
                              <DatePicker
                                 name="endDate"
                                 dateFormat="dd/MM/yyyy"
                                 locale="es"
                                 selected={endDate}
                                 isClearable
                                 placeholderText="DD/MM/AAAA"
                                 maxDate={new Date()}
                                 value={endDate}
                                 onChange={(date) => setEndDate(date)}
                                 autoComplete="off"
                              />
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-12 col-lg-2 offset-lg-5 p-0 p-lg-3">
                           <input
                              type="submit"
                              className="btn-grisDark text-white btn-Solidez mt-4 w-100"
                              value="Buscar"
                           />
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </Fragment>
   );
};

export default Busqueda;
