import {
   CHECKLIST_AGREGAR_COMENZAR,
   CHECKLIST_AGREGAR_EXITO, 
   CHECKLIST_AGREGAR_ERROR, 
   CHECKLIST_DESCARGA_COMENZAR, 
   CHECKLIST_DESCARGA_EXITO, 
   CHECKLIST_DESCARGA_ERROR, 
   CHECKLIST_OBTENER_COMENZAR,
   CHECKLIST_OBTENER_EXITO,
   CHECKLIST_OBTENER_ERROR,
   CHECKLIST_ESTADO_COMENZAR,
   CHECKLIST_ESTADO_EXITO,
   CHECKLIST_ESTADO_ERROR,
   CHECKLIST_PREGUNTA_DESCARGA_COMENZAR,
   CHECKLIST_PREGUNTA_DESCARGA_EXITO,
   CHECKLIST_PREGUNTA_DESCARGA_ERROR,
   CHECKLIST_RESPONDER_COMENZAR,
   CHECKLIST_RESPONDER_EXITO,
   CHECKLIST_RESPONDER_ERROR,
   CHECKLIST_REPORTE_COMENZAR, 
   CHECKLIST_REPORTE_FULL_COMENZAR, 
   CHECKLIST_REPORTE_EXITO, 
   CHECKLIST_REPORTE_FULL_EXITO, 
   CHECKLIST_REPORTE_ERROR, 
} from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

// ---------- CREAR CHECKLIST ----------
export function crearChecklistAction(data) {
   return async (dispatch) => {
      dispatch(agregarChecklist());

      try {
         const response = await clienteAxios.post("/checklists", data);
         if (data.id) {
            response.data.id_anterior = data.id;
         }
         dispatch(agregarChecklistExito(response.data));

         if (response.status === 201) {
            Swal.fire("Correcto", "El checklist se registró exitosamente", "success");
         } else {
            Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
         }
      } catch (error) {
         const code = error.response.status;
         let mensaje = "";
         if (code === 409) {
            mensaje = error.response.data.message;
         } else {
            mensaje = "Hubo un error, intenta de nuevo";
         }
         dispatch(agregarChecklistError(true));

         Swal.fire({icon: "error", title: "Hubo un error", text: mensaje});
      }
   }
}

const agregarChecklist = () => ({
   type: CHECKLIST_AGREGAR_COMENZAR
})

const agregarChecklistExito = data => ({
   type: CHECKLIST_AGREGAR_EXITO,
   payload: data
})

const agregarChecklistError = estado => ({
   type: CHECKLIST_AGREGAR_ERROR,
   payload: estado
})

// ---------- BUSCAR CHECKLIST ----------
export function buscarChecklistAction(data) {
   return async (dispatch) => {
         dispatch(descargarChecklist());

         let query = '';
         if (data.description && data.description !== '') {
            query = query + `description=${data.description}&`;
         }
         if (data.startDate && data.startDate !== '') {
            query = query + `startDate=${data.startDate}&`;
         }
         if (data.endDate && data.endDate !== '') {
            query = query + `endDate=${data.endDate}&`;
         }
         if (data.state && data.state !== '') {
            query = query + `state=${data.state}&`;
         }

         const url = query === '' ? '/checklists' : '/checklists?' + query;

         try {
            const resultado = await clienteAxios.get(url);

            dispatch(descargarChecklistExito(resultado.data))
         } catch (error) {
            dispatch(descargarChecklistError());
         }
   }
}

const descargarChecklist = () => ({
   type: CHECKLIST_DESCARGA_COMENZAR,
   payload: true
})

const descargarChecklistExito = data => ({
   type: CHECKLIST_DESCARGA_EXITO,
   payload: data
})

const descargarChecklistError = () => ({
   type: CHECKLIST_DESCARGA_ERROR,
   payload: true
})

// ---------- OBTENER EXAMEN ----------
export function obtenerChecklistAction(id) {
   return async (dispatch) => {
      if (id) {
         dispatch(descargarObtenerChecklist());

         try {
            const resultado = await clienteAxios.get(`/messages/${id}`);

            dispatch(descargarObtenerChecklistExito(resultado.data))
         } catch (error) {
            dispatch(descargarObtenerChecklistError());
         }
      }
   }
}

const descargarObtenerChecklist = () => ({
   type: CHECKLIST_OBTENER_COMENZAR,
   payload: true
})

const descargarObtenerChecklistExito = data => ({
   type: CHECKLIST_OBTENER_EXITO,
   payload: data
})

const descargarObtenerChecklistError = () => ({
   type: CHECKLIST_OBTENER_ERROR,
   payload: true
})

// ---------- CAMBIAR ESTADO ----------
export function cambiarEstadoChecklistAction(id) {
   return async (dispatch) => {

      dispatch(cambiarEstadoChecklist());

      try {
         await clienteAxios.patch(`/checklists/state/${id}`);

         dispatch(cambiarEstadoChecklistExito(id));
         //Swal.fire("Correcto", "Se cambio el estado correctamente", "success");
      } catch (error) {
         dispatch(cambiarEstadoChecklistError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const cambiarEstadoChecklist = () => ({
   type: CHECKLIST_ESTADO_COMENZAR
})

const cambiarEstadoChecklistExito = id => ({
   type: CHECKLIST_ESTADO_EXITO,
   payload: id
})

const cambiarEstadoChecklistError = () => ({
   type: CHECKLIST_ESTADO_ERROR,
   payload: true
})


// ---------- OBTENER PREGUNTAS ----------
export function obtenerChecklistQuestionsAction(checklistId) {
   return async (dispatch) => {
      if (checklistId) {
         dispatch(descargarObtenerChecklistQuestions());

         try {
            const resultado = await clienteAxios.get(`/checklists/questions?id=${checklistId}`);

            dispatch(descargarObtenerChecklistQuestionsExito(resultado.data))
         } catch (error) {
            dispatch(descargarObtenerChecklistQuestionsError());
         }
      }
   }
}

const descargarObtenerChecklistQuestions = () => ({
   type: CHECKLIST_PREGUNTA_DESCARGA_COMENZAR,
   payload: true
})

const descargarObtenerChecklistQuestionsExito = data => ({
   type: CHECKLIST_PREGUNTA_DESCARGA_EXITO,
   payload: data
})

const descargarObtenerChecklistQuestionsError = () => ({
   type: CHECKLIST_PREGUNTA_DESCARGA_ERROR,
   payload: true
})

// ---------- RESPONDER EXAMEN ----------
export function responderChecklistAction(data) {
   return async (dispatch) => {
      if (data) {
         dispatch(responderChecklisten());

         try {
            const response = await clienteAxios.post(`/checklists/answered`, data);

            if (response.status === 200) {
               Swal.fire("Correcto", "Terminó de responder el checklist", "success");
            } else {
               Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
            }

            dispatch(responderChecklistenExito(response.data))
         } catch (error) {
            dispatch(responderChecklistenError());
         }
      }
   }
}

const responderChecklisten = () => ({
   type: CHECKLIST_RESPONDER_COMENZAR,
   payload: true
})

const responderChecklistenExito = data => ({
   type: CHECKLIST_RESPONDER_EXITO,
   payload: data
})

const responderChecklistenError = () => ({
   type: CHECKLIST_RESPONDER_ERROR,
   payload: true
})

// ---------- REPORTE CHECKLIST ----------
export function reporteChecklistAction(data, offset) {
   return async (dispatch) => {
         dispatch(reporteChecklist());

         let query = `limit=${data.limit}&offset=${offset}&`;
         if (data.personalId && data.personalId !== '') {
            query = query + `personalId=${data.personalId}&`;
         }
         if (data.storeId && data.storeId !== '') {
            query = query + `storeId=${data.storeId}&`;
         }
         if (data.startDate && data.startDate !== '') {
            query = query + `startDate=${data.startDate}&`;
         }
         if (data.endDate && data.endDate !== '') {
            query = query + `endDate=${data.endDate}&`;
         }
         if (data.type && data.type !== '') {
            query = query + `type=${data.type}&`;
         }

         const url = query === '' ? '/checklists/report' : '/checklists/report?' + query;

         try {
            const resultado = await clienteAxios.get(url);

            resultado.data.limit = data.limit;

            dispatch(reporteChecklistExito(resultado.data))
         } catch (error) {
            dispatch(reporteChecklistError());
         }
   }
}

export function reporteFullChecklistAction(data) {
   return async (dispatch) => {
         dispatch(reporteFullChecklist());

         let query = '';
         if (data.checklistId && data.checklistId !== '') {
            query = query + `checklistId=${data.checklistId}&`;
         }
         if (data.personalId && data.personalId !== '') {
            query = query + `personalId=${data.personalId}&`;
         }
         if (data.storeId && data.storeId !== '') {
            query = query + `storeId=${data.storeId}&`;
         }
         if (data.fecha && data.fecha !== '') {
            query = query + `fecha=${data.fecha}&`;
         }
         if (data.startDate && data.startDate !== '') {
            query = query + `startDate=${data.startDate}&`;
         }
         if (data.endDate && data.endDate !== '') {
            query = query + `endDate=${data.endDate}&`;
         }

         const url = query === '' ? '/checklists/report' : '/checklists/report?' + query;

         try {
            const resultado = await clienteAxios.get(url);

            dispatch(reporteFullChecklistExito(resultado.data))
         } catch (error) {
            dispatch(reporteChecklistError());
         }
   }
}

const reporteChecklist = () => ({
   type: CHECKLIST_REPORTE_COMENZAR,
   payload: true
})

const reporteFullChecklist = () => ({
   type: CHECKLIST_REPORTE_FULL_COMENZAR,
   payload: true
})

const reporteChecklistExito = data => ({
   type: CHECKLIST_REPORTE_EXITO,
   payload: data
})

const reporteFullChecklistExito = data => ({
   type: CHECKLIST_REPORTE_FULL_EXITO,
   payload: data
})

const reporteChecklistError = () => ({
   type: CHECKLIST_REPORTE_ERROR,
   payload: true
})