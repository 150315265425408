import React, { Fragment, useState, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form } from "react-bootstrap";
import Select from 'react-select'

import { crearMessageAction } from "../../actions/messageActions";

const NuevoMessage = ({ handleCloseModal }) => {
   const dispatch = useDispatch();

   const personals = useSelector((state) => state.personal.personalsActivos);
   const user = useSelector((state) => state.auth.user);

   let optionsPersonal = [];
   if (personals) {
      personals.map(personal => optionsPersonal.push({
         value: personal.id,
         label: personal.name + ' ' + personal.lastName
      }));
   }

   const [optionsSelect, setOptionsSelect] = useState({});
   const [data, onHandleData] = useState({
      subject: "",
      message: ""
   });

   const {
      subject,
      message,
   } = data;

   const onChangeData = (e) => {
      if (e.target && (e.target.name === 'subject' || e.target.name === 'message')) {
         onHandleData({
            ...data,
            [e.target.name]: e.target.value,
         });
      } else {
         setOptionsSelect(e);
      }
   };

   const onHandleSubmit = (e) => {
      e.preventDefault();

      let personalsSelected = [];
      optionsSelect.map(option => personalsSelected.push({personalId: option.value}));

      const messageObject = {
         subject: subject,
         message: message,
         sendedById: user.id,
         personals: personalsSelected
      };

      dispatch(crearMessageAction(messageObject));

      e.target.reset();
      handleCloseModal();
   };

   return (
      <Fragment>
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="code" className="c-grisDark mb-0">
                           Enviar a:
                        </label>
                        <Select
                           placeholder="Seleccionar"
                           options={optionsPersonal}
                           className="basic-multi-select"
                           onChange={onChangeData}
                           isMulti
                        />
                     </div>
                  </div>

                  <div className="row">
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="subject" className="c-grisDark mb-0">
                           Asunto:
                        </label>
                        <input
                           type="text"
                           name="subject"
                           className="form-control"
                           placeholder="Ingrese el asunto"
                           value={subject}
                           onChange={onChangeData}
                           required
                        />
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="message" className="c-grisDark mb-0">
                           Mensaje:
                        </label>
                        <textarea
                           name="message"
                           className="form-control"
                           placeholder="Ingrese el mensaje"
                           value={message}
                           onChange={onChangeData}
                           rows="4"
                           required
                        />
                     </div>
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               <button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="submit"
               >
                  Aceptar
               </button>
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default NuevoMessage;
