export const DNI = '01';
export const PASAPORTE = '02';
export const CARNET_EXTRANJERIA = '03';

export const ADMINISTRADOR_TIENDA = '01';
export const ADMINISTRADOR_VOLANTE = '02';
export const AUXILIAR_ALMACEN = '03';
export const SUB_ADMINISTRADOR = '04';
export const SUB_ADMINISTRADOR_VOLANTE = '05';
export const VENDEDOR = '06';
export const VENDEDOR_VOLANTE = '07';

export const MONTHS = [
   {description: 'Enero', value: '01'},
   {description: 'Febrero', value: '02'},
   {description: 'Marzo', value: '03'},
   {description: 'Abril', value: '04'},
   {description: 'Mayo', value: '05'},
   {description: 'Junio', value: '06'},
   {description: 'Julio', value: '07'},
   {description: 'Agosto', value: '08'},
   {description: 'Setiembre', value: '09'},
   {description: 'Octubre', value: '10'},
   {description: 'Noviembre', value: '11'},
   {description: 'Diciembre', value: '12'},
]

export const YEARS = [
   {description: (new Date()).getFullYear() + 2, value: (new Date()).getFullYear() + 2},
   {description: (new Date()).getFullYear() + 1, value: (new Date()).getFullYear() + 1},
   {description: (new Date()).getFullYear(), value: (new Date()).getFullYear()},
   {description: (new Date()).getFullYear() - 1, value: (new Date()).getFullYear() - 1},
   {description: (new Date()).getFullYear() - 2, value: (new Date()).getFullYear() - 2},
   {description: (new Date()).getFullYear() - 3, value: (new Date()).getFullYear() - 3},
   {description: (new Date()).getFullYear() - 4, value: (new Date()).getFullYear() - 4},
   {description: (new Date()).getFullYear() - 5, value: (new Date()).getFullYear() - 5},
   {description: (new Date()).getFullYear() - 6, value: (new Date()).getFullYear() - 6},
]