import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../store";

import Restablecer from "../components/auth/Restablecer";
import Home from "../components/home/Home";
import App from "../containers/App";
import Personal from "../containers/Personal";
import Store from "../containers/Store";
import Campaing from "../containers/Campaing";
import Commission from "../containers/Commission";
import Schedule from "../containers/Schedule";
import Message from "../containers/Message";
import Product from "../containers/Product";
import Exam from "../containers/Exam";
import Policy from "../containers/Policy";
import Profile from "../containers/Profile";
import Ticket from "../containers/Ticket";
import Memo from "../containers/Memo";
import Checklist from "../containers/Checklist";
import ReportExam from "../containers/ReportExam";
import ReportCampaing from "../containers/ReportCampaing";
import ReportProduct from "../containers/ReportProduct";
import ReportSchedule from "../containers/ReportSchedule";
import ReportTicket from "../containers/ReportTicket";
import ReportMemo from "../containers/ReportMemo";
import ReportChecklist from "../containers/ReportChecklist";
import ReportPersonal from "../containers/ReportPersonal";

const Router = () => {
   return (
      <BrowserRouter>
         <Provider store={store}>
            <Routes>
               <Route exact path="/" element={<App />} />
               <Route exact path="/restablecer" element={<Restablecer />} />
               <Route exact path="/home" element={<Home />} />
               <Route exact path="/personal" element={<Personal />} />
               <Route exact path="/store" element={<Store />} />
               <Route exact path="/campaing" element={<Campaing />} />
               <Route exact path="/commission" element={<Commission />} />
               <Route exact path="/schedule" element={<Schedule />} />
               <Route exact path="/message" element={<Message />} />
               <Route exact path="/product" element={<Product />} />
               <Route exact path="/exam" element={<Exam />} />
               <Route exact path="/policy" element={<Policy />} />
               <Route exact path="/profile" element={<Profile />} />
               <Route exact path="/ticket" element={<Ticket />} />
               <Route exact path="/memo" element={<Memo />} />
               <Route exact path="/checklist" element={<Checklist />} />
               <Route exact path="/report_exam" element={<ReportExam />} />
               <Route exact path="/report_campaing" element={<ReportCampaing />} />
               <Route exact path="/report_product" element={<ReportProduct />} />
               <Route exact path="/report_presence" element={<ReportSchedule />} />
               <Route exact path="/report_ticket" element={<ReportTicket />} />
               <Route exact path="/report_memo" element={<ReportMemo />} />
               <Route exact path="/report_checklist" element={<ReportChecklist />} />
               <Route exact path="/report_personal" element={<ReportPersonal />} />
            </Routes>
         </Provider>
      </BrowserRouter>
   );
};

export default Router;
