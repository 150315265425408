import React, { useState } from "react";
import { Modal, ModalTitle } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Pagination from '@mui/material/Pagination';

import { cambiarEstadoCommissionAction } from "../../actions/commissionActions";
import { buscarCommissionAction } from "../../actions/commissionActions";

import EditarCommission from "./EditarCommission";
import CalculoCommission from "./CalculoCommission";
import CommissionItem from "./CommissionItem";

const ListadoCommission = ({ data, setData }) => {
   const dispatch = useDispatch();

   const commissions = useSelector((state) => state.commission.commissions);
   const size = useSelector((state) => state.commission.size);

   const [showModal, setShowModal] = useState(false);
   const [showModal2, setShowModal2] = useState(false);
   const [currentCommission, setCurrentCommission] = useState({});

   const { limit, offset } = data;

   const handleCloseModal = () => {
      setShowModal(false);
   };

   const handleOpenModal = (commission) => {
      setShowModal(true);
      setCurrentCommission(commission);
   };

   const handleCloseModal2 = () => {
      setShowModal2(false);
   };

   const handleOpenModal2 = (commission) => {
      setShowModal2(true);
      setCurrentCommission(commission);
   };

   const onHandleChange = (id) => {
      dispatch(cambiarEstadoCommissionAction(id));
   };

   const onChangePage = (e, page) => {
      const offset = 0 + (page - 1) * limit;

      setData({
         ...data,
         'offset': offset
      });
  
      dispatch(buscarCommissionAction(data, offset));
    }

    const changeValuePage = (e) => {
      setData({
         ...data,
         [e.target.name]: e.target.value
      });

      data.limit = Number(e.target.value);
  
      dispatch(buscarCommissionAction(data, offset));
    }

   return (
      <div className="container-fluid p-0">
         <div className="cajaTable ">
            <div className="resultadoTable d-none">
               <p className="txtResultado text-center">
                  No hay resultado de búsqueda
               </p>
            </div>
            <div class="container-fluid imputsCamposMostrar">
               <div class="row mt-lg-3">
                  <label  htmlFor="limit" className="mr-3 mt-1">Mostrar resultados:</label>
                  <select name='limit' onChange={changeValuePage}>
                     <option value="10">10</option>
                     <option value="15">15</option>
                     <option value="25">25</option>
                     <option value="50">50</option>
                     <option value="100">100</option> 
                  </select> 
               </div>
         </div>
            <div className="cabeceraTable2 cuperoTable3 mt-3 mb-3">
               <table>
                  <thead>
                     <tr>
                        <th>Nombres y Apellidos</th>
                        <th>Periodo</th>
                        <th>Meta</th>
                        <th>Ventas</th>
                        <th>Comisión</th>
                        {/*<th>Estado</th>*/}
                        <th>Ver</th>
                        <th>Calcular</th>
                     </tr>
                  </thead>
                  <tbody>
                     {commissions &&
                        commissions.map((commission) => (
                           <CommissionItem key={commission.id} commission={commission} onHandleChange={onHandleChange} handleOpenModal={handleOpenModal} handleOpenModal2={handleOpenModal2}/>
                        ))}
                  </tbody>
               </table>
            </div>
            <Pagination count={size} onChange={onChangePage} />
         </div>

         <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
               <ModalTitle>
                  <h3 className="titulo text-center p-3">Editar comisión</h3>
               </ModalTitle>
            </Modal.Header>
            <EditarCommission
               currentCommission={currentCommission}
               handleCloseModal={handleCloseModal}
            />
         </Modal>

         <Modal show={showModal2} onHide={handleCloseModal2}>
            <Modal.Header closeButton>
               <ModalTitle>
                  <h3 className="titulo text-center p-3">Calcular comisión</h3>
               </ModalTitle>
            </Modal.Header>
            <CalculoCommission
               currentCommission={currentCommission}
               handleCloseModal={handleCloseModal2}
            />
         </Modal>
      </div>
   );
};

export default ListadoCommission;
