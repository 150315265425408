import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ProductItem from "./ProductItem";

const ListadoProduct = () => {
   const products = useSelector((state) => state.product.report);

   return (
      <div id="tablaReporteProducto" className="container-fluid p-0">
         <div className="col-12">
               <h2 className="titulo text-center">Reporte de Productos</h2>
            </div>
         <div className="cajaTable ">
            <div className="resultadoTable d-none">
               <p className="txtResultado text-center">
                  No hay resultado de búsqueda
               </p>
            </div>
            <div className="cabeceraTable2 cuperoTable3 mt-3 mb-100">
               <table>
                  <thead>
                     <tr>
                        <th>Producto</th>
                        <th>Tamaño</th>
                        <th>Precio</th>
                        <th>Temporada</th>
                        <th>Fecha Creación</th>
                     </tr>
                  </thead>
                  <tbody>
                     {products &&
                        products.map((product) => (
                           <ProductItem key={product.id} product={product} />
                        ))}
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   );
};

export default ListadoProduct;
