import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";

import { recuperarContraseniaAction } from "../../actions/authActions";

const Restablecer = () => {
   const dispatch = useDispatch();

   const [email, setEmail] = useState();

   const onSubmitEmail = (e) => {
      e.preventDefault();

      if (email.trim() === "" || email.trim() === "") {
         return;
      }

      dispatch(recuperarContraseniaAction({email: email}));
   };

   return (
      <div className="row">
         <div className="d-none d-lg-block col-md-6">
            <div className="row">
               <h1 className="titulo text-white titleFixed">
                  SOLIDEZ EMPRESARIAL
               </h1>
               <div className="image-update image-principal-2 w-100"></div>
            </div>
         </div>
         <div className="col-12 col-lg-6 paddingTop paddingLeft bg-grayLigth">
            <div className="container p-md-0">
               <h1 className="titulo d-block d-lg-none">SOLIDEZ EMPRESARIAL</h1>

               <h2 className="c-gris titulo paddingTop2 c-grisDark">
                  Restablecer contraseña
               </h2>
            </div>

            <Form onSubmit={onSubmitEmail} className="container form p-md-0">
               <div className="col-12 col-lg-8 mt-5 p-md-0">
                  <p className="parrafo mt-4">
                     Por favor introduzca su correo
                     electrónico.
                  </p>

                  <div className="form-group mt-5">
                     <label htmlFor="email" className="c-grisDark mb-0">
                        Correo electrónico
                     </label>
                     <input
                        name={email}
                        type="email"
                        className="form-control"
                        placeholder="Ingrese tu usuario o correo electrónico"
                        onChange={e => setEmail(e.target.value)}
                        required
                     />
                  </div>

                  <div className="form-group">
                     <div className="row">
                        <div className="col-12">
                           <input
                              type="submit"
                              className="btn-grisDark text-white btn-Solidez mt-4"
                              value="Solicitar contraseña"
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </Form>
            <p className="c-link powered">
               Powered by{" "}
               <a href="https://putuquia.com" target="_blank" rel="noreferrer">
                  Putuquia
               </a>
            </p>
         </div>
      </div>
   );
};

export default Restablecer;
