import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { buscarPolicyAction } from "../../actions/policyActions";

const Busqueda = ({ data, setData }) => {
   const dispatch = useDispatch();

   const user = useSelector((state) => state.auth.user);

   const { description, state, offset } = data;

   const onChangeBuscar = (e) => {
      setData({
         ...data,
         [e.target.name]: e.target.value,
      });
   };

   const onHandleBuscarSubmit = (e) => {
      e.preventDefault();

      dispatch(buscarPolicyAction(data, offset));
   };

   return (
      <Fragment>
         <div className="row pl-3 pr-3 pt-0">
            <div className="container">
               <form onSubmit={onHandleBuscarSubmit}>
                  <div className="btnsOptions ml-lg-3 mt-md-3 mt-xl-0  align-items-center">
                     <div className="row imputsCampos2">
                        <div className="col-12 col-lg-10 offset-lg-2">
                           <div className="row">
                              <h2 className="subTituloInterno pt-4 pb-2 pt-lg-5 pb-lg-5">
                                 Seleccionar filtro:
                              </h2>
                           </div>
                           <div className="row">
                              <label htmlFor="description" className="labelFecha-1">
                                 Norma:
                              </label>
                              <input
                                 name="description"
                                 type="text"
                                 placeholder="Ingresar Nombres"
                                 value={description}
                                 onChange={onChangeBuscar}
                              />

                              <label htmlFor="state" className="labelCliente">
                                 Estado:
                              </label>
                              <select
                                 name="state"
                                 value={state}
                                 onChange={onChangeBuscar}
                                 disabled={user.role === '01' || user.role === '03' ? false : true}
                              >
                                 <option value="">Seleccionar Estado</option>
                                 <option value="1">Activo</option>
                                 <option value="0">Inactivo</option>
                              </select>
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-12 col-lg-2 offset-lg-5 p-0 p-lg-3">
                           <input
                              type="submit"
                              className="btn-grisDark text-white btn-Solidez mt-4 w-100"
                              value="Buscar"
                           />
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </Fragment>
   );
};

export default Busqueda;
