import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Busqueda from "../components/product/Busqueda";
import ListadoProduct from "../components/product/ListadoProduct";
import TituloTabla from "../components/product/TituloTabla";
import Loading from "../components/layout/Loading";

const Product = () => {
   const user = useSelector((state) => state.auth.user);

   const loading = useSelector((state) => state.product.loading);

   const [data, setData] = useState({
      title: "",
      state: user.role === '05' ? '1' : '',
      limit: 10,
      offset: 0
   });

   return (
      <Fragment>
         <Header title={"ADMINISTRACIÓN DE PRODUCTOS"}/>
         <Loading loading={loading} />
         <Busqueda data={data} setData={setData} />
         <TituloTabla />
         <ListadoProduct data={data} setData={setData} />
         <Footer/>
      </Fragment>
   );
};

export default Product;
