import {
   PRODUCT_AGREGAR_COMENZAR,
   PRODUCT_AGREGAR_EXITO, 
   PRODUCT_AGREGAR_ERROR, 
   PRODUCT_DESCARGA_COMENZAR, 
   PRODUCT_DESCARGA_EXITO, 
   PRODUCT_DESCARGA_ERROR,
   PRODUCT_OBTENER_COMENZAR,
   PRODUCT_OBTENER_EXITO,
   PRODUCT_OBTENER_ERROR,
   PRODUCT_EDITAR_COMENZAR,
   PRODUCT_EDITAR_EXITO,
   PRODUCT_EDITAR_ERROR,
   PRODUCT_ESTADO_COMENZAR,
   PRODUCT_ESTADO_EXITO,
   PRODUCT_ESTADO_ERROR,
   PRODUCT_REPORTE_COMENZAR, 
   PRODUCT_REPORTE_EXITO, 
   PRODUCT_REPORTE_ERROR, 
} from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

// ---------- CREAR PRODUCTO ----------
export function crearProductAction(data) {
   return async (dispatch) => {
      dispatch(agregarProduct());

      try {
         const response = await clienteAxios.post("/products", data);
         dispatch(agregarProductExito(response.data));

         if (response.status === 201) {
            Swal.fire("Correcto", "El producto se registró correctamente", "success");
         } else {
            Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
         }
      } catch (error) {
         const code = error.response.status;
         let mensaje = "";
         if (code === 409 || code === 403) {
            mensaje = error.response.data.message;
         } else {
            mensaje = "Hubo un error, intenta de nuevo";
         }
         dispatch(agregarProductError(true));

         Swal.fire({icon: "error", title: "Hubo un error", text: mensaje});
      }
   }
}

const agregarProduct = () => ({
   type: PRODUCT_AGREGAR_COMENZAR
})

const agregarProductExito = data => ({
   type: PRODUCT_AGREGAR_EXITO,
   payload: data
})

const agregarProductError = estado => ({
   type: PRODUCT_AGREGAR_ERROR,
   payload: estado
})

// ---------- BUSCAR PRODUCTOS ----------
export function buscarProductAction(data, offset) {
   return async (dispatch) => {
         dispatch(descargarProduct());

         let query = `limit=${data.limit}&offset=${offset}&`;
         if (data.title && data.title !== '') {
            query = query + `title=${data.title}&`;
         }
         if (data.state && data.state !== '') {
            query = query + `state=${data.state}&`;
         }

         const url = query === '' ? '/products' : '/products?' + query;

         try {
            const resultado = await clienteAxios.get(url);
            resultado.data.limit = data.limit;

            dispatch(descargarProductExito(resultado.data))
         } catch (error) {
            dispatch(descargarProductError());
         }
   }
}

const descargarProduct = () => ({
   type: PRODUCT_DESCARGA_COMENZAR,
   payload: true
})

const descargarProductExito = data => ({
   type: PRODUCT_DESCARGA_EXITO,
   payload: data
})

const descargarProductError = () => ({
   type: PRODUCT_DESCARGA_ERROR,
   payload: true
})

// ---------- OBTENER PRODUCTO ----------
export function obtenerProductAction(id) {
   return async (dispatch) => {
      if (id) {
         dispatch(descargarObtenerProduct());

         try {
            const resultado = await clienteAxios.get(`/products/${id}`);

            dispatch(descargarObtenerProductExito(resultado.data))
         } catch (error) {
            dispatch(descargarObtenerProductError());
         }
      }
   }
}

const descargarObtenerProduct = () => ({
   type: PRODUCT_OBTENER_COMENZAR,
   payload: true
})

const descargarObtenerProductExito = data => ({
   type: PRODUCT_OBTENER_EXITO,
   payload: data
})

const descargarObtenerProductError = () => ({
   type: PRODUCT_OBTENER_ERROR,
   payload: true
})

// ---------- ACTUALIZAR PRODUCTO ----------
export function actualizarProductAction(id, data, formData) {
   return async (dispatch) => {

      dispatch(actualizarProduct());

      try {
         const response = await clienteAxios.patch(`/products/${id}`, formData);

         data.id = id;
         data.image = response.data.image;

         dispatch(actualizarProductExito(data));
         Swal.fire("Correcto", "El producto se actualizó correctamente", "success");
      } catch (error) {
         dispatch(actualizarProductError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const actualizarProduct = () => ({
   type: PRODUCT_EDITAR_COMENZAR
})

const actualizarProductExito = data => ({
   type: PRODUCT_EDITAR_EXITO,
   payload: data
})

const actualizarProductError = () => ({
   type: PRODUCT_EDITAR_ERROR,
   payload: true
})

// ---------- CAMBIAR ESTADO ----------
export function cambiarEstadoProductAction(id) {
   return async (dispatch) => {

      dispatch(cambiarEstadoProduct());

      try {
         await clienteAxios.patch(`/products/state/${id}`);

         dispatch(cambiarEstadoProductExito(id));
         //Swal.fire("Correcto", "Se cambio el estado correctamente", "success");
      } catch (error) {
         dispatch(cambiarEstadoProductError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const cambiarEstadoProduct = () => ({
   type: PRODUCT_ESTADO_COMENZAR
})

const cambiarEstadoProductExito = id => ({
   type: PRODUCT_ESTADO_EXITO,
   payload: id
})

const cambiarEstadoProductError = () => ({
   type: PRODUCT_ESTADO_ERROR,
   payload: true
})

// ---------- REPORTE PRODUCTOS ----------
export function reporteProductAction(data) {
   return async (dispatch) => {
         dispatch(reporteProduct());

         let query = '';
         if (data.title && data.title !== '') {
            query = query + `title=${data.title}&`;
         }
         if (data.state && data.state !== '') {
            query = query + `state=${data.state}&`;
         }

         const url = query === '' ? '/products/report' : '/products/report?' + query;

         try {
            const resultado = await clienteAxios.get(url);

            dispatch(reporteProductExito(resultado.data))
         } catch (error) {
            dispatch(reporteProductError());
         }
   }
}

const reporteProduct = () => ({
   type: PRODUCT_REPORTE_COMENZAR,
   payload: true
})

const reporteProductExito = data => ({
   type: PRODUCT_REPORTE_EXITO,
   payload: data
})

const reporteProductError = () => ({
   type: PRODUCT_REPORTE_ERROR,
   payload: true
})