import {
   MEMO_AGREGAR_COMENZAR,
   MEMO_AGREGAR_EXITO, 
   MEMO_AGREGAR_ERROR, 
   MEMO_DESCARGA_COMENZAR, 
   MEMO_DESCARGA_EXITO, 
   MEMO_DESCARGA_ERROR, 
   MEMO_DESCARGA_COMENZAR_TOTAL,
   MEMO_DESCARGA_EXITO_TOTAL, 
   MEMO_DESCARGA_ERROR_TOTAL, 
   MEMO_OBTENER_COMENZAR,
   MEMO_OBTENER_EXITO,
   MEMO_OBTENER_ERROR,
   MEMO_FIRMAR_COMENZAR,
   MEMO_FIRMAR_EXITO,
   MEMO_FIRMAR_ERROR,
   MEMO_YEAR_COMENZAR,
   MEMO_YEAR_EXITO,
   MEMO_YEAR_ERROR,
   MEMO_REPORTE_COMENZAR, 
   MEMO_REPORTE_EXITO, 
   MEMO_REPORTE_ERROR, 
} from '../types';

const initialState = {
   memos: [],
   memosTotal: [],
   sizeTotal: 0,
   report: [],
   years: [],
   currentMemo: null,
   loading: null
};

export default function(state = initialState, action) {
   switch(action.type) {
      case MEMO_AGREGAR_COMENZAR:
      case MEMO_FIRMAR_COMENZAR:
      case MEMO_OBTENER_COMENZAR:
         return {
            ...state,
            currentMemo: null,
            loading: true
         }
      case MEMO_OBTENER_EXITO:
         return {
            ...state,
            currentMemo: action.payload,
            loading: false
         }
      case MEMO_AGREGAR_EXITO:
         return {
            ...state,
            memos: [...state.memos, action.payload[0]],
            currentMemo: action.payload,
            loading: false
         }
      case MEMO_FIRMAR_EXITO:
         const currentMemo = action.payload;
         let memoModify = state.memos.find(ticket => ticket.id === currentMemo.id);
         memoModify.signing = currentMemo.signing;
         
         return {
            ...state,
            memos: state.memos.filter(ticket => ticket.id === memoModify.id ? memoModify : ticket),
            loading: false
         }
      case MEMO_AGREGAR_ERROR:
      case MEMO_FIRMAR_ERROR:
      case MEMO_OBTENER_ERROR:
         return {
            ...state,
            currentMemo: null,
            loading: false
         }
      case MEMO_DESCARGA_COMENZAR:
         return {
            ...state,
            memos: [],
            loading: true
         }
      case MEMO_DESCARGA_COMENZAR_TOTAL:
         return {
            ...state,
            memosTotal: [],
            sizeTotal: 0,
            loading: true
         }
      case MEMO_DESCARGA_EXITO:
         return {
            ...state,
            memos: action.payload,
            loading: false
         }
      case MEMO_DESCARGA_EXITO_TOTAL:
         return {
            ...state,
            memosTotal: action.payload.rows,
            sizeTotal: Math.ceil(action.payload.count / action.payload.limit),
            loading: false
         }
      case MEMO_DESCARGA_ERROR:
         return {
            ...state,
            memos: [],
            loading: false
         }
      case MEMO_DESCARGA_ERROR_TOTAL:
         return {
            ...state,
            memosTotal: [],
            sizeTotal: 0,
            loading: false
         }
      case MEMO_YEAR_COMENZAR:
      case MEMO_YEAR_ERROR:
         return {
            ...state,
            years: [],
            loading: true
         }
      case MEMO_YEAR_EXITO:
         return {
            ...state,
            years: action.payload,
            loading: false
         }
      case MEMO_REPORTE_COMENZAR:
         return {
            ...state,
            report: [],
            loading: true
         }
      case MEMO_REPORTE_EXITO:
         return {
            ...state,
            report: action.payload,
            loading: false
         }
      case MEMO_REPORTE_ERROR:
         return {
            ...state,
            report: [],
            loading: false
         }
      default:
         return state;
   }
}