import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Busqueda from "../components/memo/Busqueda";
import ListadoMemo from "../components/memo/ListadoMemo";
import ListadoMemoTodos from "../components/memo/ListadoMemoTodos";
import TituloTabla from "../components/memo/TituloTabla";
import Loading from "../components/layout/Loading";
import { obtenerYearAction } from "../actions/memoActions";

const Memorandum = () => {
   const dispatch = useDispatch();

   const user = useSelector((state) => state.auth.user);
   const loading = useSelector((state) => state.memo.loading);

   const [key, setKey] = useState("personal");

   const [data, setData] = useState({
      year: "",
      name: "",
      typeDocument: "",
      numDocument: "",
      limit: 10,
      offset: 0
   });

   useEffect(() => {
      dispatch(obtenerYearAction());
   }, []);

   return (
      <Fragment>
         <Header title={"MEMORANDUM"}/>
         <Loading loading={loading} />
         <Busqueda type={key} data={data} setData={setData} />
         <TituloTabla />
         {user && (user.role === "01" || user.role === "03") ? (
            <Tabs
               id="controlled-tab-example"
               activeKey={key}
               onSelect={(k) => setKey(k)}
               className="mb-2"
            >
               <Tab eventKey="personal" title="Memos personales">
                  <ListadoMemo />
               </Tab>
               <Tab eventKey="total" title="Memos de todos">
                  <ListadoMemoTodos data={data} setData={setData} />
               </Tab>
            </Tabs>
         ) : (
            <ListadoMemo />
         )}
         <Footer/>
      </Fragment>
   );
};

export default Memorandum;
