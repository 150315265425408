import React from "react";
import Switch from "react-switch";

import eyesIcon from "../../assets/images/eye.svg";
import calculatorIcon from "../../assets/images/calculator.svg";

const CommissionItem = ({ commission, onHandleChange, handleOpenModal, handleOpenModal2 }) => {
   return (
      <tr>
         <td>{commission.personal.name} {commission.personal.lastName}</td>
         <td>{commission.year + ' - ' + commission.month}</td>
         <td>{commission.goal}</td>
         <td>{commission.price}</td>
         <td>{commission.amount}</td>
         {/*<td>
            <Switch
               onChange={() => onHandleChange(commission.id)}
               checked={commission.state}
               className="react-switch"
            />
   </td>*/}
         <td>
            <button type="button" onClick={() => handleOpenModal(commission)}>
               <img src={eyesIcon} className="mr-2 iconTabla" alt="Ver" />
            </button>
         </td>
         <td>
            <button type="button" onClick={() => handleOpenModal2(commission)}>
               <img src={calculatorIcon} className="mr-2 iconTabla" alt="Calcular comisión" />
            </button>
         </td>
      </tr>
   );
};

export default CommissionItem;
