import React, { useState } from "react";
import { Modal, ModalTitle } from "react-bootstrap";
import { useSelector } from "react-redux";

import ViewExam from "./ViewExam";
import ExamItem from "./ExamItem";

const ListadoExam = () => {

   const exams = useSelector((state) => state.exam.exams);
   const user = useSelector((state) => state.auth.user);

   const [showModal, setShowModal] = useState(false);
   const [currentExam, setCurrentExam] = useState({});

   const handleCloseModal = () => {
      setShowModal(false);
   };

   const handleOpenModal = (exam) => {
      setShowModal(true);
      setCurrentExam(exam);
   };

   return (
      <div className="container-fluid p-0">
         <div className="cajaTable ">
            <div className="resultadoTable d-none">
               <p className="txtResultado text-center">
                  No hay resultado de búsqueda
               </p>
            </div>
            <div className="cabeceraTable2 cuperoTable3 mt-3 mb-100">
               <table>
                  <thead>
                     <tr>
                        <th>Código</th>
                        <th>Descripción</th>
                        {user && (user.role === '01' || user.role === '02' || user.role === '03') ? null : (
                           <th>Nota</th>
                        )}
                        <th>Fecha Inicio</th>
                        <th>Fecha Fin</th>
                        <th>Ver</th>
                     </tr>
                  </thead>
                  <tbody>
                     {exams &&
                        exams.map((exam) => (
                           <ExamItem key={exam.id} exam={exam} handleOpenModal={handleOpenModal}/>
                        ))}
                  </tbody>
               </table>
            </div>
         </div>

         <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
               <ModalTitle>
                  <h3 className="titulo text-center p-3">Ver exámen</h3>
               </ModalTitle>
            </Modal.Header>
            <ViewExam
               currentExam={currentExam}
               handleCloseModal={handleCloseModal}
            />
         </Modal>
      </div>
   );
};

export default ListadoExam;
