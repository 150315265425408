import React from "react";
import { useSelector } from "react-redux";

import TicketItem from "./TicketItem";

const ListadoTicket = () => {
   const tickets = useSelector((state) => state.ticket.report);

   return (
      <div id="tablaReporteBoleta"  className="container-fluid p-0">
         <div className="col-12">
               <h2 className="titulo text-center">Reporte de Boletas</h2>
            </div>
         <div className="cajaTable ">
            <div className="resultadoTable d-none">
               <p className="txtResultado text-center">
                  No hay resultado de búsqueda
               </p>
            </div>
            <div className="cabeceraTable2 cuperoTable3 mt-3 mb-100">
               <table>
                  <thead>
                     <tr>
                        <th>Descripción</th>
                        <th>Tipo</th>
                        <th>Periodo</th>
                        <th>Persona</th>
                        <th>Tipo Documento</th>
                        <th>Número Documento</th>
                        <th>Firma</th>
                        <th>Fecha Creación</th>
                        <th>Fecha Firma</th>
                     </tr>
                  </thead>
                  <tbody>
                     {tickets &&
                        tickets.map((ticket) => (
                           <TicketItem key={ticket.id} ticket={ticket} />
                        ))}
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   );
};

export default ListadoTicket;
