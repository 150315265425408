import {
   MALL_AGREGAR_COMENZAR,
   MALL_AGREGAR_EXITO, 
   MALL_AGREGAR_ERROR, 
   MALL_DESCARGA_COMENZAR, 
   MALL_DESCARGA_EXITO, 
   MALL_DESCARGA_ERROR, 
   MALL_OBTENER_COMENZAR,
   MALL_OBTENER_EXITO,
   MALL_OBTENER_ERROR,
   MALL_EDITAR_COMENZAR,
   MALL_EDITAR_EXITO,
   MALL_EDITAR_ERROR,
   MALL_ESTADO_COMENZAR,
   MALL_ESTADO_EXITO,
   MALL_ESTADO_ERROR,
} from '../types';

const initialState = {
   malls: [],
   currentMall: null,
   loading: null
};

export default function(state = initialState, action) {
   switch(action.type) {
      case MALL_AGREGAR_COMENZAR:
      case MALL_EDITAR_COMENZAR:
      case MALL_OBTENER_COMENZAR:
      case MALL_ESTADO_COMENZAR:
         return {
            ...state,
            currentMall: null,
            loading: true
         }
      case MALL_AGREGAR_EXITO:
      case MALL_OBTENER_EXITO:
         return {
            ...state,
            currentMall: action.payload,
            loading: false
         }
      case MALL_EDITAR_EXITO:
         const currentMall = action.payload;
         let mallModify = state.malls.find(mall => mall.id === currentMall.id);
         /*mallModify.code = currentMall.code;
         mallModify.name = currentMall.name;
         mallModify.lastName = currentMall.lastName;
         mallModify.typeDocument = currentMall.typeDocument;
         mallModify.numDocument = currentMall.numDocument;
         mallModify.phone = currentMall.phone;
         mallModify.position = currentMall.position;
         mallModify.type = currentMall.type;
         mallModify.state = currentMall.state === "1" ? true : false;
         mallModify.birthday = currentMall.birthday;
         mallModify.admissionDate = currentMall.admissionDate;
         mallModify.user.code = currentMall.user.code;
         if (mallModify.work && mallModify.work.length > 0) {
            mallModify.work[0].id = currentMall.work.PersonalStore.storeId;
            mallModify.work[0].address = currentMall.work.address;
            mallModify.work[0].PersonalStore.storeId = currentMall.work.PersonalStore.storeId;
         }*/
         
         return {
            ...state,
            malls: state.malls.filter(mall => mall.id === mallModify.id ? mallModify : mall),
            loading: false
         }
      case MALL_ESTADO_EXITO:
         const idMall = action.payload;
         let mallState = state.malls.find(mall => mall.id === idMall);
         mallState.state = !mallState.state
            
         return {
            ...state,
            malls: state.malls.filter(mall => mall.id === mallState.id ? mallState : mall),
            loading: false
         }
      case MALL_AGREGAR_ERROR:
      case MALL_EDITAR_ERROR:
      case MALL_OBTENER_ERROR:
      case MALL_ESTADO_ERROR:
         return {
            ...state,
            currentMall: null,
            loading: false
         }
      case MALL_DESCARGA_COMENZAR:
         return {
            ...state,
            malls: [],
            loading: true
         }
      case MALL_DESCARGA_EXITO:
         return {
            ...state,
            malls: action.payload,
            loading: false
         }
      case MALL_DESCARGA_ERROR:
         return {
            ...state,
            malls: [],
            loading: false
         }
      default:
         return state;
   }
}