import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Scheduler } from "@aldabil/react-scheduler";
import { fr } from 'date-fns/locale'
import Swal from "sweetalert2";

import {
   crearScheduleAction,
   editarScheduleAction,
   cambiarEstadoScheduleAction,
} from "../../actions/scheduleActions";

const SchedulePersonal = ({ idPersonal }) => {
   const dispatch = useDispatch();

   const user = useSelector((state) => state.auth.user);
   const personals = useSelector((state) => state.personal.personalsActivos);

   let optionsPersonal = [];
   if (personals && personals.length > 0) {
      personals.map((personal) =>
         optionsPersonal.push({
            personalId: personal.id,
            name: personal.name + " " + personal.lastName,
         })
      );
   }

   const data = useSelector((state) => state.schedule.schedules);

   let weekOption = {};
   if (user.role === "01" || user.role === "03") {
      weekOption = {
         weekDays: [0, 1, 2, 3, 4, 5, 6],
         weekStartOn: 6,
         startHour: 8,
         endHour: 23,
         step: 60,
      };
   } else {
      weekOption = {
         weekDays: [0, 1, 2, 3, 4, 5, 6],
         weekStartOn: 6,
         startHour: 8,
         endHour: 23,
         step: 60,
         cellRenderer: ({ height, start, onClick }) => {
            const hour = start.getHours();
            const color = hour <= 10 ? "red" : hour > 12 ? "blue" : "";
            const disabled = true;
            return (
               <button
                  style={{
                     width: "100%",
                     height,
                     margin: 0,
                     border: 0,
                     cursor: "not-allowed",
                     color,
                     background: "transparent",
                  }}
                  disabled={disabled}
                  onClick={onClick}
               ></button>
            );
         },
      };
   }

   const translations = {
      navigation: {
         month: "Mes",
         week: "Semana",
         day: "Día",
         today: "Hoy",
      },
      form: {
         addTitle: "Registrar Horario",
         editTitle: "Editar Horario",
         confirm: "Confirmar",
         delete: "Eliminar",
         cancel: "Cancelar",
      },
      event: {
         title: "Descripción",
         start: "Inicio",
         end: "Fin",
      },
   };

   const crearSchedule = (dataSchedule) =>
      dispatch(crearScheduleAction(dataSchedule));
   const editarSchedule = (id, dataSchedule) =>
      dispatch(editarScheduleAction(id, dataSchedule));
   const cambiarEstadoSchedule = (id) =>
      dispatch(cambiarEstadoScheduleAction(id));

   const date = new Date();
   const currentYear = date.getFullYear();
   const currentMonth = date.getMonth();
   const currentDay = date.getDate();

   const handleConfirm = (event, action) => {
      const idPersonal = document.getElementById("idPersonal").value;

      if (idPersonal !== "") {
         let obj = {
            personalId: idPersonal,
            description: event.title,
            type: event.type,
            startDate: event.start,
            endDate: event.end,
         };
         if (action === "edit") {
            if (user.role === "01" || user.role === "03") {
               editarSchedule(event.event_id, obj);
            } else {
               Swal.fire(
                  "Alerta",
                  "No tiene permisos para editar el horario",
                  "warning"
               );
            }

            obj.event_id = event.event_id;
            obj.start = new Date(event.start);
            obj.end = new Date(event.end);
            obj.title = event.title;
            delete obj.description;
            delete obj.startDate;
            delete obj.endDate;
            delete obj.personalId;
            delete obj.id;
         } else if (action === "create") {
            crearSchedule(obj);
         }

         return obj;
      } else {
         Swal.fire(
            "Alerta",
            "Debe seleccionar un personal para registrar un horario",
            "warning"
         );
      }
   };

   const handleDelete = (deletedId) => {
      if (user.role === "01" || user.role === "03") {
         cambiarEstadoSchedule(deletedId);
      } else {
         Swal.fire(
            "Alerta",
            "No tiene permisos para eliminar el horario",
            "warning"
         );
      }
   };

   return (
      <div className="container-fluid p-0">
         <div className="cajaTable ">
            <Scheduler
               week={weekOption}
               events={data}
               onConfirm={handleConfirm}
               onDelete={handleDelete}
               selectedDate={new Date(currentYear, currentMonth, currentDay)}
               locale={fr}
               translations={translations}
               fields={[
                  {
                     name: "type",
                     type: "select",
                     // Should provide options with type:"select"
                     options: [
                        { id: "1", text: "Trabajo", value: "1" },
                        { id: "2", text: "Vacaciones", value: "2" },
                     ],
                     config: {
                        label: "Tipo",
                        required: true,
                        errMsg: "Seleccione el tipo",
                     },
                  },
               ]}
            />
         </div>
      </div>
   );
};

export default SchedulePersonal;
