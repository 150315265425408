import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { obtenerPersonalActivosAction } from "../actions/personalActions";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Personal from "../components/schedule/Personal";
import SchedulePersonal from "../components/schedule/SchedulePersonal";
import Loading from "../components/layout/Loading";

const Schedule = () => {
   const dispatch = useDispatch();

   const user = useSelector((state) => state.auth.user);

   const [idPersonal, onHandlePersonal] = useState(user.personal.id);

   const loading = useSelector((state) => state.schedule.loading);

   useEffect(() => {
      dispatch(obtenerPersonalActivosAction());
   }, []);

   return (
      <Fragment>
         <Header title={"ADMINISTRACIÓN DE HORARIOS"} />
         <Loading loading={loading} />
         <Personal idPersonal={idPersonal} onHandlePersonal={onHandlePersonal}/>
         <SchedulePersonal idPersonal={idPersonal}/>
         <Footer />
      </Fragment>
   );
};

export default Schedule;
