import {
   CAMPAING_AGREGAR_COMENZAR,
   CAMPAING_AGREGAR_EXITO, 
   CAMPAING_AGREGAR_ERROR, 
   CAMPAING_DESCARGA_COMENZAR, 
   CAMPAING_DESCARGA_EXITO, 
   CAMPAING_DESCARGA_ERROR, 
   CAMPAING_OBTENER_COMENZAR,
   CAMPAING_OBTENER_EXITO,
   CAMPAING_OBTENER_ERROR,
   CAMPAING_EDITAR_COMENZAR,
   CAMPAING_EDITAR_EXITO,
   CAMPAING_EDITAR_ERROR,
   CAMPAING_ESTADO_COMENZAR,
   CAMPAING_ESTADO_EXITO,
   CAMPAING_ESTADO_ERROR,
   CAMPAING_REPORTE_COMENZAR, 
   CAMPAING_REPORTE_EXITO, 
   CAMPAING_REPORTE_ERROR, 
} from '../types';

const initialState = {
   campaings: [],
   report: [],
   currentCampaing: null,
   loading: null
};

export default function(state = initialState, action) {
   switch(action.type) {
      case CAMPAING_AGREGAR_COMENZAR:
      case CAMPAING_EDITAR_COMENZAR:
      case CAMPAING_OBTENER_COMENZAR:
      case CAMPAING_ESTADO_COMENZAR:
         return {
            ...state,
            currentCampaing: null,
            loading: true
         }
      case CAMPAING_OBTENER_EXITO:
         return {
            ...state,
            currentCampaing: action.payload,
            loading: false
         }
      case CAMPAING_AGREGAR_EXITO:
         return {
            ...state,
            campaings: [...state.campaings, action.payload],
            currentCampaing: action.payload,
            loading: false
         }
      case CAMPAING_EDITAR_EXITO:
         const currentCampaing = action.payload;
         let campaingModify = state.campaings.find(campaing => campaing.id === currentCampaing.id);
         campaingModify.title = currentCampaing.title;
         campaingModify.description = currentCampaing.description;
         campaingModify.startDate = currentCampaing.startDate;
         campaingModify.endDate = currentCampaing.endDate;
         campaingModify.state = currentCampaing.state;
         campaingModify.url = currentCampaing.url;
         
         return {
            ...state,
            campaings: state.campaings.filter(campaing => campaing.id === campaingModify.id ? campaingModify : campaing),
            loading: false
         }
      case CAMPAING_ESTADO_EXITO:
         const idCampaing = action.payload;
         let campaingState = state.campaings.find(campaing => campaing.id === idCampaing);
         campaingState.state = !campaingState.state
            
         return {
            ...state,
            campaings: state.campaings.filter(campaing => campaing.id === campaingState.id ? campaingState : campaing),
            loading: false
         }
      case CAMPAING_AGREGAR_ERROR:
      case CAMPAING_EDITAR_ERROR:
      case CAMPAING_OBTENER_ERROR:
      case CAMPAING_ESTADO_ERROR:
         return {
            ...state,
            currentCampaing: null,
            loading: false
         }
      case CAMPAING_DESCARGA_COMENZAR:
      case CAMPAING_REPORTE_COMENZAR:
         return {
            ...state,
            campaings: [],
            report: [],
            loading: true
         }
      case CAMPAING_DESCARGA_EXITO:
         return {
            ...state,
            campaings: action.payload,
            loading: false
         }
      case CAMPAING_REPORTE_EXITO:
         return {
            ...state,
            report: action.payload,
            loading: false
         }
      case CAMPAING_DESCARGA_ERROR:
      case CAMPAING_REPORTE_ERROR:
         return {
            ...state,
            campaings: [],
            report: [],
            loading: false
         }
      default:
         return state;
   }
}