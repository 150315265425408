import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Busqueda from "../components/checklist/Busqueda";
import ListadoChecklist from "../components/checklist/ListadoChecklist";
import TituloTabla from "../components/checklist/TituloTabla";
import Loading from "../components/layout/Loading";

const Checklist = () => {

   const loading = useSelector((state) => state.checklist.loading);

   return (
      <Fragment>
         <Header title={"CHECKLIST"}/>
         <Loading loading={loading} />
         <Busqueda />
         <TituloTabla />
         <ListadoChecklist />
         <Footer/>
      </Fragment>
   );
};

export default Checklist;
