import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Busqueda from "../components/campaing/Busqueda";
import ListadoCampaing from "../components/campaing/ListadoCampaing";
import TituloTabla from "../components/campaing/TituloTabla";
import Loading from "../components/layout/Loading";

const Campaing = () => {

   const loading = useSelector((state) => state.campaing.loading);

   return (
      <Fragment>
         <Header title={"CAPACITACIONES"}/>
         <Loading loading={loading} />
         <Busqueda />
         <TituloTabla />
         <ListadoCampaing />
         <Footer/>
      </Fragment>
   );
};

export default Campaing;
