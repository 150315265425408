import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { obtenerProfileAction } from "./../../actions/profileActions";
import { cantidadMessageAction } from './../../actions/messageActions';

import Footer from "../layout/Footer";
import Header from "../layout/Header";

const Home = () => {
   const dispatch = useDispatch();

   const menus = useSelector((state) => state.profile.menus);
   const user = useSelector((state) => state.auth.user);
   const count = useSelector((state) => state.message.count);

   useEffect(() => {
      dispatch(obtenerProfileAction(user.role));
      dispatch(cantidadMessageAction(user.personal.id));
   }, []);

   return (
      <Fragment>
         <Header />
         <div className="row">
            <h2 className="titulo w-100 mt-4 pt-4 mt-md-5 pt-md-5 text-center">
               ¿Qué proceso deseas realizar?
            </h2>
         </div>
         <div className="container">
            <div className="row">
               <h2 className="subTituloInterno w-100 text-uppercase pl-3 pt-5 mt-4">
                  Administración
               </h2>
               <div className="pt-2 botonesHome">
                  {menus &&
                     menus.map((menu) =>
                        menu.selected && menu.module === "Administración" ? (
                           <Link
                              key={menu.id}
                              to={menu.url}
                              className="btn-color1 text-white text-center btn-Home-Solidez"
                           >
                              {menu.description}
                           </Link>
                        ) : null
                     )}
               </div>
            </div>
            <div className="row">
               <h2 className="subTituloInterno w-100 text-uppercase pl-3 pt-5">
                  RRHH
               </h2>
               <div className="pt-2 botonesHome">
                  {menus &&
                     menus.map((menu) =>
                        menu.selected && menu.module === "RRHH" ? (
                           <Link
                              key={menu.id}
                              to={menu.url}
                              className="btn-color2 text-white text-center btn-Home-Solidez"
                           >
                              {menu.description}
                           </Link>
                        ) : null
                     )}
               </div>
            </div>
            <div className="row">
               <h2 className="subTituloInterno w-100 text-uppercase pl-3 pt-5">
                  Otros
               </h2>
               <div className="pt-2 botonesHome" style={{position: 'relative'}}>
                  {menus &&
                     menus.map((menu) =>
                        menu.selected && menu.module === "Otros" ? (
                           <Link
                              key={menu.id}
                              to={menu.url}
                              className="btn-color3 text-white text-center btn-Home-Solidez"
                           >
                              {menu.description}
                              {menu.description === 'Mensajes' ? (<span className="message">{count}</span>) : (null)}
                           </Link>
                        ) : null
                     )}
                     
               </div>
            </div>
            <div className="row">
               <h2 className="subTituloInterno w-100 text-uppercase pl-3 pt-5">
                  Reportes
               </h2>
               <div className="pt-2 botonesHome">
                  {menus &&
                     menus.map((menu) =>
                        menu.selected && menu.module === "Reportes" ? (
                           <Link
                              key={menu.id}
                              to={menu.url}
                              className="btn-color4 text-white text-center btn-Home-Solidez"
                           >
                              {menu.description}
                           </Link>
                        ) : null
                     )}
               </div>
            </div>
         </div>
         <Footer />
      </Fragment>
   );
};

export default Home;
