import React from "react";
import Switch from "react-switch";
import { useSelector } from "react-redux";

import eyesIcon from "../../assets/images/eye.svg";

const ProductItem = ({ product, onHandleChange, handleOpenModal }) => {
   const user = useSelector((state) => state.auth.user);

   let images = [];
   if (product.image) {
      images = product.image.split(',');
   }

   let image = '';
   if (images[0] === '' && images[1].includes('http')) {
      image = images[1];
   } else {
      image = product.image;
   }

   return (
      <tr>
         <td><img src={image} alt="" height={100}/></td>
         <td>{product.title}</td>
         <td>{product.size}</td>
         <td>{product.price}</td>
         {user.role === '05' ? (null) : (
         <td>
         <Switch
            onChange={() => onHandleChange(product.id)}
            checked={product.state}
            className="react-switch"
         />
      </td>
         )}
         
         <td>
            <button type="button" onClick={() => handleOpenModal(product)}>
               <img src={eyesIcon} className="mr-2 iconTabla" alt="Ver" />
            </button>
         </td>
      </tr>
   );
};

export default ProductItem;
