import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { obtenerProfileAction } from "./../../actions/profileActions";

const Busqueda = ({idRole, onHandleRole}) => {
   const dispatch = useDispatch();

   const roles = useSelector((state) => state.profile.roles);
   const cargarPerfiles = (idRole) => dispatch(obtenerProfileAction(idRole));

   const onChangeBuscarPerfiles = (e) => {
      const target = e.target;
      onHandleRole(target.value);
      //onHandleBuscarSubmit(e);
      cargarPerfiles(target.value);
   };

   const onHandleBuscarSubmit = (e) => {
      e.preventDefault();

      cargarPerfiles(idRole);
   };

   return (
      <div className="row pl-3 pr-3 pt-0">
         <div className="container">
            <form onSubmit={onHandleBuscarSubmit}>
               <div className="btnsOptions ml-lg-3 mt-md-3 mt-xl-0  align-items-center">
                  <div className="row imputsCampos2">
                     <div className="col-12 col-lg-10 offset-lg-2">
                        <div className="row">
                           <h2 className="subTituloInterno pt-4 pb-2 pt-lg-5 pb-lg-5">
                              Seleccionar filtro:
                           </h2>
                        </div>
                        <div className="form-group row">
                           <label htmlFor="idRole" className="labelFecha-1">
                              Rol:
                           </label>
                           <select
                              className="form-control"
                              id="idRole"
                              name="idRole"
                              value={idRole}
                              onChange={onChangeBuscarPerfiles}
                              style={{width: '400px'}}
                              required
                           >
                              <option value="">-- Seleccionar --</option>
                              {roles &&
                                 roles.map((profile) => (
                                    <option value={profile.id} key={profile.id}>{profile.description}</option>
                                 ))}
                           </select>
                        </div>
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
   );
};

export default Busqueda;
