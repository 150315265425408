import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Busqueda from "../components/policy/Busqueda";
import ListadoPolicy from "../components/policy/ListadoPolicy";
import TituloTabla from "../components/policy/TituloTabla";
import Loading from "../components/layout/Loading";

const Policy = () => {

   const loading = useSelector((state) => state.policy.loading);

   const user = useSelector((state) => state.auth.user);

   const [data, setData] = useState({
      description: "",
      state: user.role === '01' || user.role === '03' ? '' : '1',
      limit: 10,
      offset: 0
   });

   return (
      <Fragment>
         <Header title={"NORMAS Y LEYES"}/>
         <Loading loading={loading} />
         <Busqueda data={data} setData={setData} />
         <TituloTabla />
         <ListadoPolicy data={data} setData={setData} />
         <Footer/>
      </Fragment>
   );
};

export default Policy;
