import {
   SCHEDULE_AGREGAR_COMENZAR,
   SCHEDULE_AGREGAR_EXITO, 
   SCHEDULE_AGREGAR_ERROR, 
   SCHEDULE_DESCARGA_COMENZAR, 
   SCHEDULE_DESCARGA_EXITO, 
   SCHEDULE_DESCARGA_ERROR,
   SCHEDULE_OBTENER_COMENZAR,
   SCHEDULE_OBTENER_EXITO,
   SCHEDULE_OBTENER_ERROR,
   SCHEDULE_EDITAR_COMENZAR,
   SCHEDULE_EDITAR_EXITO,
   SCHEDULE_EDITAR_ERROR,
   SCHEDULE_ESTADO_COMENZAR,
   SCHEDULE_ESTADO_EXITO,
   SCHEDULE_ESTADO_ERROR,
   SCHEDULE_REPORTE_COMENZAR,
   SCHEDULE_REPORTE_EXITO,
   SCHEDULE_REPORTE_ERROR,
} from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

// ---------- CREAR HORARIO ----------
export function crearScheduleAction(data) {
   return async (dispatch) => {
      dispatch(agregarSchedule());

      try {
         const response = await clienteAxios.post("/schedules", data);
         response.data.PersonalId = data.personalId;
         dispatch(agregarScheduleExito(response.data));

         if (response.status === 201) {
            Swal.fire("Correcto", "El horario se registró correctamente", "success");
         } else {
            Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
         }
      } catch (error) {
         const code = error.response.status;
         let mensaje = "";
         if (code === 409) {
            mensaje = error.response.data.message;
         } else {
            mensaje = "Hubo un error, intenta de nuevo";
         }
         dispatch(agregarScheduleError(true));

         Swal.fire({icon: "error", title: "Hubo un error", text: mensaje});
      }
   }
}

const agregarSchedule = () => ({
   type: SCHEDULE_AGREGAR_COMENZAR
})

const agregarScheduleExito = data => ({
   type: SCHEDULE_AGREGAR_EXITO,
   payload: data
})

const agregarScheduleError = estado => ({
   type: SCHEDULE_AGREGAR_ERROR,
   payload: estado
})

// ---------- BUSCAR HORARIOS ----------
export function buscarScheduleAction(data) {
   return async (dispatch) => {
         dispatch(descargarSchedule());

         let query = '';
         if (data.personalId && data.personalId !== '') {
            query = query + `personalId=${data.personalId}&`;
         }
         if (data.state && data.state !== '') {
            query = query + `state=${data.state}&`;
         }

         const url = query === '' ? '/schedules' : '/schedules?' + query;

         try {
            const resultado = await clienteAxios.get(url);

            dispatch(descargarScheduleExito(resultado.data))
         } catch (error) {
            dispatch(descargarScheduleError());
         }
   }
}

const descargarSchedule = () => ({
   type: SCHEDULE_DESCARGA_COMENZAR,
   payload: true
})

const descargarScheduleExito = data => ({
   type: SCHEDULE_DESCARGA_EXITO,
   payload: data
})

const descargarScheduleError = () => ({
   type: SCHEDULE_DESCARGA_ERROR,
   payload: true
})

// ---------- OBTENER HORARIO ----------
export function obtenerScheduleAction(id) {
   return async (dispatch) => {
      if (id) {
         dispatch(descargarObtenerSchedule());

         try {
            const resultado = await clienteAxios.get(`/schedules/${id}`);

            resultado.data.personalId = id;
            dispatch(descargarObtenerScheduleExito(resultado.data))
         } catch (error) {
            dispatch(descargarObtenerScheduleError());
         }
      }
   }
}

const descargarObtenerSchedule = () => ({
   type: SCHEDULE_OBTENER_COMENZAR,
   payload: true
})

const descargarObtenerScheduleExito = data => ({
   type: SCHEDULE_OBTENER_EXITO,
   payload: data
})

const descargarObtenerScheduleError = () => ({
   type: SCHEDULE_OBTENER_ERROR,
   payload: true
})

// ---------- ACTUALIZAR HORARIO ----------
export function editarScheduleAction(id, data) {
   return async (dispatch) => {

      dispatch(actualizarSchedule());

      try {
         await clienteAxios.patch(`/schedules/${id}`, data);

         dispatch(actualizarScheduleExito(data));
         Swal.fire("Correcto", "El horario se actualizó correctamente", "success");
      } catch (error) {
         dispatch(actualizarScheduleError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const actualizarSchedule = () => ({
   type: SCHEDULE_EDITAR_COMENZAR
})

const actualizarScheduleExito = data => ({
   type: SCHEDULE_EDITAR_EXITO,
   payload: data
})

const actualizarScheduleError = () => ({
   type: SCHEDULE_EDITAR_ERROR,
   payload: true
})

// ---------- CAMBIAR ESTADO ----------
export function cambiarEstadoScheduleAction(id) {
   return async (dispatch) => {

      dispatch(cambiarEstadoSchedule());

      try {
         await clienteAxios.patch(`/schedules/state/${id}`);

         dispatch(cambiarEstadoScheduleExito(id));
         //Swal.fire("Correcto", "Se cambio el estado correctamente", "success");
      } catch (error) {
         dispatch(cambiarEstadoScheduleError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const cambiarEstadoSchedule = () => ({
   type: SCHEDULE_ESTADO_COMENZAR
})

const cambiarEstadoScheduleExito = id => ({
   type: SCHEDULE_ESTADO_EXITO,
   payload: id
})

const cambiarEstadoScheduleError = () => ({
   type: SCHEDULE_ESTADO_ERROR,
   payload: true
})

// ---------- REPORTE SCHEDULE ----------
export function reporteScheduleAction(data) {
   return async (dispatch) => {
         dispatch(reporteSchedule());

         let parameters = '';
         if (data.personalId) {
            parameters = parameters + `personalId=${data.personalId}`;
         }
         if (data.type) {
            parameters = parameters + `type=${data.type}`;
         }
         if (parameters !== '') {
            parameters = parameters + '&';
         }
         if (data.startDate) {
            parameters = parameters + `startDate=${data.startDate}`;
         }
         if (parameters !== '') {
            parameters = parameters + '&';
         }
         if (data.endDate) {
            parameters = parameters + `endDate=${data.endDate}`;
         }
         const url = data === '' ? '/schedules/report' : `/schedules/report?${parameters}`;

         try {
            const resultado = await clienteAxios.get(url);

            dispatch(reporteScheduleExito(resultado.data))
         } catch (error) {
            dispatch(reporteScheduleError());
         }
   }
}

const reporteSchedule = () => ({
   type: SCHEDULE_REPORTE_COMENZAR,
   payload: true
})

const reporteScheduleExito = data => ({
   type: SCHEDULE_REPORTE_EXITO,
   payload: data
})

const reporteScheduleError = () => ({
   type: SCHEDULE_REPORTE_ERROR,
   payload: true
})