export function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}
 
export function formatDate(date) {
   return [
     padTo2Digits(date.getDate()),
     padTo2Digits(date.getMonth() + 1),
     date.getFullYear(),
   ].join('/');
 }

 export function monthDescription(index) {
  const value = Number(index);
  switch (value) {
    case 1: return 'Enero';
    case 2: return 'Febrero';
    case 3: return 'Marzo';
    case 4: return 'Abril';
    case 5: return 'Mayo';
    case 6: return 'Junio';
    case 7: return 'Julio';
    case 8: return 'Agosto';
    case 9: return 'Setiembre';
    case 10: return'Octubre';
    case 11: return 'Noviembre';
    case 12: return 'Diciembre';
    default: return '';
  }
 }

export function formatDateHour(date) {
  return (
    [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')
  );
}

export function getYears() {
  const maxYear = (new Date()).getFullYear();
  const minYear = 2021;
  const years = [];

  for (let i = maxYear; i >= minYear; i--) {
    years.push({description: i, value: i});
  }

  return years;
}

export function compareDate(date1, date2) {
  const array1 = date1.split('/');
  const array2 = date2.split('/');

  const newDate1 = new Date(array1[2], array1[1] - 1, array1[0]);
  const newDate2 = new Date(array2[2], array2[1] - 1, array2[0]);

  return newDate1 > newDate2;
}