import React, { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Busqueda from "../components/reports/exam/Busqueda";
import ListadoExamReport1 from "../components/reports/exam/ListadoExamReport1";
import ListadoExamReport2 from "../components/reports/exam/ListadoExamReport2";
import TituloTabla from "../components/reports/exam/TituloTabla";
import Loading from "../components/layout/Loading";

const ReportExam = () => {
   const loading = useSelector((state) => state.exam.loading);

   const [key, setKey] = useState("report1");

   return (
      <Fragment>
         <Header title={"REPORTE DE EXÁMENES"} />
         <Loading loading={loading} />
         <Busqueda type={key} />
         <TituloTabla type={key} />
         <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-2"
         >
            <Tab eventKey="report1" title="Reporte Por Examen">
               <ListadoExamReport1 />
            </Tab>
            <Tab eventKey="report2" title="Reporte Total">
               <ListadoExamReport2 />
            </Tab>
         </Tabs>
         <Footer />
      </Fragment>
   );
};

export default ReportExam;
