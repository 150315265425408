import {
   LOGIN_INICIO,
   LOGIN_EXITOSO, 
   LOGIN_ERROR, 
   CONFIRMAR_CUENTA_COMENZAR, 
   CONFIRMAR_CUENTA_EXITO, 
   CONFIRMAR_CUENTA_ERROR, 
   CERRAR_SESION,
   USER_CONTRASENIA_COMENZAR,
   USER_CONTRASENIA_EXITO,
   USER_CONTRASENIA_ERROR,
   USER_RECUPERAR_CONTRASENIA_COMENZAR,
   USER_RECUPERAR_CONTRASENIA_EXITO,
   USER_RECUPERAR_CONTRASENIA_ERROR
} from '../types';

const initialState = {
   token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
   autenticado: null,
   user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
   loading: false,
   error: false,
};

export default function(state = initialState, action) {
   switch(action.type) {
      case LOGIN_INICIO:
         localStorage.removeItem("token");
         localStorage.removeItem("user");

         return {
            ...state,
            token: null,
            loading: true
         }
      case LOGIN_EXITOSO:
         localStorage.setItem("token", action.payload.token);
         localStorage.setItem("user", JSON.stringify(action.payload.user));
         
         return {
            ...state,
            token: action.payload.token,
            user: action.payload.user,
            autenticado: true,
            loading: false
         }
      case CONFIRMAR_CUENTA_COMENZAR:
         return {
            ...state,
            loading: true
         }
      case CONFIRMAR_CUENTA_EXITO:
         return {
            ...state,
            loading: false
         }
      case CONFIRMAR_CUENTA_ERROR:
         return {
            ...state,
            loading: false
         }
      case USER_CONTRASENIA_COMENZAR:
         return {
            ...state,
            loading: true
         }
      case USER_CONTRASENIA_EXITO:
         return {
            ...state,
            loading: false
         }
      case USER_CONTRASENIA_ERROR:
         return {
            ...state,
            loading: false
         }
      case USER_RECUPERAR_CONTRASENIA_COMENZAR:
         return {
            ...state,
            loading: true
         }
      case USER_RECUPERAR_CONTRASENIA_EXITO:
         return {
            ...state,
            loading: false
         }
      case USER_RECUPERAR_CONTRASENIA_ERROR:
         return {
            ...state,
            loading: false
         }
      case CERRAR_SESION:
      case LOGIN_ERROR:
         localStorage.removeItem("token");
         localStorage.removeItem("user");

         return {
            ...state,
            token: null,
            user: null,
            autenticado: null,
            loading: false
         }
      default:
         return state;
   }
}