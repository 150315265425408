import React, { useState, Fragment } from "react";
import { Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";

import { actualizarPersonalAction } from "../../actions/personalActions";

import "react-datepicker/dist/react-datepicker.css";

const EditarPersonal = ({ currentPersonal, handleCloseModal }) => {
   const dispatch = useDispatch();

   const stores = useSelector((state) => state.store.storesActivos);

   const dateBirthday = currentPersonal.birthday.split("-");
   const dateAdmissionDate = currentPersonal.admissionDate.split("-");
   const [valueBirthday, setBirthday] = useState(new Date(dateBirthday[0], dateBirthday[1], dateBirthday[2]));
   const [valueAdmissionDate, setAdmissionDate] = useState(new Date(dateAdmissionDate[0], dateAdmissionDate[1], dateAdmissionDate[2]));

   const [data, onHandleData] = useState({
      name: currentPersonal.name,
      lastName: currentPersonal.lastName,
      typeDocument: currentPersonal.typeDocument,
      numDocument: currentPersonal.numDocument,
      phone: currentPersonal.phone,
      email: currentPersonal.email,
      position: currentPersonal.position,
      type: currentPersonal.type,
      role: currentPersonal.user.role,
      code: currentPersonal.user.code,
      state: currentPersonal.state ? "1" : "0",
      storeId: currentPersonal.work && currentPersonal.work.length > 0 ? currentPersonal.work[0].PersonalStore.storeId : "",
   });

   const {
      name,
      lastName,
      typeDocument,
      numDocument,
      phone,
      email,
      position,
      type,
      role,
      code,
      storeId,
      state
   } = data;

   const onChangeData = (e) => {
      onHandleData({
         ...data,
         [e.target.name]: e.target.value,
      });
   };

   const onHandleSubmit = (e) => {
      e.preventDefault();
      
      const personalObject = {
         name: name,
         lastName: lastName,
         typeDocument: typeDocument,
         numDocument: numDocument,
         phone: phone ? phone : '',
         position: position,
         type: type,
         birthday: valueBirthday.toISOString().slice(0, 10),
         admissionDate: valueAdmissionDate.toISOString().slice(0, 10),
         state: state,
         email: email ? email : '',
         user: {
            code: code,
            role: role
         },
         work: {
            id: currentPersonal.work && currentPersonal.work.length > 0 ? currentPersonal.work[0].id : 0,
            PersonalStore: {
               personalId: currentPersonal.id,
               storeId: storeId,
               inCharge: currentPersonal.work && currentPersonal.work.length > 0 && currentPersonal.work[0].PersonalStore.inCharge ? 1 : 0
           }
         }
     }

     const otherObject = {
        address: document.getElementById("storeId").options[document.getElementById("storeId").selectedIndex].text,
     }

     let descStore = '';
      if (storeId && storeId !== '') {
         const store = document.getElementById("storeId");
         descStore = store.options[store.selectedIndex].text
      }

     dispatch(actualizarPersonalAction(currentPersonal.id, {...personalObject}, otherObject, descStore));

      setBirthday(null);
      setAdmissionDate(null);
      e.target.reset();
      handleCloseModal();
   };

   return (
      <Fragment>
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                     <div className="form-group col-12 col-md-6">
                        <label htmlFor="code" className="c-grisDark mb-0">
                           Código: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <input
                           type="text"
                           name="code"
                           className="form-control"
                           placeholder="Ingrese el código"
                           value={code}
                           onChange={onChangeData}
                           minLength={3}
                           maxLength={15}
                           required
                        />
                     </div>
                     <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                        <label
                           htmlFor="storeId"
                           className="labelCliente mb-0 pt-0"
                        >
                           Tienda: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <select
                           name="storeId"
                           id="storeId"
                           className="w-100"
                           value={storeId}
                           onChange={onChangeData}
                        >
                           <option value="">Seleccionar Tienda</option>
                           {stores &&
                              stores.length &&
                              stores.map((store) => (
                                 <option key={store.id} value={store.id}>
                                    {store.address}
                                 </option>
                              ))}
                        </select>
                     </div>
                  </div>

                  <div className="row">
                     <div className="form-group col-12 col-md-6">
                        <label htmlFor="name" className="c-grisDark mb-0">
                           Nombres: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <input
                           type="text"
                           name="name"
                           className="form-control"
                           placeholder="Ingrese sus nombre"
                           value={name}
                           onChange={onChangeData}
                           minLength={1}
                           maxLength={50}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-6">
                        <label htmlFor="lastName" className="c-grisDark mb-0">
                           Apellidos: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <input
                           type="text"
                           name="lastName"
                           className="form-control"
                           placeholder="Ingrese sus apellidos"
                           value={lastName}
                           onChange={onChangeData}
                           minLength={1}
                           maxLength={50}
                           required
                        />
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group col-12 col-md-6">
                        <label htmlFor="birthday" className="c-grisDark mb-0">
                           Fecha de Nacimiento: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <DatePicker
                           name="birthday"
                           dateFormat="dd/MM/yyyy"
                           selected={valueBirthday}
                           isClearable
                           placeholderText="DD/MM/AAAA"
                           className="form-control"
                           maxDate={new Date()}
                           value={valueBirthday}
                           onChange={(date) => setBirthday(date)}
                           autoComplete="off"
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-6">
                        <label
                           htmlFor="admissionDate"
                           className="c-grisDark mb-0"
                        >
                           Fecha de Ingreso: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <DatePicker
                           name="admissionDate"
                           dateFormat="dd/MM/yyyy"
                           selected={valueAdmissionDate}
                           isClearable
                           placeholderText="DD/MM/AAAA"
                           className="form-control"
                           maxDate={new Date()}
                           value={valueAdmissionDate}
                           onChange={(date) => setAdmissionDate(date)}
                           autoComplete="off"
                           required
                        />
                     </div>
                  </div>

                  <div className="row">
                     <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                        <label
                           htmlFor="typeDocument"
                           className="labelCliente mb-0 pt-0"
                        >
                           Tipo de Documento: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <select
                           name="typeDocument"
                           className="w-100"
                           value={typeDocument}
                           onChange={onChangeData}
                           required
                        >
                           <option value="">
                              Seleccionar tipo de documento
                           </option>
                           <option value="01">DNI</option>
                           <option value="02">Pasaporte</option>
                           <option value="03">Carnet de Extranjería</option>
                        </select>
                     </div>
                     <div className="form-group col-12 col-md-6">
                        <label
                           htmlFor="numDocument"
                           className="c-grisDark mb-0"
                        >
                           Número de documento: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <input
                           type="number"
                           name="numDocument"
                           className="form-control"
                           placeholder="Ingrese su número de documento"
                           value={numDocument}
                           onChange={onChangeData}
                           minLength={5}
                           maxLength={15}
                           required
                        />
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                        <label htmlFor="position" className="labelCliente mb-0 pt-0">
                           Puesto: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <select
                           name="position"
                           className="w-100"
                           value={position}
                           onChange={onChangeData}
                           required
                        >
                           <option value="">Seleccionar</option>
                           <option value="01">Administrador del Sistema</option>
                           <option value="02">Administrador de Tienda</option>
                           <option value="03">Administrador Volante</option>
                           <option value="04">Supervisor</option>
                           <option value="05">Auxiliar de Almacen</option>
                           <option value="06">Analista RRHH</option>
                           <option value="07">Sub Administrador</option>
                           <option value="08">Sub Administrador Volante</option>
                           <option value="09">Vendedor</option>
                           <option value="10">Vendedor Volante</option>
                        </select>
                     </div>
                     <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                        <label
                           htmlFor="type"
                           className="labelCliente mb-0 pt-0"
                        >
                           Tipo: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <select
                           name="type"
                           className="w-100"
                           value={type}
                           onChange={onChangeData}
                           required
                        >
                           <option value="">Seleccionar</option>
                           <option value="01">Fijo</option>
                           <option value="02">Trainning</option>
                           <option value="03">Volante</option>
                        </select>
                     </div>
                  </div>
                  <div className="row">
                  <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                        <label
                           htmlFor="role"
                           className="labelCliente mb-0 pt-0"
                        >
                           Rol: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <select
                           name="role"
                           className="w-100"
                           value={role}
                           onChange={onChangeData}
                           required
                        >
                           <option value="">Seleccionar</option>
                           <option value="01">Administrador del Sistema</option>
                           <option value="02">Supervisor</option>
                           <option value="03">RRHH</option>
                           <option value="04">Administrador de la Tienda</option>
                           <option value="05">Vendedor</option>
                        </select>
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group col-12 col-md-6 mt-0">
                        <label htmlFor="phone" className="c-grisDark mb-0">
                           Teléfono:
                        </label>
                        <input
                           type="number"
                           name="phone"
                           className="form-control"
                           placeholder="Ingrese su número de teléfono"
                           value={phone}
                           onChange={onChangeData}
                           minLength={9}
                           maxLength={9}
                        />
                     </div>
                     <div className="form-group col-12 col-md-6 mt-0">
                        <label htmlFor="email" className="c-grisDark mb-0">
                           Email:
                        </label>
                        <input
                           type="email"
                           name="email"
                           className="form-control"
                           placeholder="Ingrese su email"
                           value={email}
                           onChange={onChangeData}
                           minLength={10}
                           maxLength={150}
                        />
                     </div>
                     <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                        <label
                           htmlFor="state"
                           className="labelCliente mb-0 pt-0"
                        >
                           Estado: <span style={{color: '#dc3545'}}>(*)</span>
                        </label>
                        <select
                           name="state"
                           className="w-100"
                           value={state}
                           onChange={onChangeData}
                           required
                        >
                           <option value="1">Activo</option>
                           <option value="0">Inactivo</option>
                        </select>
                     </div>
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               <button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="submit"
               >
                  Guardar
               </button>
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default EditarPersonal;
