import React from "react";

import { formatDateHour } from '../../../utils/functions';

//currentTimestamp.setHours(currentTimestamp.getHours() - 5)
const ScheduleItem = ({ schedule }) => {

   let startDateAux = new Date(schedule.startDate);
   let endDateAux = new Date(schedule.endDate);
   const currentDate = new Date();

   startDateAux.setHours(startDateAux.getHours() - 5);
   endDateAux.setHours(endDateAux.getHours() - 5);

   const startDate = formatDateHour(new Date(startDateAux));
   const endDate = formatDateHour(new Date(endDateAux));

   return (
      <tr>
         <td>{schedule.description}</td>
         <td>{schedule.type}</td>
         <td>{schedule.personal}</td>
         <td>{startDate}</td>
         <td>{endDate}</td>
         <td>{new Date(endDate).getTime() > new Date(currentDate).getTime() ? 'Activo' : 'Inactivo'}</td>
      </tr>
   );
};

export default ScheduleItem;
