import React from "react";
import { useSelector } from "react-redux";

import { formatDate } from '../../../utils/functions';

const ExamItemReport1 = ({ data }) => {
   const user = useSelector((state) => state.auth.user);
   //const score = exam.personal && exam.personal[0].ExamPersonal && exam.personal[0].ExamPersonal.score !== null ? exam.personal[0].ExamPersonal.score.toFixed(2) : '-';

   const date = data.takedAt !== null ? formatDate(new Date(data.takedAt)) : '';
   return (
      <tr>
         <td>{data.code}</td>
         <td>{data.description}</td>
         <td>{data.startDate}</td>
         <td>{data.endDate}</td>
         <td>{data.name}</td>
         <td>{data.taked ? 'Si' : 'No'}</td>
         <td>{data.score !== null && data.score >= 0 ? data.score : '-'}</td>
         <td>{date}</td>
      </tr>
   );
};

export default ExamItemReport1;
