import {
   PROFILE_DESCARGA_COMENZAR, 
   PROFILE_DESCARGA_EXITO, 
   PROFILE_DESCARGA_ERROR, 
   PROFILE_OBTENER_COMENZAR,
   PROFILE_OBTENER_EXITO,
   PROFILE_OBTENER_ERROR,
   PROFILE_EDITAR_COMENZAR,
   PROFILE_EDITAR_EXITO,
   PROFILE_EDITAR_ERROR,
} from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

// ---------- OBTENER ROLES ----------
export function obtenerRoleAction() {
   return async (dispatch) => {
         dispatch(descargarRole());

         const url = '/roles';;

         try {
            const resultado = await clienteAxios.get(url);

            dispatch(descargarRoleExito(resultado.data))
         } catch (error) {
            dispatch(descargarRoleError());
         }
   }
}

const descargarRole = () => ({
   type: PROFILE_DESCARGA_COMENZAR,
   payload: true
})

const descargarRoleExito = data => ({
   type: PROFILE_DESCARGA_EXITO,
   payload: data
})

const descargarRoleError = () => ({
   type: PROFILE_DESCARGA_ERROR,
   payload: true
})

// ---------- OBTENER PERFILES ----------
export function obtenerProfileAction(idRole) {
   return async (dispatch) => {
      if (idRole) {
         dispatch(obtenerProfile());

         try {
            const resultado = await clienteAxios.get(`/menus?idRole=${idRole}`);

            dispatch(obtenerProfileExito(resultado.data))
         } catch (error) {
            dispatch(obtenerProfileError());
         }
      }
   }
}

const obtenerProfile = () => ({
   type: PROFILE_OBTENER_COMENZAR,
   payload: true
})

const obtenerProfileExito = data => ({
   type: PROFILE_OBTENER_EXITO,
   payload: data
})

const obtenerProfileError = () => ({
   type: PROFILE_OBTENER_ERROR,
   payload: true
})

// ---------- ACTUALIZAR PERFIL ----------
export function actualizarProfileAction(data) {
   return async (dispatch) => {

      dispatch(actualizarProfile());

      try {
         const response = await clienteAxios.post(`/profiles/`, data);

         dispatch(actualizarProfileExito(data));
         Swal.fire("Correcto", "Los permisos se actualizaron correctamente", "success");
      } catch (error) {
         dispatch(actualizarProfileError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const actualizarProfile = () => ({
   type: PROFILE_EDITAR_COMENZAR
})

const actualizarProfileExito = data => ({
   type: PROFILE_EDITAR_EXITO,
   payload: data
})

const actualizarProfileError = () => ({
   type: PROFILE_EDITAR_ERROR,
   payload: true
})

