import React, { useState } from "react";
import { Modal, ModalTitle } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Pagination from '@mui/material/Pagination';

import { cambiarEstadoPersonalAction } from "../../actions/personalActions";
import { buscarPersonalAction } from "../../actions/personalActions";

import EditarPersonal from "./EditarPersonal";
import PersonalItem from "./PersonalItem";

const ListadoPersonal = ({ data, setData }) => {
   const dispatch = useDispatch();

   const personales = useSelector((state) => state.personal.personales);
   const size = useSelector((state) => state.personal.size);

   const [showModal, setShowModal] = useState(false);
   const [currentPersonal, setCurrentPersonal] = useState({});

   const { limit, offset } = data;

   const handleCloseModal = () => {
      setShowModal(false);
   };

   const handleOpenModal = (personal) => {
      setShowModal(true);
      setCurrentPersonal(personal);
   };

   const onHandleChange = (id) => {
      dispatch(cambiarEstadoPersonalAction(id));
   };

   const onChangePage = (e, page) => {
      const offset = 0 + (page - 1) * limit;

      setData({
         ...data,
         'offset': offset
      });
  
      dispatch(buscarPersonalAction(data, offset));
    }

    const changeValuePage = (e) => {
      setData({
         ...data,
         [e.target.name]: e.target.value
      });

      data.limit = Number(e.target.value);
  
      dispatch(buscarPersonalAction(data, offset));
    }

   return (
      <div className="container-fluid p-0">
         <div className="cajaTable ">
            <div className="resultadoTable d-none">
               <p className="txtResultado text-center">
                  No hay resultado de búsqueda
               </p>
            </div>
            <div class="container-fluid imputsCamposMostrar">
               <div class="row mt-lg-3">
                  <label  htmlFor="limit" className="mr-3 mt-1">Mostrar resultados:</label>
                  <select name='limit' onChange={changeValuePage}>
                     <option value="10">10</option>
                     <option value="15">15</option>
                     <option value="25">25</option>
                     <option value="50">50</option>
                     <option value="100">100</option> 
                  </select> 
               </div>
         </div>
            <div className="cabeceraTable2 cuperoTable3 mt-3 mb-3">
               <table>
                  <thead>
                     <tr>
                        <th>Código</th>
                        <th>Nombres y Apellidos</th>
                        <th>Tipo Documento</th>
                        <th>Número de Documento</th>
                        <th>Tienda</th>
                        <th>Estado</th>
                        <th>Acción</th>
                     </tr>
                  </thead>
                  <tbody>
                     {personales &&
                        personales.map((personal) => (
                           <PersonalItem key={personal.id} personal={personal} onHandleChange={onHandleChange} handleOpenModal={handleOpenModal}/>
                        ))}
                  </tbody>
               </table>
            </div>
            <Pagination count={size} onChange={onChangePage} />
         </div>

         <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
               <ModalTitle>
                  <h3 className="titulo text-center p-3">Editar personal</h3>
               </ModalTitle>
            </Modal.Header>
            <EditarPersonal
               currentPersonal={currentPersonal}
               handleCloseModal={handleCloseModal}
            />
         </Modal>
      </div>
   );
};

export default ListadoPersonal;
