import React, { useState } from "react";
import { Modal, ModalTitle } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { cambiarEstadoChecklistAction } from "../../actions/checklistActions";

import EditarChecklist from "./EditarChecklist";
import ChecklistItem from "./ChecklistItem";
import ViewChecklist from "./ViewChecklist";

const ListadoChecklist = () => {
   const dispatch = useDispatch();

   const checklists = useSelector((state) => state.checklist.checklists);

   const user = useSelector((state) => state.auth.user);
   const canEdit = user.role === '01' || user.role === '02' || user.role === '03' ? true : false;

   const [type, setType] = useState("");
   const [showModal, setShowModal] = useState(false);
   const [currentChecklist, setCurrentChecklist] = useState({});

   const handleCloseModal = () => {
      setShowModal(false);
   };

   const handleOpenModal = (checklist, pType) => {
      setShowModal(true);
      setType(pType);

      setCurrentChecklist(checklist);
   };

   const onHandleChange = (id) => {
      dispatch(cambiarEstadoChecklistAction(id));
   };

   return (
      <div className="container-fluid p-0">
         <div className="cajaTable ">
            <div className="resultadoTable d-none">
               <p className="txtResultado text-center">
                  No hay resultado de búsqueda
               </p>
            </div>
            <div className="cabeceraTable2 cuperoTable3 mt-3 mb-100">
               <table>
                  <thead>
                     <tr>
                        <th>Descripción</th>
                        <th>Fecha</th>
                        {canEdit ? (
                           <>
                              <th>Estado</th>
                        <th>Acción</th>
                           </>
                        ) : (null)}
                        <th>Evaluar</th>
                     </tr>
                  </thead>
                  <tbody>
                     {checklists &&
                        checklists.map((checklist) => (
                           <ChecklistItem key={checklist.id} checklist={checklist} onHandleChange={onHandleChange} handleOpenModal={handleOpenModal} />
                        ))}
                  </tbody>
               </table>
            </div>
         </div>

         <Modal show={showModal} onHide={handleCloseModal} size="lg">
            {type === '1' ? (
               <div style={{margin:'auto', width: '60%'}}>
               <Modal.Header closeButton>
               <ModalTitle>
                  <h3 className="titulo text-center p-3">Editar Checklist</h3>
               </ModalTitle>
            </Modal.Header>
            <EditarChecklist
               currentChecklist={currentChecklist}
               handleCloseModal={handleCloseModal}
            />
            </div>
            ) : (
               <div>
               <Modal.Header closeButton>
            </Modal.Header>
            <ViewChecklist
               currentChecklist={currentChecklist}
               handleCloseModal={handleCloseModal}
            />
            </div>
            )}
         </Modal>
      </div>
   );
};

export default ListadoChecklist;
