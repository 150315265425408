import React, { useState } from "react";

import ViewAnswer from "./ViewAnswer";

export default function ViewQuestion({
   currentQuestion,
   number,
   listQuestion,
   setListQuestion,
   replied,
   setReplied,
   onHandleQuestionChange,
}) {

   const answers = currentQuestion && currentQuestion.answer ? currentQuestion.answer : [];
   const [listAnswer, setListAnswer] = useState(answers);

   const onHandleAnswerChange = (idx, questionId, answer, setDescription) => (evt) => {

      const type = evt.target.type; // radio - checkbox - text

      if (type === 'radio') {
         let newList = [...listAnswer];
         let newAnswer;
         newAnswer = listAnswer.filter((answer, sidx) => {
            if (idx === sidx)
               return { ...answer, description: setDescription ? evt.target.value : null, isCorrect: true };
         });

         const answerSelected = newAnswer && newAnswer.length > 0 ? newAnswer[0] : null;

         newList = replied.filter((answer) => {
            if (answer.checklistQuestionId !== questionId) {
               return {id: answer.id, description: null, checklistQuestionId: answer.chcklistQuestionId};
            }             
         });

         newList.push({id: answerSelected.id, description: answerSelected.description, question: currentQuestion.description, checklistQuestionId: answerSelected.checklistQuestionId, type: 'radio'});
         setReplied(newList);
      } else if (type === 'checkbox') {
         let newList = [];
         let copyReplied = [...replied];
         let existe = false;

         for (let i = 0; i < copyReplied.length; i++) {
            if (copyReplied[i].id === answer.id) {
               existe = true;
               break;
            }
         }

         if (existe) {
            newList = copyReplied.filter(ans => {
               if (ans.id !== answer.id) {
                  return ans;
               }
            });
            setReplied(newList);
         } else {
            copyReplied.push({id: answer.id, description: answer.description, question: currentQuestion.description, checklistQuestionId: answer.checklistQuestionId, type: 'checkbox'});
            setReplied(copyReplied);
         }
      } else if (type === 'text') {
         if (setDescription) {
            setDescription(evt.target.value);
         }

         let newList = [];
         let copyReplied = [...replied];
         let existe = false;

         for (let i = 0; i < copyReplied.length; i++) {
            if (copyReplied[i].id === answer.id) {
               existe = true;
               break;
            }
         }

         if (existe) {
            newList = copyReplied.filter(ans => {
               if (ans.id !== answer.id) {
                  return {id: answer.id, description: evt.target.value, question: currentQuestion.description, checklistQuestionId: answer.checklistQuestionId, type: 'text'};
               }
            });
            setReplied(newList);
         } else {
            copyReplied.push({id: answer.id, description: evt.target.value, question: currentQuestion.description, checklistQuestionId: answer.checklistQuestionId, type: 'text'});
            setReplied(copyReplied);
         }
      }
   };

   const onSetListAnswer = () => {
      if (listAnswer.length < 5) {
         setListAnswer([...listAnswer, { description: "", isCorrect: false }]);
      }
   };

   return (
      <div className="boxQuestion">
         <div className="questions">
            <p>
            {number}{'. '}{currentQuestion.description}
            </p>
         </div>
         <div className="checkboxAll">
            {listAnswer.map((answer, index) => (
                  <ViewAnswer
                     key={index}
                     number={index + 1}
                     answer={answer}
                     group={"group_" + (number)}
                     marked={currentQuestion.marked}
                     type={currentQuestion.type}
                     descriptionQuestion={currentQuestion.description}
                     onHandleAnswerChange={onHandleAnswerChange}
                  />
               ))}
         </div>
      </div>
   );
}
