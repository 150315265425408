import {
   CHECKLIST_AGREGAR_COMENZAR,
   CHECKLIST_AGREGAR_EXITO, 
   CHECKLIST_AGREGAR_ERROR, 
   CHECKLIST_DESCARGA_COMENZAR, 
   CHECKLIST_DESCARGA_EXITO, 
   CHECKLIST_DESCARGA_ERROR, 
   CHECKLIST_OBTENER_COMENZAR,
   CHECKLIST_OBTENER_EXITO,
   CHECKLIST_OBTENER_ERROR,
   CHECKLIST_PREGUNTA_DESCARGA_COMENZAR,
   CHECKLIST_PREGUNTA_DESCARGA_EXITO,
   CHECKLIST_PREGUNTA_DESCARGA_ERROR,
   CHECKLIST_RESPONDER_COMENZAR,
   CHECKLIST_RESPONDER_EXITO,
   CHECKLIST_RESPONDER_ERROR,
   CHECKLIST_REPORTE_COMENZAR, 
   CHECKLIST_REPORTE_FULL_COMENZAR, 
   CHECKLIST_REPORTE_EXITO, 
   CHECKLIST_REPORTE_FULL_EXITO, 
   CHECKLIST_REPORTE_ERROR,
   CHECKLIST_ESTADO_COMENZAR,
   CHECKLIST_ESTADO_EXITO,
   CHECKLIST_ESTADO_ERROR,
} from '../types';

const initialState = {
   checklists: [],
   questions: [],
   report: [],
   reportWeb: [],
   reportFull: [],
   size: 0,
   currentChecklist: null,
   loading: null
};

export default function(state = initialState, action) {
   switch(action.type) {
      case CHECKLIST_AGREGAR_COMENZAR:
      case CHECKLIST_OBTENER_COMENZAR:
      case CHECKLIST_ESTADO_COMENZAR:
         return {
            ...state,
            currentChecklist: null,
            loading: true
         }
      case CHECKLIST_OBTENER_EXITO:
         return {
            ...state,
            currentChecklist: action.payload,
            loading: false
         }
      case CHECKLIST_AGREGAR_EXITO:
         const oldChecklist = action.payload;
         let allChecklist = state.checklists.filter(checklist => checklist.id !== oldChecklist.id_anterior);
         const fecha = oldChecklist.createdAt.split('T')[0].split('-');
         oldChecklist.createdAt = fecha[2] + '/' + fecha[1] + '/' + fecha[0];
         return {
            ...state,
            checklists: [...allChecklist, oldChecklist],
            currentChecklist: action.payload,
            loading: false
         }
      case CHECKLIST_AGREGAR_ERROR:
      case CHECKLIST_OBTENER_ERROR:
      case CHECKLIST_ESTADO_ERROR:
         return {
            ...state,
            currentChecklist: null,
            loading: false
         }
      case CHECKLIST_DESCARGA_COMENZAR:
         return {
            ...state,
            checklists: [],
            loading: true
         }
      case CHECKLIST_DESCARGA_EXITO:
         return {
            ...state,
            checklists: action.payload,
            loading: false
         }
      case CHECKLIST_DESCARGA_ERROR:
         return {
            ...state,
            checklists: [],
            loading: false
         }
      case CHECKLIST_PREGUNTA_DESCARGA_COMENZAR:
         return {
            ...state,
            questions: [],
            loading: true
         }
      case CHECKLIST_PREGUNTA_DESCARGA_EXITO:
         return {
            ...state,
            questions: action.payload,
            loading: false
         }
      case CHECKLIST_PREGUNTA_DESCARGA_ERROR:
         return {
            ...state,
            questions: [],
            loading: false
         }
      case CHECKLIST_REPORTE_COMENZAR:
         return {
            ...state,
            report: [],
            loading: true
         }
      case CHECKLIST_REPORTE_FULL_COMENZAR:
         return {
            ...state,
            reportFull: [],
            loading: true
         }
      case CHECKLIST_REPORTE_EXITO:
         return {
            ...state,
            reportWeb: action.payload.rows,
            size: Math.ceil(action.payload.count.length / action.payload.limit),
            loading: false
         }
      case CHECKLIST_REPORTE_FULL_EXITO:
         return {
            ...state,
            reportFull: action.payload.rows,
            loading: false
         }
      case CHECKLIST_REPORTE_ERROR:
         return {
            ...state,
            report: [],
            reportFull: [],
            loading: false
         }
      case CHECKLIST_RESPONDER_COMENZAR:
         return {
            ...state,
            loading: true
         }
      case CHECKLIST_RESPONDER_EXITO:
         return {
            ...state,
            currentChecklist: action.payload,
            loading: false
         }
      case CHECKLIST_RESPONDER_ERROR:
         return {
            ...state,
            loading: false
         }
      case CHECKLIST_ESTADO_EXITO:
         const idChecklist = action.payload;
         let checklistState = state.checklists.find(checklist => checklist.id === idChecklist);
         checklistState.state = !checklistState.state
            
         return {
            ...state,
            checklists: state.checklists.filter(checklist => checklist.id === checklistState.id ? checklistState : checklist),
            loading: false
         }
      default:
         return state;
   }
}