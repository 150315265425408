import React from "react";
import { useSelector } from "react-redux";

import MemoItem from "./MemoItem";

const ListadoMemo = () => {
   const memos = useSelector((state) => state.memo.report);

   return (
      <div id="tablaReporteMemo"  className="container-fluid p-0">
         <div className="col-12">
               <h2 className="titulo text-center">Reporte de Memorandums</h2>
            </div>
         <div className="cajaTable ">
            <div className="resultadoTable d-none">
               <p className="txtResultado text-center">
                  No hay resultado de búsqueda
               </p>
            </div>
            <div className="cabeceraTable2 cuperoTable3 mt-3 mb-100">
               <table>
                  <thead>
                     <tr>
                        <th>Descripción</th>
                        <th>Periodo</th>
                        <th>Persona</th>
                        <th>Tipo Documento</th>
                        <th>Número Documento</th>
                        <th>Firma</th>
                        <th>Fecha Creación</th>
                     </tr>
                  </thead>
                  <tbody>
                     {memos &&
                        memos.map((memo) => (
                           <MemoItem key={memo.id} memo={memo} />
                        ))}
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   );
};

export default ListadoMemo;
