import {
   CAMPAING_AGREGAR_COMENZAR,
   CAMPAING_AGREGAR_EXITO, 
   CAMPAING_AGREGAR_ERROR, 
   CAMPAING_DESCARGA_COMENZAR, 
   CAMPAING_DESCARGA_EXITO, 
   CAMPAING_DESCARGA_ERROR,
   CAMPAING_OBTENER_COMENZAR,
   CAMPAING_OBTENER_EXITO,
   CAMPAING_OBTENER_ERROR,
   CAMPAING_EDITAR_COMENZAR,
   CAMPAING_EDITAR_EXITO,
   CAMPAING_EDITAR_ERROR,
   CAMPAING_ESTADO_COMENZAR,
   CAMPAING_ESTADO_EXITO,
   CAMPAING_ESTADO_ERROR,
   CAMPAING_REPORTE_COMENZAR, 
   CAMPAING_REPORTE_EXITO, 
   CAMPAING_REPORTE_ERROR, 
} from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

import { formatDate } from '../utils/functions';

// ---------- CREAR CAMPAING ----------
export function crearCampaingAction(data, startDate, endDate) {
   return async (dispatch) => {
      dispatch(agregarCampaing());

      try {
         const response = await clienteAxios.post("/campaings", data);

         response.data.startDateLabel = formatDate(startDate);
         response.data.endDateLabel = formatDate(endDate);
         dispatch(agregarCampaingExito(response.data));

         if (response.status === 201) {
            Swal.fire("Correcto", "La capacitación se registró correctamente", "success");
         } else {
            Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
         }
      } catch (error) {
         const code = error.response.status;
         let mensaje = "";
         if (code === 409) {
            mensaje = error.response.data.message;
         } else {
            mensaje = "Hubo un error, intenta de nuevo";
         }
         dispatch(agregarCampaingError(true));

         Swal.fire({icon: "error", title: "Hubo un error", text: mensaje});
      }
   }
}

const agregarCampaing = () => ({
   type: CAMPAING_AGREGAR_COMENZAR
})

const agregarCampaingExito = data => ({
   type: CAMPAING_AGREGAR_EXITO,
   payload: data
})

const agregarCampaingError = estado => ({
   type: CAMPAING_AGREGAR_ERROR,
   payload: estado
})

// ---------- BUSCAR CAMPAINGS ----------
export function buscarCampaingAction(data) {
   return async (dispatch) => {
         dispatch(descargarCampaing());

         let query = '';
         if (data.title && data.title !== '') {
            query = query + `title=${data.title}&`;
         }
         if (data.state && data.state !== '') {
            query = query + `state=${data.state}&`;
         }

         const url = query === '' ? '/campaings' : '/campaings?' + query;

         try {
            const resultado = await clienteAxios.get(url);

            dispatch(descargarCampaingExito(resultado.data))
         } catch (error) {
            dispatch(descargarCampaingError());
         }
   }
}

const descargarCampaing = () => ({
   type: CAMPAING_DESCARGA_COMENZAR,
   payload: true
})

const descargarCampaingExito = data => ({
   type: CAMPAING_DESCARGA_EXITO,
   payload: data
})

const descargarCampaingError = () => ({
   type: CAMPAING_DESCARGA_ERROR,
   payload: true
})

// ---------- OBTENER CAMPAING ----------
export function obtenerCampaingAction(id) {
   return async (dispatch) => {
      if (id) {
         dispatch(descargarObtenerCampaing());

         try {
            const resultado = await clienteAxios.get(`/campaings/${id}`);

            dispatch(descargarObtenerCampaingExito(resultado.data))
         } catch (error) {
            dispatch(descargarObtenerCampaingError());
         }
      }
   }
}

const descargarObtenerCampaing = () => ({
   type: CAMPAING_OBTENER_COMENZAR,
   payload: true
})

const descargarObtenerCampaingExito = data => ({
   type: CAMPAING_OBTENER_EXITO,
   payload: data
})

const descargarObtenerCampaingError = () => ({
   type: CAMPAING_OBTENER_ERROR,
   payload: true
})

// ---------- ACTUALIZAR CAMPAING ----------
export function actualizarCampaingAction(id, data, formData) {
   return async (dispatch) => {

      dispatch(actualizarCampaing());

      try {
         const response = await clienteAxios.patch(`/campaings/${id}`, formData);

         dispatch(actualizarCampaingExito(response.data));
         Swal.fire("Correcto", "La capacitación se actualizó correctamente", "success");
      } catch (error) {
         dispatch(actualizarCampaingError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const actualizarCampaing = () => ({
   type: CAMPAING_EDITAR_COMENZAR
})

const actualizarCampaingExito = data => ({
   type: CAMPAING_EDITAR_EXITO,
   payload: data
})

const actualizarCampaingError = () => ({
   type: CAMPAING_EDITAR_ERROR,
   payload: true
})

// ---------- CAMBIAR ESTADO ----------
export function cambiarEstadoCampaingAction(id) {
   return async (dispatch) => {

      dispatch(cambiarEstadoCampaing());

      try {
         await clienteAxios.patch(`/campaings/state/${id}`);

         dispatch(cambiarEstadoCampaingExito(id));
         //Swal.fire("Correcto", "Se cambio el estado correctamente", "success");
      } catch (error) {
         dispatch(cambiarEstadoCampaingError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const cambiarEstadoCampaing = () => ({
   type: CAMPAING_ESTADO_COMENZAR
})

const cambiarEstadoCampaingExito = id => ({
   type: CAMPAING_ESTADO_EXITO,
   payload: id
})

const cambiarEstadoCampaingError = () => ({
   type: CAMPAING_ESTADO_ERROR,
   payload: true
})

// ---------- REPORTE CAMPAÑAS ----------
export function reporteCampaingAction(data) {
   return async (dispatch) => {
         dispatch(reporteCampaing());

         let query = '';
         if (data.title && data.title !== '') {
            query = query + `title=${data.title}&`;
         }
         if (data.startDate && data.startDate !== '') {
            query = query + `startDate=${data.startDate}&`;
         }
         if (data.endDate && data.endDate !== '') {
            query = query + `endDate=${data.endDate}&`;
         }

         const url = query === '' ? '/campaings/report' : '/campaings/report?' + query;

         try {
            const resultado = await clienteAxios.get(url);

            dispatch(reporteCampaingExito(resultado.data))
         } catch (error) {
            dispatch(reporteCampaingError());
         }
   }
}

const reporteCampaing = () => ({
   type: CAMPAING_REPORTE_COMENZAR,
   payload: true
})

const reporteCampaingExito = data => ({
   type: CAMPAING_REPORTE_EXITO,
   payload: data
})

const reporteCampaingError = () => ({
   type: CAMPAING_REPORTE_ERROR,
   payload: true
})