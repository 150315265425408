import React, { useState, Fragment } from "react";
import { Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { actualizarPolicyAction } from "../../actions/policyActions";

const EditarPolicy = ({ currentPolicy, handleCloseModal }) => {
   const dispatch = useDispatch();

   const [pdf, setPdf] = useState({ data: "" });
   const [data, onHandleData] = useState({
      description: currentPolicy.description,
      state: currentPolicy.state ? "1" : "0",
   });

   const { description, state } = data;

   const onChangeData = (e) => {
      if (
         e.target.name === "description"
      ) {
         onHandleData({
            ...data,
            [e.target.name]: e.target.value,
         });
      } else {
         if (e.target.name === "pdf") {
            const pdf = {
               data: e.target.files[0],
            };
            setPdf(pdf);
         }
      }
   };

   const onHandleSubmit = (e) => {
      e.preventDefault();

      const formData = new FormData();

      const json = JSON.stringify({
         description,
      });

      formData.append("json", json);
      formData.append("file", pdf.data);

      dispatch(
         actualizarPolicyAction(
            currentPolicy.id,
            { ...data },
            formData
         )
      );

      e.target.reset();
      handleCloseModal();
   };

   return (
      <Fragment>
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="description" className="c-grisDark mb-0">
                           Norma:
                        </label>
                        <input
                           type="text"
                           name="description"
                           className="form-control"
                           placeholder="Ingrese la norma"
                           value={description}
                           onChange={onChangeData}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="pdf" className="c-grisDark mb-0">
                           PDF:
                        </label>
                        <input
                           type="file"
                           name="pdf"
                           className="form-control"
                           placeholder="Seleccione el PDF"
                           onChange={onChangeData}
                        />
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                        <label
                           htmlFor="state"
                           className="labelCliente mb-0 pt-0"
                        >
                           Estado:
                        </label>
                        <select
                           name="state"
                           className="w-100"
                           value={state}
                           onChange={onChangeData}
                           required
                        >
                           <option value="1">Activo</option>
                           <option value="0">Inactivo</option>
                        </select>
                     </div>
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               <button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="submit"
               >
                  Aceptar
               </button>
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default EditarPolicy;
