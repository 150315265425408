import {
   EXAM_AGREGAR_COMENZAR,
   EXAM_AGREGAR_EXITO, 
   EXAM_AGREGAR_ERROR, 
   EXAM_DESCARGA_COMENZAR, 
   EXAM_DESCARGA_EXITO, 
   EXAM_DESCARGA_ERROR, 
   EXAM_OBTENER_COMENZAR,
   EXAM_OBTENER_EXITO,
   EXAM_OBTENER_ERROR,
   EXAM_EDITAR_COMENZAR,
   EXAM_EDITAR_EXITO,
   EXAM_EDITAR_ERROR,
   EXAM_PREGUNTA_DESCARGA_COMENZAR, 
   EXAM_PREGUNTA_DESCARGA_EXITO, 
   EXAM_PREGUNTA_DESCARGA_ERROR, 
   EXAM_RESPONDER_COMENZAR, 
   EXAM_RESPONDER_EXITO, 
   EXAM_RESPONDER_ERROR,
   EXAM_REPORTE_1_COMENZAR, 
   EXAM_REPORTE_1_EXITO, 
   EXAM_REPORTE_1_ERROR, 
   EXAM_REPORTE_2_COMENZAR, 
   EXAM_REPORTE_2_EXITO, 
   EXAM_REPORTE_2_ERROR, 
} from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

// ---------- CREAR EXAMEN ----------
export function crearExamAction(data) {
   return async (dispatch) => {
      dispatch(agregarExam());

      try {
         const response = await clienteAxios.post("/exams", data);
         dispatch(agregarExamExito(response.data));

         if (response.status === 201) {
            Swal.fire("Correcto", "El examen se envió exitosamente", "success");
         } else {
            Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
         }
      } catch (error) {
         const code = error.response.status;
         let mensaje = "";
         if (code === 409) {
            mensaje = error.response.data.message;
         } else {
            mensaje = "Hubo un error, intenta de nuevo";
         }
         dispatch(agregarExamError(true));

         Swal.fire({icon: "error", title: "Hubo un error", text: mensaje});
      }
   }
}

const agregarExam = () => ({
   type: EXAM_AGREGAR_COMENZAR
})

const agregarExamExito = data => ({
   type: EXAM_AGREGAR_EXITO,
   payload: data
})

const agregarExamError = estado => ({
   type: EXAM_AGREGAR_ERROR,
   payload: estado
})

// ---------- BUSCAR EXAMENES ----------
export function buscarExamAction(data) {
   return async (dispatch) => {
         dispatch(descargarExam());

         let query = '';

         if (data.code && data.code !== '') {
            query = query + `code=${data.code}&`;
         }
         if (data.description && data.description !== '') {
            query = query + `description=${data.description}&`;
         }
         if (data.startDate && data.startDate !== '') {
            query = query + `startDate=${data.startDate}&`;
         }
         if (data.endDate && data.endDate !== '') {
            query = query + `endDate=${data.endDate}&`;
         }
         if (data.finished && data.finished !== '') {
            query = query + `finished=${data.finished}&`;
         }
         if (data.userId && data.userId !== '') {
            query = query + `userId=${data.userId}&`;
         }

         const url = query === '' ? '/exams' : '/exams?' + query;

         try {
            const resultado = await clienteAxios.get(url);

            dispatch(descargarExamExito(resultado.data))
         } catch (error) {
            dispatch(descargarExamError());
         }
   }
}

const descargarExam = () => ({
   type: EXAM_DESCARGA_COMENZAR,
   payload: true
})

const descargarExamExito = data => ({
   type: EXAM_DESCARGA_EXITO,
   payload: data
})

const descargarExamError = () => ({
   type: EXAM_DESCARGA_ERROR,
   payload: true
})

// ---------- OBTENER EXAMEN ----------
export function obtenerExamAction(id) {
   return async (dispatch) => {
      if (id) {
         dispatch(descargarObtenerExam());

         try {
            const resultado = await clienteAxios.get(`/messages/${id}`);

            dispatch(descargarObtenerExamExito(resultado.data))
         } catch (error) {
            dispatch(descargarObtenerExamError());
         }
      }
   }
}

const descargarObtenerExam = () => ({
   type: EXAM_OBTENER_COMENZAR,
   payload: true
})

const descargarObtenerExamExito = data => ({
   type: EXAM_OBTENER_EXITO,
   payload: data
})

const descargarObtenerExamError = () => ({
   type: EXAM_OBTENER_ERROR,
   payload: true
})

// ---------- OBTENER PREGUNTAS ----------
export function obtenerExamQuestionsAction(examId, personalId) {
   return async (dispatch) => {
      if (examId) {
         dispatch(descargarObtenerExamQuestions());

         try {
            const resultado = await clienteAxios.get(`/questions?examId=${examId}&personalId=${personalId}`);

            dispatch(descargarObtenerExamQuestionsExito(resultado.data))
         } catch (error) {
            dispatch(descargarObtenerExamQuestionsError());
         }
      }
   }
}

const descargarObtenerExamQuestions = () => ({
   type: EXAM_PREGUNTA_DESCARGA_COMENZAR,
   payload: true
})

const descargarObtenerExamQuestionsExito = data => ({
   type: EXAM_PREGUNTA_DESCARGA_EXITO,
   payload: data
})

const descargarObtenerExamQuestionsError = () => ({
   type: EXAM_PREGUNTA_DESCARGA_ERROR,
   payload: true
})

// ---------- RESPONDER EXAMEN ----------
export function responderExamAction(data) {
   return async (dispatch) => {
      if (data) {
         dispatch(responderExamen());

         try {
            const response = await clienteAxios.post(`/exams/answered`, data);

            if (response.status === 200) {
               Swal.fire("Correcto", "Finalizo de resolver el exámen", "success");
            } else {
               Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
            }

            dispatch(responderExamenExito(response.data))
         } catch (error) {
            dispatch(responderExamenError());
         }
      }
   }
}

const responderExamen = () => ({
   type: EXAM_RESPONDER_COMENZAR,
   payload: true
})

const responderExamenExito = data => ({
   type: EXAM_RESPONDER_EXITO,
   payload: data
})

const responderExamenError = () => ({
   type: EXAM_RESPONDER_ERROR,
   payload: true
})

// ---------- REPORTE EXAMENES ----------
export function reporte1ExamAction(data) {
   return async (dispatch) => {
         dispatch(reporte1Exam());

         let query = '';
         if (data.code && data.code !== '') {
            query = query + `code=${data.code}&`;
         }
         if (data.description && data.description !== '') {
            query = query + `description=${data.description}&`;
         }
         if (data.startDate && data.startDate !== '') {
            query = query + `startDate=${data.startDate}&`;
         }
         if (data.endDate && data.endDate !== '') {
            query = query + `endDate=${data.endDate}&`;
         }
         if (data.finished && data.finished !== '') {
            query = query + `finished=${data.finished}&`;
         }
         if (data.type && data.type !== '') {
            query = query + `type=${data.type}&`;
         }
         if (data.userId && data.userId !== '') {
            query = query + `userId=${data.userId}&`;
         }

         const url = query === '' ? '/exams/report' : '/exams/report?' + query;

         try {
            const resultado = await clienteAxios.get(url);

            dispatch(reporte1ExamExito(resultado.data))
         } catch (error) {
            dispatch(reporte1ExamError());
         }
   }
}

const reporte1Exam = () => ({
   type: EXAM_REPORTE_1_COMENZAR,
   payload: true
})

const reporte1ExamExito = data => ({
   type: EXAM_REPORTE_1_EXITO,
   payload: data
})

const reporte1ExamError = () => ({
   type: EXAM_REPORTE_1_ERROR,
   payload: true
})

export function reporte2ExamAction(data) {
   return async (dispatch) => {
         dispatch(reporte2Exam());

         let query = '';
         if (data.code && data.code !== '') {
            query = query + `code=${data.code}&`;
         }
         if (data.description && data.description !== '') {
            query = query + `description=${data.description}&`;
         }
         if (data.startDate && data.startDate !== '') {
            query = query + `startDate=${data.startDate}&`;
         }
         if (data.endDate && data.endDate !== '') {
            query = query + `endDate=${data.endDate}&`;
         }
         if (data.finished && data.finished !== '') {
            query = query + `finished=${data.finished}&`;
         }
         if (data.type && data.type !== '') {
            query = query + `type=${data.type}&`;
         }
         if (data.userId && data.userId !== '') {
            query = query + `userId=${data.userId}&`;
         }

         const url = query === '' ? '/exams/report' : '/exams/report?' + query;

         try {
            const resultado = await clienteAxios.get(url);

            dispatch(reporte2ExamExito(resultado.data))
         } catch (error) {
            dispatch(reporte2ExamError());
         }
   }
}

const reporte2Exam = () => ({
   type: EXAM_REPORTE_2_COMENZAR,
   payload: true
})

const reporte2ExamExito = data => ({
   type: EXAM_REPORTE_2_EXITO,
   payload: data
})

const reporte2ExamError = () => ({
   type: EXAM_REPORTE_2_ERROR,
   payload: true
})