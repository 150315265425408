import React, { useState } from "react";
import { Modal, ModalTitle } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Pagination from '@mui/material/Pagination';

import { cambiarEstadoPolicyAction, buscarPolicyAction } from "../../actions/policyActions";

import EditarPolicy from "./EditarPolicy";
import PolicyItem from "./PolicyItem";

const ListadoPolicy = ({ data, setData }) => {
   const dispatch = useDispatch();

   const policies = useSelector((state) => state.policy.policies);
   const size = useSelector((state) => state.policy.size);
   const user = useSelector((state) => state.auth.user);

   const [showModal, setShowModal] = useState(false);
   const [currentPolicy, setCurrentPolicy] = useState({});

   const { limit, offset } = data;

   const handleCloseModal = () => {
      setShowModal(false);
   };

   const handleOpenModal = (policy) => {
      setShowModal(true);
      setCurrentPolicy(policy);
   };

   const onHandleChange = (id) => {
      dispatch(cambiarEstadoPolicyAction(id));
   };

   const onChangePage = (e, page) => {
      const offset = 0 + (page - 1) * limit;

      setData({
         ...data,
         'offset': offset
      });
  
      dispatch(buscarPolicyAction(data, offset));
    }

    const changeValuePage = (e) => {
      setData({
         ...data,
         [e.target.name]: e.target.value
      });

      data.limit = Number(e.target.value);
  
      dispatch(buscarPolicyAction(data, offset));
    }

   return (
      <div className="container-fluid p-0">
         <div className="cajaTable ">
            <div className="resultadoTable d-none">
               <p className="txtResultado text-center">
                  No hay resultado de búsqueda
               </p>
            </div>
            <div class="container-fluid imputsCamposMostrar">
               <div class="row mt-lg-3">
                  <label  htmlFor="limit" className="mr-3 mt-1">Mostrar resultados:</label>
                  <select name='limit' onChange={changeValuePage}>
                     <option value="10">10</option>
                     <option value="15">15</option>
                     <option value="25">25</option>
                     <option value="50">50</option>
                     <option value="100">100</option> 
                  </select> 
               </div>
         </div>
            <div className="cabeceraTable2 cuperoTable3 mt-3 mb-100">
               <table>
                  <thead>
                     <tr>
                        <th>Norma</th>
                        <th>PDF</th>
                        {user.role === '01' || user.role === '03' ? (<th>Estado</th>) : (null)}
                        {user.role === '01' || user.role === '03' ? (<th>Acción</th>) : (null)}
                     </tr>
                  </thead>
                  <tbody>
                     {policies &&
                        policies.map((policy) => (
                           <PolicyItem key={policy.id} policy={policy} onHandleChange={onHandleChange} handleOpenModal={handleOpenModal}/>
                        ))}
                  </tbody>
               </table>
            </div>
            <Pagination count={size} onChange={onChangePage} />
         </div>

         <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
               <ModalTitle>
                  <h3 className="titulo text-center p-3">Editar norma</h3>
               </ModalTitle>
            </Modal.Header>
            <EditarPolicy
               currentPolicy={currentPolicy}
               handleCloseModal={handleCloseModal}
            />
         </Modal>
      </div>
   );
};

export default ListadoPolicy;
