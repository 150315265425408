import {
   COMMISSION_AGREGAR_COMENZAR,
   COMMISSION_AGREGAR_EXITO, 
   COMMISSION_AGREGAR_ERROR, 
   COMMISSION_DESCARGA_COMENZAR, 
   COMMISSION_DESCARGA_EXITO, 
   COMMISSION_DESCARGA_ERROR, 
   COMMISSION_OBTENER_COMENZAR,
   COMMISSION_OBTENER_EXITO,
   COMMISSION_OBTENER_ERROR,
   COMMISSION_EDITAR_COMENZAR,
   COMMISSION_EDITAR_EXITO,
   COMMISSION_EDITAR_ERROR,
   COMMISSION_ESTADO_COMENZAR,
   COMMISSION_ESTADO_EXITO,
   COMMISSION_ESTADO_ERROR,
   COMMISSION_META_COMENZAR,
   COMMISSION_META_EXITO,
   COMMISSION_META_ERROR,
   COMMISSION_DETALLE_COMENZAR,
   COMMISSION_DETALLE_EXITO,
   COMMISSION_DETALLE_ERROR,
   COMMISSION_PRECIO_COMENZAR,
   COMMISSION_PRECIO_EXITO,
   COMMISSION_PRECIO_ERROR,
   COMMISSION_COMISION_COMENZAR,
   COMMISSION_COMISION_EXITO,
   COMMISSION_COMISION_ERROR,
} from '../types';
import { monthDescription } from '../utils/functions';

const initialState = {
   commissions: [],
   details: [],
   goal: 0,
   commission: 0,
   price: 0,
   size: 0,
   currentCommission: null,
   loading: null
};

export default function(state = initialState, action) {
   switch(action.type) {
      case COMMISSION_AGREGAR_COMENZAR:
      case COMMISSION_EDITAR_COMENZAR:
      case COMMISSION_OBTENER_COMENZAR:
      case COMMISSION_ESTADO_COMENZAR:
         return {
            ...state,
            currentCommission: null,
            loading: true
         }
      case COMMISSION_OBTENER_EXITO:
         return {
            ...state,
            currentCommission: action.payload,
            loading: false
         }
      case COMMISSION_AGREGAR_EXITO:
         const current = action.payload;
         let currentModify = state.commissions.find(commission => commission.personalId === current.personalId && commission.period === current.period);
         console.log(current);
         console.log(currentModify);
         if (currentModify) {
            currentModify.price = (Number(currentModify.price) + Number(current.price)).toFixed(2);
         } else {
            currentModify = current;
         }

         return {
            ...state,
            commissions: state.commissions.filter(commission => commission.personalId === currentModify.personalId && commission.period === currentModify.period ? currentModify : commission),
            loading: false
         }
      case COMMISSION_EDITAR_EXITO:
         const currentCommission = action.payload;
         let commissionModify = state.commissions.find(commission => commission.id === currentCommission.id);
         commissionModify.amount = currentCommission.amount;
         commissionModify.period = currentCommission.period;
         commissionModify.year = currentCommission.period.slice(0, 4);
         commissionModify.month = monthDescription(currentCommission.period.substring(4, 6));
         commissionModify.state = currentCommission.state;
         
         return {
            ...state,
            commissions: state.commissions.filter(commission => commission.id === commissionModify.id ? commissionModify : commission),
            loading: false
         }
      case COMMISSION_ESTADO_EXITO:
         const idCommission = action.payload;
         let commissionState = state.commissions.find(commission => commission.id === idCommission);
         commissionState.state = !commissionState.state
            
         return {
            ...state,
            commissions: state.commissions.filter(commission => commission.id === commissionState.id ? commissionState : commission),
            loading: false
         }
      case COMMISSION_AGREGAR_ERROR:
      case COMMISSION_EDITAR_ERROR:
      case COMMISSION_OBTENER_ERROR:
      case COMMISSION_ESTADO_ERROR:
         return {
            ...state,
            currentCommission: null,
            loading: false
         }
      case COMMISSION_DESCARGA_COMENZAR:
         return {
            ...state,
            commissions: [],
            loading: true
         }
      case COMMISSION_DESCARGA_EXITO:
         let count = 0;
         if (action.payload.count.length) {
            count = action.payload.count.length;
         } else {
            count = action.payload.count;
         }

         return {
            ...state,
            commissions: action.payload.rows,
            size: Math.ceil(count / 10),
            loading: false
         }
      case COMMISSION_DESCARGA_ERROR:
         return {
            ...state,
            commissions: [],
            loading: false
         }
      case COMMISSION_META_COMENZAR:
         return {
            ...state,
            goal: -1,
            commission: -1,
            price: -1,
            loading: true
         }
      case COMMISSION_META_EXITO:
         return {
            ...state,
            goal: action.payload.goal,
            commission: action.payload.amount,
            price: action.payload.price,
            loading: false
         }
      case COMMISSION_META_ERROR:
         return {
            ...state,
            goal: null,
            commission: null,
            price: null,
            loading: false
         }
      case COMMISSION_DETALLE_COMENZAR:
         return {
            ...state,
            details: [],
            loading: true
         }
      case COMMISSION_DETALLE_EXITO:
         return {
            ...state,
            details: action.payload,
            //size: Math.ceil(action.payload.count / 10),
            loading: false
         }
      case COMMISSION_DETALLE_ERROR:
         return {
            ...state,
            details: [],
            loading: false
         }
      case COMMISSION_PRECIO_COMENZAR:
         return {
            ...state,
            price: 0,
            loading: true
         }
      case COMMISSION_PRECIO_EXITO:
         const commission = action.payload;
         let modify = state.details.find(item => item.id === commission.id);
         modify.price = commission.price;
         let updateCommission2 = state.commissions.find(item => item.personalId === commission.personalId && item.period === commission.period);
         updateCommission2.price = (Number(updateCommission2.price) - Number(commission.priceAnterior) + Number(commission.price)).toFixed(2);

         return {
            ...state,
            details: state.details.filter(item => item.id === modify.id ? modify : item),
            commissions: state.commissions.filter(item => item.personalId === updateCommission2.personalId && item.period === updateCommission2.period ? updateCommission2 : item),
            loading: false
         }
      case COMMISSION_PRECIO_ERROR:
         return {
            ...state,
            price: 0,
            loading: false
         }
      case COMMISSION_COMISION_COMENZAR:
         return {
            ...state,
            commission: 0,
            loading: true
         }
      case COMMISSION_COMISION_EXITO:
         const getCommission = action.payload;
         let updateCommission = state.commissions.find(item => item.personalId === getCommission.personalId && item.period === getCommission.period);
         updateCommission.amount = getCommission.amount.toFixed(2);

         return {
            ...state,
            commissions: state.commissions.filter(item => item.personalId === updateCommission.personalId && item.period === updateCommission.period ? updateCommission : item),
            loading: false
         }
      case COMMISSION_COMISION_ERROR:
         return {
            ...state,
            commission: 0,
            loading: false
         }
      default:
         return state;
   }
}