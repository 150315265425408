import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { MONTHS } from "../../utils/Constans";

import { getYears } from "../../utils/functions";

import { buscarCommissionAction } from "../../actions/commissionActions";

const Busqueda = ({ data, setData }) => {
   const dispatch = useDispatch();

   const personals = useSelector((state) => state.personal.personalsActivos);
   const user = useSelector((state) => state.auth.user);

   const { personalId, year, month, state, offset } = data;

   const [optionsSelect, setOptionsSelect] = useState({});
   const multi = user.role === '05' ? false : true;
   let optionsPersonal = [];
   if (personals) {
      if (multi) {
         optionsPersonal.push({
            value: '',
            label: 'Todos'
         });
         personals.map(personal => optionsPersonal.push({
            value: personal.id,
            label: personal.name + ' ' + personal.lastName
         }));
      }
   }

   const YEARS = getYears();

   const onChangeBuscar = (e) => {
      if (e.target && (e.target.name === "year" || e.target.name === "month" || e.target.name === "state")) {
         setData({
            ...data,
            [e.target.name]: e.target.value,
         });
      } else {
         setOptionsSelect(e);
      }
   };

   const onHandleBuscarSubmit = (e) => {
      e.preventDefault();

      if (optionsSelect && optionsSelect.value) {
         data.personalId = optionsSelect.value;
      }

      dispatch(buscarCommissionAction(data, offset));
   };

   return (
      <Fragment>
         <div className="row pl-3 pr-3 pt-0">
            <div className="container">
               <form onSubmit={onHandleBuscarSubmit}>
                  <div className="btnsOptions ml-lg-3 mt-md-3 mt-xl-0  align-items-center">
                     <div className="row imputsCampos2">
                        <div className="col-12 col-lg-10 offset-lg-2">
                           <div className="row">
                              <h2 className="subTituloInterno pt-4 pb-2 pt-lg-5 pb-lg-5">
                                 Seleccionar filtro:
                              </h2>
                           </div>
                           <div className="row">
                              <label
                                 htmlFor="personalId"
                                 className="labelCliente"
                              >
                                 Personal:
                              </label>
                              {multi ? (
                                 <Select
                                    placeholder="Seleccionar"
                                    options={optionsPersonal}
                                    onChange={onChangeBuscar}
                                    className="imputsCampos3"
                                    isDisabled={user.role === '05' ? true : false}
                                 />
                              ) : (
                                 <select
                                 name="personalId"
                                 value={personalId}
                                 onChange={onChangeBuscar}
                                 disabled={user.role === '05' ? true : false}
                              >
                                 <option value="">Todos</option>
                                 {personals &&
                                    personals.map((personal) => (
                                       <option
                                          key={personal.id}
                                          value={personal.id}
                                       >
                                          {personal.name} {personal.lastName}
                                       </option>
                                    ))}
                                    </select>
                              )}
                              <label htmlFor="state" className="labelCliente">
                                 Estado:
                              </label>
                              <select
                                 name="state"
                                 value={state}
                                 onChange={onChangeBuscar}
                              >
                                 <option value="">Seleccionar Estado</option>
                                 <option value="1">Activo</option>
                                 <option value="0">Inactivo</option>
                              </select>
                           </div>
                           <div className="row mt-lg-3">
                              <label htmlFor="year" className="c-grisDark mb-0">
                                 Año:
                              </label>
                              <select
                                 name="year"
                                 value={year}
                                 onChange={onChangeBuscar}
                              >
                                 <option value="">Seleccionar Año</option>
                                 {YEARS &&
                                    YEARS.length > 0 &&
                                    YEARS.map((year) => (
                                       <option
                                          key={year.value}
                                          value={year.value}
                                       >
                                          {year.description}
                                       </option>
                                    ))}
                              </select>

                              <label
                                 htmlFor="month"
                                 className="c-grisDark mb-0"
                              >
                                 Mes:
                              </label>
                              <select
                                 name="month"
                                 value={month}
                                 onChange={onChangeBuscar}
                              >
                                 <option value="">Seleccionar Mes</option>
                                 {MONTHS &&
                                    MONTHS.length > 0 &&
                                    MONTHS.map((month) => (
                                       <option
                                          key={month.value}
                                          value={month.value}
                                       >
                                          {month.description}
                                       </option>
                                    ))}
                              </select>
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-12 col-lg-2 offset-lg-5 p-0 p-lg-3">
                           <input
                              type="submit"
                              className="btn-grisDark text-white btn-Solidez mt-4 w-100"
                              value="Buscar"
                           />
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </Fragment>
   );
};

export default Busqueda;
