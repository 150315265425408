import axios from 'axios';

const token = localStorage.getItem("token");

//https://service.solidezempresarial.com/api/v1
const clienteAxios = axios.create({
   baseURL: 'https://ws.solidezempresarial.com/api/v1',//process.env.REACT_APP_BACKEND_URL,
   headers: {'Authorization': `Bearer ${token}`}
});

clienteAxios.interceptors.request.use(
   config => {
     const token = localStorage.getItem("token");
     if (token) {
       config.headers.Authorization = `Bearer ${token}`;
     }
     return config;
   },
   error => Promise.reject(error)
 );

export default clienteAxios;
