import React from "react";
import { useSelector } from "react-redux";

import eyesIcon from "../../assets/images/eye.svg";

const ExamItem = ({ exam, onHandleChange, handleOpenModal }) => {
   const user = useSelector((state) => state.auth.user);
   const score = exam.personal && exam.personal[0].ExamPersonal && exam.personal[0].ExamPersonal.score !== null ? exam.personal[0].ExamPersonal.score.toFixed(2) : '-';

   return (
      <tr>
         <td>{exam.code}</td>
         <td>{exam.description}</td>
         {user && (user.role === '01' || user.role === '02' || user.role === '03') ? null : (
            <td>{score}</td>
         )}
         <td>{exam.startDate}</td>
         <td>{exam.endDate}</td>
         <td>
            <button type="button" onClick={() => handleOpenModal(exam)}>
               <img src={eyesIcon} className="mr-2 iconTabla" alt="Ver" />
            </button>
         </td>
      </tr>
   );
};

export default ExamItem;
