import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";

import { iniciarSesionAction } from "../../actions/authActions";
import { useEffect } from "react";

const Login = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const autenticado = useSelector((state) => state.auth.autenticado);

   const [login, setLogin] = useState({
      code: '',
      password: ''
   });

   const onChangeLogin = (e) => {
      setLogin({
         ...login,
         [e.target.name]: e.target.value,
      });
   };

   const onSubmitLogin = (e) => {
      e.preventDefault();

      if (code.trim() === "" || password.trim() === "") {
         return;
      }

      dispatch(iniciarSesionAction(login));
   };

   useEffect(() => {
      if (autenticado) {
         navigate("/home");
      }
   }, [autenticado]);

   const [passwordType, setPasswordType] = useState("password");

    const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }

   const { code, password } = login;

   return (
      <div className="row">
         <div className="d-none d-lg-block col-md-6">
            <div className="row">
               <h1 className="titulo text-white titleFixed"><strong>SOLIDEZ EMPRESARIAL</strong></h1>
               <div className="image-update image-principal-1 w-100"></div>
            </div>
         </div>
         <div className="col-12 col-lg-6 paddingTop paddingLeft bg-grayLigth">
            <div className="container p-md-0">
               <h1 className="titulo d-block d-lg-none"><strong>SOLIDEZ EMPRESARIAL</strong></h1>
               <h2 className="c-gris titulo paddingTop2 c-grisDark">
                  <strong>INICIE SESIÓN</strong>
               </h2>
            </div>

            <Form onSubmit={onSubmitLogin} className="container form p-md-0">
               <div className="col-12 col-lg-8 mt-5 p-md-0">
                  <div className="form-group">
                     <label htmlFor="code" className="c-grisDark mb-0">
                        Código
                     </label>
                     <input
                        type="text"
                        name="code"
                        className="form-control"
                        placeholder="Ingrese su código"
                        value={code}
                        onChange={onChangeLogin}
                        required
                     />
                  </div>
                  <div className="form-group position-relative">
                     <label htmlFor="password" className="c-grisDark mb-0">
                        Contraseña
                     </label>
                     <input
                        type={passwordType}
                        name="password"
                        className="form-control txtPassword"
                        placeholder="Ingrese tu contraseña"
                        value={password}
                        onChange={onChangeLogin}
                        required
                     />
                     <button type="button"
                        className="btn show_password"
                        onClick={togglePassword}
                     >
                        { passwordType==="password"? <i className="hideeyes icon"></i> :<i className="showeyes icon"></i> }
                     </button>
                  </div>
                  <div className="form-group">
                     <div className="row">
                        <div className="col-12">
                           <input
                              type="submit"
                              className="btn-grisDark text-white btn-Solidez mt-4"
                              value="Ingresar"
                           />
                        </div>
                     </div>
                  </div>
                  <div className="form-group">
                     <div className="row">
                        <div className="col-12 mt-3 mb-5 mb-md-3">
                           <Link to="/restablecer" className="c-link">
                           ¿Olvidaste tu contraseña?
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </Form>
            <p className="c-link powered">
               Powered by{" "}
               <a href="https://putuquia.com" target="_blank" rel="noreferrer">
                  Putuquia
               </a>
            </p>
         </div>
      </div>
   );
};

export default Login;
