import React, { Fragment, useState, useEffect, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form } from "react-bootstrap";
import Swal from 'sweetalert2';

import { obtenerChecklistQuestionsAction, responderChecklistAction } from '../../actions/checklistActions';
import { obtenerStoreActivosAction } from "../../actions/storeActions";
import ViewQuestion from "./ViewQuestion";

const ViewChecklist = ({ currentChecklist, handleCloseModal }) => {
   const dispatch = useDispatch();

   const personal = useSelector((state) => state.auth.user.personal);
   const user = useSelector((state) => state.auth.user);
   const canEval = user.role === '01' || user.role === '02' || user.role === '04' ? true : false;

   const [data, setData] = useState({
      description: currentChecklist.description,
      storeId: '',
      meters: ''
   });

   const stores = useSelector((state) => state.store.storesActivos);
   const questions = useSelector(state => state.checklist.questions);
   const [replied, setReplied] = useState([]);
   const [listQuestion, setListQuestion] = useState([
      {
         description: "",
         answers: [{ description: "" }],
      },
   ]);

   const { description, storeId, meters } = data;

   const onChangeData = (e) => {
         setData({
            ...data,
            [e.target.name]: e.target.value,
         });
   };
   
   const onHandleSubmit = (e) => {
      e.preventDefault();

      const select = document.getElementById("storeId");
      const store = select.options[select.selectedIndex].text;
console.log('PREGUNTAS', questions);
console.log('RESPUESTAS', replied);
      const countText = replied.filter(item => item.type && item.type === 'text').length;
      const inputReplied = replied.filter(item => item.type && item.type === 'text');
console.log('INPUT RESPUESTAS', inputReplied);
console.log('CANTIDAD TEXTO RESPUESTAS', countText);
      const inputTexts = document.querySelectorAll('#formPreguntas input[type="text"]');
      console.log('INPUT', inputTexts);
      console.log('CANTIDAD INPUT', inputTexts.length);
      //const inputTextFill = inputTexts.filter(item => item.value !== '');
      //const inputTextEmpty = inputTexts.filter(item => item.value === '');
      //inputTexts.map(item => console.log('test', item));
      let emptyImputs = 0;
      let fillInputs = 0;
      inputTexts.forEach((item, index) => {
         if (item.value === '') {
            emptyImputs++;
         } else {
            fillInputs++;
         }
      });
      console.log('LLENOS', fillInputs);
      console.log('VACIOS', emptyImputs);

      const correctNumber = questions.length - inputTexts.length + fillInputs;
      if (correctNumber === replied.length) { // Los campos llenados son correctos
         dispatch(responderChecklistAction({
            checklistId: currentChecklist.id,
            checklist: currentChecklist.description ? currentChecklist.description : '',
            store: store,
            person: personal.name ? personal.name : '' + ' ' + personal.last_name ? personal.last_name : '',
            storeId: storeId,
            meters: meters,
            personalId: personal.id,
            replied: replied
         }));
   
         e.target.reset();
         handleCloseModal();
      } else { // Hay algun error en un campo llenado
         let copyReplied = [...replied];
         inputTexts.forEach(item => {
            if (item.value !== '') {
               const name = item.name;
               const id = Number(name.split('_')[1]);
               const tag = replied.filter(item => item.checklistQuestionId === id);
               
               if (tag && tag.length === 0) {
                  console.log(name, id);
                  console.log('ERROR 1', tag);
                  document.getElementById(name).focus();

                  const fullText = item.alt.split('-');
                  const question = fullText[0];
                  const answerId = Number(fullText[1]);
                  copyReplied.push({id: answerId, description: item.value, question: question, checklistQuestionId: id, type: 'text'});
               }
            }
         });
         
console.log('RESPUESTA FINAL 1', copyReplied);

         const uniqueIds = [];

         const unique = copyReplied.filter(element => {
         const isDuplicate = uniqueIds.includes(element.id);

         if (!isDuplicate) {
            uniqueIds.push(element.id);

            return true;
         }

         return false;
         });

         console.log('RESPUESTA FINAL 2', unique);

         setReplied(unique);

         dispatch(responderChecklistAction({
            checklistId: currentChecklist.id,
            checklist: currentChecklist.description ? currentChecklist.description : '',
            store: store,
            person: personal.name ? personal.name : '' + ' ' + personal.last_name ? personal.last_name : '',
            storeId: storeId,
            meters: meters,
            personalId: personal.id,
            replied: unique
         }));

         e.target.reset();
         handleCloseModal();
      }
   };

   const onSetListQuestion = () => {
      setListQuestion([
         ...listQuestion,
         { description: "", answers: [{ description: "", isCorrect: false }] },
      ]);
   };

   useEffect(() => {
      dispatch(obtenerStoreActivosAction());
      dispatch(obtenerChecklistQuestionsAction(currentChecklist.id));
   }, []);

   return (
      <Fragment>
         {currentChecklist.state ? (null) : (
            <p style={{color: 'red'}}>Este checklist no puede ser completado debido a que esta deshabilitado</p>
         )}
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                     <div className="form-group col-8">
                        <h2>{currentChecklist.description}</h2>
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group col-12">
                     <label
                           htmlFor="storeId"
                           className="labelCliente mb-0 pt-0"
                        >
                           Tienda:
                        </label>
                        <select
                           name="storeId"
                           id="storeId"
                           className="form-control"
                           value={storeId}
                           onChange={onChangeData}
                           required
                        >
                           <option value="">Seleccionar Tienda</option>
                           {stores &&
                              stores.length &&
                              stores.map((store) => (
                                 <option key={store.id} value={store.id}>
                                    {store.address}
                                 </option>
                              ))}
                        </select>
                     </div>
                  </div>

                  <div className="row">
                     <div className="form-group col-12">
                     <label
                           htmlFor="meters"
                           className="labelCliente mb-0 pt-0"
                        >
                           Indicar los metros cuadrados de la tienda (Sólo poner valores numéricos):
                        </label>
                        <input type="text" name="meters"
                           className="form-control"
                           placeholder="Ingrese los metros cuadrados"
                           value={meters}
                           onChange={onChangeData}
                           required/>
                     </div>
                  </div>

                  <hr />
<div id='formPreguntas'>
                  {questions &&
                     questions.map((question, index) => (
                        <ViewQuestion
                           key={index}
                           currentQuestion={question}
                           number={index + 1}
                           listQuestion={questions}
                           setListQuestion={setListQuestion}
                           replied={replied}
                           setReplied={setReplied}
                        />
                     ))}
</div>
                  <hr />
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               {questions && questions.length > 0 && questions[0].marked ? (
                  null
               ) : (
                  currentChecklist.state ? (
                     canEval ? (
                        <button
                        className="btn-grisDark text-white btn-Solidez mt-4"
                        type="submit"
                     >
                        Aceptar
                     </button>
                     ) : (null)
                  ) : (null)
               )}
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default ViewChecklist;
