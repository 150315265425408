import React from "react";
import { useSelector } from "react-redux";
import Switch from "react-switch";

import eyesIcon from "../../assets/images/eye.svg";
import pdfIcon from "../../assets/images/pdf_icon.svg";

const ChecklistItem = ({ campaing, onHandleChange, handleOpenModal }) => {
   const user = useSelector((state) => state.auth.user);

   return (
      <tr>
         <td>{campaing.title}</td>
         <td>{campaing.startDateLabel}</td>
         <td>{campaing.endDateLabel}</td>
         {user.role === '05' ? (null) : (
         <td>
         <Switch
            onChange={() => onHandleChange(campaing.id)}
            checked={campaing.state}
            className="react-switch"
         />
      </td>
         )}
         <td>
            <a href={campaing.url} target="_blank" alt="" rel="noreferrer">
               <img src={pdfIcon} className="mr-2 iconTabla" alt="Ver" />
            </a>
         </td>
         <td>
            <button type="button" onClick={() => handleOpenModal(campaing)}>
               <img src={eyesIcon} className="mr-2 iconTabla" alt="Ver" />
            </button>
         </td>
      </tr>
   );
};

export default ChecklistItem;
