import {
   STORE_AGREGAR_COMENZAR,
   STORE_AGREGAR_EXITO,
   STORE_AGREGAR_ERROR,
   STORE_DESCARGA_COMENZAR,
   STORE_DESCARGA_EXITO,
   STORE_DESCARGA_ERROR,
   STORE_ACTIVOS_DESCARGA_COMENZAR,
   STORE_ACTIVOS_DESCARGA_EXITO,
   STORE_ACTIVOS_DESCARGA_ERROR,
   STORE_OBTENER_COMENZAR,
   STORE_OBTENER_EXITO,
   STORE_OBTENER_ERROR,
   STORE_EDITAR_COMENZAR,
   STORE_EDITAR_EXITO,
   STORE_EDITAR_ERROR,
   STORE_ESTADO_COMENZAR,
   STORE_ESTADO_EXITO,
   STORE_ESTADO_ERROR,
   STORE_MANAGERS_DESCARGA_COMENZAR,
   STORE_MANAGERS_DESCARGA_EXITO,
   STORE_MANAGERS_DESCARGA_ERROR
} from "../types";
import clienteAxios from "../config/axios";
import Swal from "sweetalert2";

// ---------- CREAR STORE ----------
export function crearStoreAction(data, mall, city, district) {
   return async (dispatch) => {
      dispatch(agregarStore());

      try {
         const response = await clienteAxios.post("/stores", data);
         response.data.mall = new Object();
         response.data.city = new Object();
         response.data.district = new Object();
         response.data.mall.description = mall;
         response.data.city.description = city;
         response.data.district.description = district;
         dispatch(agregarStoreExito(response.data));

         if (response.status === 201) {
            Swal.fire("Correcto", "La tienda se registró correctamente", "success");
         } else {
            Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
         }
      } catch (error) {
         const code = error.response.status;
         let mensaje = "";
         if (code === 409 || code === 403) {
            mensaje = error.response.data.message;
         } else {
            mensaje = "Hubo un error, intenta de nuevo";
         }
         dispatch(agregarStoreError(true));

         Swal.fire({icon: "error", title: "Hubo un error", text: mensaje});
      }
   }
}

const agregarStore = () => ({
   type: STORE_AGREGAR_COMENZAR
})

const agregarStoreExito = data => ({
   type: STORE_AGREGAR_EXITO,
   payload: data
})

const agregarStoreError = estado => ({
   type: STORE_AGREGAR_ERROR,
   payload: estado
})

// ---------- BUSCAR TIENDAS ----------
export function buscarStoreAction(data, offset) {
   return async (dispatch) => {
      if (data) {
         dispatch(descargarStore());

         let query = `limit=${data.limit}&offset=${offset}&`;
         if (data.code && data.code !== '') {
            query = query + `code=${data.code}&`;
         }
         if (data.address && data.address !== '') {
            query = query + `address=${data.address}&`;
         }
         if (data.storeId && data.storeId !== '') {
            query = query + `storeId=${data.storeId}&`;
         }
         if (data.mallId && data.mallId !== '') {
            query = query + `mallId=${data.mallId}&`;
         }
         if (data.inChargeId && data.inChargeId !== '') {
            query = query + `inChargeId=${data.inChargeId}&`;
         }
         if (data.state && data.state !== '') {
            query = query + `state=${data.state}&`;
         }

         const url = query === '' ? '/stores' : '/stores?' + query;

         try {
            const resultado = await clienteAxios.get(url);
            resultado.data.limit = data.limit;

            dispatch(descargarStoreExito(resultado.data))
         } catch (error) {
            dispatch(descargarStoreError());
         }
      }
   }
}

const descargarStore = () => ({
   type: STORE_DESCARGA_COMENZAR,
   payload: true
})

const descargarStoreExito = data => ({
   type: STORE_DESCARGA_EXITO,
   payload: data
})

const descargarStoreError = () => ({
   type: STORE_DESCARGA_ERROR,
   payload: true
})

// ---------- LISTAR TIENDAS ACTIVAS ----------
export function obtenerStoreActivosAction() {
   return async (dispatch) => {
      dispatch(descargarStoreActivos());

      try {
         const resultado = await clienteAxios.get('/stores/list');

         dispatch(descargarStoreActivosExito(resultado.data))
      } catch (error) {
         dispatch(descargarStoreActivosError());
      }
   }
}

const descargarStoreActivos = () => ({
   type: STORE_ACTIVOS_DESCARGA_COMENZAR,
   payload: true
})

const descargarStoreActivosExito = data => ({
   type: STORE_ACTIVOS_DESCARGA_EXITO,
   payload: data
})

const descargarStoreActivosError = () => ({
   type: STORE_ACTIVOS_DESCARGA_ERROR,
   payload: true
})

// ---------- LISTAR ENCARGADOS DE TIENDA ----------
export function obtenerManagersAction() {
   return async (dispatch) => {
      dispatch(descargarManagers());

      try {
         const resultado = await clienteAxios.get('/stores/managers');

         dispatch(descargarManagersExito(resultado.data))
      } catch (error) {
         dispatch(descargarManagersError());
      }
   }
}

const descargarManagers = () => ({
   type: STORE_MANAGERS_DESCARGA_COMENZAR,
   payload: true
})

const descargarManagersExito = data => ({
   type: STORE_MANAGERS_DESCARGA_EXITO,
   payload: data
})

const descargarManagersError = () => ({
   type: STORE_MANAGERS_DESCARGA_ERROR,
   payload: true
})

// ---------- OBTENER TIENDA ----------
export function obtenerStoreAction(id) {
   return async (dispatch) => {
      if (id) {
         dispatch(descargarObtenerStore());

         try {
            const resultado = await clienteAxios.get(`/stores/${id}`);

            dispatch(descargarObtenerStoreExito(resultado.data))
         } catch (error) {
            dispatch(descargarObtenerStoreError());
         }
      }
   }
}

const descargarObtenerStore = () => ({
   type: STORE_OBTENER_COMENZAR,
   payload: true
})

const descargarObtenerStoreExito = data => ({
   type: STORE_OBTENER_EXITO,
   payload: data
})

const descargarObtenerStoreError = () => ({
   type: STORE_OBTENER_ERROR,
   payload: true
})

// ---------- ACTUALIZAR TIENDA ----------
export function actualizarStoreAction(id, data, other) {
   return async (dispatch) => {

      dispatch(actualizarStore());

      try {
         await clienteAxios.patch(`/stores/${id}`, data);

         data.id = id;
         data.mall = {
            id: data.mallId,
            description: other.descriptionMall
         }
         data.city = {
            id: data.cityId,
            description: other.descriptionCity
         }
         data.district = {
            id: data.districtId,
            description: other.descriptionDistrict
         }
         
         dispatch(actualizarStoreExito(data));
         Swal.fire("Correcto", "La tienda se actualizó correctamente", "success");
      } catch (error) {
         dispatch(actualizarStoreError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const actualizarStore = () => ({
   type: STORE_EDITAR_COMENZAR
})

const actualizarStoreExito = data => ({
   type: STORE_EDITAR_EXITO,
   payload: data
})

const actualizarStoreError = () => ({
   type: STORE_EDITAR_ERROR,
   payload: true
})

// ---------- CAMBIAR ESTADO ----------
export function cambiarEstadoStoreAction(id) {
   return async (dispatch) => {

      dispatch(cambiarEstadoStore());

      try {
         await clienteAxios.patch(`/stores/state/${id}`);

         dispatch(cambiarEstadoStoreExito(id));
         //Swal.fire("Correcto", "Se cambio el estado correctamente", "success");
      } catch (error) {
         dispatch(cambiarEstadoStoreError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const cambiarEstadoStore = () => ({
   type: STORE_ESTADO_COMENZAR
})

const cambiarEstadoStoreExito = id => ({
   type: STORE_ESTADO_EXITO,
   payload: id
})

const cambiarEstadoStoreError = () => ({
   type: STORE_ESTADO_ERROR,
   payload: true
})
