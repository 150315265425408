import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalTitle } from "react-bootstrap";

import MemoItem from "./MemoItem";
import ViewMemo from "./ViewMemo";

const ListadoMemo = () => {
   const memos = useSelector((state) => state.memo.memos);

   const [showModal, setShowModal] = useState(false);
   const [currentMemo, setCurrentMemo] = useState({});

   const handleCloseModal = () => {
      setShowModal(false);
   };

   const handleOpenModal = (memo) => {
      setShowModal(true);
      setCurrentMemo(memo);
   };

   const onHandleChange = (id) => {
      //dispatch(cambiarEstadoProductAction(id));
   };

   return (
      <div className="container-fluid">
         <div className="container-fluid mt-md-4 p-0 mt-5 pt-5">
            <div className="col-12">
               <h2 className="titulo text-center mb-5">
                  Resultado de la búsqueda
               </h2>
            </div>
         </div>
         <div className="container">
            <div className="row pt-2">
               {memos &&
                  memos.map((memo) => (
                     <MemoItem
                        key={memo.id}
                        memo={memo}
                        onHandleChange={onHandleChange}
                        handleOpenModal={handleOpenModal}
                     />
                  ))}
            </div>
         </div>

         <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <div className="modal-content">
               <Modal.Header closeButton>
                  <ModalTitle>
                     <h3 className="titulo text-center p-3">Ver memorandum</h3>
                  </ModalTitle>
               </Modal.Header>
               <ViewMemo
                  currentMemo={currentMemo}
                  handleCloseModal={handleCloseModal}
               />
            </div>
         </Modal>
      </div>
   );
};

export default ListadoMemo;
