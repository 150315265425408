import React, { useState } from "react";
import { Modal, ModalTitle } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { cambiarEstadoCampaingAction } from "../../actions/campaingActions";

import EditarCampaing from "./EditarCampaing";
import CampaingItem from "./CampaingItem";

const ListadoCampaing = () => {
   const dispatch = useDispatch();

   const campaings = useSelector((state) => state.campaing.campaings);
   const user = useSelector((state) => state.auth.user);

   const [showModal, setShowModal] = useState(false);
   const [currentCampaing, setCurrentCampaing] = useState({});

   const handleCloseModal = () => {
      setShowModal(false);
   };

   const handleOpenModal = (campaing) => {
      setShowModal(true);
      setCurrentCampaing(campaing);
   };

   const onHandleChange = (id) => {
      dispatch(cambiarEstadoCampaingAction(id));
   };

   return (
      <div className="container-fluid p-0">
         <div className="cajaTable ">
            <div className="resultadoTable d-none">
               <p className="txtResultado text-center">
                  No hay resultado de búsqueda
               </p>
            </div>
            <div className="cabeceraTable2 cuperoTable3 mt-3 mb-100">
               <table>
                  <thead>
                     <tr>

                        <th>Capacitación</th>
                        <th>Inicio</th>
                        <th>Fin</th>
                        {user.role === '05' ? (null) : (<th>Estado</th>)}
                        <th>Documento</th>
                        <th>Acción</th>
                     </tr>
                  </thead>
                  <tbody>
                     {campaings &&
                        campaings.map((campaing) => (
                           <CampaingItem key={campaing.id} campaing={campaing} onHandleChange={onHandleChange} handleOpenModal={handleOpenModal}/>
                        ))}
                  </tbody>
               </table>
            </div>
         </div>

         <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
               <ModalTitle>
                  <h3 className="titulo text-center p-3">Editar capacitación</h3>
               </ModalTitle>
            </Modal.Header>
            <EditarCampaing
               currentCampaing={currentCampaing}
               handleCloseModal={handleCloseModal}
            />
         </Modal>
      </div>
   );
};

export default ListadoCampaing;
