import React from "react";

const ProductItem = ({ product }) => {
   return (
      <tr>
         <td>{product.title}</td>
         <td>{product.size}</td>
         <td>{product.price}</td>
         <td>{product.season}</td>
         <td>{product.createdAt}</td>
      </tr>
   );
};

export default ProductItem;
