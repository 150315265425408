import React, { Fragment, useState } from "react";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";

const override = css`
   display: block;
   margin: 0 auto;
   border-color: red;
`;

const Loading = ({ loading }) => {
   const [color, setColor] = useState("#144280");

   return (
      <Fragment>
         {loading ? (
            <div className={loading ? "parentDisable" : ""} width="100%">
               <div className="overlay-box">
                  <HashLoader
                     color={color}
                     loading={loading}
                     css={override}
                     size={150}
                  />
               </div>
            </div>
         ) : null}
      </Fragment>
   );
};

export default Loading;
