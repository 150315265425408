import {
   PRODUCT_AGREGAR_COMENZAR,
   PRODUCT_AGREGAR_EXITO, 
   PRODUCT_AGREGAR_ERROR, 
   PRODUCT_DESCARGA_COMENZAR, 
   PRODUCT_DESCARGA_EXITO, 
   PRODUCT_DESCARGA_ERROR, 
   PRODUCT_OBTENER_COMENZAR,
   PRODUCT_OBTENER_EXITO,
   PRODUCT_OBTENER_ERROR,
   PRODUCT_EDITAR_COMENZAR,
   PRODUCT_EDITAR_EXITO,
   PRODUCT_EDITAR_ERROR,
   PRODUCT_ESTADO_COMENZAR,
   PRODUCT_ESTADO_EXITO,
   PRODUCT_ESTADO_ERROR,
   PRODUCT_REPORTE_COMENZAR, 
   PRODUCT_REPORTE_EXITO, 
   PRODUCT_REPORTE_ERROR, 
} from '../types';

const initialState = {
   products: [],
   size: 0,
   report: [],
   currentProduct: null,
   loading: null
};

export default function(state = initialState, action) {
   switch(action.type) {
      case PRODUCT_AGREGAR_COMENZAR:
      case PRODUCT_EDITAR_COMENZAR:
      case PRODUCT_OBTENER_COMENZAR:
      case PRODUCT_ESTADO_COMENZAR:
         return {
            ...state,
            currentProduct: null,
            loading: true
         }
      case PRODUCT_AGREGAR_EXITO:
         return {
            ...state,
            products: [...state.products, action.payload],
            currentProduct: action.payload,
            loading: false
         }
      case PRODUCT_OBTENER_EXITO:
         return {
            ...state,
            currentProduct: action.payload,
            loading: false
         }
      case PRODUCT_EDITAR_EXITO:
         const currentProduct = action.payload;
         let productModify = state.products.find(product => product.id === currentProduct.id);
         productModify.title = currentProduct.title;
         productModify.description = currentProduct.description;
         productModify.size = currentProduct.size;
         productModify.price = currentProduct.price;
         productModify.state = currentProduct.state;
         productModify.image = currentProduct.image;
         
         return {
            ...state,
            products: state.products.filter(product => product.id === productModify.id ? productModify : product),
            loading: false
         }
      case PRODUCT_ESTADO_EXITO:
         const idSchedule = action.payload;
         let productState = state.products.find(product => product.id === idSchedule);
         productState.state = !productState.state
            
         return {
            ...state,
            products: state.products.filter(product => product.id === productState.id ? productState : product),
            loading: false
         }
      case PRODUCT_AGREGAR_ERROR:
      case PRODUCT_EDITAR_ERROR:
      case PRODUCT_OBTENER_ERROR:
      case PRODUCT_ESTADO_ERROR:
         return {
            ...state,
            currentProduct: null,
            loading: false
         }
      case PRODUCT_DESCARGA_COMENZAR:
      case PRODUCT_REPORTE_COMENZAR:
         return {
            ...state,
            products: [],
            report: [],
            loading: true
         }
      case PRODUCT_DESCARGA_EXITO:
         return {
            ...state,
            products: action.payload.rows,
            size: Math.ceil(action.payload.count / action.payload.limit),
            loading: false
         }
      case PRODUCT_REPORTE_EXITO:
         return {
            ...state,
            report: action.payload,
            loading: false
         }
      case PRODUCT_DESCARGA_ERROR:
      case PRODUCT_REPORTE_ERROR:
         return {
            ...state,
            products: [],
            report: [],
            size: 0,
            loading: false
         }
      default:
         return state;
   }
}