import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from '@mui/material/Pagination';

import { reportePersonalAction } from "../../../actions/personalActions";
import PersonalItem from "./PersonalItem";

const ListadoPersonal = ({ data, setData, type, setType }) => {
   const dispatch = useDispatch();

   const personales = useSelector((state) => state.personal.reportWeb);
   const personalesFull = useSelector((state) => state.personal.reportFull);
   const size = useSelector((state) => state.personal.size);

   const { limit, offset } = data;


   const onChangePage = (e, page) => {
      const offset = 0 + (page - 1) * limit;

      setData({
         ...data,
         'offset': offset
      });

      const dataObject = {
         ...data,
         type: '1',
         offset: offset
      };

      dispatch(reportePersonalAction(dataObject, offset));
    }

    const changeValuePage = (e) => {
      setData({
         ...data,
         [e.target.name]: e.target.value
      });

      const dataObject = {
         ...data,
         limit: Number(e.target.value),
         type: '1',
      };
  
      dispatch(reportePersonalAction(dataObject, offset));
    }

   return (
      <div className="container-fluid p-0">
         <div className="cajaTable ">
            <div className="resultadoTable d-none">
               <p className="txtResultado text-center">
                  No hay resultado de búsqueda
               </p>
            </div>
            <div class="container-fluid imputsCamposMostrar">
               <div class="row mt-lg-3">
                  <label  htmlFor="limit" className="mr-3 mt-1">Mostrar resultados:</label>
                  <select name='limit' onChange={changeValuePage}>
                     <option value="10">10</option>
                     <option value="15">15</option>
                     <option value="25">25</option>
                     <option value="50">50</option>
                     <option value="100">100</option> 
                  </select> 
               </div>
         </div>
            <div className="cabeceraTable2 cuperoTable3 mt-3 mb-3">
               <table>
                  <thead>
                     <tr>
                        <th>Código</th>
                        <th>Nombres y Apellidos</th>
                        <th>Tipo Documento</th>
                        <th>Número de Documento</th>
                        <th>Celular</th>
                        <th>Email</th>
                        <th>Tienda</th>
                        <th>Puesto</th>
                     </tr>
                  </thead>
                  <tbody>
                     {personales &&
                        personales.map((personal) => (
                           <PersonalItem key={personal.id} personal={personal}/>
                        ))}
                  </tbody>
               </table>
            </div>
            <Pagination count={size} onChange={onChangePage} />
         </div>

         <div style={{visibility: 'hidden'}}>
         <div id="tablaReportePersonal" className="container-fluid p-0">
         <div className="cajaTable ">
            <div className="resultadoTable d-none">
               <p className="txtResultado text-center">
                  No hay resultado de búsqueda
               </p>
            </div>
            <div class="container-fluid imputsCamposMostrar">
         </div>
            <div className="cabeceraTable2 cuperoTable30 mt-3 mb-3">
               <table>
                  <thead>
                     <tr>
                     <th>Código</th>
                        <th>Nombres y Apellidos</th>
                        <th>Tipo Documento</th>
                        <th>Número de Documento</th>
                        <th>Celular</th>
                        <th>Email</th>
                        <th>Tienda</th>
                        <th>Puesto</th>
                     </tr>
                  </thead>
                  <tbody>
                     {personalesFull &&
                        personalesFull.map((personal) => (
                           <PersonalItem key={personal.id} personal={personal}/>
                        ))}
                  </tbody>
               </table>
            </div>
         </div>
         </div>
         </div>
      </div>
   );
};

export default ListadoPersonal;
