import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Busqueda from "../components/reports/ticket/Busqueda";
import ListadoTicket from "../components/reports/ticket/ListadoTicket";
import TituloTabla from "../components/reports/ticket/TituloTabla";
import Loading from "../components/layout/Loading";
import { obtenerYearAction } from "../actions/ticketActions";

const ReportTicket = () => {
   const dispatch = useDispatch();

   const loading = useSelector((state) => state.ticket.loading);

   useEffect(() => {
      dispatch(obtenerYearAction());
   }, []);

   return (
      <Fragment>
         <Header title={"REPORTE DE BOLETAS"}/>
         <Loading loading={loading} />
         <Busqueda />
         <TituloTabla />
         <ListadoTicket />
         <Footer/>
      </Fragment>
   );
};

export default ReportTicket;
