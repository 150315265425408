import {
   PROFILE_DESCARGA_COMENZAR, 
   PROFILE_DESCARGA_EXITO, 
   PROFILE_DESCARGA_ERROR, 
   PROFILE_OBTENER_COMENZAR,
   PROFILE_OBTENER_EXITO,
   PROFILE_OBTENER_ERROR,
   PROFILE_EDITAR_COMENZAR,
   PROFILE_EDITAR_EXITO,
   PROFILE_EDITAR_ERROR,
} from '../types';

const initialState = {
   roles: [],
   menus: [],
   currentRole: null,
   loading: null,
   error: null
};

export default function(state = initialState, action) {
   switch(action.type) {
      case PROFILE_DESCARGA_COMENZAR:
         return {
            ...state,
            currentRole: null,
            roles: [],
            menus: [],
            loading: true
         }
      case PROFILE_EDITAR_COMENZAR:
      case PROFILE_OBTENER_COMENZAR:
         return {
            ...state,
            loading: true
         }
      case PROFILE_OBTENER_EXITO:
         return {
            ...state,
            menus: action.payload,
            loading: false
         }
      case PROFILE_EDITAR_EXITO:
         const currentProfile = action.payload;
         //let profileModify = state.menus.find(menu => menu.id === currentProfile.id);
         
         return {
            ...state,
            //menus: state.menus.filter(menu => menu.id === profileModify.id ? profileModify : menu),
            loading: false
         }
      case PROFILE_EDITAR_ERROR:
         return {
            ...state,
            error: action.payload,
            loading: false
         }
      case PROFILE_OBTENER_ERROR:
         return {
            ...state,
            error: action.payload,
            menus: [],
            loading: false
         }
      case PROFILE_DESCARGA_EXITO:
         return {
            ...state,
            roles: action.payload,
            loading: false
         }
      case PROFILE_DESCARGA_ERROR:
         return {
            ...state,
            roles: [],
            error: action.payload,
            loading: false
         }
      default:
         return state;
   }
}