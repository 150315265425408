import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { obtenerScheduleAction } from "./../../actions/scheduleActions";

const Personal = ({idPersonal, onHandlePersonal}) => {
   const dispatch = useDispatch();

   const personals = useSelector((state) => state.personal.personalsActivos);
   let optionsPersonal = [];
   if (personals) {
      personals.map(personal => optionsPersonal.push({
         value: personal.id,
         label: personal.name + ' ' + personal.lastName
      }));
   }

   const onChangePersonal = (e) => {
      const target = e.target;

      onHandlePersonal(target.value);
      dispatch(obtenerScheduleAction(target.value));
   };

   const onHandleBuscarSubmit = (e) => {
      e.preventDefault();

      obtenerScheduleAction(idPersonal);
   };

   useEffect(() => {
      dispatch(obtenerScheduleAction(idPersonal));
   }, [idPersonal])

   return (
      <div className="row pl-3 pr-3 pt-0">
         <div className="container">
            <form onSubmit={onHandleBuscarSubmit}>
               <div className="btnsOptions ml-lg-3 mt-md-3 mt-xl-0  align-items-center">
                  <div className="row imputsCampos2">
                     <div className="col-12 col-lg-10 offset-lg-4">
                        <div className="row">
                           <h2 className="subTituloInterno pt-4 pb-2 pt-lg-5 pb-lg-5">
                              Seleccionar filtro:
                           </h2>
                        </div>
                        <div className="row">
                           <label htmlFor="title" className="labelFecha-1">
                              Personal:
                           </label>
                           <select
                              className="btn btnInputsCombos dropdown-toggle"
                              id="idPersonal"
                              name="idPersonal"
                              value={idPersonal}
                              onChange={onChangePersonal}
                              required
                           >
                              <option value="">-- Seleccionar --</option>
                              {optionsPersonal &&
                                 optionsPersonal.map((person) => (
                                    <option value={person.value} key={person.value}>{person.label}</option>
                                 ))}
                           </select>
                        </div>
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
   );
};

export default Personal;
