import {
   POLICY_AGREGAR_COMENZAR,
   POLICY_AGREGAR_EXITO, 
   POLICY_AGREGAR_ERROR, 
   POLICY_DESCARGA_COMENZAR, 
   POLICY_DESCARGA_EXITO, 
   POLICY_DESCARGA_ERROR, 
   POLICY_OBTENER_COMENZAR,
   POLICY_OBTENER_EXITO,
   POLICY_OBTENER_ERROR,
   POLICY_EDITAR_COMENZAR,
   POLICY_EDITAR_EXITO,
   POLICY_EDITAR_ERROR,
   POLICY_ESTADO_COMENZAR,
   POLICY_ESTADO_EXITO,
   POLICY_ESTADO_ERROR,
} from '../types';

const initialState = {
   policies: [],
   size: 0,
   currentPolicy: null,
   loading: null
};

export default function(state = initialState, action) {
   switch(action.type) {
      case POLICY_AGREGAR_COMENZAR:
      case POLICY_EDITAR_COMENZAR:
      case POLICY_OBTENER_COMENZAR:
      case POLICY_ESTADO_COMENZAR:
         return {
            ...state,
            currentPolicy: null,
            loading: true
         }
      case POLICY_OBTENER_EXITO:
         return {
            ...state,
            currentPolicy: action.payload,
            loading: false
         }
      case POLICY_AGREGAR_EXITO:
         return {
            ...state,
            policies: [...state.policies, action.payload],
            loading: false
         }
      case POLICY_EDITAR_EXITO:
         const currentPolicy = action.payload;
         let policyModify = state.policies.find(policy => policy.id === currentPolicy.id);
         policyModify.description = currentPolicy.description;
         policyModify.url = currentPolicy.url;
         policyModify.state = currentPolicy.state;
         
         return {
            ...state,
            policies: state.policies.filter(policy => policy.id === policyModify.id ? policyModify : policy),
            loading: false
         }
      case POLICY_ESTADO_EXITO:
         const idSchedule = action.payload;
         let policyState = state.policies.find(policy => policy.id === idSchedule);
         policyState.state = !policyState.state
            
         return {
            ...state,
            policies: state.policies.filter(policy => policy.id === policyState.id ? policyState : policy),
            loading: false
         }
      case POLICY_AGREGAR_ERROR:
      case POLICY_EDITAR_ERROR:
      case POLICY_OBTENER_ERROR:
      case POLICY_ESTADO_ERROR:
         return {
            ...state,
            currentPolicy: null,
            loading: false
         }
      case POLICY_DESCARGA_COMENZAR:
         return {
            ...state,
            policies: [],
            size: 0,
            loading: true
         }
      case POLICY_DESCARGA_EXITO:
         return {
            ...state,
            policies: action.payload.rows,
            size: Math.ceil(action.payload.count / action.payload.limit),
            loading: false
         }
      case POLICY_DESCARGA_ERROR:
         return {
            ...state,
            policies: [],
            size: 0,
            loading: false
         }
      default:
         return state;
   }
}