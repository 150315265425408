import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Busqueda from "../components/reports/checklist/Busqueda";
import ListadoChecklist from "../components/reports/checklist/ListadoChecklist";
import TituloTabla from "../components/reports/checklist/TituloTabla";
import Loading from "../components/layout/Loading";

import { obtenerPersonalActivosAction } from "../actions/personalActions";
import { obtenerStoreActivosAction } from "../actions/storeActions";

const ReportChecklist = () => {
   const dispatch = useDispatch();

   const loading = useSelector((state) => state.checklist.loading);

   const [startDate, setStartDate] = useState("");
   const [endDate, setEndDate] = useState("");
   const [type, setType] = useState("");

   const [data, setData] = useState({
      personalId: "",
      storeId: "",
      limit: 10,
      offset: 0
   });

   useEffect(() => {
      dispatch(obtenerStoreActivosAction());
      dispatch(obtenerPersonalActivosAction());
   }, []);

   return (
      <Fragment>
         <Header title={"REPORTE DE ENCUESTAS"}/>
         <Loading loading={loading} />
         <Busqueda data={data} setData={setData} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
         <TituloTabla data={data} startDate={startDate} endDate={endDate} type={type} setType={setType} />
         <ListadoChecklist data={data} setData={setData} startDate={startDate} endDate={endDate} type={type} setType={setType} />
         <Footer/>
      </Fragment>
   );
};

export default ReportChecklist;
