import {
   STORE_AGREGAR_COMENZAR,
   STORE_AGREGAR_EXITO, 
   STORE_AGREGAR_ERROR, 
   STORE_DESCARGA_COMENZAR, 
   STORE_DESCARGA_EXITO, 
   STORE_DESCARGA_ERROR, 
   STORE_ACTIVOS_DESCARGA_COMENZAR, 
   STORE_ACTIVOS_DESCARGA_EXITO, 
   STORE_ACTIVOS_DESCARGA_ERROR, 
   STORE_OBTENER_COMENZAR,
   STORE_OBTENER_EXITO,
   STORE_OBTENER_ERROR,
   STORE_EDITAR_COMENZAR,
   STORE_EDITAR_EXITO,
   STORE_EDITAR_ERROR,
   STORE_ESTADO_COMENZAR,
   STORE_ESTADO_EXITO,
   STORE_ESTADO_ERROR,
   STORE_MANAGERS_DESCARGA_COMENZAR,
   STORE_MANAGERS_DESCARGA_EXITO,
   STORE_MANAGERS_DESCARGA_ERROR
} from '../types';

const initialState = {
   stores: [],
   size: 0,
   storesActivos: [],
   managers: [],
   currentStore: null,
   loading: null
};

export default function(state = initialState, action) {
   switch(action.type) {
      case STORE_AGREGAR_COMENZAR:
      case STORE_EDITAR_COMENZAR:
      case STORE_OBTENER_COMENZAR:
      case STORE_ESTADO_COMENZAR:
         return {
            ...state,
            currentStore: null,
            loading: true
         }
      case STORE_OBTENER_EXITO:
         return {
            ...state,
            currentStore: action.payload,
            loading: false
         }
      case STORE_AGREGAR_EXITO:
         return {
            ...state,
            stores: [...state.stores, action.payload],
            loading: false
         }
      case STORE_EDITAR_EXITO:
         const currentStore = action.payload;
         let storeModify = state.stores.find(store => store.id === currentStore.id);
         const collaborators = storeModify.collaborators.filter(collaborator => collaborator.PersonalStore.personalId === currentStore.inChargeId ? (
            {
               id: collaborator.id,
               name: collaborator.name,
               lastName: collaborator.lastname,
               typeDocument: collaborator.typeDocument,
               numDocument: collaborator.numDocument,
               userId: collaborator.userId,
               PersonalStore: {
                  id: collaborator.PersonalStore.id,
                  personalId: collaborator.PersonalStore.personalId,
                  storeId: collaborator.PersonalStore.storeId,
                  inCharge: true
               }
            }
          ) : {
            id: collaborator.id,
            name: collaborator.name,
            lastName: collaborator.lastname,
            typeDocument: collaborator.typeDocument,
            numDocument: collaborator.numDocument,
            userId: collaborator.userId,
            PersonalStore: {
               id: collaborator.PersonalStore.id,
               personalId: collaborator.PersonalStore.personalId,
               storeId: collaborator.PersonalStore.storeId,
               inCharge: false
            }
          });
         storeModify.code = currentStore.code;
         storeModify.description = currentStore.description;
         storeModify.address = currentStore.address;
         storeModify.mallId = currentStore.mallId;
         storeModify.serie_number = currentStore.serie_number;
         storeModify.email = currentStore.email;
         storeModify.phone = currentStore.phone;
         storeModify.rpm = currentStore.rpm;
         storeModify.state = currentStore.state === "1" ? true : false;
         storeModify.cityId = currentStore.cityId;
         storeModify.districtId = currentStore.districtId;
         storeModify.collaborators = collaborators;
         if (storeModify.mall) {
            storeModify.mall.id = currentStore.mall.id;
            storeModify.mall.description = currentStore.mall.description;
         }
         if (storeModify.city) {
            storeModify.city.id = currentStore.city.id;
            storeModify.city.description = currentStore.city.description;
         }
         if (storeModify.district) {
            storeModify.district.id = currentStore.district.id;
            storeModify.district.description = currentStore.district.description;
         }
         
         return {
            ...state,
            stores: state.stores.filter(store => store.id === storeModify.id ? storeModify : store),
            loading: false
         }
      case STORE_ESTADO_EXITO:
         const idStore = action.payload;
         let storeState = state.stores.find(store => store.id === idStore);
         storeState.state = !storeState.state
            
         return {
            ...state,
            stores: state.stores.filter(store => store.id === storeState.id ? storeState : store),
            loading: false
         }
      case STORE_AGREGAR_ERROR:
      case STORE_EDITAR_ERROR:
      case STORE_OBTENER_ERROR:
      case STORE_ESTADO_ERROR:
         return {
            ...state,
            currentStore: null,
            loading: false
         }
      case STORE_DESCARGA_COMENZAR:
         return {
            ...state,
            stores: [],
            loading: true
         }
      case STORE_DESCARGA_EXITO:
         return {
            ...state,
            stores: action.payload.rows,
            size: Math.ceil(action.payload.count / action.payload.limit),
            loading: false
         }
      case STORE_DESCARGA_ERROR:
         return {
            ...state,
            stores: [],
            loading: false
         }
      case STORE_ACTIVOS_DESCARGA_COMENZAR:
         return {
            ...state,
            storesActivos: [],
            loading: true
         }
      case STORE_ACTIVOS_DESCARGA_EXITO:
         return {
            ...state,
            storesActivos: action.payload,
            loading: false
         }
      case STORE_ACTIVOS_DESCARGA_ERROR:
         return {
            ...state,
            storesActivos: [],
            loading: false
            }
      case STORE_MANAGERS_DESCARGA_COMENZAR:
         return {
            ...state,
            managers: [],
            loading: false
         }
      case STORE_MANAGERS_DESCARGA_EXITO:
         return {
            ...state,
            managers: action.payload,
            loading: false
         }
      case STORE_MANAGERS_DESCARGA_ERROR:
         return {
            ...state,
            managers: [],
            loading: false
         }
      default:
         return state;
   }
}