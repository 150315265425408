import {
   CITY_DESCARGA_COMENZAR,
   CITY_DESCARGA_EXITO,
   CITY_DESCARGA_ERROR,
   DISTRICT_DESCARGA_COMENZAR,
   DISTRICT_DESCARGA_EXITO,
   DISTRICT_DESCARGA_ERROR
} from "../types";
import clienteAxios from "../config/axios";

// ---------- LISTAR CIUDAD ----------
export function listarCiudadesAction() {
   return async (dispatch) => {
      dispatch(descargarCity());

      try {
         const resultado = await clienteAxios.get('/cities');

         dispatch(descargarCityExito(resultado.data))
      } catch (error) {
         dispatch(descargarCityError());
      }
   }
}

const descargarCity = () => ({
   type: CITY_DESCARGA_COMENZAR,
   payload: true
})

const descargarCityExito = data => ({
   type: CITY_DESCARGA_EXITO,
   payload: data
})

const descargarCityError = () => ({
   type: CITY_DESCARGA_ERROR,
   payload: true
})

// ---------- LISTAR DISTRITOS ----------
export function listarDistritosAction(cityId) {
   return async (dispatch) => {
      dispatch(descargarDistrict());

      try {
         const resultado = await clienteAxios.get(`/districts?cityId=${cityId}`);

         dispatch(descargarDistrictExito(resultado.data))
      } catch (error) {
         dispatch(descargarDistrictError());
      }
   }
}

const descargarDistrict = () => ({
   type: DISTRICT_DESCARGA_COMENZAR,
   payload: true
})

const descargarDistrictExito = data => ({
   type: DISTRICT_DESCARGA_EXITO,
   payload: data
})

const descargarDistrictError = () => ({
   type: DISTRICT_DESCARGA_ERROR,
   payload: true
})