import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actualizarProfileAction } from "../../actions/profileActions";

const ListProfile = ({ idRole }) => {
   const dispatch = useDispatch();

   const menus = useSelector((state) => state.profile.menus);
   const [listMenu, setListMenu] = useState([]);

   const actualizarProfile = (data) => dispatch(actualizarProfileAction(data));

   const onChangeRegistrar = (e) => {
      const target = e.target;
      const id = parseInt(target.name);
      var value = target.value;

      const cloneMenu = listMenu.map((menu) =>
         menu.id === id
            ? {
                 id: menu.id,
                 description: menu.description,
                 url: menu.url,
                 module: menu.module,
                 selected: !menu.selected,
              }
            : menu
      );

      setListMenu(cloneMenu);
   };

   const onSubmitActualizar = (e) => {
      e.preventDefault();

      const parametros = listMenu.map((menu) =>
         menu.selected ? menu.id : null
      );
      const final = parametros.filter((n) => n);

      actualizarProfile({
         roleId: idRole,
         data: final,
      });
   };

   useEffect(() => {
      setListMenu(menus);
   }, [menus]);

   return (
      <div className="container-fluid p-0">
         <div className="cajaTable ">
            {listMenu && listMenu.length > 0 && idRole !== '' ? (
               <form className="pt-4 " onSubmit={onSubmitActualizar}>
                  <div className="card">
                     <div className="card-body">
                        {listMenu.map((menu, index) => (
                           <div
                              key={menu.id}
                              className="form-check form-switch"
                           >
                              <label className="toggler-wrapper style-22">
                                 <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={menu.id}
                                    name={menu.id}
                                    value={menu.id}
                                    onChange={onChangeRegistrar}
                                    checked={menu.selected}
                                 />
                                 <div className="toggler-slider">
                                    <div className="toggler-knob"></div>
                                 </div>
                              </label>
                              &nbsp;
                              <label
                                 className="form-check-label"
                                 htmlFor={menu.id}
                              >
                                 {menu.description}
                              </label>
                           </div>
                        ))}
                     </div>
                  </div>

                  <div className="row offset-0 offset-lg-9 offset-xl-10 col-lg-3 col-xl-2 pl-0 pr-0 pl-lg-4 pr-lg-0 pl-xl-3 pr-xl-3 padding-Registro">
                     <div className="form-group col-12  pl-0 pr-0 pl-lg-0 pr-lg-0 pl-xl-3 pr-xl-3">
                        <button
                           className="btn-grisDark text-white btn-Solidez mt-4"
                           type="submit"
                        >
                           Guardar
                        </button>
                     </div>
                  </div>
               </form>
            ) : (
               <div>
                  <hr/>
               <center><p>Debe de seleccionar un rol</p></center>
               </div>
            )}
         </div>
      </div>
   );
};

export default ListProfile;
