import React from "react";

const MemoItem = ({ memo }) => {
   return (
      <tr>
         <td>{memo.description}</td>
         <td>{memo.period}</td>
         <td>{memo.person}</td>
         <td>{memo.typeDocument}</td>
         <td>{memo.numDocument}</td>
         <td>{memo.signing}</td>
         <td>{memo.createdAt}</td>
      </tr>
   );
};

export default MemoItem;
