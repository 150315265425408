import React, { useState, Fragment } from "react";
import { Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

import { actualizarCampaingAction } from "../../actions/campaingActions";

import "react-datepicker/dist/react-datepicker.css";

registerLocale("es", es);

const EditarCampaing = ({ currentCampaing, handleCloseModal }) => {
   const dispatch = useDispatch();

   const user = useSelector((state) => state.auth.user);

   const [yearStart, monthStart, dayStart] = currentCampaing.startDate.split('-');
   const [yearEnd, monthEnd, dayEnd] = currentCampaing.endDate.split('-');

   const [startDate, setStartDate] = useState(new Date(yearStart, monthStart - 1, dayStart));
   const [endDate, setEndDate] = useState(new Date(yearEnd, monthEnd - 1, dayEnd));
   const [pdf, setPdf] = useState({ data: "" });
   const [data, onHandleData] = useState({
      title: currentCampaing.title,
      description: currentCampaing.description,
      state: currentCampaing.state ? "1" : "0",
   });

   const { title, description, state } = data;

   const disabled = user.role === '05' ? true : false;

   const onChangeData = (e) => {
      if (
         e.target.name === "title" ||
         e.target.name === "description"
      ) {
         onHandleData({
            ...data,
            [e.target.name]: e.target.value,
         });
      } else {
         if (e.target.name === "pdf") {
            const pdf = {
               data: e.target.files[0],
            };
            setPdf(pdf);
         }
      }
   };

   const onHandleSubmit = (e) => {
      e.preventDefault();
      const formData = new FormData();

      const json = JSON.stringify({
         title,
         description,
         state,
         startDate: startDate.toISOString().slice(0, 10),
         endDate: endDate.toISOString().slice(0, 10),
      });

      formData.append("json", json);
      formData.append("file", pdf.data);

      dispatch(
         actualizarCampaingAction(
            currentCampaing.id,
            { ...data },
            formData
         )
      );

      e.target.reset();
      handleCloseModal();
   };

   return (
      <Fragment>
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="title" className="c-grisDark mb-0">
                           Capacitación:
                        </label>
                        <input
                           type="text"
                           name="title"
                           className="form-control"
                           placeholder="Ingrese el nombre de la campaña"
                           value={title}
                           onChange={onChangeData}
                           disabled={disabled}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-12">
                        <label
                           htmlFor="description"
                           className="c-grisDark mb-0"
                        >
                           Descripción:
                        </label>
                        <textarea
                           name="description"
                           className="form-control"
                           placeholder="Ingrese la descripción"
                           value={description}
                           onChange={onChangeData}
                           disabled={disabled}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-6">
                        <label htmlFor="startDate" className="c-grisDark mb-0">
                           Fecha Inicio:
                        </label>
                        <DatePicker
                           name="startDate"
                           dateFormat="dd/MM/yyyy"
                           locale="es"
                           selected={startDate}
                           isClearable
                           placeholderText="DD/MM/AAAA"
                           value={startDate}
                           onChange={(date) => setStartDate(date)}
                           disabled={disabled}
                           autoComplete="off"
                        />
                     </div>
                     <div className="form-group col-12 col-md-6">
                        <label htmlFor="endDate" className="c-grisDark mb-0">
                           Fecha Fin:
                        </label>
                        <DatePicker
                           name="endDate"
                           dateFormat="dd/MM/yyyy"
                           locale="es"
                           selected={endDate}
                           isClearable
                           placeholderText="DD/MM/AAAA"
                           value={endDate}
                           onChange={(date) => setEndDate(date)}
                           disabled={disabled}
                           autoComplete="off"
                        />
                     </div>
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="pdf" className="c-grisDark mb-0">
                           PDF:
                        </label>
                        <input
                           type="file"
                           name="pdf"
                           className="form-control"
                           placeholder="Seleccione un PDF"
                           disabled={disabled}
                           onChange={onChangeData}
                        />
                     </div>
                  </div>
                  <div className="row">
                     <div className="form-group imputsCampos2 col-12 col-md-6 mt-0">
                        <label
                           htmlFor="state"
                           className="labelCliente mb-0 pt-0"
                        >
                           Estado:
                        </label>
                        <select
                           name="state"
                           className="w-100"
                           value={state}
                           onChange={onChangeData}
                           disabled={disabled}
                           required
                        >
                           <option value="1">Activo</option>
                           <option value="0">Inactivo</option>
                        </select>
                     </div>
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               {disabled ? (null) : (
                  <button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="submit"
               >
                  Guardar
               </button>
               )}
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default EditarCampaing;
