import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form } from "react-bootstrap";
import SurveyTitle from "./SurveyTitle";
import SurveyQuestion from "./SurveyQuestion";
import { useInputValue } from "../../hooks";
import Question from "../../models/Question";
import ListController from "../../controllers/ListController";

import { crearChecklistAction } from '../../actions/checklistActions';

import styles from "../../css/checklist.css";

export default function SurveyBuilder({ handleCloseModal }) {
   const dispatch = useDispatch();

   const [title, handleChangeTitle] = useInputValue("Nuevo Checklist");
   const [questions, setQuestions] = useState([
      new Question({
         text: "Modifique esta pregunta?",
         options: ["Respuesta 1", "Respuesta 2", "Respuesta 4", "Respuesta 5"],
      }),
   ]);

   const listController = new ListController(questions, setQuestions);

   const onHandleSubmit = (e) => {
      e.preventDefault();

      dispatch(crearChecklistAction({description: title, questions: listController.array}));

      e.target.reset();
      handleCloseModal();
   };

   return (
      <div id="modal-checklist" className={styles.container}>
         <SurveyTitle title={title} handleChangeTitle={handleChangeTitle} />
         <Form onSubmit={onHandleSubmit}>
            <ol>
               {questions.map((question, i) => (
                  <SurveyQuestion
                     key={question.id}
                     question={question}
                     setQuestion={(question) => listController.set(i, question)}
                     removeQuestion={() => listController.remove(i)}
                     moveQuestionUp={() => listController.moveUp(i)}
                     moveQuestionDown={() => listController.moveDown(i)}
                  />
               ))}
            </ol>
            <button
               type="button"
               onClick={() => listController.add(new Question())}
               className={styles.btnChecklist}
            >
               <i className="fas fa-plus icon" /> Agregar Pregunta
            </button>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               <button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="submit"
               >
                  Aceptar
               </button>
            </Modal.Footer>
         </Form>
      </div>
   );
}
