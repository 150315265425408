import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from '@mui/material/Pagination';

import ChecklistItem from "./ChecklistItem";

import { reporteChecklistAction } from "../../../actions/checklistActions";

const ListadoCampaing = ({ data, setData, startDate, endDate, type, setType }) => {
   const dispatch = useDispatch();
  
   const checklists = useSelector((state) => state.checklist.reportWeb);
   const checklistsFull = useSelector((state) => state.checklist.reportFull);
   const size = useSelector((state) => state.checklist.size);

   const { limit, offset } = data;

   const onChangePage = (e, page) => {
      const offset = 0 + (page - 1) * limit;

      setData({
         ...data,
         'offset': offset
      });

      const dataObject = {
         personalId: data.personalId,
         storeId: data.storeId,
         startDate,
         endDate,
         type: '1',
         limit: data.limit
      };
  
      dispatch(reporteChecklistAction(dataObject, offset));
    }

    const changeValuePage = (e) => {
      setData({
         ...data,
         [e.target.name]: e.target.value
      });

      const dataObject = {
         personalId: data.personalId,
         storeId: data.storeId,
         startDate,
         endDate,
         type: '1',
         limit: Number(e.target.value)
      };
  
      dispatch(reporteChecklistAction(dataObject, offset));
    }

   return (
      <Fragment>
      <div className="container-fluid p-0">
         <div className="col-12">
               <h2 className="titulo text-center">Reporte de Checklist</h2>
            </div>
         <div className="cajaTable ">
            <div className="resultadoTable d-none">
               <p className="txtResultado text-center">
                  No hay resultado de búsqueda
               </p>
            </div>
            <div class="container-fluid imputsCamposMostrar">
               <div class="row mt-lg-3">
                  <label  htmlFor="limit" className="mr-3 mt-1">Mostrar resultados:</label>
                  <select name='limit' onChange={changeValuePage}>
                     <option value="10">10</option>
                     <option value="15">15</option>
                     <option value="25">25</option>
                     <option value="50">50</option>
                     <option value="100">100</option> 
                  </select> 
               </div>
         </div>
            <div className="cabeceraTable2 cuperoTable3 mt-3 mb-100">
               <table>
                  <thead>
                     <tr>
                        <th>Descripción</th>
                        <th>Encuestador</th>
                        <th>Tienda</th>
                        <th>Fecha</th>
                        <th>PDF</th>
                        <th>Excel</th>
                     </tr>
                  </thead>
                  <tbody>
                     {checklists &&
                        checklists.map((checklist) => (
                           <ChecklistItem key={checklist.id} checklist={checklist} data={data} startDate={startDate} endDate={endDate} icon="SI" setType={setType} />
                        ))}
                  </tbody>
               </table>
            </div>
            <Pagination count={size} onChange={onChangePage} />
         </div>
      </div>

<div style={{visibility: 'hidden'}}>
      <div id="tablaReporteChecklist" className="container-fluid p-0">
         <div className="col-12">
               <h2 className="titulo text-center">Reporte de Checklist</h2>
            </div>
         <div className="cajaTable ">
            <div className="cabeceraTable2 cuperoTable30 mt-3 mb-100">
               <table>
                  <thead>
                     <tr>
                        <th>Descripción</th>
                        <th>Encuestador</th>
                        <th>Tienda</th>
                        <th>Pregunta</th>
                        <th>Respuesta</th>
                        <th>Fecha</th>
                     </tr>
                  </thead>
                  <tbody>
                     {checklistsFull &&
                        checklistsFull.map((checklist) => (
                           <ChecklistItem key={checklist.id} checklist={checklist} data={data} startDate={startDate} endDate={endDate} icon="NO" />
                        ))}
                  </tbody>
               </table>
            </div>
         </div>
      </div>
      </div>
      </Fragment>
   );
};

export default ListadoCampaing;
