import {
   MEMO_AGREGAR_COMENZAR,
   MEMO_AGREGAR_EXITO, 
   MEMO_AGREGAR_ERROR, 
   MEMO_DESCARGA_COMENZAR, 
   MEMO_DESCARGA_EXITO, 
   MEMO_DESCARGA_ERROR, 
   MEMO_DESCARGA_COMENZAR_TOTAL,
   MEMO_DESCARGA_EXITO_TOTAL, 
   MEMO_DESCARGA_ERROR_TOTAL, 
   MEMO_OBTENER_COMENZAR,
   MEMO_OBTENER_EXITO,
   MEMO_OBTENER_ERROR,
   MEMO_FIRMAR_COMENZAR,
   MEMO_FIRMAR_EXITO,
   MEMO_FIRMAR_ERROR,
   MEMO_YEAR_COMENZAR,
   MEMO_YEAR_EXITO,
   MEMO_YEAR_ERROR,
   MEMO_REPORTE_COMENZAR, 
   MEMO_REPORTE_EXITO, 
   MEMO_REPORTE_ERROR, 
} from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

// ---------- OBTENER AÑOS ----------
export function obtenerYearAction() {
   return async (dispatch) => {
         dispatch(obtenerYear());

         try {
            const tope = 5;
            const initialYear = new Date().getFullYear() - (tope - 1);
            const years = Array.from({length: tope}, (_, i) => i + initialYear);

            dispatch(obtenerYearExito(years))
         } catch (error) {
            dispatch(obtenerYearError());
         }
   }
}

const obtenerYear = () => ({
   type: MEMO_YEAR_COMENZAR,
   payload: true
})

const obtenerYearExito = data => ({
   type: MEMO_YEAR_EXITO,
   payload: data
})

const obtenerYearError = () => ({
   type: MEMO_YEAR_ERROR,
   payload: true
})

// ---------- CARGAR MEMORANDUM ----------
export function cargarMemoAction(data) {
   return async (dispatch) => {
      dispatch(cargarMemo());

      try {
         const response = await clienteAxios.post("/memos", data);
         dispatch(cargarMemoExito(response.data));

         if (response.status === 201) {
            Swal.fire("Correcto", "Se cargaron los memos correctamente", "success");
         } else {
            Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
         }
      } catch (error) {
         const code = error.response.status;
         let mensaje = "";
         if (code === 409) {
            mensaje = error.response.data.message;
         } else {
            mensaje = "Hubo un error, intenta de nuevo";
         }
         dispatch(cargarMemoError(true));

         Swal.fire({icon: "error", title: "Hubo un error", text: mensaje});
      }
   }
}

const cargarMemo = () => ({
   type: MEMO_AGREGAR_COMENZAR
})

const cargarMemoExito = data => ({
   type: MEMO_AGREGAR_EXITO,
   payload: data
})

const cargarMemoError = estado => ({
   type: MEMO_AGREGAR_ERROR,
   payload: estado
})

// ---------- OBTENER MEMORANDUM ----------
export function descargarMemosAction(data, personalId, offset) {
   return async (dispatch) => {
      if (data) {
         let parameters = '';

         if (personalId) {
            dispatch(descargarMemos());
         } else {
            dispatch(descargarMemosTotal());
            parameters = `limit=${data.limit}&offset=${offset}&`;
         }

         if (personalId) {
            parameters = parameters + `personalId=${personalId}`
         }
         if (data.name) {
            parameters = parameters + `name=${data.name}`
         }
         if (data.typeDocument) {
            parameters = parameters + `&typeDocument=${data.typeDocument}`
         }
         if (data.numDocument) {
            parameters = parameters + `&numDocument=${data.numDocument}`
         }

         try {
            const url = personalId ? `/memos/year/${data.year}?personalId=${personalId}` : `/memos/year/${data.year}?${parameters}`;
            const resultado = await clienteAxios.get(url);

            if (personalId) {
               dispatch(descargarMemosExito(resultado.data))
            } else {
               resultado.data.limit = data.limit;
               dispatch(descargarMemosTotalExito(resultado.data))
            }
         } catch (error) {
            if (personalId) {
               dispatch(descargarMemosError());
            } else {
               dispatch(descargarMemosTotalError());
            }
         }
      }
   }
}

const descargarMemos = () => ({
   type: MEMO_DESCARGA_COMENZAR,
   payload: true
})

const descargarMemosExito = data => ({
   type: MEMO_DESCARGA_EXITO,
   payload: data
})

const descargarMemosError = () => ({
   type: MEMO_DESCARGA_ERROR,
   payload: true
})

const descargarMemosTotal = () => ({
   type: MEMO_DESCARGA_COMENZAR_TOTAL,
   payload: true
})

const descargarMemosTotalExito = data => ({
   type: MEMO_DESCARGA_EXITO_TOTAL,
   payload: data
})

const descargarMemosTotalError = () => ({
   type: MEMO_DESCARGA_ERROR_TOTAL,
   payload: true
})

// ---------- FIRMAR MEMORANDUM ----------
export function firmarMemoAction(id) {
   return async (dispatch) => {

      dispatch(firmarMemoComenzar());

      try {
         const response = await clienteAxios.patch(`/memos/signing/${id}`);

         let data = response.data;
         data.id = id;
         data.signing = 1;

         dispatch(firmarMemoExito(data));
         Swal.fire("Correcto", "La boleta se firmó correctamente", "success");
      } catch (error) {
         dispatch(firmarMemoError());
         Swal.fire({icon: "error", title: "Hubo un error", text: "Hubo un error, intenta de nuevo"});
      }
   }
}

const firmarMemoComenzar = () => ({
   type: MEMO_FIRMAR_COMENZAR
})

const firmarMemoExito = data => ({
   type: MEMO_FIRMAR_EXITO,
   payload: data
})

const firmarMemoError = () => ({
   type: MEMO_FIRMAR_ERROR,
   payload: true
})

// ---------- REPORTE MEMOS ----------
export function reporteMemosAction(data) {
   return async (dispatch) => {
         dispatch(reporteMemo());

         let query = '';
         if (data.name) {
            query = query + `name=${data.name}&`
         }
         if (data.typeDocument) {
            query = query + `typeDocument=${data.typeDocument}&`
         }
         if (data.numDocument) {
            query = query + `numDocument=${data.numDocument}&`
         }
         if (data.year) {
            query = query + `year=${data.year}&`
         }
         if (data.month) {
            query = query + `month=${data.month}&`
         }
         if (data.signing) {
            query = query + `signing=${data.signing}&`
         }

         const url = query === '' ? '/memos/report' : '/memos/report?' + query;

         try {
            const resultado = await clienteAxios.get(url);

            dispatch(reporteMemoExito(resultado.data))
         } catch (error) {
            dispatch(reporteMemoError());
         }
   }
}

const reporteMemo = () => ({
   type: MEMO_REPORTE_COMENZAR,
   payload: true
})

const reporteMemoExito = data => ({
   type: MEMO_REPORTE_EXITO,
   payload: data
})

const reporteMemoError = () => ({
   type: MEMO_REPORTE_ERROR,
   payload: true
})