import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Busqueda from "../components/reports/personal/Busqueda";
import ListadoPersonal from "../components/reports/personal/ListadoPersonal";
import TituloTabla from "../components/reports/personal/TituloTabla";
import Loading from "../components/layout/Loading";

const ReportProduct = () => {
   const loading = useSelector((state) => state.personal.loading);

   const [type, setType] = useState("");

   const [data, setData] = useState({
      code: '',
      name: '',
      storeId: '',
      state: '',
      typeDocument: '',
      numDocument: '',
      limit: 10,
      offset: 0
   });

   return (
      <Fragment>
         <Header title={"REPORTE DE PERSONAL"}/>
         <Loading loading={loading} />
         <Busqueda data={data} setData={setData} />
         <TituloTabla data={data} type={type} setType={setType}  />
         <ListadoPersonal data={data} setData={setData} type={type} setType={setType}  />
         <Footer/>
      </Fragment>
   );
};

export default ReportProduct;
