import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Busqueda from "../components/commission/Busqueda";
import ListadoCommission from "../components/commission/ListadoCommission";
import TituloTabla from "../components/commission/TituloTabla";
import Loading from "../components/layout/Loading";
import { obtenerPersonalActivosAction } from "../actions/personalActions";

const Commission = () => {
   const dispatch = useDispatch();

   const loading = useSelector((state) => state.commission.loading);
   const user = useSelector((state) => state.auth.user);

   const [data, setData] = useState({
      personalId: user.role === '05' ? user.personal.id : '',
      year: "",
      month: "",
      state: "",
      limit: 10,
      offset: 0
   });

   useEffect(() => {
      dispatch(obtenerPersonalActivosAction());
   }, []);

   return (
      <Fragment>
         <Header title={"COMISIONES"}/>
         <Loading loading={loading} />
         <Busqueda data={data} setData={setData} />
         <TituloTabla />
         <ListadoCommission data={data} setData={setData} />
         <Footer/>
      </Fragment>
   );
};

export default Commission;
