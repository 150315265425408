import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Form } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

import { crearCampaingAction } from "../../actions/campaingActions";

import "react-datepicker/dist/react-datepicker.css";

registerLocale("es", es);

const NuevoCampaing = ({ handleCloseModal }) => {
   const dispatch = useDispatch();

   const [startDate, setStartDate] = useState("");
   const [endDate, setEndDate] = useState("");
   const [pdf, setPdf] = useState({ data: "" });
   const [data, onHandleData] = useState({
      title: "",
      description: ""
   });

   const { title, description } = data;

   const onChangeData = (e) => {
      if (e.target.name === "title" || e.target.name === "description") {
         onHandleData({
            ...data,
            [e.target.name]: e.target.value,
         });
      } else {
         if (e.target.name === 'pdf') {
            const pdf = {
               data: e.target.files[0],
            };
            setPdf(pdf);
         }
      }
   };

   const onHandleSubmit = (e) => {
      e.preventDefault();

      const formData = new FormData();

      const json = JSON.stringify({
         title,
         description,
         startDate: startDate.toISOString().slice(0, 10),
         endDate: endDate.toISOString().slice(0, 10)
      });

      formData.append(
         "json",
         json
       );
      formData.append(
         "file",
         pdf.data
       );

      dispatch(crearCampaingAction(formData, startDate, endDate));

      e.target.reset();
      handleCloseModal();
   };

   return (
      <Fragment>
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="title" className="c-grisDark mb-0">
                           Capacitación:
                        </label>
                        <input
                           type="text"
                           name="title"
                           className="form-control"
                           placeholder="Ingrese el nombre de la capacitación"
                           value={title}
                           onChange={onChangeData}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-12">
                        <label
                           htmlFor="description"
                           className="c-grisDark mb-0"
                        >
                           Descripción:
                        </label>
                        <textarea
                           name="description"
                           className="form-control"
                           placeholder="Ingrese la descripción"
                           value={description}
                           onChange={onChangeData}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-6">
                        <label
                           htmlFor="startDate"
                           className="c-grisDark mb-0"
                        >
                           Fecha Inicio:
                        </label>
                        <DatePicker
                           name="startDate"
                           dateFormat="dd/MM/yyyy"
                           locale="es"
                           selected={startDate}
                           isClearable
                           placeholderText="DD/MM/AAAA"
                           value={startDate}
                           onChange={(date) => setStartDate(date)}
                           autoComplete="off"
                        />
                     </div>
                     <div className="form-group col-12 col-md-6">
                        <label
                           htmlFor="endDate"
                           className="c-grisDark mb-0"
                        >
                           Fecha Fin:
                        </label>
                        <DatePicker
                           name="endDate"
                           dateFormat="dd/MM/yyyy"
                           locale="es"
                           selected={endDate}
                           isClearable
                           placeholderText="DD/MM/AAAA"
                           value={endDate}
                           onChange={(date) => setEndDate(date)}
                           autoComplete="off"
                        />
                     </div>
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="pdf" className="c-grisDark mb-0">
                           PDF:
                        </label>
                        <input
                           type="file"
                           name="pdf"
                           className="form-control"
                           placeholder="Seleccione un PDF"
                           onChange={onChangeData}
                           required
                        />
                     </div>
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               <button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="submit"
               >
                  Guardar
               </button>
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default NuevoCampaing;
