import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Busqueda from "../components/exam/Busqueda";
import ListadoExam from "../components/exam/ListadoExam";
import TituloTabla from "../components/exam/TituloTabla";
import Loading from "../components/layout/Loading";

const Exam = () => {
   const loading = useSelector((state) => state.exam.loading);

   return (
      <Fragment>
         <Header title={"EXÁMENES"}/>
         <Loading loading={loading} />
         <Busqueda />
         <TituloTabla />
         <ListadoExam />
         <Footer/>
      </Fragment>
   );
};

export default Exam;
