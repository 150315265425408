import React from 'react';
import Switch from "react-switch";

import eyesIcon from "../../assets/images/eye.svg";

const PersonalItem = ({ personal, onHandleChange, handleOpenModal }) => {
  return (
    <tr>
    <td>{personal.user.code}</td>
    <td>{personal.name + " " + personal.lastName}</td>
    <td>{personal.typeDocument === '01' ? 'DNI' : personal.typeDocument === '02' ? 'Pasaporte' : 'Carnet de Extranjeria'}</td>
    <td>{personal.numDocument}</td>
    <td>
       {personal.work && personal.work.length > 0
          ? personal.work[0].address
          : ""}
    </td>
    <td>
       <Switch
          onChange={() => onHandleChange(personal.id)}
          checked={personal.state}
          className="react-switch"
       />
    </td>
    <td>
       <button
          type="button"
          onClick={() => handleOpenModal(personal)}
       >
          <img
             src={eyesIcon}
             className="mr-2 iconTabla"
             alt="Ver"
          />
       </button>
    </td>
 </tr>
  );
};

export default PersonalItem;
