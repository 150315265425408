import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Form } from "react-bootstrap";

import { crearPolicyAction } from "../../actions/policyActions";

const NuevoPolicy = ({ handleCloseModal }) => {
   const dispatch = useDispatch();

   const [pdf, setPdf] = useState({ data: "" });
   const [data, onHandleData] = useState({
      description: "",
   });

   const { description } = data;

   const onChangeData = (e) => {
      if (e.target.name === "description") {
         onHandleData({
            ...data,
            [e.target.name]: e.target.value,
         });
      } else {
         if (e.target.name === 'pdf') {
            const pdf = {
               data: e.target.files[0],
            };
            setPdf(pdf);
         }
      }
   };

   const onHandleSubmit = (e) => {
      e.preventDefault();

      const formData = new FormData();

      const json = JSON.stringify(data);

      formData.append(
         "json",
         json
       );
      formData.append(
         "file",
         pdf.data
       );

      dispatch(crearPolicyAction(formData));

      e.target.reset();
      handleCloseModal();
   };

   return (
      <Fragment>
         <Form onSubmit={onHandleSubmit}>
            <Modal.Body>
               <div className="container">
                  <div className="row">
                     <div className="form-group col-12 col-md-12">
                        <label
                           htmlFor="description"
                           className="c-grisDark mb-0"
                        >
                           Norma:
                        </label>
                        <input
                           type="text"
                           name="description"
                           className="form-control"
                           placeholder="Ingrese la descripción"
                           value={description}
                           onChange={onChangeData}
                           required
                        />
                     </div>
                     <div className="form-group col-12 col-md-12">
                        <label htmlFor="pdf" className="c-grisDark mb-0">
                           PDF:
                        </label>
                        <input
                           type="file"
                           name="pdf"
                           accept="application/pdf"
                           className="form-control"
                           placeholder="Seleccione el PDF"
                           onChange={onChangeData}
                           required
                        />
                     </div>
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               <button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="submit"
               >
                  Aceptar
               </button>
            </Modal.Footer>
         </Form>
      </Fragment>
   );
};

export default NuevoPolicy;
