import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form } from "react-bootstrap";
import SurveyTitle from "./SurveyTitle";
import SurveyQuestion from "./SurveyQuestion";
import { useInputValue } from "../../hooks";
import Question from "../../models/Question";
import ListController from "../../controllers/ListController";

import { crearChecklistAction, obtenerChecklistQuestionsAction } from '../../actions/checklistActions';

import styles from "../../css/checklist.css";

export default function EditarChecklist({ currentChecklist, handleCloseModal }) {
   const dispatch = useDispatch();

   const listQuestions = useSelector((state) => state.checklist.questions);

   const [title, handleChangeTitle] = useInputValue(currentChecklist.description);
   const [questions, setQuestions] = useState([]);

   const listController = new ListController(questions, setQuestions);

   const onHandleSubmit = (e) => {
      e.preventDefault();

      dispatch(crearChecklistAction({description: title, id: currentChecklist.id, questions: listController.array}));

      e.target.reset();
      handleCloseModal();
   };

   useEffect(() => {
      dispatch(obtenerChecklistQuestionsAction(currentChecklist.id));
   }, []);

   useEffect(() => {
      if (listQuestions && listQuestions.length > 0) {
         const list = listQuestions.map(question => (new Question({
            text: question.description,
            options: question.type === '03' ? [] : question.answer.map(answer => (answer.description)),
            type: question.type,
         })));

         setQuestions(list);
      }
   }, [listQuestions]);
   
   return (
      <div id="modal-checklist" className={styles.container}>
         <SurveyTitle title={title} handleChangeTitle={handleChangeTitle} />
         <Form onSubmit={onHandleSubmit}>
            <ol>
               {questions.map((question, i) => (
                  <SurveyQuestion
                     key={question.id}
                     question={question}
                     setQuestion={(question) => listController.set(i, question)}
                     removeQuestion={() => listController.remove(i)}
                     moveQuestionUp={() => listController.moveUp(i)}
                     moveQuestionDown={() => listController.moveDown(i)}
                  />
               ))}
            </ol>
            <button
               type="button"
               onClick={() => listController.add(new Question())}
               className={styles.btnChecklist}
            >
               <i className="fas fa-plus icon" /> Agregar Pregunta
            </button>
            <Modal.Footer>
               <button
                  className="btn-grisDark2 btn-Solidez mt-4"
                  type="reset"
                  onClick={handleCloseModal}
               >
                  Cancelar
               </button>
               <button
                  className="btn-grisDark text-white btn-Solidez mt-4"
                  type="submit"
               >
                  Aceptar
               </button>
            </Modal.Footer>
         </Form>
      </div>
   );
}
